import { Alert, Button, Column, FormGroup, Row } from '@dabapps/roe';
import { Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { FormProps, reduxForm } from 'redux-form';
import { RESET_PASSWORD, resetPassword } from '../../actions/auth';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import { IAsyncActionSet } from '../../utils';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import GroupField from '../forms/group-field';

export interface IFormData {
  new_password1: string;
  new_password2: string;
}

interface IProps {
  params: {
    token: string;
    uid: string;
  };
  response: IResponseStatus;
  createNewPassword: boolean;
  onSubmit(data: IFormData): void;
}

export class SetPasswordForm extends React.PureComponent<any, void> {
  public render() {
    const { response, params, createNewPassword } = this.props;
    const { token, uid } = params;
    const { isLoading, hasSucceeded, formErrors } = response;
    const messages = defineMessages({
      newpassword1: {
        description: '(New) Password',
        id: createNewPassword
          ? 'generic.password'
          : 'pages.change-password.new',
      },
      newpassword2: {
        description: 'Confirm (New) Password',
        id: createNewPassword
          ? 'generic.confirm_password'
          : 'pages.change-password.confirm',
      },
    });
    return (
      <form
        onSubmit={this.props.handleSubmit((data: IFormData) =>
          this.props.onSubmit(
            uid,
            token,
            data.new_password1,
            data.new_password2
          )
        )}
      >
        <hr />

        <h1>
          <FormattedMessage
            id={
              createNewPassword
                ? 'generic.choose_password'
                : 'login.reset-password'
            }
          />
        </h1>

        <b>
          <FormattedMessage id="pages.change-password.note" />:{' '}
        </b>
        <FormattedMessage id="pages.change-password.help" />

        <Row>
          <Column sm={12} md={5} lg={5}>
            <GroupField
              block
              label={messages.newpassword1}
              name="new_password1"
              type="password"
              disabled={isLoading}
              formErrors={formErrors}
            />

            <GroupField
              block
              label={messages.newpassword2}
              name="new_password2"
              type="password"
              disabled={isLoading}
              formErrors={formErrors}
            />
          </Column>
        </Row>

        <hr />

        <FormGroup block>
          {hasSucceeded && (
            <Alert className="success">
              <FontAwesome.IconCheck className={'icon-small'} />
              {' '}
              <FormattedMessage id="pages.reset-password-success" />
              {' '}
              <Link to="/">
                <FormattedMessage id="pages.reset-password-return-1" />
              </Link>
              <FormattedMessage id="pages.reset-password-return-2" />
            </Alert>
          )}

          {this.hasTokenError(formErrors) && (
            <Row>
              <Column sm={12} md={5} lg={5}>
                <Alert className="error">
                  <FontAwesome.IconExclamationTriangle
                    className={'icon-small'}
                  />
                  <FormattedMessage id="pages.reset-password.token-error" />
                </Alert>
              </Column>
            </Row>
          )}

          <Row>
            <Column sm={12} md={5} lg={3}>
              <Button className="primary" type="submit">
                <FormattedMessage
                  id={
                    createNewPassword
                      ? 'generic.save_password'
                      : 'pages.change-password.save'
                  }
                />
              </Button>
            </Column>
          </Row>
        </FormGroup>

        <Row>
          <Column sm={12} md={5} lg={4}>
            <FormErrorsRenderer formErrors={formErrors} errorKey="errors" />
          </Column>
        </Row>
      </form>
    );
  }
  private hasTokenError(formErrors: Map<any, any>) {
    if (formErrors) {
      return formErrors.has('token');
    }
    return false;
  }
}

export default reduxForm({
  form: 'reset-password-form',
})(SetPasswordForm);
