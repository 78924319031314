import {
  Column,
  ContentBox,
  ContentBoxHeader,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import * as _ from 'underscore';

import { ICollection, ICollectionOptions } from '../../collections/types';
import Pagination from '../../components/content/pagination';
import Loading from '../loading';
import { IBaseTableProps, IColumnData } from './simple-table';
import SortIcon from './sort-icon';

const { IconSort, IconSortAsc, IconSortDesc } = FontAwesome;

interface IClickableProps {
  clickable: true;
  linkTo: string;
}

interface INotClickableProps {
  clickable?: false | never;
  linkTo?: never;
}

type Props<TData> = IBaseTableProps<TData> & (IClickableProps | INotClickableProps) & {
  collection: ICollection<TData>;
  pageSize: number;
  preventInitialRequest?: boolean;
  getCollection(options: ICollectionOptions): void;
}

export default class CollectionTable<
  TData extends { id: string, user: any }
> extends React.PureComponent<Props<TData>, void> {
  public constructor(props: Props<TData>) {
    super(props);

    this.onPaginationClick = this.onPaginationClick.bind(this);
  }

  public componentWillMount() {
    if (!this.props.preventInitialRequest) {
      const options = {
        ordering: this.props.collection.ordering,
        page: this.props.collection.page,
        pageSize: this.props.pageSize,
        reverseOrdering: this.props.collection.reverseOrdering,
      };

      this.props.getCollection(options);
    }
  }

  public onPaginationClick(
    nextPage: number
  ): void {
    const options = {
      filters: this.props.collection.filters,
      ordering: this.props.collection.ordering,
      page: nextPage,
      pageSize: this.props.pageSize,
      reverseOrdering: this.props.collection.reverseOrdering,
    };

    this.props.getCollection(options);
  }

  public sortBy(key: string) {
    const { ordering, reverseOrdering = false } = this.props.collection;

    const options = {
      filters: this.props.collection.filters,
      ordering: key,
      page: this.props.collection.page,
      pageSize: this.props.pageSize,
      reverseOrdering: ordering === key ? !reverseOrdering : reverseOrdering,
    };

    this.props.getCollection(options);
  }

  public render() {
    const {
      loading,
      headers,
      fixRowHeaders,
      collection,
      pageSize,
      clickable,
      linkTo,
    } = this.props;
    const firstColumnWidth = headers[0].width || 100;
    const tableProps = fixRowHeaders
      ? {
          fixRowHeaders,
          rowHeaderWidth: firstColumnWidth,
        }
      : {};
    return (
      <Row>
        <Column>
          <Table className="class-table" fill {...tableProps}>
            {!loading && this.renderHeaders()}
            <TableBody>
              {loading || !collection.results.count()
                ? <tr>
                    <td>
                      {
                        loading ? <Loading /> : 'No results'
                      }
                    </td>
                  </tr>
                : collection.results.map((collectionItem) => {
                    const first = _.first(headers);
                    const rest = _.rest(headers);
                    if (!first) {
                      return null;
                    }

                    return (
                      <TableRow key={collectionItem.id}>
                        <TableHeader width={first.width}>
                          {first.content(collectionItem)}
                        </TableHeader>
                        {rest.map((header) =>
                          <TableCell
                            key={header.headerLabel}
                            width={header.width}
                          >
                          {header.isButton && header.isButtonDisabled
                            ?
                            <button
                              className='tertiary'
                              disabled={header.isButtonDisabled(collectionItem)}
                              onClick={() => header.onButtonClick && header.onButtonClick(collectionItem)}
                            >
                              {header.content(collectionItem)}
                            </button>
                            :
                            (clickable && collectionItem.user
                              ?
                              <Link to={`${linkTo}${collectionItem.id}/`} >
                                {header.content(collectionItem)}
                              </Link>
                              :
                              <div>{header.content(collectionItem)}</div>
                            )
                          }
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <Pagination
            itemCount={collection.count}
            pageSize={pageSize}
            currentPage={collection.page}
            changePage={this.onPaginationClick}
          />
        </Column>
      </Row>
    );
  }

  private renderHeaders() {
    const { headers, collection } = this.props;

    return (
      <TableHead>
        <TableRow>
          {headers.map((header, index) =>
            <TableHeader key={header.headerLabel} width={header.width}>
              {header.headerLabel}
              {header.sortable &&
                <SortIcon
                  orderingKey={header.key}
                  ordering={collection.ordering}
                  reverseOrdering={collection.reverseOrdering}
                  onClick={this.sortBy.bind(this, header.key)}
                />}
            </TableHeader>
          )}
        </TableRow>
      </TableHead>
    );
  }
}
