import { Button, FormGroup, ModalFooter } from '@dabapps/roe';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FORGOT_PASSWORD, forgotPassword } from '../../actions/auth';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import GroupField from '../forms/group-field';

interface IProps {
  response: IResponseStatus;
  onSubmit(data: IFormData): void;
  forgotPassword(email: string): void;
  onLoginClick(): void;
}

interface IFormData {
  email: string;
}

export class ResetPasswordForm extends React.PureComponent<any, void> {
  public render() {
    const messages = defineMessages({
      email: {
        description: 'Reset password label',
        id: 'login.email',
      }
    });

    return (
      <form onSubmit={this.props.handleSubmit((data: IFormData) => this.props.forgotPassword(data.email))}>
        {!this.props.response.hasSucceeded && (
          <div>
          <p>
          <FormattedMessage id='login.reset-password-description' />
          </p>
          <GroupField
            block
            label={messages.email}
            name="email"
            type="email"
            disabled={this.props.response.isLoading}
            formErrors={this.props.response.formErrors}
           />

          <FormGroup block>
            <Button className="primary" type="submit">
              <FormattedMessage id='login.reset-password'/>
            </Button>
          </FormGroup>
          </div>
        )}

          {this.props.response.hasSucceeded && (
            <div><h2><strong><FormattedMessage id="login.reset-check-inbox"/></strong></h2>
            <p><FormattedMessage id="login.reset-password-email-sent"/></p></div>
          )}

        <ModalFooter>
          <p className="text-align-center">
            <a onClick={this.props.onLoginClick}>
              <FormattedMessage id='login.reset-password-login'/>
            </a>
          </p>
        </ModalFooter>
      </form>
    );
  }
}

export function mapStateToProps({responses}: IStore, existing: Partial<IProps>) {
  return {
    ...existing,
    response: getResponseStatus(responses, FORGOT_PASSWORD)
  }
}

export default connect(mapStateToProps, { forgotPassword })(
  reduxForm({
    form: 'reset',
  })(ResetPasswordForm)
);
