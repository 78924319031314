import * as React from 'react';
import { FormProps, reduxForm } from 'redux-form';
import { FormErrors } from '../../../utils';
import Criteria from './criteria';
import SitesSelection from './sites-selection';

export const FORM_NAME = 'study-search-form';

export type CollapseIds = 'criteria' | 'sites';

interface IOwnProps {
  hideCriteria: boolean;
  criteriaSummaryText: string;
  siteSummaryText: string;
  formErrors: FormErrors;
  openCollapse: CollapseIds | null;
  onSubmit(formData: FormData): void;
  toggleCriteriaCollapse(event: React.MouseEvent<HTMLSpanElement>): void;
  toggleSitesCollapse(event: React.MouseEvent<HTMLSpanElement>): void;
}

type Props = IOwnProps & FormProps<FormData, any, any>;

export class SearchForm extends React.PureComponent<Props, {}> {
  public render () {
    const {
      hideCriteria,
      openCollapse,
      toggleCriteriaCollapse,
      toggleSitesCollapse,
      handleSubmit,
      formErrors,
    } = this.props;

    return (
      <form className="study-search-form" onSubmit={handleSubmit}>
        {!hideCriteria &&
          <Criteria
            criteriaSummaryText={this.props.criteriaSummaryText}
            openCollapse={openCollapse}
            toggleCollapse={toggleCriteriaCollapse}
            formErrors={formErrors}
          />
        }

        <SitesSelection
          siteSummaryText={this.props.siteSummaryText}
          openCollapse={openCollapse}
          toggleCollapse={toggleSitesCollapse}
          formErrors={formErrors}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SearchForm);
