import { AxiosResponse } from 'axios';
import { IStore } from '../store/';
import {
  apiRequest,
  IAsyncActionSet,
  RequestStates,
} from '../utils';

export type UrlMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH';

export interface IAction<PayloadT, MetaT> {
  type: string;
  payload?: PayloadT;
  error?: boolean;
  meta?: MetaT;
}

export interface IActionWithPayload<P, M> extends IAction<P, M> {
  payload: P;
}

export type IActionNoPayload = IAction<undefined, undefined>;

export const REQUEST_STATE = 'REQUEST_STATE';
export function setRequestState(actionSet: IAsyncActionSet, state: RequestStates, data: any, tag?: string) {
  return {
    payload: {
      actionSet,
      data,
      state,
      tag
    },
    type: REQUEST_STATE,
  }
}

export const RESET_REQUEST_STATE = 'RESET_REQUEST_STATE';
export function resetRequestState(actionSet: IAsyncActionSet, tag?: string) {
  return {
    payload: {
      actionSet,
      tag
    },
    type: RESET_REQUEST_STATE,
  }
}

export type TActionAny = IAction<any, any>;
export type Thunk<T> = (dispatch: DispatchCallback, getState: GetStateCallback) => Promise<T>;
export type AsyncAction = Thunk<AxiosResponse | null> | Promise<AxiosResponse | null>;
export type DispatchCallback = (action: TActionAny | AsyncAction) => void
export type GetStateCallback = () => IStore;

export interface IRequestMetaData {
  itemId?: string;
  collectionName?: string;
  shouldAppend?: boolean;
  ordering?: string;
  location?: google.maps.LatLng;
}

export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export function setUploadProgress(event: ProgressEvent, fileNumber: number, totalFiles: number) {
  const progress = Math.round((event.loaded * 100) / event.total);
  return {
    payload: {
      fileNumber,
      progress,
      totalFiles,
    },
    type: SET_UPLOAD_PROGRESS,
  };
}

function isResponse (response?: any): response is AxiosResponse {
  return typeof response === 'object' &&
    response.hasOwnProperty('data') &&
    response.hasOwnProperty('status') &&
    response.hasOwnProperty('config');
}

export function metaWithResponse (meta: IRequestMetaData, response?: AxiosResponse) {
  if (!isResponse(response)) {
    return meta;
  }

  return {...meta, response};
}

export function dispatchGenericRequest(
  actionSet: IAsyncActionSet,
  url: string,
  method: UrlMethod,
  data?: any,
  tag?: string,
  metaData: IRequestMetaData = {},
  preserveOriginal?: boolean,
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    const meta: IRequestMetaData = {...metaData, tag};

    dispatch({ type: actionSet.REQUEST, meta, payload: { preserveOriginal } });
    dispatch(setRequestState(actionSet, 'REQUEST', null, tag));

    return apiRequest(url, method, data)
      .then((response) => {
        dispatch({
          type: actionSet.SUCCESS,
          payload: response.data,
          meta: metaWithResponse(meta, response)
        });
        dispatch(setRequestState(actionSet, 'SUCCESS', response.data, tag));
        return response;
      })
      .catch((error) => {
        console.error(error);  // tslint:disable-line:no-console
        const errorData = error && error.response && error.response.data;

        dispatch({
          type: actionSet.FAILURE,
          payload: errorData,
          meta: metaWithResponse(meta, error && error.response)
        });
        dispatch(setRequestState(actionSet, 'FAILURE', errorData, tag));
        return Promise.reject(error);
      });
  };
}
