const PREFIXES = ['webkit', 'moz', 'ms', 'o'];
const MATCHES_NEEDS_PREFIXING = /\b(transform|transition)\b/g;

const prefixKey = (key: string, prefix: string) => {
  return key.replace(MATCHES_NEEDS_PREFIXING, (subString) => {
    return `${prefix}${subString.charAt(0).toUpperCase()}${subString.substring(1).toLowerCase()}`;
  });
};

const prefixValue = (keyOrValue: number | string | null, prefix: string) => {
  if (typeof keyOrValue === 'string') {
    return keyOrValue.replace(MATCHES_NEEDS_PREFIXING, (subString) => {
      return `-${prefix}-${subString}`;
    });
  }

  return keyOrValue;
};

type CSSStyles = Partial<Omit<CSSStyleDeclaration, 'length' | 'parentRule'>>;

const applyStyles = (element: HTMLElement, styles: CSSStyles): void => {
  for (const key in styles) {
    if (styles.hasOwnProperty(key)) {
      const value = styles[key as keyof CSSStyles];
      element.style[key as keyof CSSStyles] = value;

      if (MATCHES_NEEDS_PREFIXING.test(key) || MATCHES_NEEDS_PREFIXING.test(value)) {
        PREFIXES.forEach((prefix) => {
          const prefixedKey = prefixKey(key, prefix);
          const prefixedValue = prefixValue(value, prefix);

          element.style[prefixedKey as keyof CSSStyles] = prefixedValue;
        });
      }
    }
  }
};

export const getErrorClass = () => {
  if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
    return null;
  }

  return class GoogleMapError extends google.maps.OverlayView {
    private anchor: HTMLElement;
    private location: google.maps.LatLng;
    private error: string;

    public constructor (location: google.maps.LatLng, error: string) {
      super(location);

      this.error = error;
      this.location = location;
      this.anchor = document.createElement('div');
      this.anchor.textContent = this.error;

      applyStyles(
        this.anchor,
        {
          position: 'absolute',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '2px',
          padding: '5px',
          border: '1px solid #FF0000',
          fontSize: '14px',
          color: '#FF0000',
          opacity: '0',
          transform: 'translate(-50%, -100%) scale(0, 0)',
          transition: 'ease-in-out 0.2s transform, ease-in-out 0.2s opacity',
        }
      );
    }

    public draw () {
      const location = this.getProjection().fromLatLngToDivPixel({
        lat: () => this.location.lat,
        lng: () => this.location.lng,
      });

      applyStyles(
        this.anchor,
        {
          left: location.x + 'px',
          top: location.y + 'px',
        }
      );
    }

    public onAdd () {
      this.getPanes().floatPane.appendChild(this.anchor);

      window.setTimeout(this.fadeIn, 50);
    }

    public onRemove () {
      if (this.anchor.parentElement) {
        this.anchor.parentElement.removeChild(this.anchor);
      }
    }

    private fadeIn = () => {
      applyStyles(
        this.anchor,
        {
          transform: 'translate(-50%, -100%) scale(1, 1)',
          opacity: '1',
        }
      );

      window.setTimeout(this.fadeOut, 3000);
    }

    private fadeOut = () => {
      applyStyles(
        this.anchor,
        {
          transition: 'ease-in-out 1s opacity',
          opacity: '0',
        }
      );

      window.setTimeout(this.unmount, 1000);
    }

    private unmount = () => {
      this.setMap(null);
    }
  }
}
