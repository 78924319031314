'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconBarChart (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconBarChart'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconBarChart'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(128.81, 1791.82) scale(1, -1)',
          d: 'M640 640v-512h-256v512h256zM1024 1152v-1024h-256v1024h256zM2048 0v-128h-2048v1536h128v-1408h1920zM1408 896v-768h-256v768h256zM1792 1280v-1152h-256v1152h256z'
        }
      )
    );
  };

})();
