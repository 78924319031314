import * as classNames from 'classnames';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import * as _ from 'underscore';
import { clearSimpleComponentState, setSimpleComponentState } from '../actions/actions';
import { IStore } from '../store';
import { clickOutside } from '../utils';

const { IconCaretSquareODown } = FontAwesome;

interface IExternalProps {
  componentKey: string;
  message?: string;
  className?: string;
}

interface IProps extends IExternalProps {
  stateOpen: boolean;
  componentKey: string;
  setSimpleComponentState: typeof setSimpleComponentState;
  clearSimpleComponentState: typeof clearSimpleComponentState;
}

export class Dropdown extends React.PureComponent<IProps, void> {
  private element: HTMLElement;
  private anchors: NodeListOf<HTMLAnchorElement>;

  public componentDidMount () {
    if (this.element) {
      this.anchors = this.element.getElementsByTagName('a');
    }
    window.addEventListener('click', this.onWindowClick);
    if (this.element && this.props.stateOpen && this.anchors) {
      _.forEach(this.anchors, (anchor) => {
        anchor.addEventListener('click', this.closeDropdown);
      });
    }
  }

  public componentDidUpdate () {
    if (this.props.stateOpen && this.anchors) {
      _.forEach(this.anchors, (anchor) => {
        anchor.addEventListener('click', this.closeDropdown);
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
    this.props.clearSimpleComponentState(this.props.componentKey);
    if (this.anchors) {
      _.forEach(this.anchors, (anchor) => {
        anchor.removeEventListener('click', this.closeDropdown);
      });
    }
  }

  public render() {
    const { message, children, className } = this.props;
    return (
      <span
        ref={(element) => this.element = element}
        className={classNames('dropdown-wrapper display-inline-block', className)}
      >
        {/* display-inline-block class required to prevent weird reflow issues */}
        <span className="dropdown-toggle-span" onClick={this.toggleDropdown}>
          {message && (
            <FormattedMessage id={message}/>
          )}
          <IconCaretSquareODown className="icon-large margin-left-small secondary"/>
        </span>
        <CSSTransitionGroup
          transitionName="dropdown-transition"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}
        >
          {
            this.props.stateOpen ? (
              <span className="dropdown-popup">
                {children}
              </span>
            ) : null
          }
        </CSSTransitionGroup>
      </span>
    );
  }

  private onWindowClick = (event: MouseEvent) => {
    clickOutside(this.element, event.target as HTMLElement, this.closeDropdown);
  }

  private toggleDropdown = () => {
    this.props.setSimpleComponentState(this.props.componentKey, !this.props.stateOpen);
  }

  private closeDropdown = () => {
    this.props.setSimpleComponentState(this.props.componentKey, false);
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  return {
    ...props,
    stateOpen: state.simpleComponentState.get(props.componentKey, false)
  }
}

export default connect(mapStateToProps, {setSimpleComponentState, clearSimpleComponentState})(Dropdown);
