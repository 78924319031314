import { fromJS } from 'immutable';
import * as React from 'react';

import GenericList from '^/components/chadmin/generic-list';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS = fromJS([
  {
    name: ['name'],
    display_name: 'Name',
    type: 'detail',
    route: DETAIL_ROUTE,
    sortable: false,
  },
  {
    name: ['ad_type_display', 'name'],
    display_name: 'Type',
    type: 'text',
  },
  {
    name: ['start_date'],
    display_name: 'Start date',
    type: 'date',
  },
  {
    name: ['end_date'],
    display_name: 'End date',
    type: 'date',
  },
  {
    name: ['cost'],
    display_name: 'Cost',
    type: 'float',
    max_length: 10,
  },
  {
    name: ['active'],
    display_name: 'is Active?',
    type: 'boolean',
  }
]);

const MESSAGES = {
  title: 'generic.adverts',
  create: 'drivers.adverts.create',
};

const COLLECTION_NAME = 'ADVERTS';

// tslint:disable-next-line:no-unused-variable
type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, void> {
  public render() {
    return (
      <GenericList
        canCreate={this.props.permissions.canCreate}
        messages={MESSAGES}
        collectionColumns={LIST_COLUMNS}
        collectionName={COLLECTION_NAME}
        collectionKey={'drivers/adverts'}
        defaultFilters={fromJS({include_inactive: false})}
      />
    );
  }
}
