'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconChevronLeft (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconChevronLeft'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconChevronLeft'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(512.81, 1791.82) scale(1, -1)',
          d: 'M1171 1235l-531 -531l531 -531q19 -19 19 -45t-19 -45l-166 -166q-19 -19 -45 -19t-45 19l-742 742q-19 19 -19 45t19 45l742 742q19 19 45 19t45 -19l166 -166q19 -19 19 -45t-19 -45z'
        }
      )
    );
  };

})();
