import { List, Map } from "immutable";
import * as React from "react";
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux";
import { Link } from 'react-router';
import { FormProps, formValueSelector, reduxForm } from 'redux-form';
import { Animation, Easing } from 'slik';
import { getCollectionByName } from "../../../../collections/reducers";
import { IPermissionsProps } from '../../../../permissions/config/default-permissions';
import { collectionsModule } from "../../../../reducers/collections";
import { hasFailed, hasSucceeded } from '../../../../responses';
import { IStore } from "../../../../store";
import {
  DisplayUnit,
  Hba1cUnit,
  IAdvertRecord,
  ICallOutcomeRecord,
  IContactTypeRecord,
  IFormMeasurements,
  ITelephoneRecord,
  IUserRecord
} from "../../../../store/data-types";
import { disableSubmitOnEnter, FormErrors } from "../../../../utils";
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import PageHeader from "../../../page-header";
import ProfileLogActions from '../../profile-logs/profile-log-actions';
import { getHba1cOrPercentage } from '../../utils';
import { IDataProps } from "../form-fields/edit-drivers-details-form-fields";
import EditDriversDetailsFormFields from '../form-fields/edit-drivers-details-form-fields';
import EditMedicalDetailsFormFields from '../form-fields/edit-medical-details-form-fields';
import EditPersonalDetailsFormFields from '../form-fields/edit-personal-details-form-fields';
import EditVolunteerMedicationComponent from '../form-fields/edit-volunteer-medication-component';
const { actions: { getCollection, getAllCollection} } = collectionsModule;
import { ADD_TO_COLLECTION } from '../../../../collections/actions';
import CopyToClipboard from '../../../../components/copy-to-clipboard';

import { Button, FormGroup } from '@dabapps/roe';

const SCROLL_ANIMATION = new Animation({
  duration: 500,
  to: 0,
});

const MESSAGES = {
  medical: "volunteer_profile.medical",
  drivers: "generic.drivers",
  personal: "generic.personal_details",
  medication: "generic.medications",
  copy_id: "generic.copy_id"
};

const FORM_NAME = 'volunteer-details-form';

const CALL_OUTCOMES_COLLECTION_NAME: string = 'call_outcomes';
const CONTACT_TYPE_COLLECTION_NAME: string = 'contact_types';

export interface IFormData {
  title: string,
  first_name: string | undefined,
  last_name: string | undefined,
  email: string,
  volunteer: {
    availability: string,
    comments: string,
    gender: string,
    birth_date: string | null,
    telephone_numbers: List<ITelephoneRecord>,
    address_1: string,
    address_2: string,
    address_3: string,
    city: string,
    county: string,
    postcode: string,
    advert_type: string;
    advert_source: string | null;
    diabetes_type: string;
    month_diagnosed: string;
    year_diagnosed: string | null;
    hbA1c_result: number | null;
    hbA1c_result_display_unit: Hba1cUnit;
    height: IFormMeasurements;
    weight: IFormMeasurements;
    motivations: List<string>;
  }
}

interface IStateProps {
  advertOptions: IDataProps[];
  selectedAdvertType: IDataProps | string;
  errors?: FormErrors | null;
  initialValues?: IFormData;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  hba1cResultValue: number;
  selectedHba1cUnit: Hba1cUnit;
  selectedHeightUnit: DisplayUnit;
  selectedWeightUnit: DisplayUnit;
  callOutcomesList: List<ICallOutcomeRecord>;
  contactTypeList: List<IContactTypeRecord>;
}

interface IExternalProps {
  userProfile?: IUserRecord;
  titleChoices: Array<{value: string, label: string}>;
  telephoneErrors: Map<string, List<string>>;
  motivationErrors: Map<string, List<string>>;
  permissions: Partial<IPermissionsProps>;
  item: IUserRecord;
  itemIsPending: boolean;
  nonFieldErrors: Map<string, List<string>>;
  advertTypesOptions: IDataProps[];
  onSubmit(data: IFormData): void;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
  getAllCollection: typeof getAllCollection;
}

type IProps = FormProps<IFormData, void, void> & IDispatchProps & IExternalProps & IStateProps

class EditVolunteerDetailForm extends React.PureComponent<IProps, {}> {

  public constructor(props: IProps) {
    super(props);

    this.scrollStep = this.scrollStep.bind(this);
  }

  public componentWillMount() {
    this.getLabelsAndTypesCollections();
    SCROLL_ANIMATION
      .reset()
      .bind('update', this.scrollStep);
  }

  public componentWillUnmount() {
    SCROLL_ANIMATION
      .reset()
      .unbind('update', this.scrollStep);
  }

  public scrollStep(value: number) {
    window.scrollTo(0, value);
  }

  public scrollToTop() {
    SCROLL_ANIMATION
      .from(window.scrollY)
      .start();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.selectedAdvertType !== this.props.selectedAdvertType) {
      let adType;
      if (typeof nextProps.selectedAdvertType === 'string') {
        adType = nextProps.selectedAdvertType;
      } else {
        adType = nextProps.selectedAdvertType.value;
      }
      this.props.getCollection(
        "drivers/adverts",
        {
          pageSize: 150,
          filters: adType ? Map({ ad_type: adType }) : Map<string, string>(),
        },
        "drivers/adverts"
      );
    }

    if (
      (this.props.itemHasSucceeded !== nextProps.itemHasSucceeded && nextProps.itemHasSucceeded) ||
      (this.props.itemHasFailed !== nextProps.itemHasFailed && nextProps.itemHasFailed)
    ) {
      this.scrollToTop();
    }

    if (this.props.selectedHba1cUnit && this.props.selectedHba1cUnit !== nextProps.selectedHba1cUnit) {
      this.changeHba1cResultFormValue();
    }
  }

  public render() {
    const {
      item,
      handleSubmit,
      advertTypesOptions,
      errors,
      itemIsPending,
      advertOptions,
      titleChoices,
      nonFieldErrors,
      telephoneErrors,
      motivationErrors,
      permissions,
      selectedHba1cUnit,
      selectedHeightUnit,
      selectedWeightUnit,
      userProfile,
      callOutcomesList,
      contactTypeList
    } = this.props;

    return (
      <form onSubmit={handleSubmit} onKeyDown={disableSubmitOnEnter} >
        <ProfileLogActions
          contactTypeList={contactTypeList}
          callOutcomesList={callOutcomesList}
          userProfile={userProfile}
          componentKey="profile-log-actions"
        />
        <FormErrorsRenderer formErrors={errors} errorKey='profile_logs' />
        <PageHeader className="margin-top-base" header={<FormattedMessage id={MESSAGES.personal} />}>
          <span className="xs-float-right header-user-id">
            {`${item.volunteer ? item.volunteer.id : ''} - `}
            <CopyToClipboard
              value={item.volunteer ? item.volunteer.id : ''}
              title="Click to copy volunteer's ID"
              className="secondary"
            >
              <FormattedMessage id={MESSAGES.copy_id} />
            </CopyToClipboard>
          </span>
        </PageHeader>
        <EditPersonalDetailsFormFields
          item={item}
          errors={errors}
          telephoneErrors={telephoneErrors}
          titleChoices={titleChoices}
          permissions={permissions}
          formName={FORM_NAME}
        />
        <PageHeader header={<FormattedMessage id={MESSAGES.drivers} />} />
        <EditDriversDetailsFormFields
          advertOptions={advertOptions}
          advertTypesOptions={advertTypesOptions}
          errors={errors}
          motivationErrors={motivationErrors}
        />
        <PageHeader className="margin-top-base" header={<FormattedMessage id={MESSAGES.medical} />} />
        <EditMedicalDetailsFormFields
          item={item}
          errors={errors}
          selectedHba1cUnit={selectedHba1cUnit}
          selectedHeightUnit={selectedHeightUnit}
          selectedWeightUnit={selectedWeightUnit}
        />
        <PageHeader className="margin-top-base" header={<FormattedMessage id={MESSAGES.medication} />} />
        <FormErrorsRenderer formErrors={errors} errorKey="medications"/>
        <EditVolunteerMedicationComponent volunteerId={item.volunteer ? item.volunteer.id : ''}/>
        <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors"/>
        <FormGroup className="form-controls content-right">
          <Link to="/app/volunteers/" className="cancel-link">
            <FormattedMessage id='generic.cancel'/>
          </Link>
          <Button type="submit" className="button primary" disabled={itemIsPending}>
            <FormattedMessage id='volunteer_profile.save' />
          </Button>
        </FormGroup>
      </form>
    )
  }

  private getLabelsAndTypesCollections() {
    this.props.getAllCollection('profile-logs/call-outcomes', {}, CALL_OUTCOMES_COLLECTION_NAME);
    this.props.getAllCollection('profile-logs/contact-types', {}, CONTACT_TYPE_COLLECTION_NAME);
  }

  private changeHba1cResultFormValue() {
    const { change, hba1cResultValue, selectedHba1cUnit } = this.props;
    const convertedValue = getHba1cOrPercentage(hba1cResultValue, selectedHba1cUnit);
    if (change && convertedValue) {
      change('volunteer.hbA1c_result', convertedValue.toFixed(1));
    }
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IStateProps & IExternalProps {
  const { collections, responses } = state;
  const selector = formValueSelector(FORM_NAME);
  const adverts = getCollectionByName(
    collections["drivers/adverts"],
    "drivers/adverts"
  ).results;
  const contactTypeList = getCollectionByName(
    collections['profile-logs/contact-types'],
    CONTACT_TYPE_COLLECTION_NAME
  ).results;
  const callOutcomesList = getCollectionByName(
    collections['profile-logs/call-outcomes'],
    CALL_OUTCOMES_COLLECTION_NAME
  ).results;

  return {
    ...props,
    advertOptions: adverts.map((entry: IAdvertRecord) => {
      return {
        value: entry.id,
        label: entry.name
      };
    }).toArray(),
    selectedAdvertType: selector(state, 'volunteer.advert_type'),
    hba1cResultValue: selector(state, 'volunteer.hbA1c_result'),
    selectedHba1cUnit: selector(state, 'volunteer.hbA1c_result_display_unit'),
    selectedHeightUnit: selector(state, 'volunteer.height.display_unit'),
    selectedWeightUnit: selector(state, 'volunteer.weight.display_unit'),
    itemHasFailed: hasFailed(responses, ADD_TO_COLLECTION, 'users/volunteers'),
    itemHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, 'users/volunteers'),
    callOutcomesList,
    contactTypeList
  };
}

const ConnectedVolunteerForm = reduxForm({
  form: FORM_NAME
})(EditVolunteerDetailForm)

export default connect(
  mapStateToProps,
  {
    getCollection,
    getAllCollection,
  }
)(ConnectedVolunteerForm);
