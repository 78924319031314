import * as React from 'react';

import * as moment from 'moment';
import DatePicker from 'react-datepicker';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

import {
  FORMAT_DATE_BACKEND,
  formatBackendDate,
  formatBackendDateTime,
  formatDate,
} from '../../../utils';

interface IFieldDateProps {
  isDateTime?: boolean;
}

export class FieldDate extends React.PureComponent<
  React.HTMLProps<JSX.Element> & IWrappedFieldProps & IFieldDateProps,
  void
> {
  public render() {
    const { readOnly, config, value, errors, className } = this.props;

    return (
      <FieldGroup className={className} config={config} errors={errors}>
        { readOnly ?
          <span className="input">
            {formatDate(value)}
          </span>
        :
          <span className="input date-field">
            <DatePicker
              fixedHeight={true}
              showYearDropdown={true}
              dateFormat={FORMAT_DATE_BACKEND}
              selected={this.getValue()}
              onChange={(e) => this.handleChange(e)}
            />
          </span>
        }
      </FieldGroup>
    );
  }

  private handleChange(value: any) {
    const { onChange, isDateTime } = this.props;
    const newValue = value.target ? value.target.value : value;
    onChange(
      isDateTime
        ? formatBackendDateTime(newValue) as any
        : formatBackendDate(newValue) as any
    );
  }

  private getValue() {
    const value = moment.utc(this.props.value);
    return value.isValid() ? value : null;
  }
}

export default FieldDate;
