import { ICircle, IPoly } from '^/store/data-types/studies';

export const convertPolygonForServer = (polygon: google.maps.Polygon): IPoly => {
  const path = polygon.getPath().getArray().map((point) => [
    point.lng(),
    point.lat()
  ] as [number, number]);

  path.unshift(path[path.length - 1]);

  return {
    type: 'Polygon',
    coordinates: [
      path,
    ]
  }
};

export const markersChanged = <T extends Array<google.maps.LatLng | null>>(prevMarkers: T, markers: T) => {
  return Boolean(prevMarkers) !== Boolean(markers) || prevMarkers.length !== markers.length ||
    prevMarkers.some((prevMarker, index) => {
      const marker = markers[index];

      return Boolean(prevMarker) !== Boolean(marker) ||
        Boolean(prevMarker && marker && (
          prevMarker.lat !== marker.lat ||
          prevMarker.lng !== marker.lng
        ));
    });
};

export const circlesChanged = <T extends Array<null | ICircle>>(prevCircles: T, circles: T) => {
  return Boolean(prevCircles) !== Boolean(circles) || prevCircles.length !== circles.length ||
    prevCircles.some((prevCircle, index) => {
      const circle = circles[index];

      return Boolean(prevCircle) !== Boolean(circle) ||
        Boolean(prevCircle && circle && (
          prevCircle.center.lat !== circle.center.lat ||
          prevCircle.center.lng !== circle.center.lng ||
          prevCircle.radius !== circle.radius
        ));
    });
};

export const polygonsChanged = <T extends Array<null | google.maps.LatLng[]>>(prevPolygons: T, polygons: T) => {
  return Boolean(prevPolygons) !== Boolean(polygons) || prevPolygons.length !== polygons.length ||
    prevPolygons.some((prevPolygon, index) => {
      const polygon = polygons[index];

      return Boolean(prevPolygon) !== Boolean(polygon) ||
        Boolean(prevPolygon && polygon && markersChanged(prevPolygon, polygon));
    });
};
