import { DatePickerWrapper as DatePicker } from "^/components/forms/date-picker";
import { IStore } from "^/store";
import { constructHeaders } from "^/utils";
import * as React from "react";
import { FormattedMessage } from "react-intl"
import PollButton from '../../poll-button';

import { connect } from 'react-redux';
import { Field, FormProps, formValueSelector, reduxForm } from 'redux-form';

import { Column, FormGroup, Row } from '@dabapps/roe';

export interface IFormData {
  start_date: string,
  end_date: string
}

interface IExternalProps extends FormProps<IFormData, void, void> {
  baseUrl: string;
  buttonFormattedMessageId: string;
  formId: string;
  instructionFormattedMessageId: string;
}

interface IProps extends IExternalProps {
  queryParams: string;
}

class CsvDateRangeFilterForm extends React.PureComponent<IProps, {}> {
  public render() {
    const { baseUrl, buttonFormattedMessageId, instructionFormattedMessageId } = this.props;
    return (
      <div>
      <form>
          <p><FormattedMessage id={instructionFormattedMessageId} /></p >
          <Row>
            <Column md={12} lg={4} className="padding-large">
              <FormGroup>
                <label><FormattedMessage id="generic.start_date" /></label>
                <Field
                  component={DatePicker}
                  name='start_date'
                />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.end_date" /></label>
                <Field
                  component={DatePicker}
                  name='end_date'
                />
              </FormGroup>
            </Column>
          </Row>
      </form>
      <PollButton
        initialMessage={(<FormattedMessage id={buttonFormattedMessageId} />)}
        headers={constructHeaders()}
        getPollUrl={(id) => `/api/export/status/${id}/`}
        requestUrl={`${baseUrl}?${this.props.queryParams}`}/>
      </div>
    )
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IProps {
  const { formId } = props;
  const selector = formValueSelector(formId);
  const startDate = selector(state, 'start_date');
  const endDate = selector(state, 'end_date');

  return {
    ...props,
    form: formId,
    queryParams: `start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
  };
}

export default connect(mapStateToProps)(reduxForm({})(CsvDateRangeFilterForm));
