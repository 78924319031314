import { List, Map } from 'immutable';
import { IAction } from '../actions';
import { ADD_RESEARCHER, REMOVE_RESEARCHER } from '../actions/sites';
import { LOAD_ITEM } from '../items/actions';
// tslint:disable-next-line:no-unused-variable
import { IUserRecord, UserRecord } from '../store/data-types';
// tslint:disable-next-line:no-unused-variable
export type TResearchersList = List<IUserRecord>;
export type TResearcherState = Map<string, TResearchersList>;
export const defaultState: TResearcherState = Map<string, TResearchersList>();

export default function siteResearcher(
  state: TResearcherState = defaultState,
  action: IAction<{id?: string, user?: IUserRecord, researchers_display?: Array<Partial<IUserRecord>>},
  { itemId: string, tag: string, siteId: string }>
): TResearcherState {
  switch (action.type) {
    case LOAD_ITEM.SUCCESS:
      if (action.payload && action.meta && action.meta.tag === 'sites') {
        return action.payload.researchers_display ? state.set(
          action.meta.itemId,
          List(action.payload.researchers_display).map((item) => UserRecord(item))
        ) : state;
      }
      return state;
    case ADD_RESEARCHER:
    if (action.payload && action.meta && action.payload.user) {
        return state.set(action.meta.siteId, state.get(action.meta.siteId, List<IUserRecord>())
        .push(action.payload.user))
      }
    return state;
    case REMOVE_RESEARCHER:
      if (action.payload && action.meta && action.payload.id) {
        return state.set(action.meta.siteId, state.get(action.meta.siteId, List<IUserRecord>())
          .filterNot((researchersDisplay) =>
          action.payload && action.payload.id ? researchersDisplay.get('id') === action.payload.id : false))
      }
      return state;
    default:
  return state;
  }
}
