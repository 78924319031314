import { DisplayUnit, Hba1cUnit, IFormMeasurements } from '^/store/data-types';
import { List } from 'immutable';

export const METRIC_UNITS = List(['CM', 'KG']);

function getFactor(unit: DisplayUnit): number {
  if (unit === 'INCHES') {
    return 12;
  } else if (unit === 'POUND') {
    return 14;
  }
  return 0;
}

export function
  convertValueToMeasurements(value: number | null, unit: DisplayUnit): IFormMeasurements {
  if (!value) {
    return {
      large_unit_value: null,
      small_unit_value: null,
      display_unit: unit
    }
  }
  if (METRIC_UNITS.contains(unit)) {
    return {
      large_unit_value: value.toString(),
      small_unit_value: null,
      display_unit: unit
    }
  }

  const factor = getFactor(unit);
  const formattedValue = parseFloat(value.toString());

  return {
    large_unit_value: (Math.floor(formattedValue / factor)).toString(),
    small_unit_value: (formattedValue % factor).toString(),
    display_unit: unit
  }
}

export function convertMeasurementsToValue(measurement: IFormMeasurements): number {
  if (METRIC_UNITS.contains(measurement.display_unit)) {
    return parseFloat(measurement.large_unit_value || '0');
  }
  const factor = getFactor(measurement.display_unit);
  const smallUnitValue: number = parseFloat(measurement.small_unit_value ? measurement.small_unit_value : '0');
  const largeUnitValue: number = parseFloat(measurement.large_unit_value ? measurement.large_unit_value : '0');
  return (largeUnitValue * factor) + smallUnitValue;
}

export function convertValueAndUnitToString(value: number | null, unit: DisplayUnit): string {
  if (!value) {
    return '-'
  }
  if (METRIC_UNITS.contains(unit)) {
    return `${value} ${unit}`;
  }
  const term = unit === 'INCHES' ? 'foot' : 'stone';
  // tslint:disable-next-line:max-line-length
  return `${convertValueToMeasurements(value, unit).large_unit_value} ${term} ${convertValueToMeasurements(value, unit).small_unit_value}`
}

export function getHba1cOrPercentage(value: number, targetUnit: Hba1cUnit): number {
  /*
    this formula can be found at:
    http://diabetes-support.org.uk/diabetes_forum/index.php?topic=2286.0
  */
  const CONVERSION_FORMULA_CONST_A = 2.15;
  const CONVERSION_FORMULA_CONST_B = 10.929;
  const convertedValues = {
    PERCENTAGE: (value - CONVERSION_FORMULA_CONST_A) * CONVERSION_FORMULA_CONST_B,
    MMOL: (value / CONVERSION_FORMULA_CONST_B) + CONVERSION_FORMULA_CONST_A
  }
  return convertedValues[targetUnit];
}
