import { Column, Container, Row, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from "react-intl"
import { connect } from 'react-redux';
import { Link } from "react-router";
import { logout, stopImpersonate } from '../../actions/auth';
import { IStore } from '../../store';
import { IUserRecord } from '../../store/data-types';
import Dropdown from '../dropdown';

interface IProps {
  user: IUserRecord;
  logout: typeof logout;
  stopImpersonate: typeof stopImpersonate;
}

function getDisplayName(user: IUserRecord) {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  return user.email;  // fallback
}

export class Banner extends React.PureComponent<IProps, void> {
  public render() {
    const { user } = this.props;
    return (
      <div className={ (user.original_user ? "nav-banner-impersonate " : "") + " nav-banner-wrapper"}>
        <Container>
          <Row className="margin-vertical-large">
            <Column xs={12} sm={6}>
              <h2>
                <span className="secondary">OneResearch</span>
                  <span className="primary">Volunteer</span> Outreach Programme
              </h2>
            </Column>
            <Column xs={12} sm={6} className="secondary has-dropdown">
              <SpacedGroup className="sm-float-right">
                <span>
                  { getDisplayName(user) } ({user.type_display})
                </span>
                <Dropdown componentKey="banner-account" message="banner.myaccount">
                  <ul className="nav-link-list">
                    <li>
                      <Link to="/app/change-email/" className="secondary">
                        <FormattedMessage id="pages.change-email.header"/>
                      </Link>
                    </li>
                    <li>
                      <Link to="/app/change-password/" className="secondary">
                        <FormattedMessage id="pages.change-password.header"/>
                      </Link>
                    </li>
                  </ul>
                </Dropdown>
                <a onClick={user.original_user ? this.props.stopImpersonate : this.props.logout} className="secondary">
                  { user.original_user ?
                    <FormattedMessage id="banner.stop-impersonate"/>
                  : <FormattedMessage id="banner.logout"/> }
                  <FontAwesome.IconSignOut className="icon-large secondary" />
                </a>
              </SpacedGroup>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}

export function mapStateToProps(state: IStore) {
  return {
    user: state.userProfile
  }
}

export default connect(mapStateToProps, {logout, stopImpersonate})(Banner);
