import { Column, Row, Well } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  COLLECTION_KEY_STATS,
  COLLECTION_KEY_STUDY_OPTIONS,
  getStudyStats,
  loadStudyOptionsAndStudyStats,
} from '../../actions/statistics';
import { GET_COLLECTION } from '../../collections/actions';
import { STUDY_OUTCOME_NAMES } from '../../consts/constants';
import { isPending } from '../../responses';
import { IStore } from "../../store";
import { IStudyOptionRecord } from "../../store/data-types/studies";
import Dropdown from '../dropdown';
import GoogleCharts from '../google-charts';
import { DashboardStudyOutcomesTable } from './dashboard-study-outcomes-table';

interface IExternalProps {
  title: string;
  icon?: JSX.Element;
  defaultFilters?: Map<string, string>;
  className?: string;
}

interface IStateProps {
  loading: boolean;
  studyStats?: Map<string, any>;
  studyOptions: ReadonlyArray<IStudyOptionRecord>;
}

interface IDispatchProps {
  loadStudyOptionsAndStudyStats: typeof loadStudyOptionsAndStudyStats;
  getStudyStats: typeof getStudyStats;
}

type IProps = IExternalProps & IDispatchProps & IStateProps;

export class DashboardReportPanel extends React.PureComponent<IProps, void> {

  public componentWillMount() {
    this.props.loadStudyOptionsAndStudyStats();
  }

  public render() {
    const {
      icon,
      title,
      className,
      loading,
      studyStats
    } = this.props;

    return (
      <div className={className}>
        <Well className="clearfix padding-base margin-vertical-large">
          <h6 className="header font-size-medium bold margin-top-base">{icon} {title}</h6>
          <Well className="padding-base inner-well">
            {
              loading ? (
                <p>Loading...</p>
              ) :
              this.renderStudyStats()
            }
            { studyStats &&
              <div className="padding-large margin-large">
                <DashboardStudyOutcomesTable
                  studyStats={studyStats}
                />
              </div>
            }
          </Well>
        </Well>
      </div>
    );
  }

  private loadStudyStatsFromClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLAnchorElement;
    this.props.getStudyStats(target.title);
  }

  private renderStudyStats = () => {
    const studyStats = this.props.studyStats;
    if (studyStats) {
      const dataValues = studyStats
        .get('study_outcomes', List())
        .map((outcome: Map<string, string | number>) => [
          outcome.get('display'),
          outcome.get('count'),
          outcome.get('color'),
          outcome.get('count')
        ]);

      const data = [
        ['Status', '', { role: 'style' }, { role: 'annotation' }],
        ...dataValues.toArray()
      ];

      return (
        <Row key={studyStats.get('study_id')}>
          <Column xs={12} md={2}>
            <p className="margin-left-base margin-vertical-large display-inline-block">
              <Link
                to={{
                  pathname: '/app/volunteers/',
                  state: { studyIdAsFilter: studyStats.get('study_id') }
                }}>
                {studyStats.getIn(['study_name']) || '--'}
              </Link>
            </p>
            <Dropdown componentKey="dashboard-study-list">
              <ul className="nav-link-list">
                {this.props.studyOptions.map((option) => (
                  <li key={option.id}>
                    <a
                      onClick={this.loadStudyStatsFromClick}
                      title={option.id}
                    >
                      {option.study_name}
                    </a>
                  </li>
                ))}
              </ul>
            </Dropdown>
          </Column>
          <Column xs={12} md={10} className="google-charts-stats-panel">
            <GoogleCharts
              chartType="BarChart"
              data={data}
              options={{
                chartArea: {
                  left: '30%',
                  width: '60%'
                },
                vAxis: {
                  title: 'Status'
                },
                legend: 'none',
                bar: {groupWidth: "60%"},
              }}
            />
          </Column>
        </Row>
      );
    } else {
      return (
        <p>No results</p>
      );
    }
  }
}

// tslint:disable-next-line:no-unused-variable
type Outcome = Map<string, string | number>;
type Outcomes = List<Outcome>;
// tslint:disable-next-line:no-unused-variable
type StudyStats = Map<string, string | Outcomes>

const COLOR_MAP = Map({
  [STUDY_OUTCOME_NAMES.PRESTUDY_CHECK]: '#22AA99',
  [STUDY_OUTCOME_NAMES.FAILED_PRESTUDY_CHECK]: '#FF9900',
  [STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED]: '#0099C6',
  [STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS]: '#5574A6',
  [STUDY_OUTCOME_NAMES.NOT_ELIGIBLE]: '#B82E2E',
  [STUDY_OUTCOME_NAMES.REQUEST_REMOVAL]: '#DD4477',
  [STUDY_OUTCOME_NAMES.SUITABLE]: '#66AA00',
});

export const mapStateToProps =
  ({ statistics, responses, studyOptions }: IStore, props: IExternalProps): IExternalProps & IStateProps => {
  // There will be lots of casting types around here as all existing types are wrong,
  // but quite engrained at this point
  let studyStats: StudyStats = statistics.get('STUDY_STATISTICS') as StudyStats;

  if (studyStats) {
    const studyOutcomes = (studyStats.get('study_outcomes', List<Outcome>()) as Outcomes)
    // Remove unknown outcome types
    .filter((outcome) => {
      const type = outcome.get('type', '') as string;
      return COLOR_MAP.has(type);
    })
    // Map colors to outcomes
    .map((outcome) => {
      const type = outcome.get('type', '') as string;
      return outcome.set('color', COLOR_MAP.get(type, '')).toMap();
    });

    studyStats = studyStats.set('study_outcomes', studyOutcomes);
  }

  return {
    ...props,
    loading:
      isPending(responses, GET_COLLECTION, COLLECTION_KEY_STATS) ||
      isPending(responses, GET_COLLECTION, COLLECTION_KEY_STUDY_OPTIONS),
    studyStats,
    studyOptions
  }
}

export default connect(mapStateToProps, {
  getStudyStats,
  loadStudyOptionsAndStudyStats
})(DashboardReportPanel);
