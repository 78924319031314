import { Button, FormGroup} from '@dabapps/roe';
import { Map } from 'immutable';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Field, FormProps, reduxForm } from 'redux-form';
import { Animation, Easing } from 'slik';
import { resetRequestState } from '../../actions';
import { SITES_DETAIL } from '../../actions/sites';
import { addResearchersToSite, removeResearcher } from '../../actions/sites';
import { LOAD_ITEM, UPDATE_ITEM } from "../../items/actions";
import { collectionsModule, ICollectionsState, } from '../../reducers/collections';
import { itemsModule, ItemType } from '../../reducers/items';
import { TResearchersList } from '../../reducers/sites';
import {
  getFormErrors,
  hasFailed,
  hasSucceeded,
  isPending
} from "../../responses";
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import { ISiteRecord, SiteRecord } from '../../store/data-types/index';
import SitesDetailFields, { ISitesDetail } from './sites-detail-fields';
import IExternalProps from './sites-researcher-list';
import SiteResearcherList from './sites-researcher-list';

const { actions: { getAllCollection } } = collectionsModule;
const { actions: { loadItem } } = itemsModule;

const SCROLL_ANIMATION = new Animation({
  duration: 500,
  to: 0,
});

const FORM_NAME = 'SitesDetailField';

interface IOwnProps {
  sites: ISiteRecord;
  item: ISiteRecord;
  itemsName: ItemType;
  loadItem: typeof loadItem;
  initialValues: ISitesDetail;
  getAllCollection: typeof getAllCollection;
  resetRequestState: typeof resetRequestState;
  response: IResponseStatus;
  siteResearcher: Map<string, TResearchersList>;
  params: { id: string; } ;
  onSubmit(data: IFormData): void;
}

interface IProps {
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
}

export type IFormData = ISitesDetail;

export class SitesDetailForm extends React.PureComponent<IOwnProps & IProps & FormProps <IFormData, {}, {}>, void> {

  public constructor(props: IOwnProps & IProps) {
    super(props);

    this.scrollStep = this.scrollStep.bind(this);
  }

  public componentWillMount() {
    const { params, itemsName } = this.props;

    SCROLL_ANIMATION
      .reset()
      .bind('update', this.scrollStep);

    this.props.resetRequestState(SITES_DETAIL);
    this.props.loadItem(
      itemsName,
      params.id,
    )
  }

  public componentWillUnmount() {
    SCROLL_ANIMATION
      .reset()
      .unbind('update', this.scrollStep);
  }

  public scrollStep(value: number) {
    window.scrollTo(0, value);
  }

  public scrollToTop() {
    SCROLL_ANIMATION
      .from(window.scrollY)
      .start();
  }

  public componentWillReceiveProps(nextProps: IOwnProps & IProps) {
    if (
      (this.props.itemHasSucceeded !== nextProps.itemHasSucceeded && nextProps.itemHasSucceeded) ||
      (this.props.itemHasFailed !== nextProps.itemHasFailed && nextProps.itemHasFailed)
    ) {
      this.scrollToTop();
    }
  }

  public render () {
    const { handleSubmit, params } = this.props;
    const { id } = this.props.params;

    return (
      <form onSubmit={handleSubmit}>
        <p>
          <Link to="/app/sites/"> &lt;- Back to Sites</Link>
        </p>
        <h1 className="border-bottom-sites">{this.props.item && (this.props.item.get('name'))}</h1>
        <SitesDetailFields
          initialValues={this.props.initialValues}
          response={this.props.response}
          formName={FORM_NAME}
        />
        <SiteResearcherList
          params={this.props.params}
          siteResearcher={this.props.siteResearcher}
          itemsName={this.props.itemsName}
          response={this.props.response}
        />
        <FormGroup className="form-controls">
          <Button type="submit" className="button primary">
            <FormattedMessage id='generic.save' />
          </Button>
          <Link to="/app/sites/" className="cancel-link">
            <FormattedMessage id='generic.cancel'/>
          </Link>
        </FormGroup>
      </form>
    );
  }
}

export function mapStateToProps(state: IStore, props: IOwnProps) {
  const { items, responses } = state;
  const itemsName = "sites";
  const item = items.get(itemsName) || SiteRecord();

  const {
    id,
    name,
    radius,
    active,
    address_1,
    address_2,
    postcode,
    city,
    county,
  } = item;

  return {
    ...props,
    initialValues: {
      id,
      name,
      radius,
      active,
      address_1,
      address_2,
      postcode,
      city,
      county,
    },
    item,
    itemsName,
    itemHasFailed: hasFailed(responses, SITES_DETAIL),
    itemHasSucceeded: hasSucceeded(responses, SITES_DETAIL),
  }
}

export default connect(mapStateToProps, { loadItem, getAllCollection })(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  })(SitesDetailForm)
);
