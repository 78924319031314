import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormComponentProps, FormProps, reduxForm } from 'redux-form';

export interface IDataShape {
  impactNumber: string | undefined;
  study_name: string | undefined;
  active: boolean | undefined;
}

type ExternalProps = FormComponentProps;

type Props = ExternalProps & FormProps<IDataShape, {}, {}>;

const MESSAGES = {
  impact: 'studies.impact_number',
  study_name: 'studies.study_name',
  showActive: 'studies.show_active',
  filter: 'studies.filter',
};

export class StudiesFilters extends React.PureComponent<Props, {}> {
  public render () {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Row className="flex flex-wrap studies-filters">
          <Column sm={6} md={3}>
            <FormGroup block className="margin-bottom-none">
              <label className="font-size-small">
                <FormattedMessage id={MESSAGES.impact} />
              </label>
              <Field
                component="input"
                name="impactNumber"
                type="text"
                placeholder="Search by number"
              />
            </FormGroup>
          </Column>
          <Column sm={6} md={3}>
            <FormGroup block className="margin-bottom-none">
              <label className="font-size-small">
                <FormattedMessage id={MESSAGES.study_name} />
              </label>
              <Field
                component="input"
                name="study_name"
                type="text"
                placeholder="Search by name"
              />
            </FormGroup>
          </Column>
          <Column className="flex align-bottom" sm={6} md={3}>
            <FormGroup className="margin-bottom-none margin-horizontal-none">
              <Field
                component="input"
                name="active"
                type="checkbox"
              />
              <label>
                <span className="flex input-sized">
                  <span className="flex vertical-align-center">
                    <FormattedMessage id={MESSAGES.showActive} />
                  </span>
                </span>
              </label>
            </FormGroup>
          </Column>
          <Column className="flex align-bottom" sm={6} md={3}>
            <Button type="submit" className="primary">
              <FormattedMessage id={MESSAGES.filter} />
            </Button>
          </Column>
        </Row>
      </form>
    );
  }
}

export default reduxForm({form: 'studies-filters'})(StudiesFilters);
