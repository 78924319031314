import * as React from "react"
import { FormattedMessage } from "react-intl"
import { connect, } from 'react-redux';
import { resetRequestState } from '../../actions';
import { CHANGE_PASSWORD, changePassword} from '../../actions/auth';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import ChangePasswordForm, {IFormData} from './change-password-form';

interface IProps {
  response: IResponseStatus;
  loading: boolean;
  resetRequestState: typeof resetRequestState;
  changePassword: typeof changePassword;
}

export class ChangePassword extends React.PureComponent<IProps, void> {

  public componentWillMount() {
    this.props.resetRequestState(CHANGE_PASSWORD);
  }

  public render() {
    return(
     <div>
     <h1><FormattedMessage id="pages.change-password.header"/></h1>
     <hr/>
     <ChangePasswordForm onSubmit={this.changePassword.bind(this)} response={this.props.response} />
     </div>
    )
  }

  private changePassword(data: IFormData) {
    this.props.changePassword(data.current_password, data.password, data.confirm_password);
  }
}

export function mapStateToProps({responses}: IStore, existing: Partial<IProps>) {
  return {
    ...existing,
    response: getResponseStatus(responses, CHANGE_PASSWORD)
  }
}

export default connect(mapStateToProps, {changePassword, resetRequestState})(ChangePassword);
