import * as moment from 'moment';
import { map } from 'underscore';
import { BLANK_NOT_FILTERED } from '../../../consts/constants';
import { INumericRange } from '../../../store/data-types';
import {
  FormattedFormData,
  FormattedStudyMedication,
  FormattedStudySite,
  FormData,
  GeneralOptions,
  IncludeOptions,
  IStudyRecord,
} from '../../../store/data-types/studies';

const isNonEmptyString = (value?: string | number | null): value is string => {
  return Boolean(typeof value === 'string' && value);
};

const asTruthyStringOrUndefined = (value?: string | number | null): string | undefined => {
  return isNonEmptyString(value) ? value : undefined;
};

const asFloatOrUndefined = (value?: string | number | null): number | undefined => {
  return isNonEmptyString(value) ? parseFloat(value) : undefined;
};

const asIntOrNull = (value?: string | undefined | null | number): number | null => {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }

  return parseInt(`${value}`, 10);
};

const asGeneralOption = (value?: GeneralOptions | null): GeneralOptions => {
  if (typeof value === 'undefined' || value === null) {
    return BLANK_NOT_FILTERED.value;
  }

  return value;
};

const asIncludeOptionOrUndefined = (value?: IncludeOptions | null): IncludeOptions | undefined => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }

  return value;
};

const asStringNumberOrUndefined = (value?: number | null): string | undefined => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }

  return `${value}`;
};

const asStringDateOrUndefined = (value?: string | null | moment.Moment): string | undefined => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }

  const date = moment.utc(value);

  return date.isValid() ? date.format('YYYY-MM-DD') : undefined;
};

const asBoolean = (value?: boolean | null): boolean => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  return value;
};

export const convertFormDataForServer = ({
  study_medications = [],
  general = {},
  study_sites = [],
  details = {}
}: FormData): FormattedFormData => {

  const studyMedications = study_medications.map((med): FormattedStudyMedication => {
    const { min_dosage, max_dosage, include, med_type, medication, med_class, generic_name } = med;

    return {
      med_type: asTruthyStringOrUndefined(med_type),
      medication: asTruthyStringOrUndefined(medication),
      med_class: asTruthyStringOrUndefined(med_class),
      generic_name: asTruthyStringOrUndefined(generic_name),
      min_dosage: asFloatOrUndefined(min_dosage),
      max_dosage: asFloatOrUndefined(max_dosage),
      include: asIncludeOptionOrUndefined(include)
    };
  });

  const studySites = study_sites.map((site): FormattedStudySite => {
    const { researchers, site_number, ...rest } = site;

    return {
      ...rest,
      researchers,
      site_number: asIntOrNull(site.site_number)
    };
  });

  const {
    age_range,
    bmi_range,
    ...remainingGeneral
  } = general;

  const ageRangeLower = age_range && asFloatOrUndefined(age_range.lower);
  const ageRangeUpper = age_range && asFloatOrUndefined(age_range.upper);
  const bmiRangeLower = bmi_range && asFloatOrUndefined(bmi_range.lower);
  const bmiRangeUpper = bmi_range && asFloatOrUndefined(bmi_range.upper);

  return {
    ...details,
    ...remainingGeneral,
    age_range: {
      lower: ageRangeLower,
      upper: ageRangeUpper,
    },
    bmi_range: {
      lower: bmiRangeLower,
      upper: bmiRangeUpper,
    },
    study_medications: studyMedications,
    study_sites: studySites,
  };
};

export const convertServerDataForForm = (study: IStudyRecord): FormData | undefined => {
  if (!study) {
    return;
  }

  return {
    details: {
      study_name: asTruthyStringOrUndefined(study.study_name),
      project_manager: asTruthyStringOrUndefined(study.project_manager),
      impact_number: asTruthyStringOrUndefined(study.impact_number),
      fpfv_date: asStringDateOrUndefined(study.fpfv_date),
      lpfv_date: asStringDateOrUndefined(study.lpfv_date),
      active: asBoolean(study.active),
    },
    study_medications: study.study_medications ?
      study.study_medications.map((medication) => ({
        id: asTruthyStringOrUndefined(medication.id),
        med_type: asTruthyStringOrUndefined(medication.med_type),
        medication: asTruthyStringOrUndefined(medication.medication),
        generic_name: asTruthyStringOrUndefined(medication.generic_name),
        med_class: asTruthyStringOrUndefined(medication.med_class),
        min_dosage: asTruthyStringOrUndefined(medication.min_dosage),
        max_dosage: asTruthyStringOrUndefined(medication.max_dosage),
        include: asIncludeOptionOrUndefined(medication.include)
      })).toArray() : undefined,
    general: {
      diabetes_type: study.diabetes_type,
      tablets: asGeneralOption(study.tablets),
      injection_insulin: asGeneralOption(study.injection_insulin),
      injection_other: asGeneralOption(study.injection_other),
      age_range: {
        lower: asStringNumberOrUndefined(study.age_range.lower),
        upper: asStringNumberOrUndefined(study.age_range.upper),
      },
      bmi_range: {
        lower: asStringNumberOrUndefined(study.bmi_range.lower),
        upper: asStringNumberOrUndefined(study.bmi_range.upper),
      }
    },
    study_sites: study.study_sites ?
      study.study_sites.map((site) => ({
        id: asTruthyStringOrUndefined(site.id),
        site: site.site,
        site_number: asStringNumberOrUndefined(site.site_number),
        green_lit: asBoolean(site.green_lit),
        cra: asTruthyStringOrUndefined(site.cra),
        researchers: site.researchers,
        is_confirmed_site: site.is_confirmed_site
      })).toArray() : undefined
  };
};
