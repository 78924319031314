import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { IStore } from "../store";
import { IUserRecord } from '../store/data-types';
import { IPermission, IPermissionsProps, PageName } from './config/default-permissions';
import { getCustomProps, hasPageAccess, redirectTo } from './permissions-config';

interface IStateProps {
  user: IUserRecord | null;
  customPermissionProps: IPermission['customProps'];
}

interface IExternalProps {
  params?: { id?: string };
  pushRoute: (redirect: string) => any;
}

export type PermissionHOCProps = IStateProps & IExternalProps;
export type PermissionHOCChildProps = IExternalProps & {
  permissions: IPermission['customProps'];
  user: IUserRecord;
}

type ComponentType = React.ComponentClass<PermissionHOCChildProps> |
  React.StatelessComponent<PermissionHOCChildProps>;

export default function mapPermissionsToProps(Component: ComponentType, path: PageName) {
  class PermissionsWrapper extends React.Component<PermissionHOCProps, {}> {

    public componentWillMount() {
      this.checkPermissons(this.props.user, path);
    }

    public render() {
      const { user, customPermissionProps } = this.props;

      if (!user) {
        return null;
      }

      return <Component {...this.props} user={user} permissions={customPermissionProps} />
    }

    private checkPermissons(user: IUserRecord | null, page: PageName) {
      if (!user) {
        return;
      }

      const hasAccess = hasPageAccess(user.getIn(['type']), page);
      const redirect = redirectTo(user.getIn(['type']), page);

      if (!hasAccess) {
        this.props.pushRoute(redirect);
      }
    }
  }

  const mapStateToProps = ({ userProfile, routing }: IStore, props: IExternalProps): IStateProps & IExternalProps => {
    return {
      ...props,
      user: userProfile,
      customPermissionProps: (userProfile && getCustomProps(userProfile.getIn(['type']), path)
        || {} as IPermissionsProps),
    }
  }

  const mapDispatchToProps = {
    pushRoute: push
  }

  return connect(mapStateToProps, mapDispatchToProps)(PermissionsWrapper);
}
