import {
  Button,
  Column,
  FormGroup,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import { getCollectionByName } from '^/collections/reducers';
import DateInput from '^/components/date-input';
import Select from '^/components/forms/select';
import { collectionsModule } from '^/reducers/collections';
import { IStore } from '^/store';
import { FormData, IStudyRecord } from '^/store/data-types/studies';
import { Map } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Field, FormProps, FormSection, reduxForm } from 'redux-form';

const { actions: { getCollection } } = collectionsModule;

export const FORM_NAME = 'study-save-form';

interface IExternalProps extends FormProps<FormData, any, any> {
  params: {
    id?: string | undefined;
  },
  study: IStudyRecord;
  onSubmit(formData: FormData): void;
}

interface IPropsWithoutActions extends IExternalProps {
  projectManagers: any[];
}

interface IProps extends IPropsWithoutActions {
  getCollection: typeof getCollection;
}

const MESSAGES = {
  studyName: 'studies.study_name',
  impactNumber: 'studies.impact_number',
  fpfvDate: 'studies.fpfv_date',
  lpfvDate: 'studies.lpfv_date',
  projectManager: 'studies.project_manager',
  saveStudy: 'studies.save_study',
  active: 'generic.active',
  cancel: 'generic.cancel',
};

class SaveForm extends React.PureComponent<IProps, {}> {
  public componentWillMount() {
    this.loadAllOptions();
  }

  public render () {
    const { handleSubmit, study, params: { id }, projectManagers } = this.props;

    return (
      <form onSubmit={handleSubmit} className="margin-vertical-base">
        <FormSection name="details">
          <Row>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.studyName} />
                </label>
                <Field
                  name="study_name"
                  placeholder="Enter study name"
                  component="input"
                  type="text"
                />
              </FormGroup>
            </Column>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.impactNumber} />
                </label>
                <Field
                  name="impact_number"
                  placeholder="Enter IMPACT number"
                  component="input"
                  type="text"
                />
              </FormGroup>
            </Column>
          </Row>
          <Row>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.fpfvDate} />
                </label>
                <Field
                  name="fpfv_date"
                  placeholder="Choose date..."
                  component={DateInput}
                />
              </FormGroup>
            </Column>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.lpfvDate} />
                </label>
                <Field
                  name="lpfv_date"
                  placeholder="Choose date..."
                  component={DateInput}
                />
              </FormGroup>
            </Column>
          </Row>
          <Row>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.projectManager} />
                </label>
                <Field
                  name="project_manager"
                  placeholder="Select a project manager"
                  options={projectManagers}
                  component={Select}
                />
              </FormGroup>
            </Column>
            <Column md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage id={MESSAGES.active} />
                </label>
                {
                  study.active ? (
                    <span className="input bold success">
                      ACTIVE
                    </span>
                  ) : (
                    <Field
                      name="active"
                      component="input"
                      type="checkbox"
                    />
                  )
                }
              </FormGroup>
            </Column>
          </Row>
        </FormSection>
        <Row>
          <Column>
            <SpacedGroup block className="margin-vertical-base">
              <Button className="primary">
                <FormattedMessage id={MESSAGES.saveStudy} />
              </Button>
              <Link className="button" to={`/app/studies/${id}/`}>
                <FormattedMessage id={MESSAGES.cancel} />
              </Link>
            </SpacedGroup>
          </Column>
        </Row>
      </form>
    );
  }
  private loadAllOptions() {
    this.props.getCollection(
      'users',
      {
        filters:  Map({ type: 'PROJECT_MANAGER', status: 'ACTIVE' }),
        pageSize: 150,
      },
      'users'
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IPropsWithoutActions {
  const { collections } = state;

  return {
    ...props,
    projectManagers: getCollectionByName(collections.users, 'users').results.map((user) =>
      ({value: user.id, label: user.name_email_dropdown})).toArray()
  };
}

const connectedForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(SaveForm);

export default connect(
  mapStateToProps,
  {
    getCollection
  }
)(connectedForm);
