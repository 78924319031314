import {
  Button,
  Column,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { FormSection } from 'redux-form';
import { FormErrors } from '../../../../utils';
import Collapse from '../../../collapse';
import FormArray from '../../../forms/form-array';
import FormErrorsRenderer from '../../../forms/form-errors-renderer';
import GeneralCriteria from './general-criteria';
import Medications from './medications';

const { IconChevronDown, IconChevronUp } = FontAwesome;

const MESSAGES = {
  criteria: 'studies.criteria',
  notSpecified: 'generic.not_specified',
  medications: 'generic.medications',
  done: 'generic.done',
  updateResults: 'generic.update_results',
  generalCriteria: 'studies.general_criteria',
  addMedication: 'studies.add_medication',
};

interface IOwnProps {
  formErrors: FormErrors;
  openCollapse: string | null;
  criteriaSummaryText: string;
  toggleCollapse(event: React.MouseEvent<HTMLElement>): void;
}

type Props = IOwnProps;

export default class Criteria extends React.PureComponent<Props, {}> {

  public render () {
    const { openCollapse, toggleCollapse, formErrors, criteriaSummaryText } = this.props;

    return (
      <div>
        <div className="collapse-header">
          <span className="collapse-icon" onClick={toggleCollapse}>
            {openCollapse === 'criteria' ? <IconChevronUp /> : <IconChevronDown />}
          </span>
          <SpacedGroup block large className="margin-vertical-large">
            <h3 className="font-size-base font-weight-bold display-inline">
              <a className="primary" onClick={toggleCollapse}>
                <FormattedMessage id={MESSAGES.criteria} />
              </a>
            </h3>
          </SpacedGroup>
            {openCollapse !== 'criteria' && (<p className="italic">{criteriaSummaryText}</p>)}
        </div>
        <Collapse open={openCollapse === 'criteria'}>
          <p>
            <strong>
              <FormattedMessage id={MESSAGES.medications} />
            </strong>
          </p>

          <FormArray
            name="study_medications"
            component={Medications}
          />

          <hr />

          <p>
            <strong>
              <FormattedMessage id={MESSAGES.generalCriteria} />
            </strong>
          </p>

          <FormSection name="general">
            <GeneralCriteria />
          </FormSection>

          <FormErrorsRenderer formErrors={formErrors} errorKey="errors" />

          <Row className="criteria-footer">
            <Column>
              <SpacedGroup>
                <Button className="primary">
                  <FormattedMessage id={MESSAGES.updateResults} />
                </Button>
                <Button>
                  <FormattedMessage id={MESSAGES.done} />
                </Button>
              </SpacedGroup>
            </Column>
          </Row>
        </Collapse>
      </div>
    );
  }

}
