import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import * as classNames from 'classnames';
import { List, Map, OrderedMap } from 'immutable';
import * as React from 'react';
import { Animation, Easing } from 'slik';

import Loading from '../common/Loading';
import { IBasicDictionary, TItem, TResponse } from '../types';
import { hasSucceeded, isPending } from '../utils';
import Form from './dynamic-forms/Form';

interface IProps {
  className?: string;
  createItem?: (data: any) => void;
  defaultValues?: {[i: string]: any};
  fields: List<string>;
  formName: string;
  getFields?: (item: TItem) => List<string>;
  getInitialValues?: (item: TItem) => IBasicDictionary;
  getTitle?: (item: TItem) => string;
  hideMessages?: boolean;
  item?: TItem;
  itemOptions: Map<string, any>;
  itemIsPending?: boolean;
  itemHasSucceeded?: boolean;
  itemHasFailed?: boolean;
  itemErrors?: Map<string, any>;
  patchItem?: (data: any) => void;
  readOnly?: boolean;
  loading?: boolean;
  saveButtonText?: string;
  setPendingUploadInForm?: (file: any) => void;
  title?: string;
  transformDataToSubmit?: (data: any) => any;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
  onCancelLinkTo?: string;
}

const SCROLL_ANIMATION = new Animation({
  duration: 500,
  to: 0,
});

export default class AdminEditCreate extends React.PureComponent<
  IProps,
  {}
> {
  public constructor(props: IProps) {
    super(props);

    this.scrollStep = this.scrollStep.bind(this);
  }

  public componentWillMount() {
    SCROLL_ANIMATION
      .reset()
      .bind('update', this.scrollStep);
  }

  public componentWillUnmount() {
    SCROLL_ANIMATION
      .reset()
      .unbind('update', this.scrollStep);
  }

  public componentWillReceiveProps (nextProps: IProps) {
    if (
      (this.props.itemHasSucceeded !== nextProps.itemHasSucceeded && nextProps.itemHasSucceeded) ||
      (this.props.itemHasFailed !== nextProps.itemHasFailed && nextProps.itemHasFailed)
    ) {
      this.scrollToTop();
    }
  }

  public scrollStep(value: number) {
    window.scrollTo(0, value);
  }

  public scrollToTop() {
    SCROLL_ANIMATION
      .from(window.scrollY)
      .start();
  }

  public render() {
    const { item, itemOptions, loading } = this.props;
    const failedLoading = !itemOptions || (this.isEditing() && !item);

    if (loading) {
      return <Loading />;
    } else if (failedLoading) {
      return <div>Failed to load item.</div>;
    } else {
      return this.renderItem();
    }
  }

  private isEditing() {
    return !!this.props.item;
  }

  private submitData(data: object) {
    const { defaultValues, transformDataToSubmit, patchItem } = this.props;
    const dataToSubmit = transformDataToSubmit
      ? transformDataToSubmit(data)
      : data;

    if (this.isEditing()) {
      return this.props.patchItem && this.props.patchItem(data);
    } else {

      const defaultedData = Map((defaultValues as any) || {})
        .mergeDeep(Map(dataToSubmit))
        .toJS();
      return this.props.createItem && this.props.createItem(defaultedData);
    }
  }

  private getFields() {
    const { getFields, item, fields } = this.props;
    if (item && getFields) {
      return getFields(item);
    }
    return fields;
  }

  private getInitialValues(): IBasicDictionary {
    const { item, getInitialValues } = this.props;

    if (!item || !this.isEditing()) {
      return this.props.defaultValues || {};
    }

    if (getInitialValues) {
      return getInitialValues(item);
    }

    return item.toJS();
  }

  private renderItem() {
    const {
      item,
      itemOptions,
      title,
      getTitle,
      hideMessages,
      formName,
      readOnly,
      className,
      saveButtonText,
      itemIsPending,
      itemHasSucceeded,
      itemHasFailed,
      itemErrors,
      additionalSuccessMessage,
      additionalFailureMessage,
      onCancelLinkTo
    } = this.props;

    const fields = this.getFields();

    type FieldConfig = List<string | Map<string, string | List<List<string>>>>;

    const fieldConfig: FieldConfig = List(
      fields.map((field: any) =>
        (typeof field === 'string')
          ? Map({
            wrapperClassName: '',
            fieldClassNames: List.of(List.of('column sx-12')),
            elements: List.of(List.of(Map({
              [field]: itemOptions.getIn(field.split('.').join('.children.').split('.'), Map())
            })))
          })
          : Map({
            wrapperClassName: field.get('wrapperClassName') || List(),
            fieldClassNames: field.get('fieldClassNames') || List(),
            elements: field.get('elements').map((fieldItems: string[]) =>
              fieldItems.map((fieldItem: string) => Map({[fieldItem]: itemOptions.get(fieldItem)}))
            )
          })
        )
    );

    return (
      <div>
        <Row>
          <Column xs={12}>
            <Form
              title={getTitle && item ? getTitle(item) : title}
              className={classNames('admin-edit-create-form', className)}
              fieldClassName="admin-edit-create-field"
              onSubmit={(data: any) => {
                // dont return anything because redux form will clear files on failure
                this.submitData(data);
              }}
              onCancelLinkTo={onCancelLinkTo}
              fields={fields}
              form={formName}
              formName={formName}
              initialValues={this.getInitialValues()}
              fieldConfig={fieldConfig}
              setPendingUploadInForm={this.props.setPendingUploadInForm}
              readOnly={readOnly}
              isPending={itemIsPending}
              hasSucceeded={itemHasSucceeded}
              hasFailed={itemHasFailed}
              errors={itemErrors}
              isEditing={this.isEditing()}
              saveButtonText={
                saveButtonText || (this.isEditing() ? 'Save' : 'Create')
              }
              hideMessages={hideMessages}
              additionalSuccessMessage={additionalSuccessMessage}
              additionalFailureMessage={additionalFailureMessage}
            />
          </Column>
        </Row>
      </div>
    );
  }
}
