import { UserTypes } from '../store/data-types';

type KeyValuePairs<T extends string> = {[K in T]: K};

export const USER_TYPES: KeyValuePairs<UserTypes> = {
  VOLUNTEER: 'VOLUNTEER',
  CALL_HANDLER: 'CALL_HANDLER',
  QA: 'QA',
  CRA: 'CRA',
  RESEARCHER: 'RESEARCHER',
  PROJECT_MANAGER: 'PROJECT_MANAGER',
  ADMIN: 'ADMIN',
  MARKETING: 'MARKETING',
};
