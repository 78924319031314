import * as classNames from 'classnames';
import { List } from 'immutable';
import * as React from 'react';
import * as Select from 'react-select';

import { FieldGroup } from './FieldGroup';
import { IChoice, IWrappedFieldProps } from './types';

/* Displays a multi-select with choices passed in.
 * Similar to FieldSelect. The choices should be passed
 * in the form:
 *  { value: <uuid>, display_name: <whatever string to display> }
 *
 * The field will set an array of UUIDs in onChange to post to the endpoint.
*/

export const FieldMany = (
  props: React.HTMLProps<JSX.Element> & IWrappedFieldProps
) => {
  const { readOnly, config, name, errors, value, onChange, className } = props;

  const {
    choices = [],
    label,
    placeholder,
    clearable = true,
  } = config;

  if (readOnly) {
    // find matching choices and transform to display names
    const chosenItems =
      choices &&
      choices
        .filter((choice) => value.indexOf(choice.value) !== -1)
        .map((choice) => choice.display_name);

    return (
      <FieldGroup className={className} config={config} errors={errors}>
        <ul className="input readonly-many-list">
          {chosenItems &&
            chosenItems.map((item) =>
              <li>
                {item}
              </li>
            )}
        </ul>
      </FieldGroup>
    );
  }

  return (
    <FieldGroup config={config} errors={errors}>
      <Select
        clearable={clearable}
        className="select"
        labelKey="display_name"
        options={choices}
        value={value}
        name={name}
        multi={true}
        placeholder={placeholder}
        onChange={(opts: Select.Option[]) =>
          onChange(opts.map((opt) => opt.value) as any)}
      />
    </FieldGroup>
  );
};

export default FieldMany;
