import { SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';

type DateFormat = 'DD' | 'MM' | 'YYYY';

// Inputs and outputs date in format 'YYYY-MM-DD'
export class DateInput extends React.PureComponent<WrappedFieldInputProps, {}> {
  public render() {
    return (
      <SpacedGroup className="date-input">
        <input
          className="date-day"
          type="number"
          min="1"
          max="31"
          placeholder="DD"
          value={this.getPartialDate('DD')}
          onChange={(e) => this.handleChange(e.target.value, 'DD')}
        />
        /
        <input
          className="date-month"
          type="number"
          min="1"
          max="12"
          placeholder="MM"
          value={this.getPartialDate('MM')}
          onChange={(e) => this.handleChange(e.target.value, 'MM')}
        />
        /
        <input
          className="date-year"
          type="number"
          min="1900"
          max="2200"
          placeholder="YYYY"
          value={this.getPartialDate('YYYY')}
          onChange={(e) => this.handleChange(e.target.value, 'YYYY')}
        />
      </SpacedGroup>
    );
  }

  private getPartialDate(format: DateFormat) {
    const value = this.props.value || '---';
    const dateSplit = value.split('-');
    switch (format) {
      case 'DD':
        return dateSplit[2];
      case 'MM':
        return dateSplit[1];
      case 'YYYY':
        return dateSplit[0];
      default:
        return '';
    }
  }

  private getNewDate(date: string, value: string, format: DateFormat) {
    switch (format) {
      case 'DD':
        return `${this.getPartialDate('YYYY')}-${this.getPartialDate('MM')}-${value}`;
      case 'MM':
        return `${this.getPartialDate('YYYY')}-${value}-${this.getPartialDate('DD')}`;
      case 'YYYY':
        return `${value}-${this.getPartialDate('MM')}-${this.getPartialDate('DD')}`;
      default:
        return '---';
    }
  }

  private handleChange(partialValue: string, format: DateFormat) {
    const { value, onChange } = this.props;
    onChange(this.getNewDate(value, partialValue, format));
  }
}

export const DateInputWrapper = ({input}: WrappedFieldProps<any>) => (
  <DateInput
    {...input}
  />
)

export default DateInputWrapper;
