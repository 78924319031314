// tslint:disable-next-line:no-unused-variable
import { AxiosResponse } from 'axios'; // Required for dist
import * as _ from 'underscore';

import { dispatchGenericRequest } from '../actions';
// tslint:disable-next-line:no-unused-variable
import { IAction, RecordInstance, TTypeToRecordMapping, } from '../store/data-types';
import * as collections from './actions';
import {
  addCollectionItem,
  clearCollection,
  deleteCollectionItem,
  setCollectionFromResponseAction,
} from './reducers';
import {
  ICollectionOptions,
  // tslint:disable-next-line:no-unused-variable
  IGenericCollectionState, // Required for dist
} from './types';

export function collectionsFunctor<T> (
  typeToRecordMapping: TTypeToRecordMapping<T>,
  CollectionStateRecord: () => RecordInstance<T>
) {

  function addItemAction (type: keyof T, data: any, collectionName?: string, collectionOverrideUrl?: string) {
    const url = collectionOverrideUrl ? `/api/${collectionOverrideUrl}/` : `/api/${type}/`;
    return dispatchGenericRequest(collections.ADD_TO_COLLECTION, url, 'POST', data, type, { collectionName });
  }

  function addToCollectionAction (type: keyof T, url: string, data: any, collectionName?: string) {
    return dispatchGenericRequest(collections.ADD_TO_COLLECTION, url, 'POST', data, type, { collectionName });
  }

  function clearCollectionAction (type: keyof T, collectionName?: string) {
    return {
      payload: {
        collectionName,
        type,
      },
      type: collections.CLEAR_COLLECTION,
    };
  }

  function deleteItemAction (type: keyof T, id: string, collectionName?: string, collectionOverrideUrl?: string) {
    const url = collectionOverrideUrl ? `/api/${collectionOverrideUrl}/` : `/api/${type}/${id}/`;
    return dispatchGenericRequest(
      collections.DELETE_FROM_COLLECTION,
      url,
      'DELETE',
      null,
      type,
      { collectionName, itemId: id }
    );
  }

  function getAllCollectionAction (type: keyof T, opts?: ICollectionOptions, collectionName?: string) {
    return getCollectionAction(
      type,
      _.extend({}, opts, { pageSize: collections.WHOLE_COLLECTION_PAGE_SIZE }),
      collectionName
    );
  }

  function getCollectionAction (
    type: keyof T,
    options: ICollectionOptions = {},
    collectionName?: string,
    collectionOverrideUrl?: string) {
    const url = collectionOverrideUrl ? `/api/${collectionOverrideUrl}/` : `/api/${type}/`;
    const meta = {
      collectionName,
      filters: options.filters,
      ordering: options.ordering,
      page: options.page,
      reverseOrdering: options.reverseOrdering,
      shouldAppend: options.shouldAppend,
    };

    const urlWithParams = `${url}${collections.formatCollectionQueryParams(options)}`;

    return dispatchGenericRequest(collections.GET_COLLECTION, urlWithParams, 'GET', null, type, meta);
  }

  function collectionsReducer (state: RecordInstance<T> = CollectionStateRecord(), action: IAction<any, any>) {
    // The types in here are a bit janky as there doesn't seem to be a way
    // of passing a closed generic with certain types for properties
    switch (action.type) {
      case collections.GET_COLLECTION.SUCCESS:
        return setCollectionFromResponseAction(state as any, action, typeToRecordMapping as any);
      case collections.ADD_TO_COLLECTION.SUCCESS:
        return addCollectionItem(state as any, action, typeToRecordMapping as any);
      case collections.DELETE_FROM_COLLECTION.SUCCESS:
        return deleteCollectionItem(state as any, action, typeToRecordMapping as any);
      case collections.CLEAR_COLLECTION:
        return clearCollection(state as any, action, typeToRecordMapping as any);
      default:
        return state;
    }
  }

  return {
    actions: {
      addItem: addItemAction,
      addToCollection: addToCollectionAction,
      clearCollection: clearCollectionAction,
      deleteItem: deleteItemAction,
      getAllCollection: getAllCollectionAction,
      getCollection: getCollectionAction,
    },
    reducers: {
      collections: collectionsReducer,
    }
  };
}
