import { Row } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { VOLUNTEER_STATS_ADD_TO_STORE } from '../../actions/statistics';
import { postGenericAction } from '../../actions/statistics';
import { STUDY_OUTCOME_NAMES } from '../../consts/constants';
import { isPending } from '../../responses';
import { IStatisticsDataQuery } from '../../store/data-types/statistics';
import DashboardStatsPanel from './dashboard-stats-panel';

import { IStore } from '../../store';

const COLLECTION_KEY: string = 'charts/study-volunteers';

const RESEARCHER_STATS: IStatisticsDataQuery = {
  filter_sets: [
    {
      name: STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED,
      filters: [
        {
          filter: 'study_outcome',
          value: STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED
        },
      ]
    },
    {
      name: STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS,
      filters: [
        {
          filter: 'study_outcome',
          value: STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS
        },
      ]
    },
  ]
}

interface IExternalProps {
  loading: boolean;
}

interface IStatsStore {
  name: string;
  percentage: number;
  value: number;
  filters: {
    profile_status?: string;
    diabetes_type?: string;
  };
}

interface IProps extends InjectedIntlProps, IExternalProps {
  loading: boolean;
  postGenericAction: typeof postGenericAction;
  reviewReqValue: IStatsStore,
  reviewReqPerc: IStatsStore,
  reviewReqFilters: IStatsStore,
  contactInProgressValue: IStatsStore,
  contactInProgressPerc: IStatsStore,
  contactInProgressFilters: IStatsStore,
}

export class Dashboard extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    this.props.postGenericAction(COLLECTION_KEY, VOLUNTEER_STATS_ADD_TO_STORE, RESEARCHER_STATS);
  }

  public render() {
    const {
      loading,
      reviewReqValue,
      reviewReqPerc,
      reviewReqFilters,
      contactInProgressValue,
      contactInProgressPerc,
      contactInProgressFilters,
    } = this.props;

    return (
      <div>
        <Row>
          <DashboardStatsPanel
            loading={loading}
            className={'column xs-12 md-4'}
            title={'Need to be contacted'}
            data={List.of(
              Map({
                text: 'Need to be contacted',
                value: reviewReqValue,
                percentage: reviewReqPerc,
                filters: reviewReqFilters,
                pathname: '/app/volunteers/',
              }),
            )}
          />

          <DashboardStatsPanel
            loading={loading}
            className={'column xs-12 md-4'}
            title={'Contact in progress'}
            data={List.of(
              Map({
                text: 'Contact in progress',
                value: contactInProgressValue,
                percentage: contactInProgressPerc,
                filters: contactInProgressFilters,
                pathname: '/app/volunteers/',
              }),
            )}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ statistics, responses }: IStore, props: IExternalProps) => {

  return {
    ...props,
    loading: isPending(responses, VOLUNTEER_STATS_ADD_TO_STORE, COLLECTION_KEY),
    reviewReqValue: statistics.getIn([STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED, 'value']),
    reviewReqPerc: statistics.getIn([STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED, 'percentage']),
    reviewReqFilters: statistics.getIn([STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED, 'filters']),
    contactInProgressValue: statistics.getIn([STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS, 'value']),
    contactInProgressPerc: statistics.getIn([STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS, 'percentage']),
    contactInProgressFilters: statistics.getIn([STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS, 'filters']),
  }
}

const mapDispatchToProps = {
  postGenericAction,
}

const DashboardIntl = injectIntl(Dashboard);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardIntl);
