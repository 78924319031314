import { Map } from 'immutable';

export const STYLE = {
  borderColor: '#ddd',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  marginBottom: 8,
  padding: 8,
};

export const ACTIVE_STYLE = Map(STYLE)
  .mergeDeep(
    Map({
      backgroundColor: '#eee',
      borderStyle: 'solid',
    })
  )
  .toJS();

export const REJECT_STYLE = Map(STYLE)
  .mergeDeep(
    Map({
      backgroundColor: '#fdd',
      borderStyle: 'solid',
    })
  )
  .toJS();
