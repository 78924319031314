import { applyMiddleware, createStore } from 'redux';

import { List, Map } from 'immutable';
import { IntlState } from 'react-intl-redux';
import { browserHistory } from 'react-router';
import { routerMiddleware, RouterState } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { FormStateMap } from 'redux-form';
import thunk from 'redux-thunk';

import * as reduxActionSubscriber from '^/middleware/redux-action-subscriber';

// tslint:disable-next-line:no-unused-variable
import { SankeyData } from '../components/reports/recruitment-flow/sankey-chart';
import rootReducer from '../reducers';
import { ICollectionsStateRecord } from '../reducers/collections';
import { IItemsStateRecord } from '../reducers/items';
import { ResponsesReducerState } from '../reducers/responses';
// tslint:disable-next-line:no-unused-variable
import { TResearchersList } from '../reducers/sites';
import { VolunteerCounts } from '../reducers/studies';
import { IUserRecord } from './data-types';
import { IStudyOptionRecord } from './data-types/studies';

export interface IStore {
  volunteerCounts: VolunteerCounts;
  form: FormStateMap;
  modals: List<React.ReactNode>;
  routing: RouterState;
  userProfile: IUserRecord | null;
  responses: ResponsesReducerState;
  intl: IntlState;
  collections: ICollectionsStateRecord;
  items: IItemsStateRecord;
  simpleComponentState: Map<string, any>;
  researcherStudyOutcome: Map<string, string>;
  canSetPreStudyOutcome: Map<string, boolean>;
  siteResearcher: Map<string, TResearchersList>;
  markers: google.maps.LatLng[];
  statistics: Map<string, Map<string, string | number> | List<number> | SankeyData>;
  studyOptions: ReadonlyArray<IStudyOptionRecord>;
}

const middlewares = [
  thunk,
  routerMiddleware(browserHistory),
  reduxActionSubscriber.middleware,
];

export function configureStore(initialState?: IStore) {
  const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(
    ...middlewares
  ))(createStore);
  return createStoreWithMiddleware(rootReducer, initialState);
}

export default configureStore();
