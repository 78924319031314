import { ModalFooter } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { resetRequestState } from '../../actions';
import { login, LOGIN } from '../../actions/auth';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import LoginForm, { IFormData } from './login-form';

interface IOwnProps {
  onResetPasswordClick(): void;
}

export interface IProps extends IOwnProps {
  response: IResponseStatus;
  login: typeof login;
  resetRequestState: typeof resetRequestState;
}

export class LoginPage extends React.PureComponent<IProps, void> {
  public render() {
    return (
      <div>
        <LoginForm onSubmit={this.login} response={this.props.response} />
        <ModalFooter>
          <p className="text-align-center">
            <a onClick={this.props.onResetPasswordClick}>
            <FormattedMessage id='login.forgot-password'/></a>
          </p>
        </ModalFooter>
      </div>
    );
  }

  public componentWillMount() {
    this.props.resetRequestState(LOGIN);
  }

  private login = (data: IFormData) => {
    this.props.login(data.email, data.password);
  }
}

export function mapStateToProps({responses}: IStore, existing: IOwnProps) {
  return {
    ...existing,
    response: getResponseStatus(responses, LOGIN)
  }
}

export default connect(mapStateToProps, {login, resetRequestState})(LoginPage);
