import {
  Column,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getVolunteerCounts } from '../../../actions/studies';
import { getCollectionByName, getCollectionItems } from '../../../collections/reducers';
import { DIABETES_TYPE, FILTER_OPTIONS_READABLE, INCLUDE_CHOICES_READABLE } from '../../../consts/constants';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store';
import { ISiteRecord } from '../../../store/data-types';
import { IStudyMedicationRecord, IStudyRecord } from '../../../store/data-types/studies';
import { numericRangeToString } from '../../../utils';

const { actions: { getAllCollection } } = collectionsModule;

const PAGE_NAME = 'VIEW_STUDY';

const MESSAGES = {
  criteria: 'studies.criteria',
  sites: 'generic.sites',
  diabetesType: 'generic.diabetes_type',
  injectionInsulin: 'studies.injection_insulin',
  injectionOther: 'studies.injection_other',
  general_criteria: 'studies.general_criteria',
  medications: 'generic.medications',
  tablets: 'generic.tablets',
  age_range: 'studies.age_range',
  bmi_range: 'studies.bmi_range'
};

interface IStateProps {
  siteNamesMap: Map<string, string>;
  volunteerCounts: Map<string | undefined, | number>;
}

interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
  getVolunteerCounts: typeof getVolunteerCounts;
}

interface IOwnProps {
  study: IStudyRecord;
  hideSites?: boolean;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

export class StudyDetails extends React.PureComponent<Props, {}> {
  public componentWillMount () {
    const { id } = this.props.study;

    this.props.getVolunteerCounts(id);
    this.props.getAllCollection('sites', {});
  }

  public render () {
    const {
      study,
      siteNamesMap,
      volunteerCounts,
      hideSites
    } = this.props;

    const {
      active,
      diabetes_type,
      injection_insulin,
      injection_other,
      study_sites,
      tablets,
      age_range,
      bmi_range,
      study_medications
    } = study;

    return (
      <div>
        <Row>
          <Column>
            <p>
            <strong>
              <FormattedMessage id={MESSAGES.criteria} />
            </strong>
            </p>
          </Column>
          <Column md={3}>
            <p>
              <strong>
                <FormattedMessage id={MESSAGES.general_criteria} />
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.diabetesType} />
              {': '}
              <strong>
                {DIABETES_TYPE.get(diabetes_type, '--')}
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.injectionInsulin} />
              {': '}
              <strong>
                {FILTER_OPTIONS_READABLE.get(injection_insulin, '--')}
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.injectionOther} />
              {': '}
              <strong>
                {FILTER_OPTIONS_READABLE.get(injection_other, '--')}
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.tablets} />
              {': '}
              <strong>
                {FILTER_OPTIONS_READABLE.get(tablets, '--')}
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.age_range} />
              {': '}
              <strong>
                {numericRangeToString(age_range) || '--'}
              </strong>
            </p>
            <p>
              <FormattedMessage id={MESSAGES.bmi_range} />
              {': '}
              <strong>
                {numericRangeToString(bmi_range) || '--'}
              </strong>
            </p>
          </Column>
          <Column md={9}>
            <p>
              <strong>
                <FormattedMessage id={MESSAGES.medications} />
              </strong>
            </p>
            {this.renderMedications(study_medications)}
          </Column>
        </Row>

        <hr />

        {!hideSites &&
          <Row>
            <Column>
              <p>
                <strong>
                  <FormattedMessage id={MESSAGES.sites} />
                </strong>
              </p>
              <Table fill condensed>
                <TableHead>
                  <TableRow>
                    <TableHeader>
                      Name
                    </TableHeader>
                    <TableHeader>
                      Green lit
                    </TableHeader>
                    <TableHeader>
                      Researchers
                    </TableHeader>
                    <TableHeader>
                      Volunteers
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    study_sites && study_sites.map((site) => {
                      if (active && site.is_confirmed_site || !active) {
                        return (
                          <TableRow key={site.site}>
                            <TableCell>
                              {siteNamesMap.get(site.site, '--')}
                            </TableCell>
                            <TableCell>
                              {site.green_lit ? 'True' : 'False'}
                            </TableCell>
                            <TableCell>
                              {site.researchers.length}
                            </TableCell>
                            <TableCell>
                              {volunteerCounts.get(site.id || '', '--')}
                            </TableCell>
                          </TableRow>
                        )
                      }
                    })
                  }
                </TableBody>
              </Table>
            </Column>
          </Row>
        }
      </div>
    );
  }
  private renderDosage(medication: IStudyMedicationRecord): string {
    if (medication.max_dosage !== null || medication.min_dosage !== null) {
      return `${medication.min_dosage} - ${medication.max_dosage}`;
    }
    return '--';
  }

  private renderMedications(studyMedications: List<IStudyMedicationRecord> | null) {
     return (
        <Table fill condensed>
          <TableHead>
            <TableRow>
              <TableHeader>
                Trade name
              </TableHeader>
              <TableHeader>
                Generic name
              </TableHeader>
              <TableHeader>
                Class
              </TableHeader>
              <TableHeader>
                Type
              </TableHeader>
              <TableHeader>
                Min - Max Dosage
              </TableHeader>
              <TableHeader>
                Include
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            studyMedications && studyMedications.map((medication, index) => (
              <TableRow key={index}>
                <TableCell>
                  {medication.medication ? medication.medication_display.trade_name : '--'}
                </TableCell>
                <TableCell>
                  {medication.generic_name ? medication.generic_name_display.name : '--'}
                </TableCell>
                <TableCell>
                  {medication.med_class ? medication.med_class_display.name : '--'}
                </TableCell>
                <TableCell>
                  {medication.med_type ? medication.med_type : '--'}
                </TableCell>
                <TableCell>
                  {this.renderDosage(medication)}
                </TableCell>
                <TableCell>
                  {medication.include ? INCLUDE_CHOICES_READABLE[medication.include] : ''}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
     );
  }
}

export const mapStateToProps = (state: IStore, props: IOwnProps): IOwnProps & IStateProps => {
  const { collections, volunteerCounts } = state;
  const sites = getCollectionByName(collections.sites).results;
  const { study } = props;

  return {
    ...props,
    volunteerCounts,
    siteNamesMap: study && sites ?
      sites.toMap().mapEntries(([index, site]) => [site.id, site.name]) :
      Map<string, string>(),
  };
}

export default connect(mapStateToProps, {getAllCollection, getVolunteerCounts})(StudyDetails);
