import { Dispatch } from 'redux';
import { ICollectionOptions, TFilters } from '../collections/types';
import { NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE } from '../consts/constants';
import { collectionsModule } from '../reducers/collections';
import { IItemsState, itemsModule } from '../reducers/items';
import { makeAsyncActionSet } from '../utils';
import { GetStateCallback } from './';
import { dispatchGenericRequest } from './index';

const { actions: { getCollection } } = collectionsModule;
const { actions: { patchItem } } = itemsModule;

export function patchItemAndFetchProfileLogs(
  itemsName: keyof IItemsState,
  itemId: string,
  volunteerId: string,
  data: any,
  itemOverrideUrl: string,
) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return patchItem(itemsName, itemId, data, itemOverrideUrl)(dispatch, getState).then(() => {
      dispatch(getCollection(
        'profile-logs/volunteer',
        { pageSize: NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE },
        `profile-logs-volunteer-${volunteerId}`,
        `profile-logs/volunteer/${volunteerId}`
      ));
    });
  }
}

export const GET_STUDY_OUTCOME = makeAsyncActionSet('GET_STUDY_OUTCOME');
export function getVolunteerStudyOutcome(volunteerId: string) {
  return dispatchGenericRequest(
    GET_STUDY_OUTCOME,
    `/api/users/volunteers/${volunteerId}/study-outcome/`,
    'GET',
  );
}

export const SET_STUDY_OUTCOME = makeAsyncActionSet('SET_STUDY_OUTCOME');
export function setVolunteerStudyOutcomeAndFetchProfileLogs(volunteerId: string, studyOutcome: string) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      SET_STUDY_OUTCOME,
      `/api/users/volunteers/${volunteerId}/study-outcome/`,
      'POST',
      {study_outcome: studyOutcome}
    )(dispatch, getState).then(() => {
      dispatch(
        getCollection(
          'profile-logs/volunteer',
          { pageSize: NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE },
          `profile-logs-volunteer-${volunteerId}`,
          `profile-logs/volunteer/${volunteerId}`
        )
      );
    });
  };
}

export const CONFIRM_HANDOVER_CONSENT = makeAsyncActionSet('CONFIRM_HANDOVER_CONSENT');
export function confirmHandoverConsent(
    volunteerId: string,
    filters: TFilters,
    pageSize: number,
    options: ICollectionOptions,
  ) {
  return (dispatch: Dispatch<any>, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      CONFIRM_HANDOVER_CONSENT,
      `/api/users/volunteers/${volunteerId}/confirm-handover-consent/`,
      'POST',
    )(dispatch, getState).then(() => {
      dispatch(
        getCollection(
          'users/volunteers',
          {
            filters,
            pageSize,
            page: options && options.page || 1,
            ...options,
          },
          'VOLUNTEERS_LIST'
        )
      )
    }).catch((error) => {
      window.alert(error);
    })
  };
}
