import { Button, Column, FormGroup, Row, Well } from '@dabapps/roe';
import { fromJS, List } from 'immutable';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { DataShape, Field, FormProps, reduxForm } from 'redux-form';
import { setSimpleComponentState } from '../../../actions/actions';
import { NBSP } from '../../../consts/constants';
import { USER_TYPES } from '../../../consts/user-types';
import { UPDATE_ITEM } from "../../../items/actions";
import { isPending } from '../../../responses'
import { IStore } from "../../../store";
import { ICallOutcomeRecord, IContactTypeRecord, IUserRecord, UserTypes } from "../../../store/data-types";
import Select from '../../select';

export const PROFILE_LOGS_FORM_ID = 'profile-log-actions';

const QA_OPTIONS = fromJS([
  {value: true, label: 'Approve'},
  {value: false, label: 'Reject'}
]);

const NAVBAR_DEFAULT_HEIGHT = 107;

const LOG_ACTIONS_CONTENT_DEFAULT_HEIGHT = 91;

interface IExternalProps extends FormProps<DataShape, {}, {}> {
  userProfile?: IUserRecord;
  callOutcomesList: List<ICallOutcomeRecord>;
  contactTypeList: List<IContactTypeRecord>;
  componentKey: string;
}

interface IStateProps {
  itemIsPending: boolean;
  stateScrolledPast: boolean;
}

interface IDispatchProps {
  setSimpleComponentState: typeof setSimpleComponentState;
}

const MESSAGES = {
  logOutcome: 'profile-log.log_outcome',
  qaReview: 'profile-log.qa_review'
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class ProfileLogActions extends React.PureComponent<IProps, {}> {

  private contentElement: HTMLDivElement;

  private placeholderElement: HTMLDivElement;

  public componentDidMount() {
    document.addEventListener('scroll', this.addClassOnScroll);
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.addClassOnScroll);
  }

  public call_handler_fields() {
    const { userProfile } = this.props;
    let columnWidth = 9;
    if (userProfile && userProfile.type === USER_TYPES.QA) {
      columnWidth = 6;
    }

    return (
      <Column md={columnWidth}>
        <Row>
          <Column md={6} className="padding-right-none-md">
            <FormGroup block>
              <label className="bold">
                <FormattedMessage id={MESSAGES.logOutcome} />
              </label>
              <Select
                onChange={this.noop}
                name={"contact_type"}
                placeholder="Select a contact type"
                options={this.props.contactTypeList}
                getName={(item) => item.get('name')}
                getValue={(item) => item.get('id')}
              />
            </FormGroup>
          </Column>
          <Column md={6}>
            <FormGroup block>
              <label className="bold">
                {NBSP}
              </label>
              <Select
                onChange={this.noop}
                name={"call_outcome"}
                placeholder="Select a call outcome"
                options={this.props.callOutcomesList}
                getName={(item) => item.get('name')}
                getValue={(item) => item.get('id')}
              />
            </FormGroup>
          </Column>
        </Row>
      </Column>
    );
  }

  public qa_fields() {
    return(
      <span>
        <Column md={3}>
          <FormGroup block>
            <label className="bold">
              <FormattedMessage id={MESSAGES.qaReview} />
            </label>
            <Select
              onChange={this.noop}
              name={"has_passed_qa"}
              options={QA_OPTIONS}
              getName={(item) => item.get('label')}
              getValue={(item) => item.get('value')}/>
          </FormGroup>
        </Column>
        {this.call_handler_fields()}
      </span>
    )
  }

  public render() {
    const { itemIsPending, userProfile, stateScrolledPast } = this.props;
    const contentElementRect = this.contentElement && this.contentElement.getBoundingClientRect();
    const navbarElement = document.getElementById('main-navbar');
    let fields = null;
    if (userProfile && userProfile.type === USER_TYPES.CALL_HANDLER) {
      fields = this.call_handler_fields();
    } else if (userProfile && userProfile.type === USER_TYPES.QA) {
      fields = this.qa_fields();
    } else {
      return null;
    }

    return(
      <div
        ref={this.setPlaceholderRef}
        style={{height: contentElementRect ? contentElementRect.height : LOG_ACTIONS_CONTENT_DEFAULT_HEIGHT}}
      >
        <div
          ref={this.setContentRef}
          className={stateScrolledPast ? 'container profile-log-actions-scrolled' : ''}
          style={{
            position: stateScrolledPast ? 'fixed' : 'static',
            top: navbarElement ? navbarElement.clientHeight : NAVBAR_DEFAULT_HEIGHT
          }}
        >
          <Well className="dark">
            <Row className="margin-vertical-base">
              {fields}
              <Column md={3}>
                <FormGroup block>
                  <div className="profile-log-actions-save">
                    <Button type="submit" className="button primary" disabled={itemIsPending}>
                      <FormattedMessage id='volunteer_profile.save' />
                    </Button>
                  </div>
                </FormGroup>
              </Column>
            </Row>
          </Well>
        </div>
      </div>
    );
  }

  private setContentRef = (element: HTMLDivElement) => {
    this.contentElement = element;
  }

  private setPlaceholderRef = (element: HTMLDivElement) => {
    this.placeholderElement = element;
  }

  private addClassOnScroll = () => {
    const { componentKey, setSimpleComponentState, stateScrolledPast } = this.props;
    const navbarElement = document.getElementById('main-navbar');
    if (this.placeholderElement) {
      const placeholderElement = this.placeholderElement.getBoundingClientRect();
      const navbarHeight = navbarElement ? navbarElement.clientHeight : NAVBAR_DEFAULT_HEIGHT;
      const distanceToElementTop = placeholderElement.top - navbarHeight;
      if (distanceToElementTop < 0 && !stateScrolledPast) {
        setSimpleComponentState(componentKey, true);
      } else if (distanceToElementTop > 0 && stateScrolledPast) {
        setSimpleComponentState(componentKey, false);
      }
    }
  }

  // tslint:disable-next-line:no-empty
  private noop = () => {}
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { responses } = state;
  return {
    itemIsPending: isPending(responses, UPDATE_ITEM, "users/volunteers/user"),
    stateScrolledPast: state.simpleComponentState.get(props.componentKey, false)
  }
}

export const ConnectedProfileLogActions = reduxForm({
  form: PROFILE_LOGS_FORM_ID
})(ProfileLogActions);

export default connect(mapStateToProps, {
  setSimpleComponentState
})(ConnectedProfileLogActions);
