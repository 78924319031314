import { fromJS } from 'immutable';
import * as React from 'react';

import GenericDetail from '^/components/chadmin/generic-detail';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-4', 'column sm-4', 'column sm-4']],
    elements: [['name', 'display', 'active']],
  },
]);

const FORM_NAME = 'EDIT_TITLES';

const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  display: {
    label: 'Display Name',
    max_length: 255,
    type: 'text',
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  },
});

const MESSAGES = {
  edit: 'titles.user_titles.edit',
};

interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

export default class Detail extends React.PureComponent<IExternalProps, void> {
  public render() {
    return (
      <GenericDetail
        formName={FORM_NAME}
        messages={MESSAGES}
        params={this.props.params}
        detailFields={DETAIL_FIELDS}
        detailFieldOptions={DETAIL_FIELD_OPTIONS}
        onCancelLinkTo={'/app/titles/'}
        itemsName={'users/titles'}
      />
    );
  }
}
