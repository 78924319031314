import { List, Map } from "immutable";
import * as React from "react";

import SimpleTable from '../tables/simple-table';

interface IProps {
  studyStats: Map<string, any>;
}

interface IStudyOutcomeBySite {
  id: string;
  site: string;
  SUITABLE: number;
  CONTACT_IN_PROGRESS: number;
  RESEARCHER_REVIEW_REQUIRED: number;
  PRESTUDY_CHECK: number;
  NOT_ELIGIBLE: number;
  REQUEST_REMOVAL: number;
  FAILED_PRESTUDY_CHECK: number;
}

const COLUMN_WIDTH_SMALL = 80;
const COLUMN_WIDTH_LARGE = 100;

export const HEADERS = [
  {
    content: (item: IStudyOutcomeBySite) => <span>{item.site}</span>,
    headerLabel: 'Site',
    key: 'site',
    width: COLUMN_WIDTH_SMALL,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) =>
      <span className={`${item.SUITABLE > 0 && "bold"}`}>
        {item.SUITABLE}
      </span>,
    headerLabel: 'Suitable for screening',
    key: 'SUITABLE',
    width: COLUMN_WIDTH_SMALL,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) =>
      <span className={`${item.CONTACT_IN_PROGRESS > 0 && "bold"}`}>
        {item.CONTACT_IN_PROGRESS}
      </span>,
    headerLabel: 'Contact in progress',
    key: 'CONTACT_IN_PROGRESS',
    width: COLUMN_WIDTH_SMALL,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) =>
      <span className={`${item.RESEARCHER_REVIEW_REQUIRED > 0 && "bold"}`}>
        {item.RESEARCHER_REVIEW_REQUIRED}
      </span>,
    headerLabel: 'Researcher review required',
    key: 'RESEARCHER_REVIEW_REQUIRED',
    width: COLUMN_WIDTH_LARGE,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) => <span>{item.PRESTUDY_CHECK}</span>,
    headerLabel: 'Pre-study check',
    key: 'PRESTUDY_CHECK',
    width: COLUMN_WIDTH_SMALL,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) => <span>{item.NOT_ELIGIBLE}</span>,
    headerLabel: 'Not eligible',
    key: 'NOT_ELIGIBLE',
    width: COLUMN_WIDTH_SMALL,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) => <span>{item.REQUEST_REMOVAL}</span>,
    headerLabel: 'Request removal from VOP',
    key: 'REQUEST_REMOVAL',
    width: COLUMN_WIDTH_LARGE,
    sortable: false,
  },
  {
    content: (item: IStudyOutcomeBySite) => <span>{item.FAILED_PRESTUDY_CHECK}</span>,
    headerLabel: 'Failed pre-study check',
    key: 'FAILED_PRESTUDY_CHECK',
    width: COLUMN_WIDTH_LARGE,
    sortable: false,
  },
];

export class DashboardStudyOutcomesTable extends React.PureComponent<IProps, void> {
  public render() {
    const { studyStats } = this.props;

    return (
      <SimpleTable
        className="study-outcomes-table"
        headers={HEADERS}
        items={studyStats.get('study_outcomes_by_site', List()).toJS() as List<IStudyOutcomeBySite>}
      />
    );
  }
}

export default DashboardStudyOutcomesTable;
