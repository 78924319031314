import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MedicationFormData } from '../../../../store/data-types/studies';
import { IFormArrayComponentProps } from '../../../forms/form-array';
import Medication from './medication';

const MESSAGES = {
  addMedication: 'studies.add_medication',
};

export default class Medications extends React.PureComponent<IFormArrayComponentProps<MedicationFormData>, {}> {
  public render () {
    const { items } = this.props;

    return (
      <div>
        {
          items.map(({id, value}) => (
            <Medication
              key={id}
              medicationId={id}
              medication={value}
              removeMedication={this.onRemoveItemClick}
              updateMedication={this.onUpdateItem}
            />
          ))
        }
        <p className="text-align-right font-size-small">
          <a onClick={this.onAddItemClick}>
            <FormattedMessage id={MESSAGES.addMedication} />
          </a>
        </p>
      </div>
    );
  }

  private onUpdateItem = (id: string, value: MedicationFormData) => {
    this.props.onChange(id, value);
  }

  private onAddItemClick = () => {
    this.props.addItem(null, {});
  }

  private onRemoveItemClick = (id: string) => {
    this.props.removeItem(id);
  }
}
