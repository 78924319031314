import { fromJS } from 'immutable';
import * as React from 'react';

import GenericList from '^/components/chadmin/generic-list';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS = fromJS([
  {
    name: ['name'],
    display_name: 'Name',
    type: 'detail',
    route: DETAIL_ROUTE,
    sortable: false,
  },
  {
    name: ['active'],
    display_name: 'Is active?',
    type: 'boolean',
    sortable: false,
  }
]);

const MESSAGES = {
  title: 'medication.list.generic-name.title',
  create: 'medication.list.generic-name.create',
};

const COLLECTION_NAME = 'MEDICINE_GENERIC_NAME_LIST';

// tslint:disable-next-line:no-unused-variable
type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, void> {
  public render() {
    return (
      <GenericList
        canCreate={this.props.permissions.canCreate}
        messages={MESSAGES}
        collectionColumns={LIST_COLUMNS}
        collectionName={COLLECTION_NAME}
        collectionKey={'medications/generic-name'}
        defaultFilters={fromJS({include_inactive: false})}
      />
    );
  }
}
