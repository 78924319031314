import * as Cookies from 'js-cookie';
import { push, replace } from 'react-router-redux'
import { IUserRecord } from '../store/data-types';
import { makeAsyncActionSet } from '../utils';
import { DispatchCallback, dispatchGenericRequest, GetStateCallback } from './index';
import { closeModal } from './modals';

export const LOGIN = makeAsyncActionSet('LOGIN');
export function login(email: string, password: string) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      LOGIN,
      '/api/users/login/',
      'POST',
      {username: email, password}
    )(dispatch, getState).then(() => {
      dispatch(closeModal());
      return dispatch(getUserInfo());
    }).then(() => {
      return dispatch(push('/app/'));
    });
  };
}

export const REGISTER = makeAsyncActionSet('REGISTER');
export function register(
  email: string, firstName: string, lastName: string,
  telephone: string, title: string, readTermsAndConditions: boolean,
  birthDate: string, advert: string
) {
  return dispatchGenericRequest(
    REGISTER,
    '/api/users/register/',
    'POST',
    {
      email,
      first_name: firstName,
      last_name: lastName,
      telephone,
      title,
      read_terms_and_conditions: readTermsAndConditions,
      birth_date: birthDate,
      advert_source: advert
    }
  )
}

export const FORGOT_PASSWORD = makeAsyncActionSet('FORGOT_PASSWORD');
export function forgotPassword(email: string) {
  return dispatchGenericRequest(
    FORGOT_PASSWORD,
    '/api/users/password/reset/',
    'POST',
    {email}
  );
}

export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export function resetPassword(uid: string, token: string, newpassword1: string, newpassword2: string) {
 return dispatchGenericRequest(
   RESET_PASSWORD,
  `/api/users/password/reset/${uid}/${token}/`,
   'POST',
   {
    new_password1: newpassword1,
    new_password2: newpassword2
   }
 );
}

export const GET_USER_INFO = makeAsyncActionSet('GET_USER_INFO');
export function getUserInfo() {
  return dispatchGenericRequest(
    GET_USER_INFO,
    '/api/users/me/',
    'GET'
  );
}

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export function logout() {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      LOGOUT,
      '/api/users/logout/',
      'POST'
    )(dispatch, getState).then(() => {
      Cookies.remove('sessionid');
      window.location.href = '/';
    });
  };
}

export function checkLoggedIn(ignoredRegex?: RegExp) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {

    const state = getState();

    if (ignoredRegex && ignoredRegex.test(state.routing.locationBeforeTransitions.pathname)) {
      return;
    }

    getUserInfo()(dispatch, getState)
      .catch((error) => {
        const status = error && error.response && error.response.status;

        if (status === 401) {
          Cookies.remove('sessionid');
          dispatch(replace('/'));
        }
      })
  };
}

export const UPDATE_USER_DETAILS = makeAsyncActionSet('UPDATE_USER_DETAILS');

export function updateUserDetails(userProfile: IUserRecord) {
  return dispatchGenericRequest(
    UPDATE_USER_DETAILS,
    '/api/users/me/',
    'PATCH',
    userProfile
  );
}

export const CHANGE_PASSWORD = makeAsyncActionSet('CHANGE_PASSWORD');

export function changePassword(currentPassword: string, password: string, confirmPassword: string) {
  return dispatchGenericRequest(
    CHANGE_PASSWORD,
    '/api/users/me/change-password/',
    'POST',
    {current_password: currentPassword, password, confirm_password: confirmPassword}
  );
}

export const CHANGE_EMAIL = makeAsyncActionSet('CHANGE_EMAIL');

export function changeEmail(email: string) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      CHANGE_EMAIL,
      '/api/users/me/change-email/',
      'PUT',
      {email}
    )(dispatch, getState).then(() => {
      dispatch(closeModal());
      return dispatch(getUserInfo());
    });
  };
}

export const START_IMPERSONATION = makeAsyncActionSet('START_IMPERSONATION');
export function impersonate(email: string) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      START_IMPERSONATION,
      '/api/users/impersonate/',
      'POST',
      { email }
    )(dispatch, getState).then(() => {
      return dispatch(getUserInfo());
    }).then(() => {
      return dispatch(push('/app/'));
    });
  };
}

export const STOP_IMPERSONATION = makeAsyncActionSet('STOP_IMPERSONATION');
export function stopImpersonate() {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return dispatchGenericRequest(
      STOP_IMPERSONATION,
      '/api/users/impersonate/',
      'DELETE',
    )(dispatch, getState).then(() => {
      return dispatch(getUserInfo());
    }).then(() => {
      return dispatch(push('/app/users/'));
    });
  };
}
