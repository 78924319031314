// tslint:disable-next-line:no-unused-variable
import { isImmutable, List, Map } from 'immutable';
import * as _ from 'underscore';
import { ResponsesReducerState } from './reducers/responses';
// tslint:disable-next-line:no-unused-variable
import { FormErrors, IAsyncActionSet, ImmutableJson, RequestStates } from './utils';

export function isPending(state: ResponsesReducerState, actionSet: IAsyncActionSet, tag?: string): boolean {
  return state.getIn([actionSet, tag, 'requestState']) === 'REQUEST';
}

export function hasFailed(state: ResponsesReducerState, actionSet: IAsyncActionSet, tag?: string): boolean {
  return state.getIn([actionSet, tag, 'requestState']) === 'FAILURE';
}

export function hasSucceeded(state: ResponsesReducerState, actionSet: IAsyncActionSet, tag?: string): boolean {
  return state.getIn([actionSet, tag, 'requestState']) === 'SUCCESS';
}

export function anyPending(
  state: ResponsesReducerState,
  actionSets: Array<IAsyncActionSet | [IAsyncActionSet, string]>
): boolean {
  return _.any(actionSets, (actionSet) => {
    if (actionSet instanceof Array) {
      const [actualSet, tag] = actionSet;
      return isPending(state, actualSet, tag);
    } else {
      return isPending(state, actionSet);
    }
  });
}

export type PossibleError = FormErrors | List<FormErrors> | Error | string | undefined;

export function getFormErrors(
  state: ResponsesReducerState,
  actionSet: IAsyncActionSet,
  tag?: string
): FormErrors | undefined {
  if (!hasFailed(state, actionSet, tag)) {
    return undefined;
  }

  const data: PossibleError = state.getIn([actionSet, tag, 'data']);

  if (Map.isMap(data)) {
    return data;
  }

  if (List.isList(data)) {
    const nestedErrors = data.map((error) => error.toList());
    // Types for flatten are wrong, so manually casting here
    const errors = nestedErrors.flatten().toList() as List<string>;

    return Map({errors});
  }

  if (typeof data === 'string') {
    return Map({errors: List.of(data)});
  }

  if (data instanceof Error) {
    return Map({errors: List.of(data.message)});
  }

  return Map({errors: List.of('Something went wrong')});
}

export interface IResponseStatus {
  formErrors?: FormErrors;
  hasFailed: boolean;
  hasSucceeded: boolean;
  isLoading: boolean;
}

export function getResponseStatus(
  state: ResponsesReducerState,
  actionSet: IAsyncActionSet,
  tag?: string
): IResponseStatus {
  return {
    formErrors: getFormErrors(state, actionSet, tag),
    hasFailed: hasFailed(state, actionSet, tag),
    hasSucceeded: hasSucceeded(state, actionSet, tag),
    isLoading: isPending(state, actionSet, tag)
  };
}
