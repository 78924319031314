import { Alert } from '@dabapps/roe';
import {
  convertMeasurementsToValue,
  convertValueToMeasurements
} from '^/components/volunteers/utils';
import * as React from "react";
import { FontAwesome } from "react-inline-icons";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { UPDATE_USER_DETAILS, updateUserDetails } from "../../../../actions/auth";
import { PermissionHOCChildProps } from '../../../../permissions/permissions-hoc';
import {
  getFormErrors,
  hasFailed,
  hasSucceeded,
  isPending
} from "../../../../responses";
import { IStore } from "../../../../store";
import {
  IUserRecord,
  IVolunteerRecord,
  UserRecord,
  VolunteerRecord
} from "../../../../store/data-types";
import { FormErrors } from '../../../../utils';
import PageHeader from "../../../page-header";
import MedicalDetailsForm, { IFormData } from './edit-medical-details-form';
const { IconLightbulbO } = FontAwesome;

const MESSAGES = {
  edit: "me.medical"
};

type IExternalProps = PermissionHOCChildProps;

interface IStateProps {
  errors?: FormErrors | null;
  item: IUserRecord;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  initialValues: IFormData;
  volunteer: IVolunteerRecord;
}

interface IDispatchProps {
  updateUserDetails: typeof updateUserDetails;
  pushRoute: typeof routerActions.push;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class Detail extends React.PureComponent<IProps, {}> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this)
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.itemHasSucceeded && !this.props.itemHasSucceeded) {
      this.props.pushRoute(`/app/`);
    }
  }

  public render() {
    const { itemHasSucceeded, itemHasFailed, ...rest } = this.props;
    return <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.edit} />} />
        {itemHasFailed && <Alert className="error">
            <p><FormattedMessage id='generic.update_fail' /></p>
          </Alert>}
        <MedicalDetailsForm {...rest} onSubmit={this.submit} />
        <p>
          <IconLightbulbO className="icon-medium-large" />Reason that makes sense to user why we store this.......
        </p>
      </div>;
  }

  private submit(data: IFormData) {
    const {
      volunteer: {
        height,
        weight,
        ...remainingVolunteer
      }
    } = data;
    let { volunteer } = this.props;

    if (height) {
      volunteer = volunteer
        .set('height', convertMeasurementsToValue(height))
        .set('height_display_unit', height.display_unit)
    }

    if (weight) {
      volunteer = volunteer
        .set('weight', convertMeasurementsToValue(weight))
        .set('weight_display_unit', weight.display_unit)
    }

    this.props.updateUserDetails(
      this.props.item.set('volunteer', volunteer.merge({...remainingVolunteer}))
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IStateProps & IExternalProps {
  const { responses, userProfile } = state;
  const volunteer = userProfile && userProfile.volunteer ? userProfile.volunteer : VolunteerRecord();
  const {
    diabetes_type,
    display_hbA1c,
    hbA1c_result_display_unit,
    display_height,
    height_display_unit,
    display_weight,
    weight_display_unit,
    month_diagnosed,
    year_diagnosed,
  } = volunteer;

  return {
    ...props,
    errors: getFormErrors(responses, UPDATE_USER_DETAILS) || null,
    item: userProfile || UserRecord(),
    itemHasFailed: hasFailed(responses, UPDATE_USER_DETAILS),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_USER_DETAILS),
    itemIsPending: isPending(responses, UPDATE_USER_DETAILS),
    initialValues: {
      volunteer: {
        diabetes_type,
        hbA1c_result: display_hbA1c,
        hbA1c_result_display_unit,
        height: convertValueToMeasurements(display_height, height_display_unit),
        weight: convertValueToMeasurements(display_weight, weight_display_unit),
        month_diagnosed: month_diagnosed || '',
        year_diagnosed,
      }
    },
    volunteer
  };
}

export default connect(mapStateToProps, {
  updateUserDetails,
  pushRoute: routerActions.push
})(Detail);
