import { SimpleRecord } from '@dabapps/simple-records';
import { fromJS, List, Map, Record } from 'immutable';
import * as moment from 'moment';
import {
  HBA1C_RESULT_UNIT_DEFAULT,
  HEIGHT_UNIT_DEFAULT,
  WEIGHT_UNIT_DEFAULT } from '../../consts/constants';

export type RecordInstance<T> = Record.Instance<T> & Readonly<T>;
export type TResponseErrors = Map<string, string>;
export type TTypeToRecordMapping<T> = {[K in keyof T]: (({}) => any) };

export interface IAction<PayloadT, MetaT> {
  type: string;
  payload?: PayloadT;
  error?: boolean;
  meta?: MetaT;
}

export interface IRouteProps { params: { id: string; }; }

export type UserTypes =
| 'VOLUNTEER'
| 'CALL_HANDLER'
| 'QA'
| 'CRA'
| 'RESEARCHER'
| 'PROJECT_MANAGER'
| 'ADMIN'
| 'MARKETING';

export type StatusType = 'ACTIVE' | 'DISABLED';

export interface IMotivation {
  id: string,
  active: boolean;
  name: string;
}

export type IMotivationRecord = RecordInstance<IMotivation>;
export const MotivationRecord = Record<IMotivation>({
  id: '',
  active: true,
  name: '',
});

type IUserVolunteerMedication = Pick<IVolunteerMedication, 'id' | 'total_dosage' | 'frequency'> & {
  medication: string;
}

export type IUserVolunteerMedicationRecord = RecordInstance<IUserVolunteerMedication>;
export const UserVolunteerMedicationRecord = Record<IUserVolunteerMedication>({
  id: '',
  frequency: '',
  medication: '',
  total_dosage: ''
});

interface ITelephone {
  id?: string,
  telephone: string,
}

export type ITelephoneRecord = RecordInstance<ITelephone>;
export const TelephoneRecord = Record<ITelephone>({
  id: '',
  telephone: '',
});

interface IStudyVolunteerUser {
  created: string,
  email: string,
  title: string,
  first_name?: string,
  id: string,
  display_full_name: string,
  last_login?: moment.Moment,
  last_name?: string,
  modified: string,
  type_display: string,
  type: UserTypes,
  email_verified: boolean,
  status: string,
  medications: List<IUserVolunteerMedicationRecord>,
  volunteer: string,
}

const StudyVolunteerUserRecordInner = Record<IStudyVolunteerUser>({
  created: '',
  email: '',
  title: '',
  first_name: '',
  id: '',
  display_full_name: '',
  last_login: undefined,
  modified: '',
  type: 'VOLUNTEER',
  type_display: 'Volunteer',
  email_verified: false,
  status: '',
  last_name: '',
  medications: List(),
  volunteer: '',
});

export type IStudyVolunteerUserRecord = RecordInstance<IStudyVolunteerUser>;
export const StudyVolunteerUserRecord = (user?: Partial<IStudyVolunteerUser>): IStudyVolunteerUserRecord => {
  const userRecord = StudyVolunteerUserRecordInner(user);

  return userRecord
    .set('medications', user && user.medications ? List(user.medications) : List())
}

export type DisplayUnit = 'CM' | 'INCHES' | 'KG' | 'POUND';
export type Hba1cUnit = 'MMOL' | 'PERCENTAGE';
export interface IFormMeasurements {
  large_unit_value: string | null | undefined;
  small_unit_value: string | null | undefined;
  display_unit: DisplayUnit;
}

export interface IVolunteer {
  advert_source: string | null,
  advert_source_display: IAdvert | null,
  bmi: number | string,
  comments: string,
  id: string,
  title: string,
  gender: string,
  postcode: string,
  address_1: string,
  address_2: string,
  address_3: string,
  city: string,
  county: string,
  telephone_numbers: List<ITelephoneRecord>,
  birth_date: string | null,
  diabetes_type: string,
  month_diagnosed: string,
  year_diagnosed: string | null,
  pharmacy: string,
  display_hbA1c: number | null,
  hbA1c_result: number | null,
  hbA1c_result_display_unit: Hba1cUnit,
  display_height: number | null,
  height: number | null,
  height_display_unit: DisplayUnit,
  display_weight: number | null,
  weight: number | null,
  weight_display_unit: DisplayUnit,
  availability: string,
  completion: string | null,
  medication_count: number | null,
  motivations: List<string>,
  motivations_display: List<IMotivationRecord>,
  user: IStudyVolunteerUserRecord | null,
}

const VolunteerRecordInner = Record<IVolunteer>({
  address_1: '',
  address_2: '',
  address_3: '',
  advert_source: '',
  advert_source_display: null,
  availability: '',
  bmi: '-',
  birth_date: null,
  city: '',
  completion: null,
  comments: '',
  county: '',
  month_diagnosed: '',
  year_diagnosed: null,
  diabetes_type: '',
  gender: '',
  display_hbA1c: null,
  hbA1c_result: null,
  hbA1c_result_display_unit: HBA1C_RESULT_UNIT_DEFAULT,
  display_height: null,
  height: null,
  height_display_unit: HEIGHT_UNIT_DEFAULT,
  id: '',
  medication_count: null,
  motivations: List(),
  motivations_display: List(),
  pharmacy: '',
  postcode: '',
  telephone_numbers: List(),
  title: '',
  display_weight: null,
  weight: null,
  weight_display_unit: WEIGHT_UNIT_DEFAULT,
  user: null,
});

export interface IVolunteerRecord extends Record.Instance<IVolunteer>, Readonly<IVolunteer> {}
export const VolunteerRecord = (volunteer?: Partial<IVolunteer>): IVolunteerRecord => {
  return VolunteerRecordInner(volunteer)
    .set('advert_source_display', volunteer && volunteer.advert_source_display ? volunteer.advert_source_display : null)
    .set('birth_date', volunteer && volunteer.birth_date ? volunteer.birth_date : null)
    .set('month_diagnosed', volunteer && volunteer.month_diagnosed ? volunteer.month_diagnosed : '')
    .set('year_diagnosed', (volunteer && volunteer.year_diagnosed !== null && volunteer.year_diagnosed !== undefined) ?
      String(volunteer.year_diagnosed) : null)
    .set('motivations', volunteer ? List(volunteer.motivations || []) : List())
    .set('motivations_display', volunteer && volunteer.motivations_display ? volunteer.motivations_display
      .map((motivation) => MotivationRecord(motivation)) : List())
    .set('telephone_numbers', volunteer && volunteer.telephone_numbers ? volunteer.telephone_numbers
      .map((telephone) => TelephoneRecord(telephone)) : List())
    .set('user', volunteer && volunteer.user && typeof volunteer.user === 'object' ?
      StudyVolunteerUserRecord(volunteer.user) : null);
}

export interface IChadminListMessages {
  title: string,
  create: string
}

export interface IChadminDetailMessages {
  edit: string
}

export interface IChadminCreateMessages {
  create: string
}

export interface IUser {
  created: string,
  email: string,
  title: string,
  first_name?: string,
  id: string,
  display_full_name: string,
  name_email_dropdown: string,
  last_login?: moment.Moment,
  last_name?: string,
  original_user?: string,
  modified: string,
  volunteer?: IVolunteerRecord,
  type_display: string,
  type: UserTypes,
  email_verified: boolean,
  status: StatusType,
  medications: List<IUserVolunteerMedicationRecord>,
  profile_logs?: Map<string, string>
}

const UserRecordInner = Record<IUser>({
  created: '',
  email: '',
  title: '',
  first_name: '',
  id: '',
  display_full_name: '',
  name_email_dropdown: '',
  last_login: undefined,
  original_user: undefined,
  modified: '',
  type: 'VOLUNTEER',
  type_display: 'Volunteer',
  email_verified: false,
  status: 'ACTIVE',
  last_name: '',
  volunteer: undefined,
  medications: List(),
  profile_logs: Map<string, string>()
});

export type IUserRecord = RecordInstance<IUser>;
export const UserRecord = (user?: Partial<IUser>): IUserRecord => {
  const userRecord = UserRecordInner(user);
  const volunteer = userRecord.get('volunteer');

  return userRecord
    .set('volunteer', volunteer ? VolunteerRecord(volunteer) : undefined)
    .set('medications', userRecord ? List(userRecord.medications || []) : List());
}

export type IStudyCraOptionRecord = Readonly<{
  id: string;
  display_full_name: string;
}>;
export const StudyCraOptionRecord = SimpleRecord<IStudyCraOptionRecord>({
  id: '',
  display_full_name: '',
});

export interface IContactType {
  active: boolean;
  name: string;
  id: string;
}

export type IContactTypeRecord = RecordInstance<IContactType>;
export const ContactTypeRecord = Record<IContactType>({
  active: true,
  name: '',
  id: '',
});

export interface ICallOutcome {
  active: boolean;
  type: string;
  created: string;
  id: string;
  name: string;
}

export type ICallOutcomeRecord = RecordInstance<ICallOutcome>;
export const CallOutcomeRecord = Record<ICallOutcome>({
  active: false,
  type: '',
  created: '',
  id: '',
  name: '',
});

export interface IStudyOutcome {
  active: boolean;
  type: string;
  created: string;
  id: string;
  name: string;
}

export type IStudyOutcomeRecord = RecordInstance<IStudyOutcome>;
export const StudyOutcomeRecord = Record<IStudyOutcome>({
  active: false,
  type: '',
  created: '',
  id: '',
  name: '',
});

export interface ILatestContactLogUser {
  first_name: string;
  id: string;
  last_name: string;
  display_full_name: string;
}

export type ILatestContactLogUserRecord = RecordInstance<ILatestContactLogUser>;
export const LatestContactLogUserRecord = Record<ILatestContactLogUser>({
  first_name: '',
  id: '',
  last_name: '',
  display_full_name: '',
});

export interface ILatestContactLog {
  contact_type: string;
  contact_type_display: IContactTypeRecord;
  created: string;
  id: string;
  included_in_study?: string;
  modified: string;
  call_outcome: string;
  call_outcome_display: ICallOutcomeRecord;
  has_passed_qa?: boolean;
  study: string;
  user: string;
  user_display: ILatestContactLogUserRecord;
  volunteer: string;
}

export type ILatestContactLogRecord = RecordInstance<ILatestContactLog>;
export const LatestContactLogRecordInner = Record<ILatestContactLog>({
  contact_type_display: ContactTypeRecord(),
  contact_type: '',
  created: '',
  call_outcome: '',
  id: '',
  included_in_study: undefined,
  modified: '',
  call_outcome_display: CallOutcomeRecord(),
  has_passed_qa: false,
  study: '',
  user_display: LatestContactLogUserRecord(),
  user: '',
  volunteer: '',
});

export const LatestContactLogRecord = (latestContactLog: ILatestContactLog): ILatestContactLogRecord => {
  return LatestContactLogRecordInner(latestContactLog)
    .set('contact_type_display', ContactTypeRecord(latestContactLog.contact_type_display))
    .set('call_outcome_display', CallOutcomeRecord(latestContactLog.call_outcome_display))
    .set('user_display', LatestContactLogUserRecord(latestContactLog.user_display))
}

export interface IBaseUser {
  created: string;
  email: string;
  first_name?: string;
  id: string;
  last_name?: string;
  modified: string;
  title: string;
  type: string;
  type_display: string;
  volunteer: string;
  display_full_name: string;
}

export type IBaseUserRecord = RecordInstance<IBaseUser>;
export const BaseUserRecord = Record<IBaseUser>({
  created: '',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  modified: '',
  title: '',
  type: '',
  type_display: '',
  volunteer: '',
  display_full_name: '',
});

export interface INamedActiveObject {
  active: boolean,
  id: string,
  name: string,
  short_name?: string
}

export type INamedActiveObjectRecord = RecordInstance<INamedActiveObject>;
export const NamedActiveObjectRecord = Record<INamedActiveObject>({
  id: '',
  active: true,
  name: '',
  short_name: ''
});

export interface IMedication {
  id: string,
  trade_name: string,
  active: boolean,
  min_dosage: string,
  max_dosage: string,
  manufacturer: string,
  generic_name: string,
  med_type: string,
  med_class: string,
  unit: string,
  manufacturer_display: INamedActiveObjectRecord,
  generic_name_display: INamedActiveObjectRecord,
  med_class_display: INamedActiveObjectRecord,
  unit_display: INamedActiveObjectRecord
}

export type IMedicationRecord = RecordInstance<IMedication>;
const MedicationRecordInner = Record<IMedication>({
  id: '',
  trade_name: '',
  active: false,
  min_dosage: '',
  max_dosage: '',
  manufacturer: '',
  generic_name: '',
  med_type: '',
  med_class: '',
  unit: '',
  manufacturer_display: NamedActiveObjectRecord(),
  generic_name_display: NamedActiveObjectRecord(),
  med_class_display: NamedActiveObjectRecord(),
  unit_display: NamedActiveObjectRecord(),
});

export const MedicationRecord = (medication?: Partial<IMedication>) : IMedicationRecord => {
  const record = MedicationRecordInner(medication);

  return record
    .set('manufacturer_display', NamedActiveObjectRecord(record.manufacturer_display))
    .set('generic_name_display', NamedActiveObjectRecord(record.generic_name_display))
    .set('med_class_display', NamedActiveObjectRecord(record.med_class_display))
    .set('unit_display', NamedActiveObjectRecord(record.unit_display))
};

export interface IVolunteerMedication {
  id: string,
  total_dosage: string,
  frequency: string,
  volunteer: string,
  frequency_display: INamedActiveObjectRecord,
  medication: IMedicationRecord,
}
export type IVolunteerMedicationRecord = RecordInstance<IVolunteerMedication>;
const VolunteerMedicationRecordInner = Record<IVolunteerMedication>({
  id: '',
  total_dosage: '',
  frequency: '',
  volunteer: '',
  frequency_display: NamedActiveObjectRecord(),
  medication: MedicationRecord({}),
});
export const VolunteerMedicationRecord =
  (volunteerMedication: Partial<IVolunteerMedication>) : IVolunteerMedicationRecord => {
  return VolunteerMedicationRecordInner(volunteerMedication)
  .set('frequency_display', NamedActiveObjectRecord(volunteerMedication.frequency_display))
  .set('medication', MedicationRecord(volunteerMedication.medication || {}))
};

interface IVolunteerList {
  id: string;
  medication_count: number;
  diabetes_type: string;
  display_hbA1c: number | null;
  handover_consent_given: boolean;
  hbA1c_result_display_unit: Hba1cUnit;
  latest_contact_log?: ILatestContactLogRecord;
  user: IBaseUserRecord;
}

const VolunteerListRecordInner = Record<IVolunteerList>({
  id: '',
  medication_count: 0,
  latest_contact_log: undefined,
  user: BaseUserRecord(),
  diabetes_type: '',
  handover_consent_given: false,
  display_hbA1c: null,
  hbA1c_result_display_unit: HBA1C_RESULT_UNIT_DEFAULT,
});

export type IVolunteerListRecord = RecordInstance<IVolunteerList>;
export const VolunteerListRecord = (volunteer: Partial<IVolunteerList>): IVolunteerListRecord => {
  return VolunteerListRecordInner(volunteer)
    .set('latest_contact_log', volunteer.latest_contact_log ?
      LatestContactLogRecord(volunteer.latest_contact_log) : undefined)
    .set('user', BaseUserRecord(volunteer.user))
}

export interface ITitle {
  id: string;
  active: boolean;
  display: string;
  name: string;
}

export type ITitleRecord = RecordInstance<ITitle>;
export const TitleRecord = Record<ITitle>({
  id: '',
  active: true,
  display: '',
  name: '',
});

export interface INumericRange {
  bounds: string,
  lower: number,
  upper: number
}

const NumericRangeRecordInner = Record<INumericRange>({
  bounds: "[)",
  lower: 0,
  upper: 0
})

export type INumericRangeRecord = RecordInstance<INumericRange>;
export const NumericRangeRecord = (numericRange: Partial<INumericRange>): INumericRangeRecord =>
  NumericRangeRecordInner(numericRange);

interface ISiteResearcher {
  id: string;
  display_full_name: string | null;
  email: string;
}

export type ISiteResearcherRecord = RecordInstance<ISiteResearcher>

export const SiteResearcherRecord = Record<ISiteResearcher>({
  id: '',
  display_full_name: null,
  email: '',
});

interface ISite {
  id: string,
  name: string,
  first_name: string,
  last_name: string,
  display_full_name: string,
  postcode: string,
  active: boolean,
  site_address: string,
  address_1: string,
  address_2: string,
  city: string,
  county: string,
  email: string,
  radius: number,
  cra: string,
  researchers_display: List<ISiteResearcherRecord>;
  location: google.maps.LatLng | null;
}

export type ISiteRecord = RecordInstance<ISite>

const SiteRecordInner = Record<ISite>({
  name: '',
  first_name: '',
  last_name: '',
  display_full_name: '',
  active: false,
  site_address: '',
  id: '',
  postcode: '',
  address_1: '',
  address_2: '',
  city: '',
  county: '',
  email: '',
  cra: '',
  radius: 5,
  researchers_display: List(),
  location: null,
});

export const SiteRecord = (input?: Partial<ISiteRecord>) => {
  const record = SiteRecordInner(input);

  const researchers = List(record.researchers_display || [])
    .map((researcher: ISiteResearcher) => SiteResearcherRecord(researcher));

  return record.set('researchers_display', researchers);
};

export type IStudySiteOptionRecord = Readonly<{
  id: string;
  name: string;
}>;
export const StudySiteOptionRecord = SimpleRecord<IStudySiteOptionRecord>({
  id: '',
  name: '',
});

interface IAdvert {
  id: string,
  name: string,
  ad_type: string,
  start_date: string,
  end_date: string,
  cost: string;
  ad_type_display: INamedActiveObjectRecord,
  active: boolean,
}

export type IAdvertRecord = RecordInstance<IAdvert>;
export const AdvertRecordInner = Record<IAdvert>({
  id: '',
  name: '',
  ad_type: '',
  start_date: '',
  end_date: '',
  cost: '',
  ad_type_display: NamedActiveObjectRecord(),
  active: true,
});

interface IVolunteerStats {
  filter_sets: any;
}

export type IVolunteerStatsRecord = RecordInstance<IVolunteerStats>;
export const VolunteerStatsRecord = Record<IVolunteerStats>({
  filter_sets: {
    name: '',
    filters: [],
    results: {},
  }
});

export const AdvertRecord = (advert?: Partial<IAdvert>) : IAdvertRecord => {
  return AdvertRecordInner(advert)
  .set('ad_type_display', advert ? NamedActiveObjectRecord(advert.ad_type_display) : NamedActiveObjectRecord())
};

interface IAdvertType {
  id: string,
  name: string,
  active: boolean,
}

export type IAdvertTypeRecord = RecordInstance<IAdvertType>;
export const AdvertTypeRecordInner = Record<IAdvertType>({
  id: '',
  name: '',
  active: true,
});

export const AdvertTypeRecord = (advertType?: Partial<IAdvertType>) : IAdvertTypeRecord =>
  AdvertTypeRecordInner(advertType)

export interface IOption {
  value: string;
  label: string;
}

export type LabelValueOptions = IOption[];

export interface IProfileLog {
  user: string,
  changes_audit: Map<string, any>,
  contact_type_display?: IContactTypeRecord,
  contact_type?: string,
  call_outcome_display?: ICallOutcomeRecord,
  user_display: ILatestContactLogUser,
  created: string,
  detail: string,
  has_passed_qa?: boolean,
}

const ProfileLogRecordInner = Record<IProfileLog>({
  user: '',
  user_display: LatestContactLogUserRecord(),
  call_outcome_display: CallOutcomeRecord(),
  contact_type: '',
  contact_type_display: ContactTypeRecord(),
  created: '',
  changes_audit: Map<string, any>(),
  detail: '',
  has_passed_qa: false,
});

export type IProfileLogRecord = RecordInstance<IProfileLog>;
export const ProfileLogRecord = (profileLog?: Partial<IProfileLog>): IProfileLogRecord => {
  const record = ProfileLogRecordInner(profileLog);

  return record
    .set('call_outcome_display', CallOutcomeRecord(record.call_outcome_display))
    .set('contact_type_display', ContactTypeRecord(record.contact_type_display))
    .set('user_display', LatestContactLogUserRecord(record.user_display))
    .set('changes_audit', fromJS(record.changes_audit));
}
