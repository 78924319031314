import { Alert } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { FontAwesome } from "react-inline-icons";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { UPDATE_USER_DETAILS, updateUserDetails } from "../../../../actions/auth";
import { getCollectionByName } from "../../../../collections/reducers";
import { PermissionHOCChildProps } from '../../../../permissions/permissions-hoc';
import { collectionsModule } from "../../../../reducers/collections";
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  isPending
} from "../../../../responses";
import { IStore } from "../../../../store";
import {
  IUserRecord,
  IVolunteerRecord,
  UserRecord,
  VolunteerRecord,
} from "../../../../store/data-types";
import {
  flattedNestedFormErrors,
  FormErrors,
  INestedFormErrorsResponse,
  NestedFormErrorsList,
  transformDisplayNameToValueLabel
} from '../../../../utils';
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import PageHeader from "../../../page-header";
import EditPersonalDetailsForm, { IFormData } from './edit-personal-details-form';
const { IconLightbulbO } = FontAwesome;
const { actions: { getCollection } } = collectionsModule;

const MESSAGES = {
  edit: "me.personal"
};

type IExternalProps = PermissionHOCChildProps;

interface IProps extends IExternalProps {
  volunteer: IVolunteerRecord;
  pushRoute: typeof routerActions.push;
  item: IUserRecord;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: FormErrors;
  updateUserDetails: typeof updateUserDetails;
  getCollection: typeof getCollection;
  initialValues: IFormData;
  telephoneErrors: Map<string, List<string>>;
  titleChoices: Array<{value: string, label: string}>;
}

export class EditPersonalDetails extends React.PureComponent<IProps, {}> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this)
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.itemHasSucceeded && !this.props.itemHasSucceeded) {
      this.props.pushRoute(`/app/`);
    }
  }

  public render() {
    const { itemHasSucceeded, itemHasFailed, ...rest } = this.props;
    return <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.edit} />} />
        {itemHasFailed && <Alert className="error">
            <p><FormattedMessage id='generic.update_fail' /></p>
          </Alert>}
        <EditPersonalDetailsForm {...rest} onSubmit={this.submit} />
        <p>
          <IconLightbulbO className="icon-medium-large" />Reason that makes sense to user why we store this.......
        </p>
      </div>;
  }

  private submit(data: IFormData) {
    const {
      volunteer,
      ...user } = data;
    this.props.updateUserDetails(
      this.props.item.merge(user).set('volunteer', this.props.volunteer.merge(data.volunteer))
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      "users/titles",
      { pageSize: 150 },
      "users/titles"
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { collections, responses, userProfile } = state;
  const item = userProfile || UserRecord();
  const volunteer = userProfile && userProfile.volunteer ? userProfile.volunteer : VolunteerRecord();
  const {
    gender,
    birth_date,
    telephone_numbers,
    address_1,
    address_2,
    address_3,
    city,
    county,
    postcode,
  } = volunteer;
  const {
    title,
    first_name,
    last_name,
    email
  } = item;

  const { formErrors = Map<string, NestedFormErrorsList>() } =
  getResponseStatus(responses, UPDATE_USER_DETAILS) as any as INestedFormErrorsResponse;

  return {
    ...props,
    item,
    telephoneErrors: flattedNestedFormErrors(formErrors, 'telephone'),
    errors: getFormErrors(responses, UPDATE_USER_DETAILS),
    itemHasFailed: hasFailed(responses, UPDATE_USER_DETAILS),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_USER_DETAILS),
    itemIsPending: isPending(responses, UPDATE_USER_DETAILS),
    titleChoices: transformDisplayNameToValueLabel(getCollectionByName(
      collections["users/titles"],
      "users/titles"
    ).results),
    initialValues: {
      title,
      first_name,
      last_name,
      email,
      volunteer: {
        gender,
        birth_date,
        telephone_numbers,
        address_1,
        address_2,
        address_3,
        city,
        county,
        postcode,
      }
    },
    volunteer
  };
}

export default connect(mapStateToProps, {
  getCollection,
  updateUserDetails,
  pushRoute: routerActions.push
})(EditPersonalDetails);
