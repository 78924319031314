'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconCaretRight (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconCaretRight'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconCaretRight'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(832.81, 1791.82) scale(1, -1)',
          d: 'M576 640q0 -26 -19 -45l-448 -448q-19 -19 -45 -19t-45 19t-19 45v896q0 26 19 45t45 19t45 -19l448 -448q19 -19 19 -45z'
        }
      )
    );
  };

})();
