import { fromJS, List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { getCollectionByName } from '^/collections/reducers';
import GenericCreate from '^/components/chadmin/generic-create';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';
import { collectionsModule } from '^/reducers/collections';
import { IResponseStatus } from '^/responses';
import { IStore } from '^/store';
import { IAdvertTypeRecord } from '^/store/data-types';

const { actions: { getCollection } } = collectionsModule;

const COLLECTION_URI = 'drivers/adverts';
const FORM_NAME = 'CREATE_ADVERTS';

const MESSAGES = {
  title: 'generic.adverts',
  create: 'drivers.adverts.create',
};

export const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['ad_type', 'name']],
  },
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['start_date', 'end_date']],
  },
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['cost', 'active']],
  },
]);

export const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  ad_type: {
    label: 'Type',
    type: 'choices',
    choices: [],
  },
  start_date: {
    label: 'Start date',
    type: 'date',
    sortable: false,
  },
  end_date: {
    label: 'End date',
    type: 'date',
    sortable: false,
  },
  cost: {
    label: 'Cost',
    type: 'float',
    max_length: 10,
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  },
});

const DEFAULT_VALUES = {
  active: true,
};

interface IValueCollections {
  adTypes: List<IAdvertTypeRecord>;
}

type IExternalProps = PermissionHOCChildProps;

// tslint:disable-next-line:no-unused-variable
interface IProps extends IValueCollections, IExternalProps {
  response: IResponseStatus;
  getCollection: typeof getCollection;
  loading: boolean;
}

export class Create extends React.PureComponent<IProps, {}> {
  public constructor() {
    super();
    this.transformFieldOptions = this.transformFieldOptions.bind(this);
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public render() {
    return (
      <GenericCreate
        messages={MESSAGES}
        defaultValues={DEFAULT_VALUES}
        detailFields={DETAIL_FIELDS}
        detailFieldOptions={this.transformFieldOptions()}
        formName={FORM_NAME}
        onCancelLinkTo={'/app/drivers/adverts/'}
        collectionKey={COLLECTION_URI}
      />
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      'drivers/adverts-types',
      { pageSize: 150 },
      'drivers/adverts-types'
    );
  }

  private transformFieldOptions() {
    return DETAIL_FIELD_OPTIONS.set(
      'ad_type',
      Map({
        label: 'Type',
        type: 'choices',
        clearable: false,
        choices: this.props.adTypes.map((entry: IAdvertTypeRecord) => {
          return Map({
            value: entry.get('id'),
            display_name: entry.get('name'),
          });
        }),
      })
    );
  }
}

function mapStateToProps({ collections }: IStore, props: IExternalProps) {
  return {
    ...props,
    adTypes: getCollectionByName(
      collections['drivers/adverts-types'],
      'drivers/adverts-types'
    ).results,
  };
}

export default connect(mapStateToProps, { getCollection })(Create);
