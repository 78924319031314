import * as React from 'react';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

import FileUpload from '../../../common/FileUpload';

export const FieldFile = ({
  readOnly,
  config,
  errors,
  value,
  className,
  formName,
  name,
  setUpload,
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) => {
  return (
    <FieldGroup className={className} config={config} errors={errors}>
    { readOnly ?
      <span className="input">
        <a href={value}>Download</a>
      </span>
    :
      <FileUpload
        className="input image-field"
        src={value}
        display={(src: any) =>
          src &&
          <a href={src.preview || src} target="_blank">
            {src.name || config.fileName || 'Download'}
          </a>}
        setPendingUpload={(file: any) => setUpload && setUpload(file)}
        onSubmit={() => null}
      />
    }
    </FieldGroup>
  );
};

export default FieldFile;
