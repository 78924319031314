import * as React from "react";
import * as Select from "react-select";
import { WrappedFieldProps } from 'redux-form';

export const ReactSelectWrapper = ({meta, input, ...remainingProps}: WrappedFieldProps<any>) => (
  <Select
    {...input}
    {...remainingProps}
    onBlur={undefined}
  />
)
