
import {
  Alert,
  Button,
  Column,
  FormGroup,
  Row,
} from '@dabapps/roe';
import { Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { defineMessages, FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import {
  change,
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldProps
} from 'redux-form';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection } from '../../../collections/types';
import { PROFILE_STATUS_VALUE_LABELS } from '../../../consts/constants';
import { collectionsModule, ICollectionsState, } from '../../../reducers/collections';
import { IResponseStatus } from '../../../responses';
import { IStore } from "../../../store";
import { ICallOutcomeRecord, ISiteRecord, IStudyOutcomeRecord } from '../../../store/data-types';
import { IStudyRecord } from '../../../store/data-types/studies';
import DateInput from '../../date-input';
import FormErrorsRenderer from "../../forms/form-errors-renderer";
import GroupField from '../../forms/group-field';
import { ReactSelectWrapper } from '../../forms/react-select-wrapper';
import OptionalFields from './optional-field';

const { actions: { getCollection } } = collectionsModule;

export const FORM_NAME = 'volunteer-list-filters';
const STUDY_COLLECTION_NAME: string = 'studies';
const SITES_COLLECTION_NAME: string = 'sites'
const CALL_OUTCOMES_COLLECTION_NAME: string = 'call_outcomes'
const STUDY_OUTCOMES_COLLECTION_NAME: string = 'study_outcomes'

const messages = defineMessages({
  completed_date: {
    id: 'volunteer.filter_completed_date'
  },
  first_name: {
    id: 'generic.first_name',
  },
  first_name_placeholder: {
    id: 'volunteer.filter_first_name_placeholder',
  },
  last_contact_date: {
    id: 'volunteer.filter_last_contact_date'
  },
  last_contact_outcome: {
    id: 'volunteer.filter_last_contact_outcome'
  },
  last_name: {
    id: 'generic.last_name',
  },
  last_name_placeholder: {
    id: 'volunteer.filter_last_name_placeholder',
  },
  last_updated_date: {
    id: 'volunteer.filter_last_updated_date'
  },
  phone_number: {
    id: 'volunteer.filter_phone_number'
  },
  phone_number_placeholder: {
    id: 'volunteer.filter_phone_number_placeholder'
  },
  postcode: {
    id: 'generic.postcode',
  },
  postcode_placeholder: {
    id: 'volunteer.filter_postcode_placeholder'
  },
  study_outcome: {
    id: 'volunteer.filter_study_outcome'
  },
  registration_date: {
    id: 'volunteer.filter_registration_date'
  },
  site: {
    id: 'volunteer.filter_site'
  },
  study: {
    id: 'volunteer.filter_study'
  },
  profile_status: {
    id: 'volunteer.profile_status',
  },
  id: {
    id: 'volunteer.filter_id'
  },
  id_placeholder: {
    id: 'voluneteer.filter_id_placeholder'
  }
});

export interface IFormData {
  postcode: string;
  first_name: string;
  last_name: string;
  id: string;
  phone_number: string;
  study: string;
  site: string;
  last_contact_outcome: string;
  last_contact_date_from: string;
  last_contact_date_to: string;
  last_updated_date_from: string;
  last_updated_date_to: string;
  registration_date_from: string;
  registration_date_to: string;
  completed_date_from: string;
  completed_date_to: string;
  study_outcome: string;
  profile_status: string;
}

interface IExternalProps extends FormProps<DataShape, void, void> {
  defaultValues?: any;
  response: IResponseStatus;
  isAnonymized: boolean;
  onSubmit(data: IFormData): void;
}

interface IProps extends IExternalProps {
  formValues: {
    study?: string;
    show_completed_date?: boolean;
    show_last_contact_outcome?: boolean;
    show_last_contact_date?: boolean;
    show_last_updated_date?: boolean;
    show_registration_date?: boolean;
    show_study_outcome?: boolean;
    show_profile_status?: boolean;
  };
  getCollection: typeof getCollection;
  sitesCollection: ICollection<ISiteRecord>;
  studiesCollection: ICollection<IStudyRecord>;
  callOutcomesCollection: ICollection<ICallOutcomeRecord>;
  studyOutcomesCollection: ICollection<IStudyOutcomeRecord>;
  initialValues: any;
  reduxChangeAction: typeof change;
}
export class ListFilterForm extends React.PureComponent<IProps, void> {

  public componentWillMount() {
    this.props.getCollection(
      'sites',
      {
        pageSize: 150,
        filters: Map({study: this.props.initialValues.study})
      },
      SITES_COLLECTION_NAME
    );
    this.props.getCollection(
      'studies',
      { pageSize: 150 },
      STUDY_COLLECTION_NAME
    );
    this.props.getCollection(
      'profile-logs/call-outcomes',
      { pageSize: 150,
        filters: Map({include_hidden: 'true'})
      },
      CALL_OUTCOMES_COLLECTION_NAME
    );
    this.props.getCollection(
      'studies/study-outcomes',
      { pageSize: 150 },
      STUDY_OUTCOMES_COLLECTION_NAME
    );
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.formValues.study !== this.props.formValues.study) {
      this.props.getCollection(
        'sites',
        {
          pageSize: 150,
          filters: Map({study: nextProps.formValues.study})
        },
        SITES_COLLECTION_NAME
      );
    }
  }

  public render() {
    const { defaultValues: { state } } = this.props;
    const { isLoading, formErrors } = this.props.response;

    return (
      <form onSubmit={this.props.handleSubmit} className="sidebar-filter-form">
        {!this.props.isAnonymized &&
          <GroupField
            label={messages.postcode}
            name="postcode"
            placeholder={messages.postcode_placeholder}
            type="text"
            disabled={isLoading}
            formErrors={formErrors}
            optional
            block
          />}
        {!this.props.isAnonymized &&
          <GroupField
            label={messages.first_name}
            name="first_name"
            placeholder={messages.first_name_placeholder}
            type="text"
            disabled={isLoading}
            formErrors={formErrors}
            optional
            block
          />}
        {!this.props.isAnonymized &&
          <GroupField
            label={messages.last_name}
            name="last_name"
            placeholder={messages.last_name_placeholder}
            type="text"
            disabled={isLoading}
            formErrors={formErrors}
            optional
            block
          />}
        <GroupField
          label={messages.id}
          name="id"
          placeholder={messages.id_placeholder}
          type="text"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        />
        {!this.props.isAnonymized &&
          <GroupField
            label={messages.phone_number}
            name="phone_number"
            placeholder={messages.phone_number_placeholder}
            type="text"
            disabled={isLoading}
            formErrors={formErrors}
            optional
            block
          />}
        <GroupField
          label={messages.study}
          name="study"
          component="select"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        >
          <option value=''>All</option>
          {this.props.studiesCollection.results.map((study) => {
            return <option value={study.id} key={study.id}>{study.study_name}</option>
          })}
        </GroupField>

        <GroupField
          label={messages.site}
          name="sites"
          component="select"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        >
          <option value=''>All</option>
          {this.props.sitesCollection.results.map((site) => {
            return <option value={site.id} key={site.id}>{site.name}</option>
          })}
        </GroupField>

        <OptionalFields
          boolFieldName='show_last_contact_outcome'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.last_contact_outcome}
          fieldNames={['last_contact_outcome']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_last_contact_outcome === true}
        >
          <Field
            name='last_contact_outcome'
            component="select"
          >
            <option value=''>All</option>
            {this.props.callOutcomesCollection.results.map((outcome) => {
              return <option value={outcome.id} key={outcome.id}>{outcome.name}</option>
            })}
          </Field>
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_last_contact_date'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.last_contact_date}
          fieldNames={['last_contact_date_from', 'last_contact_date_to']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_last_contact_date === true}
        >
          <Field
            name='last_contact_date_from'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select from date'
              />
            }
          />
          <Field
            name='last_contact_date_to'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select to date'
              />
            }
          />
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_last_updated_date'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.last_updated_date}
          fieldNames={['last_updated_date_from', 'last_updated_date_to']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_last_updated_date === true}
        >
          <Field
            name='last_updated_date_from'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select from date'
              />
            }
          />
          <Field
            name='last_updated_date_to'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select to date'
              />
            }
          />
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_registration_date'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.registration_date}
          fieldNames={['registration_date_from', 'registration_date_to']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_registration_date === true}
        >
          <Field
            name='registration_date_from'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select from date'
              />
            }
          />
          <Field
            name='registration_date_to'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select to date'
              />
            }
          />
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_completed_date'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.completed_date}
          fieldNames={['completed_date_from', 'completed_date_to']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_completed_date === true}
        >
          <Field
            name='completed_date_from'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select from date'
              />
            }
          />
          <Field
            name='completed_date_to'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select to date'
              />
            }
          />
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_study_outcome'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.study_outcome}
          fieldNames={['study_outcome']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_study_outcome === true}
        >
          <Field
            name='study_outcome'
            component="select"
          >
            <option value=''>All</option>
            {this.props.studyOutcomesCollection.results.map((outcome) => {
              return <option value={outcome.id} key={outcome.id}>{outcome.name}</option>
            })}
          </Field>
        </OptionalFields>

        <OptionalFields
          boolFieldName='show_profile_status'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.profile_status}
          fieldNames={['profile_status']}
          formName={FORM_NAME}
          shouldShow={this.props.formValues.show_profile_status === true}>
          <Field
            component={ReactSelectWrapper}
            multi={true}
            simpleValue
            name='profile_status'
            options={PROFILE_STATUS_VALUE_LABELS}
            className='select'
            searchable={false}
          />
        </OptionalFields>

        <FormGroup>
          <Button className="primary" type="submit" block disabled={isLoading}>Search</Button>
        </FormGroup>
        <FormErrorsRenderer formErrors={formErrors} errorKey="non_field_errors" />
      </form>
    );
  }
}

const ConnectedListFilterForm = reduxForm({
  form: FORM_NAME,
})(ListFilterForm)

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(store: IStore, props: IExternalProps) {
  const { defaultValues: { state } } = props;

  const sitesCollection = getCollectionByName(
    store.collections.sites,
    SITES_COLLECTION_NAME
  );

  const studiesCollection = getCollectionByName(
    store.collections.studies,
    STUDY_COLLECTION_NAME
  );

  const callOutcomesCollection = getCollectionByName(
    store.collections['profile-logs/call-outcomes'],
    CALL_OUTCOMES_COLLECTION_NAME
  );

  const studyOutcomesCollection = getCollectionByName(
    store.collections['studies/study-outcomes'],
    STUDY_OUTCOMES_COLLECTION_NAME
  );

  const initialShowLastUpdate = () => {
    if (state) {
      const last = Map(state.statsFilters).get('last_updated_date_from');
      const to = Map(state.statsFilters).get('last_updated_date_to');
      if (last || to) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const value = selector(
    store,
    'study',
    'show_last_contact_outcome',
    'show_last_contact_date',
    'show_last_updated_date',
    'show_registration_date',
    'show_completed_date',
    'show_study_outcome',
    'show_profile_status',
  )
  return {
    ...props,
    formValues: value,
    callOutcomesCollection,
    sitesCollection,
    studiesCollection,
    studyOutcomesCollection,
    initialValues: {
      study: state ? Map(state).get('studyIdAsFilter') : null,
      show_profile_status: state && Map(state.statsFilters).get('profile_status') ? true : false,
      show_study_outcome: state && Map(state.statsFilters).get('study_outcome') ? true : false,
      show_last_updated_date: initialShowLastUpdate(),
      profile_status: state ? Map(state.statsFilters).get('profile_status') : null,
      study_outcome: state ? Map(state.statsFilters).get('study_outcome') : null,
      last_updated_date_from: (state
        ? Map(state.statsFilters).get('last_updated_date_from') : null),
      last_updated_date_to: state ? Map(state.statsFilters).get('last_updated_date_to') : null,
    }
  };
}

const connectedListFilterForm = connect(
  mapStateToProps,
  {
    reduxChangeAction: change,
    getCollection,
  }
)(ConnectedListFilterForm);

export default connectedListFilterForm;
