import { Button, Column, FormGroup, Row, Well } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import {
  canSetVolunteerPrestudyCheck,
  clearSimpleComponentState,
  setSimpleComponentState,
  volunteerPrestudyCheck } from "../../../actions/actions";
import { getCollectionByName } from '../../../collections/reducers';
import {
  STUDY_OUTCOME_NAMES,
  STUDY_OUTCOMES_PRE_STUDY_CHECK
} from '../../../consts/constants';
import { USER_TYPES } from '../../../consts/user-types';
import { LOAD_ITEM } from '../../../items/actions';
import { collectionsModule, ICollectionsState } from '../../../reducers/collections';
import { itemsModule, ItemType } from '../../../reducers/items';
import { isPending } from '../../../responses';
import { IStore } from '../../../store';
import { IOption, IStudyOutcomeRecord, IUserRecord } from '../../../store/data-types';
import Select from '../../forms/select';

const { actions: { getCollection } } = collectionsModule;

const { actions: { loadItem } } = itemsModule;

const PRE_STUDY_CHECK_KEY = 'studies/study-outcomes';
const PRE_STUDY_CHECK_NAME = 'study_outcomes-prestudy_check';
const COMPONENT_KEY = 'PRE_STUDY_CHECK_OUTCOME_SELECT';

interface IExternalProps {
  volunteerId: string;
  userProfile: IUserRecord;
}

interface IProps extends IExternalProps {
  volunteerUser: IUserRecord;
  loading: boolean;
  clearSimpleComponentState: typeof clearSimpleComponentState;
  setSimpleComponentState: typeof setSimpleComponentState;
  loadItem: typeof loadItem;
  volunteerPrestudyCheck: typeof volunteerPrestudyCheck;
  getCollection: typeof getCollection;
  studyOutcomes: List<IStudyOutcomeRecord>;
  selectedOutcome: string;
  canSetPreStudyOutcome: boolean;
  canSetVolunteerPrestudyCheck: typeof canSetVolunteerPrestudyCheck;
}

const OUTCOME_TO_ACTION = Map<string, string>()
  .set(STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED, 'Approve')
  .set(STUDY_OUTCOME_NAMES.FAILED_PRESTUDY_CHECK, 'Reject');

export class PreStudyCheckBanner extends React.PureComponent<IProps, {}> {
  public componentWillMount() {
    this.props.canSetVolunteerPrestudyCheck(this.props.volunteerId);
    this.props.clearSimpleComponentState(COMPONENT_KEY);
    return this.props.getCollection(
      PRE_STUDY_CHECK_KEY,
      {filters: Map({type: STUDY_OUTCOMES_PRE_STUDY_CHECK.join()})},
      PRE_STUDY_CHECK_NAME,
    );
  }

  public buildOptionGroups() {
    return this.props.studyOutcomes
      .sortBy((vals) => vals.type)
      .reverse()
      .groupBy((vals) => vals.type)
      .map((outcomes, key) => {
        return outcomes.map((outcome) => {
          return {value: outcome.id, label: outcome.name};
        }).toList();
      }).toMap()
      .mapKeys((keyName) => OUTCOME_TO_ACTION.get(keyName, 'Incompatible Options'));
  }

  public render() {
    const { volunteerUser, canSetPreStudyOutcome } = this.props
    if (!canSetPreStudyOutcome) {
      return null;
    }
    const optionGroups = this.buildOptionGroups();
    const canSubmit = this.props.selectedOutcome ? true : false;

    return(
      <Well className="dark" >
        <span className="bold margin-base padding-base inline-block">
          Please approve or reject study match: {volunteerUser ? volunteerUser.display_full_name : ''}
        </span>
        <Button
          disabled={!canSubmit}
          className="primary float-right margin-base"
          onClick={this.selectOutcome}>
          <FormattedMessage id='generic.set'/>
        </Button>
        <Select
          onChange={(item: React.ChangeEvent<HTMLSelectElement>) => {
            this.props.setSimpleComponentState(COMPONENT_KEY, item.target.value)}}
          placeholder={"Select action"}
          className={"float-right margin-base"}
          options={optionGroups}
        />
      </Well>
    );
  }

  public selectOutcome = () => {
    this.props.volunteerPrestudyCheck(this.props.volunteerId, this.props.selectedOutcome);
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { collections, items, responses } = state;

  const { results } = getCollectionByName<any>(
    collections[PRE_STUDY_CHECK_KEY],
    PRE_STUDY_CHECK_NAME
  );
  return {
    ...props,
    studyOutcomes: results,
    volunteerUser: items.get("users/volunteers/user"),
    loading: isPending(responses, LOAD_ITEM, "users/volunteers/user"),
    selectedOutcome: state.simpleComponentState.get(COMPONENT_KEY, ''),
    canSetPreStudyOutcome: state.canSetPreStudyOutcome.get(props.volunteerId, false)
  };
}

export default connect(
  mapStateToProps, {
    loadItem,
    volunteerPrestudyCheck,
    getCollection,
    clearSimpleComponentState,
    setSimpleComponentState,
    canSetVolunteerPrestudyCheck
  })(PreStudyCheckBanner);
