import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Alert, Button, FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { change } from 'redux-form';
import * as _ from 'underscore';

import { FontAwesome } from 'react-inline-icons';
import { CRAFTY_CLICKS_URL } from '../../consts/constants';
import { IStore } from '../../store';
import { clickOutside, externalAPIRequest, validateUKPostcode } from '../../utils';

const { IconExclamationTriangle } = FontAwesome;

const apiKey = process.env.CRAFTY_CLICKS_TOKEN;
const API_REQUEST_METHOD = 'GET';

export interface IFindResponse {
  data: {
    results: IFindResult[]
  }
}

export interface IRetrieveResponse {
  data: {
    result: IRetrieveResult
  }
}

export interface IFindResult {
  count: number;
  id: string;
  labels: string[];
}

export interface IRetrieveResult {
  company_name: string;
  line_1: string;
  line_2: string;
  line_3: string;
  locality: string;
  province_name: string;
  postal_code: string;
}

export interface IChoice {
  text: string;
  id: string;
}

export interface IAddressAutoFillFields {
  address_1: string;
  address_2: string;
  address_3: string;
  city: string;
  county: string
  postcode: string;
}

export type TAddressAutoFillFieldMap = Partial<IAddressAutoFillFields>;

interface IOwnProps {
  formName: string;
  autofillFields: TAddressAutoFillFieldMap;
  columnClass?: string;
  isBlock?: boolean;
}

interface IDispatchProps {
  change: typeof change;
}

interface ISetProps { // tslint:disable-line
  isOpen: boolean;
  searchTerm: string;
  errorText: string;
  choices: IChoice[];
}

type Callback = (response: IFindResponse | IRetrieveResponse) => void;
type SetProps = SetPropsInterface<ISetProps>;
type Props = SetProps & IOwnProps & IDispatchProps;

export class PostcodeLookup extends React.PureComponent<Props, {}> {
  private element: HTMLElement;
  constructor() {
    super();
    this.findAddressMatches = _.debounce(this.findAddressMatches, 1000);
  }

  public componentDidMount () {
    window.addEventListener('click', this.onWindowClick);
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  public render() {
    const { columnClass, searchTerm, errorText, isBlock } = this.props;
    return (
      <div className={columnClass} >
        <FormGroup className={isBlock ? 'block' : ''}>
          <label>Find address by postcode</label>
          <div
            className="input postcode-lookup-inputbox"
            ref={(element) => this.element = element}
          >
            <input
              className="postcode-lookup-input"
              type="text"
              placeholder="Enter postcode..."
              onChange={this.updateSearchTerm}
            />
            {this.props.isOpen && this.renderChoices()}
          </div>
          {errorText &&
            <Alert className="error">
              <IconExclamationTriangle className={'icon-small'} />
              {' '}
              {errorText}
            </Alert>
          }
        </FormGroup>
      </div>
    )
  }

  private renderChoices = () => {
    const { choices } = this.props;

    if (choices.length) {
      return (
        <CSSTransitionGroup
          transitionName="dropdown-transition"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}
        >
          <div className="dropdown-popup postcode-lookup-popup">
            <ul className="lookups-menu">
              {choices.map((choice) =>
                <li
                  className="postcode-lookup-choice"
                  key={choice.id}
                  onClick={() => this.retrieveChosenMatch(choice.id)}
                >
                  {choice.text}
                </li>
              )}
            </ul>
          </div>
        </CSSTransitionGroup>
      )
    }
    return null;
  }

  private updateSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setProps({
      errorText: '',
      searchTerm: event.currentTarget.value,
      choices: []
    });
    this.findAddressMatches();
    this.openDropdown();
  }

  private findAddressMatches = () => {
    const validPostcode = this.validatePostcode();

    if (validPostcode) {
      const url = `${CRAFTY_CLICKS_URL}find?key=${apiKey}&query=${validPostcode}&country=GBR`;
      this.externalAPIRequest(url, this.extractMatchesFromResponse);
    }
  }

  private validatePostcode = () => {
    const validPostcode = validateUKPostcode(this.props.searchTerm);
    if (!validPostcode) {
      this.props.setProps({errorText: 'Please enter a valid UK postcode.'});
      return null;
    }
    return validPostcode;
  }

  private retrieveChosenMatch = (id: string) => {
    const url = `${CRAFTY_CLICKS_URL}retrieve?key=${apiKey}&country=GBR&id=${id}`;
    this.props.setProps({choices: []});

    if (id) {
      this.externalAPIRequest(url, this.autofillAddress);
    }
  }

  private externalAPIRequest = (url: string, callback: Callback) => {
    externalAPIRequest(url, API_REQUEST_METHOD)
      .then((response) => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line:no-console
        this.props.setProps({errorText: 'The service encountered a problem. Please try again later.'});
      });
  }

  private extractMatchesFromResponse = (response: IFindResponse) => {
    const choices: IChoice[] = [];

    response.data.results.forEach((result) => {
      if (result.labels && result.labels[1] && result.id) {
        choices.push({
          text: result.labels[1],
          id: result.id
        });
      }
    });
    this.props.setProps({choices: choices.length ? choices : [{text: 'No results found.', id: ''}]})
  }

  private autofillAddress = (response: IRetrieveResponse) => {
    const { formName, change, autofillFields } = this.props
    const formattedValues = this.formatAutofillValues(response.data.result);
    if (autofillFields) {
      Object.keys(formattedValues).forEach((fieldName: keyof IAddressAutoFillFields) => {
        const mappedFieldName = autofillFields[fieldName];
        if (mappedFieldName) {
          change(formName, mappedFieldName, formattedValues[fieldName])
        }
      })
    }
  }

  private formatAutofillValues = (result: IRetrieveResult): IAddressAutoFillFields => {
    return {
      address_1: result.company_name || result.line_1 || '',
      address_2: result.company_name ? result.line_1 : result.line_2 || '',
      address_3:  result.company_name ? result.line_2 : result.line_3 || '',
      city: result.locality || '',
      county: result.province_name || '',
      postcode:  result.postal_code || '',
    };
  }

  private onWindowClick = (event: MouseEvent) => {
    clickOutside(this.element, event.target as HTMLElement, this.closeDropdown);
  }

  private openDropdown = () => {
    this.props.setProps({isOpen: true});
  }

  private closeDropdown = () => {
    this.props.setProps({isOpen: false});
  }
}

const connected = connect<{}, IDispatchProps, IOwnProps & SetProps>(undefined, {
  change,
})(PostcodeLookup);

function getInitialProps () {
  return {
    isOpen: false,
    searchTerm: '',
    errorText: '',
    choices: []
  };
}

export default withSetProps<ISetProps, IOwnProps>(getInitialProps)(connected);
