import { push } from 'react-router-redux';
import { collectionsModule } from '../reducers/collections';
import { itemsModule } from '../reducers/items';
import {
  FormattedFormData,
  IPoly,
  IStudySiteRecord,
} from '../store/data-types/studies';
import { makeAsyncActionSet } from '../utils';
import { DispatchCallback, dispatchGenericRequest, GetStateCallback } from './';

const { actions: { addItem } } = collectionsModule;
const { actions: { patchItem, updateItem } } = itemsModule;

export const GET_VOLUNTEER_COUNTS = makeAsyncActionSet('GET_VOLUNTEER_COUNTS');
export function getVolunteerCounts (studyId: string) {
  return dispatchGenericRequest(
    GET_VOLUNTEER_COUNTS,
    `/api/studies/${studyId}/search-results/?include-sites=true`,
    'GET'
  );
}

export const LOAD_MARKERS = makeAsyncActionSet('LOAD_MARKERS');
export function loadMarkers (studyId: string) {
  return dispatchGenericRequest(
    LOAD_MARKERS,
    `/api/studies/${studyId}/search-results/`,
    'GET'
  );
}

export function addStudyLoadVolunteerCountsAndRedirectToDetailPage(
  data: FormattedFormData,
  tag?: string,
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return addItem('studies', data, tag)(dispatch, getState)
      .then((response) => {
        const studyId = response && response.data.id;

        if (studyId) {
          dispatch(push(`/app/studies/${studyId}/edit/`));
        }

        return studyId;
      })
      .then((studyId) => {
        return dispatch(getVolunteerCounts(studyId));
      })
  };
}

export function updateStudyAndLoadVolunteers(
  studyId: string,
  data: FormattedFormData,
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return updateItem('studies', studyId, data)(dispatch, getState)
      .then(() => {
        return getVolunteerCounts(studyId)(dispatch, getState);
      })
      .then(() => {
        return loadMarkers(studyId)(dispatch, getState);
      });
  };
}

export function updatePatchSite(data: IStudySiteRecord, id: string) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return patchItem('sites', id, data)(dispatch, getState);
  };
}

export function addStudyLoadVolunteerCountsAndRedirectToSavePage (
  data: FormattedFormData,
  tag?: string,
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return addItem('studies', data, tag)(dispatch, getState)
      .then((response) => {
        const studyId = response && response.data.id;

        if (studyId) {
          dispatch(push(`/app/studies/${studyId}/save/`));
        }

        return studyId;
      })
      .then((studyId) => {
        return getVolunteerCounts(studyId)(dispatch, getState);
      });
  };
}

export function updateStudyRedirectToSavePage (
  studyId: string,
  data: FormattedFormData
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return updateItem('studies', studyId, data)(dispatch, getState)
      .then(() => {
        return dispatch(push(`/app/studies/${studyId}/save/`));
      });
  };
}

export const TRIGGER_VOLUNTEER_MATCHING = makeAsyncActionSet('TRIGGER_VOLUNTEER_MATCHING');
export function saveStudyAndView (
  studyId: string,
  data: FormattedFormData
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    const { study_sites } = data;
    const dataWithSitesConfirmed = {
      ...data,
      study_sites: study_sites && study_sites.map((studySite) => {
        return {
          ...studySite,
          is_confirmed_site: true,
        };
      })
    };
    return updateItem('studies', studyId, dataWithSitesConfirmed)(dispatch, getState)
      .then(() => {
        if (data.active) {
          return dispatchGenericRequest(
            TRIGGER_VOLUNTEER_MATCHING,
            `/api/studies/${studyId}/search-results/`,
            'POST'
          )(dispatch, getState).then(() => {
            return dispatch(push(`/app/studies/${studyId}/`));
          });
        } else {
          return dispatch(push(`/app/studies/${studyId}/`));
        }
      });
  };
}

export const ADD_POLYGON = makeAsyncActionSet('ADD_POLYGON');
export function addPolygon (id: string, poly: IPoly, location: google.maps.LatLng) {
  return dispatchGenericRequest(
    ADD_POLYGON,
    `/api/studies/study-sites/${id}/`,
    'PATCH',
    {poly},
    undefined,
    {location}
  );
}

export const REMOVE_POLYGON = makeAsyncActionSet('REMOVE_POLYGON');
export function removePolygon (id: string) {
  return dispatchGenericRequest(
    REMOVE_POLYGON,
    `/api/studies/study-sites/${id}/`,
    'PATCH',
    {poly: null}
  );
}
