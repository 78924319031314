import * as React from 'react';

// tslint:disable-next-line:no-unused-variable
import { TColumn, TItem } from '../types';

import {
  FORMAT_DATE_ADMIN,
  FORMAT_DATE_TIME_ADMIN,
  formatDateTime,
  parseImmutablePropertyString,
} from '../utils';

interface IProps {
  field: TColumn;
  item: TItem;
  removeItem: (value: any, name: string) => void;
}

export default class AdminItemField extends React.PureComponent<
  IProps,
  undefined
> {
  public render() {
    const { item, field, removeItem } = this.props;
    const {
      type,
      name,
      link_text,
      route,
      valueMap,
      handler,
      display_rule,
      default_display_name,
    } = field.toObject();
    const value = name && item.getIn(name);

    switch (type) {
      case 'lookup':
        return <span>{field.getIn(['lookups', value], default_display_name || '--')}</span>;
      case 'boolean':
        return value ? <span>Yes</span> : <span>No</span>;
      case 'detail':
        return (
          <span>
            <a href={parseImmutablePropertyString(item, route)}>
              {link_text || (name && value) || default_display_name || '--'}
            </a>
          </span>
        );
      case 'datetime':
        return (
          <span>
            {formatDateTime(value, ' ', FORMAT_DATE_TIME_ADMIN) || '--'}
          </span>
        );
      case 'date':
        return (
          <span>
            {formatDateTime(value, ' ', FORMAT_DATE_ADMIN) || '--'}
          </span>
        );
      case 'remove':
        return (
          <span>
            <button onClick={() => removeItem(value, name)} className="tertiary">Remove</button>
          </span>
        );
      case 'function':
        return (
          <span>
            { (display_rule && display_rule(item)) || display_rule === undefined ?
              <button onClick={() => handler(item)} className="tertiary">
                {link_text}
              </button>
            :
              null
            }
          </span>
        );
      case 'valuemap':
        return (
          <span>
            {valueMap.get(value, default_display_name || '--')}
          </span>
        );
      case 'integer':
      case 'float':
        return (
          <span>
            {value}
          </span>
        );
      case 'position':
        return (
          <span>
            {value === -1 ? '--' : value}
          </span>
        );
      default:
        return (
          <span>
            {value || default_display_name || '--'}
          </span>
        );
    }
  }
}
