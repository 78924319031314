'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createFields = require('./createFields');

var _createFields2 = _interopRequireDefault(_createFields);

var _plain = require('./structure/plain');

var _plain2 = _interopRequireDefault(_plain);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _createFields2.default)(_plain2.default);