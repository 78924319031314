import * as React from "react";
import * as DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { connect, } from 'react-redux';
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import { IStore } from '../../store';
import { IUserRecord } from "../../store/data-types";
import {constructHeaders} from '../../utils';
import Loading from "../content/loading";
import PageHeader from '../page-header';
import PollButton from '../poll-button';
import VolunteerLanding from "../volunteers/volunteer-details/volunteer-landing";
import DashboardAdminView from './dashboard-admin-view';
import DashboardQAview from './dashboard-qa-view';
import DashboardReportPanel from './dashboard-report-panel';
import DashboardSRview from './dashboard-sr-view';

interface IExternalProps extends PermissionHOCChildProps {
  loading: boolean;
}

interface IProps extends IExternalProps {
  userProfile: IUserRecord | null;
  params: { id: string; };
}

export class Dashboard extends React.PureComponent<IProps, void> {

  public render() {
    const {
      loading,
      userProfile,
      permissions: {
        canDownloadVolunteerDiallerCsv,
        canViewQaStatsPanel,
        canViewSResearcherStats,
        canViewAdminStats,
        canViewStudyPanel,
        canViewVolunteerDashboard,
      },
    } = this.props;

    if (!userProfile || loading) {
      return <Loading />;
    }

    return (
        <DocumentTitle title='Your account| One Research Volunteer'>
          <div>
            <PageHeader header={'Overview'} />

            {/* SR, CRA, PM */}
            {canViewStudyPanel && (
              <DashboardReportPanel
                title={'Study Outcomes'}
              />
            )}

            {/* Volunteer */}
            {canViewVolunteerDashboard && userProfile.volunteer &&
              <VolunteerLanding userProfile={userProfile} volunteer={userProfile.volunteer} />}

            {/* QAStats */}
            {canViewQaStatsPanel && <DashboardQAview {...this.props} />}

            {/* Admin Stats */}
            {canViewAdminStats && <DashboardAdminView {...this.props} />}

            {/* Site Researcher Stats */}
            {canViewSResearcherStats && <DashboardSRview {...this.props} />}

            {/* CSV download button */}
            {canDownloadVolunteerDiallerCsv &&
               <PollButton
                 initialMessage={(<FormattedMessage id='dashboard.download_dialler_csv' />)}
                 headers={constructHeaders()}
                 getPollUrl={(id) => `/api/export/status/${id}/`}
                 requestUrl={'/api/users/volunteers/dialler-csv/'}/> }

          </div>
        </DocumentTitle>
      );
  }
}

const mapStateToProps = ({ userProfile }: IStore, props: IExternalProps) => {
  return {
    ...props,
    userProfile,
  }
}

export default connect(mapStateToProps)(Dashboard);
