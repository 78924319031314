import axios, { AxiosPromise } from 'axios';
import { Collection, List, Map, OrderedMap, Range } from 'immutable';
import * as Cookies from 'js-cookie';
import * as moment from 'moment';
import * as path from 'path';
import * as _ from 'underscore';
import { INumericRange } from './store/data-types/index';

// No recursive types :(
export type ImmutableJson = null | string | number | List<null | string | number> | Map<string, null | string | number>;
export type FormErrors = Map<string, List<string>>;

export interface INestedFormErrorsResponse {
  formErrors: NestedFormErrors;
}

export type NestedFormErrorsList = List<FormErrors>;
export type NestedFormErrors = Map<string, NestedFormErrorsList>;

export const numericRangeToString = (range: INumericRange): string => {
  const lowerBound = range.bounds[0];
  const upperBound = range.bounds[1];
  const { lower, upper } = range;
  if (lower === null && upper === null) {
    return '';
  }
  return `${lowerBound}${lower} - ${upper}${upperBound}`;
}

const isNestedFormErrors = (formErrors: FormErrors | NestedFormErrors): formErrors is NestedFormErrors => {
  const inner = formErrors.toList().get(0, List());

  if (!List.isList(inner)) {
    return false;
  }

  return Map.isMap(inner.get(0));
};

export const flattedNestedFormErrors = (formErrors: FormErrors | NestedFormErrors, errorKey: string): FormErrors => {
  if (!formErrors.count()) {
    return formErrors as FormErrors;
  }

  if (!isNestedFormErrors(formErrors)) {
    return formErrors;
  }

  return formErrors
    .mapEntries(([key, value]: [string, NestedFormErrorsList]) => {
      return [
        key,
        value.map((error) => error.get(errorKey, Map()))
          .flatten().toSet().toList() as List<string>
      ];
    });
};

const uniqueList = (list: List<any> | Map<any, any> | Collection<any, any>): List<any> => {
  return list.toSet().toList();
};

const mapToKeyValueStrings = (list: string | Map<string, string>): List<string> => {
  if (typeof list === 'string') {
    return List.of(list);
  }

  return list.map((value, key) => `${key}: ${value}`).toList();
};

export const combineErrors = (...errorsToCombine: any[]): FormErrors => {
  const errorsList = List(errorsToCombine).map((formErrors): List<string> => {
    // String or falsy
    if (typeof formErrors === 'undefined' || typeof formErrors === 'string' || formErrors === null) {
      return formErrors;
    }

    if (Map.isMap(formErrors)) {
      // Map of lists of maps of strings
      if (isNestedFormErrors(formErrors)) {
        return formErrors
          .toList()
          .flatten()
          .map((errors) => mapToKeyValueStrings(errors))
          .flatten()
          .toList();
        // Map of lists of strings
      } else if (List.isList(formErrors.toSet().toList().get(0, List()))) {
        return formErrors
          .map((errors, key) => {
            return errors
              .map((value: string) => `${key}: ${value}`);
          })
          .toList();
        // Map of strings
      } else {
        return mapToKeyValueStrings(formErrors);
      }
    }

    if (List.isList(formErrors)) {
      const firstError = formErrors.get(0);

      // List of maps of strings
      if (Map.isMap(firstError)) {
        return formErrors
          .map((value) => mapToKeyValueStrings(value))
          .flatten()
          .toList();
        // List of lists of strings
      } else if (List.isList(firstError)) {
        return formErrors
          .flatten()
          .toList();
        // List of strings
      } else {
        return formErrors;
      }
    }

    // Unknown
    return List();
  })
    .flatten()
    .filter((error) => typeof error !== 'undefined' && error !== null);

  return Map({
    errors: uniqueList(errorsList)
  });
};

export interface IAsyncActionSet {
  FAILURE: string;
  REQUEST: string;
  SUCCESS: string;
}

export function makeAsyncActionSet(actionName: string): Readonly<IAsyncActionSet> {
  return {
    FAILURE: actionName + '_FAILURE',
    REQUEST: actionName + '_REQUEST',
    SUCCESS: actionName + '_SUCCESS',
  };
}

export function externalAPIRequest(
  url: string,
  method: string,
  data = {},
  headers = {},
): AxiosPromise {
  return axios({
    method,
    url,
    data,
    headers,
  });
}

export const constructHeaders = (headers = {}) => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'X-CSRFToken': Cookies.get('csrftoken'),
  ...headers,
});

export function apiRequest(
  url: string,
  method: string,
  data = {},
  headers = {},
  onUploadProgress?: (event: ProgressEvent) => void,
): AxiosPromise {
  return axios({
    method,
    url: path.normalize(url),
    data,
    headers: constructHeaders(headers),
    onUploadProgress
  });
}

export type RequestStates = 'REQUEST' | 'SUCCESS' | 'FAILURE';

export function startsWith(haystack: string, needle: string) {
  return haystack.lastIndexOf(needle, 0) === 0;
}

export function formatMonth(date: moment.Moment): string {
  return date.format('MMMM');
}

export function formatMonthNumber(date: moment.Moment): string {
  return date.format('M');
}

export function formatYear(date: moment.Moment): string {
  return date.format('YYYY');
}

export function formatDate(date: moment.Moment): string {
  return date.format('Do MMMM YYYY');
}

export function formatShortDate(date: moment.Moment): string {
  return date.format('D MMM YYYY');
}

export function formatTime(date: moment.Moment): string {
  return date.local().format('HH:mm')
}

export function formatDateTime(date: moment.Moment): string {
  return formatDate(date) + ' at ' + formatTime(date)
}

export function formatTimeAgo(date: moment.Moment): string {
  return moment.utc().diff(date.utc(), 'days') >= 1 ?
    formatDateTime(date) :
    date.local().fromNow();
}

export function formatQueryParams(params?: {}): string {
  if (!params) {
    return '';
  }

  const filteredPairs = _.chain(params)
    .pairs()
    .filter(([key, value]) => value !== null && typeof value !== 'undefined')
    .value();

  if (!filteredPairs || !filteredPairs.length) {
    return '';
  }

  return '?' + filteredPairs
    .map(([key, value]) => {
      const castedVal = value as any;
      return `${key}=${encodeURIComponent(castedVal)}`;
    })
    .join('&');
}

export function formatAddress(list: _.List<string>): string {

  const address = _.filter(list,
    (item: string) => {
      if (item) {
        return true
      }
      return false
    }
  ).join(', ')

  return address;
}

export function clickOutside(
  element: Node | null,
  eventTarget: Node | null,
  callback: (...args: any[]) => any
) {
  let node: Node | null = eventTarget;

  while (node && node.parentNode) {
    if (node === element) {
      return;
    }

    node = node.parentNode;
  }

  callback();
}

export function transformMapToValueLabel(m: Map<string, string>): Array<{ value: string, label: string }> {
  return List(m).map(([key, value]) => ({ value: key, label: value })).toArray();
}

export function transformMapToDisplayNameValue(m: Map<string, string>): Array<{ display_name: string, value: string }> {
  return List(m).map(([key, value]) => ({ display_name: value, value: key })).toArray();
}

export function transformMapToValueDisplayName(m: Map<string, string>): Array<{ display_name: string, value: string }> {
  return List(m).map(([key, value]) => ({ display_name: value, value: key })).toArray();
}

export function transformIdNameToValueLabel(l: List<{ id: string, name: string }>):
  Array<{ value: string, label: string }> {
  return l.map((entry) => {
    return {
      value: entry.id,
      label: entry.name
    };
  }).toArray();
}

export function transformDisplayNameToValueLabel(l: List<{ display: string, name: string }>):
  Array<{ value: string, label: string }> {
  return l.map((entry) => {
    return {
      value: entry.display,
      label: entry.name
    };
  }).toArray();
}

export function generateMonths(): OrderedMap<string, string> {
  return (
    Range(0, 13)
      .toOrderedMap()
      .mapEntries(([value]: [number, number]) => {
        if (value === 0) {
          return [`${value}`, 'Unknown']
        } else {
          return [`${value}`, moment.utc().set('month', value - 1).format('MM (MMMM)')]
        }
     })
  );
}

export function generateYears(nrOfYears: number): OrderedMap<string, string> {
  const thisYear = Number(moment.utc().set('year'));

  return (
    Range(thisYear + 1, thisYear - nrOfYears)
      .toOrderedMap()
      .mapEntries(([key, value]: [number, number]) => {
        if (value === thisYear + 1) {
          return ['0', 'Unknown'];
        } else {
          return [`${value}`, `${value}`];
        }
      })
  );
}

export function generateDate(month: string, year: string): string {
  return `${year}-${month}-01`;
}

export function validateUKPostcode(inputValue: string): string {
  // remove spaces and convert to uppercase
  inputValue = inputValue.replace(/\s/g, '').toUpperCase();
  const regex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i; // tslint:disable-line
  return regex.test(inputValue) ? inputValue : '';
}

export function filterEmptyStringsFromList(list: List<string> | string[]): List<string> {
  return List(list).filterNot((item) => item === '');
}

export const KEYCODE_ENTER = 13;

export function disableSubmitOnEnter(e: React.KeyboardEvent<HTMLElement>) {
  if (e.keyCode === KEYCODE_ENTER) {
    e.preventDefault();
  }
}
