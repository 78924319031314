import { List, Map } from 'immutable';
import * as React from "react";
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getGenericAction, postGenericAction } from '../../actions/statistics';
import {
  ADVERT_STATS_ADD_TO_STORE,
  REGISTRATION_RATE_STATS_ADD_TO_STORE,
  VOLUNTEER_STATS_ADD_TO_STORE,
} from '../../actions/statistics';
import { DIABETES_TYPE_NAMES, PROFILE_STATUS_NAMES } from '../../consts/constants';
import { anyPending } from '../../responses';
import { IStatisticsDataQuery } from '../../store/data-types/statistics';
import { IStatsStore } from '../../types/statistics';
import DashboardChartPanel from './dashboard-chart-panel';

import { IStore } from '../../store';

const ADMIN_STATS: IStatisticsDataQuery = {
  filter_sets: [
    {
      name: 'ALL_VOLUNTEERS',
      filters: []
    },
    {
      name: PROFILE_STATUS_NAMES.NEED_QA,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
    {
      name: PROFILE_STATUS_NAMES.NEED_CALL,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
      ]
    },
    {
      name: PROFILE_STATUS_NAMES.REVIEWED,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
      ]
    },
    {
      name: PROFILE_STATUS_NAMES.ARCHIVED,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.ARCHIVED
        },
      ]
    },
    {
      name: 'DIABETES_TYPE_1_ACTIVE',
      filters: [
        {
          filter: 'diabetes_type',
          value: DIABETES_TYPE_NAMES.TYPE_1
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
      ]
    },
    {
      name: 'DIABETES_TYPE_1_INCOMPLETE',
      filters: [
        {
          filter: 'diabetes_type',
          value: DIABETES_TYPE_NAMES.TYPE_1
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
    {
      name: 'DIABETES_TYPE_2_ACTIVE',
      filters: [
        {
          filter: 'diabetes_type',
          value: DIABETES_TYPE_NAMES.TYPE_2
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
      ]
    },
    {
      name: 'DIABETES_TYPE_2_INCOMPLETE',
      filters: [
        {
          filter: 'diabetes_type',
          value: DIABETES_TYPE_NAMES.TYPE_2
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
  ]
}

interface IExternalProps {
  loading: boolean;
}

interface IStateProps extends IExternalProps {
  loading: boolean;
  allVolunteersValue: IStatsStore;
  reviewedValue: IStatsStore;
  archivedValue: IStatsStore;
  diabetesType1ActiveValue: IStatsStore;
  diabetesType2ActiveValue: IStatsStore;
  diabetesType1IncompleteValue: IStatsStore;
  diabetesType2IncompleteValue: IStatsStore;
  unavailableValue: IStatsStore;
  advertSourcesStats: google.visualization.ChartData;
  advertSourcesByAverageAgeStats: google.visualization.ChartData;
  registrationRateStats: google.visualization.ChartData;
}

interface IDispatchProps {
  postGenericAction: typeof postGenericAction;
  getGenericAction: typeof getGenericAction;
}

type IProps = IDispatchProps & IStateProps & IExternalProps;

const COLLECTION_KEY: string = 'charts/volunteers';
const ADVERT_COLLECTION_KEY: string = 'charts/advert-sources';
const REGISTRATION_RATE_COLLECTION_KEY: string = 'charts/registration-date';

export class DashboardAdminView extends React.PureComponent<IProps & InjectedIntlProps, void> {

  public componentWillMount() {
    this.props.postGenericAction(COLLECTION_KEY, VOLUNTEER_STATS_ADD_TO_STORE, ADMIN_STATS);
    this.props.getGenericAction(ADVERT_COLLECTION_KEY, ADVERT_STATS_ADD_TO_STORE);
    this.props.getGenericAction(REGISTRATION_RATE_COLLECTION_KEY, REGISTRATION_RATE_STATS_ADD_TO_STORE);
  }

  public render() {
    const {
      loading,
      allVolunteersValue,
      reviewedValue,
      archivedValue,
      diabetesType1ActiveValue,
      diabetesType2ActiveValue,
      diabetesType1IncompleteValue,
      diabetesType2IncompleteValue,
      unavailableValue,
      advertSourcesStats,
      advertSourcesByAverageAgeStats,
      registrationRateStats,
    } = this.props;

    return (
      <div>
        <DashboardChartPanel
          loading={loading}
          panelTitle={'Registration numbers'}
          className={'column xs-12 md-6'}
          chartType={'PieChart'}
          options={{ title: `Total number of volunteers: ${allVolunteersValue}` }}
          data={[
            ['Status', 'Volunteers'],
            ['Available', reviewedValue],
            ['Unavailable', unavailableValue],
            ['Archived', archivedValue],
          ]}
        />

        <DashboardChartPanel
          loading={loading}
          panelTitle={'Diabetes types breakdown'}
          className={'column xs-12 md-6'}
          chartType={'ColumnChart'}
          data={[
            ['Type', 'Active', 'Incomplete'],
            ['Type 1', diabetesType1ActiveValue, diabetesType1IncompleteValue],
            ['Type 2', diabetesType2ActiveValue, diabetesType2IncompleteValue],
          ]}
          options={{
            vAxis: { title: 'Volunteers' },
            hAxis: { title: 'Active' },
            isStacked: true,
          }}
        />

        <DashboardChartPanel
          loading={loading}
          panelTitle={'Advertising sources'}
          className={'column xs-12 md-6'}
          chartType={'PieChart'}
          data={[['Type', 'Stats'], ...advertSourcesStats ]}
        />

        <DashboardChartPanel
          loading={loading}
          panelTitle={'Average age per advert source type'}
          className={'column xs-12 md-6'}
          chartType={'BarChart'}
          data={[ ['Type', 'Average age'], ...advertSourcesByAverageAgeStats ]}
          options={{
            vAxis: { title: 'Source type' },
            hAxis: { title: 'Average age' },
            legend: 'none',
          }}
        />

        <DashboardChartPanel
          loading={loading}
          panelTitle={'Registration rate over time'}
          className={'column xs-12 md-12'}
          chartType={'AreaChart'}
          data={[ ['Month', 'Volunteers'], ...registrationRateStats ]}
          options={{
            vAxis: { title: 'Volunteers' },
            hAxis: { title: 'Last 12 Months' },
            legend: 'none',
            pointSize: 5,
            chartArea: { width: '100%', right: 40, left: 50, bottom: 70, top: 30 },
            fontSize: 11,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ statistics, responses }: IStore, props: IExternalProps): IStateProps & IExternalProps => {
  const advertSources = statistics.get('ADVERT_SOURCES', Map()) as Map<string, Map<string, string | number>>;
  const registrationRate = statistics.get('REGISTRATION_RATE', List()) as List<number[]>;

  return {
    ...props,
    loading: anyPending(responses, [[VOLUNTEER_STATS_ADD_TO_STORE, COLLECTION_KEY],
      [ADVERT_STATS_ADD_TO_STORE, ADVERT_COLLECTION_KEY],
      [REGISTRATION_RATE_STATS_ADD_TO_STORE, REGISTRATION_RATE_COLLECTION_KEY]]),
    allVolunteersValue: statistics.getIn(['ALL_VOLUNTEERS', 'value']),
    reviewedValue: statistics.getIn([PROFILE_STATUS_NAMES.REVIEWED, 'value']),
    archivedValue: statistics.getIn([PROFILE_STATUS_NAMES.ARCHIVED, 'value']),
    diabetesType1ActiveValue: statistics.getIn(['DIABETES_TYPE_1_ACTIVE', 'value']),
    diabetesType2ActiveValue: statistics.getIn(['DIABETES_TYPE_2_ACTIVE', 'value']),
    diabetesType1IncompleteValue: statistics.getIn(['DIABETES_TYPE_1_INCOMPLETE', 'value']),
    diabetesType2IncompleteValue: statistics.getIn(['DIABETES_TYPE_2_INCOMPLETE', 'value']),
    registrationRateStats: registrationRate.toArray().reverse(),

    unavailableValue: (statistics.getIn([PROFILE_STATUS_NAMES.NEED_QA, 'value']) +
      statistics.getIn([PROFILE_STATUS_NAMES.NEED_CALL, 'value'])),

    advertSourcesStats: advertSources.toList()
      .map(((item: Map<string, string>) => [item.get('name', ''), item.get('count', 0)])).toArray(),

    advertSourcesByAverageAgeStats: advertSources.toList()
      .map(((item: Map<string, string>) => [item.get('name', ''), item.get('average_age', 0)])).toArray(),
  }
}

const mapDispatchToProps = {
  postGenericAction,
  getGenericAction,
}

const DashboardAdminViewIntl = injectIntl(DashboardAdminView);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdminViewIntl);
