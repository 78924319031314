import axios, { AxiosPromise } from 'axios';
import * as path from 'path';
import * as React from 'react';

interface IExternalProps {
  headers: {[i: string]: string},
  getPollUrl: (id: string) => string,
  requestUrl: string,
  completeMessage ?: JSX.Element,
  progressMessage ?: JSX.Element,
  initialMessage ?: JSX.Element,
  failedMessage ?: JSX.Element,
  className ?: string;
}

const COMPLETE = 'COMPLETE';
const PROGRESS = 'PROGRESS';
const INITIAL = 'INITIAL';
const FAILED = 'FAILED';

interface IState {
  status: string;
  downloadUrl: string;
}

const MESSAGES: {[i: string]: JSX.Element} = {
  COMPLETE: (<span>Download ready</span>),
  INITIAL: (<span>Download document</span>),
  PROGRESS: (<span>Preparing download</span>),
  FAILED: (<span>Download failed. Click to retry</span>)
}

export class PollButton extends React.PureComponent<IExternalProps, IState> {
  private messages: {[i: string]: JSX.Element} = MESSAGES;
  private classes: string;

  public constructor(props: IExternalProps) {
    super(props);
    this.state = {
      status: INITIAL,
      downloadUrl: ''
    };
    this.classes = this.props.className || 'button primary';
    if (this.props.completeMessage) {
      this.messages.COMPLETE = this.props.completeMessage;
    }
    if (this.props.progressMessage) {
      this.messages.PROGRESS = this.props.progressMessage;
    }
    if (this.props.initialMessage) {
      this.messages.INITIAL = this.props.initialMessage;
    }
    if (this.props.failedMessage) {
      this.messages.FAIL = this.props.failedMessage;
    }
  }

  public componentWillReceiveProps(newProps: IExternalProps) {
    if (this.props.requestUrl !== newProps.requestUrl) {
      this.setState({
        status: INITIAL,
        downloadUrl: ''
      });
    }
  }

  public render() {
    const message = this.messages[this.state.status];
    if (this.state.status === COMPLETE) {
      return (
        <a className={this.classes} target="_blank" href={this.state.downloadUrl}>
          {message}
        </a>
      );
    } else {
      return (
        <button className={this.classes} onClick={this.beginPoll}>
          {message}
        </button>
      );
    }
  }

  private apiRequest ( url: string): AxiosPromise {
    return axios({
      method: 'GET',
      url: path.normalize(url),
      headers: this.props.headers,
    });
  }

  private beginPoll = () => {
    if (this.state.status === COMPLETE || this.state.status === PROGRESS ) {
      return;
    }

    return this.apiRequest(this.props.requestUrl).then((response: any) => {
      this.poll(response.data.id);
      this.setState({status: PROGRESS});
    }).catch(() => {
      this.setState({status: FAILED});
    })
  }

  private poll(id: string) {
   const pollUrl = this.props.getPollUrl(id);
   return this.apiRequest(pollUrl).then((response: any) => {
     if (response.data.download_url) {
       this.setState({
         downloadUrl: response.data.download_url,
         status: COMPLETE
       });
     } else if (response.data.status === FAILED) {
      this.setState({status: FAILED});
     }
     if (this.state.status === PROGRESS) {
       window.setTimeout(this.poll.bind(this, id), 2000);
     }
   }).catch(() => {
     this.setState({status: FAILED});
   });
  }
}

export default PollButton;
