import defaultPermissions, { IAllPermission } from './default-permissions'
// CRA Permissions
const permissions: IAllPermission = {
    ...defaultPermissions,
    volunteers: {
      hasAccess: true,
      customProps: {
        canCreate: false,
        canEdit: false,
        isAnonymized: true,
      },
    },
    volunteers_create: {
      hasAccess: false,
      customProps: {},
    },
    volunteers_detail: {
      hasAccess: true,
      customProps: {
        canCreate: false,
        canEdit: false,
        isAnonymized: true
      }
    },
    sites: {
      hasAccess: false,
      customProps: {}
    },
    sites_create: {
      hasAccess: false,
      customProps: {}
    },
    sites_edit: {
      hasAccess: false,
      customProps: {}
    },
    studies: {
      hasAccess: false,
      customProps: {},
    },
    studies_search: {
      hasAccess: false,
      customProps: {},
    },
    studies_view: {
      hasAccess: false,
      customProps: {},
    },
    studies_save: {
      hasAccess: false,
      customProps: {},
    },
    studies_edit: {
      hasAccess: false,
      customProps: {},
    },
    home: {
      hasAccess: true,
      customProps: {
        canViewStudyPanel: true,
      }
    },
    reports: {
      hasAccess: false,
      customProps: {},
    },
    lookups: {
      hasAccess: false,
      customProps: {},
    },
    drivers: {
      hasAccess: false,
      customProps: {},
    },
    adverts: {
      hasAccess: false,
      customProps: {},
    },
    adverts_create: {
      hasAccess: false,
      customProps: {},
    },
    adverts_edit: {
      hasAccess: false,
      customProps: {},
    },
    adverts_types: {
      hasAccess: false,
      customProps: {},
    },
    adverts_types_create: {
      hasAccess: false,
      customProps: {},
    },
    adverts_types_edit: {
      hasAccess: false,
      customProps: {},
    },
    motivations: {
      hasAccess: false,
      customProps: {},
    },
    motivations_create: {
      hasAccess: false,
      customProps: {},
    },
    motivations_edit: {
      hasAccess: false,
      customProps: {},
    },
    call_outcomes: {
      hasAccess: false,
      customProps: {},
    },
    call_outcomes_create: {
      hasAccess: false,
      customProps: {},
    },
    call_outcomes_edit: {
      hasAccess: false,
      customProps: {},
    },
    outcomes: {
      hasAccess: false,
      customProps: {},
    },
    study_outcomes: {
      hasAccess: false,
      customProps: {},
    },
    study_outcomes_create: {
      hasAccess: false,
      customProps: {},
    },
    study_outcomes_edit: {
      hasAccess: false,
      customProps: {},
    },
    medication: {
      hasAccess: false,
      customProps: {},
    },
    medications: {
      hasAccess: false,
      customProps: {},
    },
    medications_create: {
      hasAccess: false,
      customProps: {},
    },
    medications_edit: {
      hasAccess: false,
      customProps: {},
    },
    frequency: {
      hasAccess: false,
      customProps: {},
    },
    frequency_create: {
      hasAccess: false,
      customProps: {},
    },
    frequency_edit: {
      hasAccess: false,
      customProps: {},
    },
    manufacturer: {
      hasAccess: false,
      customProps: {},
    },
    manufacturer_create: {
      hasAccess: false,
      customProps: {},
    },
    manufacturer_edit: {
      hasAccess: false,
      customProps: {},
    },
    class: {
      hasAccess: false,
      customProps: {},
    },
    class_create: {
      hasAccess: false,
      customProps: {},
    },
    class_edit: {
      hasAccess: false,
      customProps: {},
    },
    generic_name: {
      hasAccess: false,
      customProps: {},
    },
    generic_name_create: {
      hasAccess: false,
      customProps: {},
    },
    generic_name_edit: {
      hasAccess: false,
      customProps: {},
    },
    units: {
      hasAccess: false,
      customProps: {},
    },
    units_create: {
      hasAccess: false,
      customProps: {},
    },
    units_edit: {
      hasAccess: false,
      customProps: {},
    },
    titles: {
      hasAccess: false,
      customProps: {},
    },
    titles_create: {
      hasAccess: false,
      customProps: {},
    },
    titles_edit: {
      hasAccess: false,
      customProps: {},
    },
    contact_types: {
      hasAccess: false,
      customProps: {},
    },
    contact_types_create: {
      hasAccess: false,
      customProps: {},
    },
    contact_types_edit: {
      hasAccess: false,
      customProps: {},
    },
    other: {
      hasAccess: false,
      customProps: {},
    },
};

export default permissions;
