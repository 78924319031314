import * as React from 'react';

import { List, Map } from 'immutable';

import { TColumn, TColumns, TItems, TResponse } from '../types';

import Loading from '../common/Loading';
import { isPending } from '../utils';
import AdminListItems from './AdminListItems';

export const PAGE_SIZE = 12;
export const LIST_NAME_PREFIX = 'ADMINLIST_';

interface IProps {
  items: TItems;
  changePage: (pageNumber: number) => void;
  searchItems?: (searchString: string) => void;
  sortItems?: (sortPath: string, shouldReverse: boolean) => void;
  itemCount: number;
  pageSize?: number;
  page: number;

  defaultFilters: Map<string, any>;
  deleteItem: (itemId: string | number) => void;
  columns: TColumns;
  hidePagination?: boolean;
  centered?: boolean;
  listName: string;
  onRemove?: (...args: any[]) => any;
  response?: TResponse;
  removeItemResponse?: TResponse;
  searchString?: string;
  sortBy?: string;
  sortByReversed?: boolean;
  noSearch?: boolean;
}

export default class AdminList extends React.PureComponent<IProps, undefined> {
  public render() {
    const {
      centered,
      columns,
      hidePagination,
      listName,
      noSearch,
      response,
      removeItemResponse,
      sortBy,
      sortByReversed,
      items,
      pageSize,
      page,
      itemCount,
    } = this.props;

    if (isPending(removeItemResponse)) {
      return <Loading />;
    }

    return (
      <div>
        <AdminListItems
          items={items}
          centered={centered}
          page={page}
          itemCount={itemCount}
          columns={columns}
          hidePagination={hidePagination}
          isLoading={isPending(response)}
          listName={listName}
          sortBy={sortBy}
          noSearch={noSearch}
          sortByReversed={sortByReversed}
          pageSize={pageSize || PAGE_SIZE}
          removeItem={(itemId: string) => this.removeItem(itemId)}
          setSortBy={(sortPath: string) => this.setSortBy(sortPath)}
          search={(searchString: string) =>
            this.props.searchItems ? this.props.searchItems(searchString) : null}
          changePage={(pageNumber: number) => this.props.changePage(pageNumber)}
        />
      </div>
    );
  }

  private removeItem(itemId: string | number) {
    const { onRemove, deleteItem } = this.props;
    if (window.confirm('Are you sure you want to remove?')) {
      deleteItem(itemId);
      if (onRemove) {
        onRemove();
      }
    }
  }

  private setSortBy(sortPath: string) {
    const { sortByReversed, sortBy, searchString, page, sortItems } = this.props;
    const shouldReverse = sortPath === sortBy && !sortByReversed;
    if (sortItems) {
      sortItems(sortPath, shouldReverse);
    }
  }
}
