'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconShoppingCart (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconShoppingCart'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconShoppingCart'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(320.81, 1791.82) scale(1, -1)',
          d: 'M640 0q0 -52 -38 -90t-90 -38t-90 38t-38 90t38 90t90 38t90 -38t38 -90zM1536 0q0 -52 -38 -90t-90 -38t-90 38t-38 90t38 90t90 38t90 -38t38 -90zM1664 1088v-512q0 -24 -16.5 -42.5t-40.5 -21.5l-1044 -122q13 -60 13 -70q0 -16 -24 -64h920q26 0 45 -19t19 -45t-19 -45t-45 -19h-1024q-26 0 -45 19t-19 45q0 11 8 31.5t16 36t21.5 40t15.5 29.5l-177 823h-204q-26 0 -45 19t-19 45t19 45t45 19h256q16 0 28.5 -6.5t19.5 -15.5t13 -24.5t8 -26t5.5 -29.5t4.5 -26h1201q26 0 45 -19t19 -45z'
        }
      )
    );
  };

})();
