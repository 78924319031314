import * as moment from 'moment';

import { FAILED, PENDING, SUCCESSFUL } from './responseStates';
import { TResponse } from './types';

export const FORMAT_DATE = 'Do MMM YYYY';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_DATE_TIME = FORMAT_DATE.concat(' [at] ').concat(
  FORMAT_TIME
);
export const FORMAT_DATE_PICKER = 'DD-MM-YYYY';
export const FORMAT_DATE_BACKEND = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME_BACKEND = FORMAT_DATE_BACKEND.concat('THH:mm:ss');
export const FORMAT_DATE_TIME_ADMIN = FORMAT_DATE_BACKEND.concat(' ').concat(
  FORMAT_TIME
);
export const FORMAT_DATE_ADMIN = FORMAT_DATE_BACKEND;

export function formatDateTime(
  date: string | moment.Moment,
  noDate: any,
  format = FORMAT_DATE_TIME
) {
  if (!date) {
    return noDate || 'unknown';
  }
  return moment.utc(date).format(format);
}

export function formatDate(date: string | moment.Moment) {
  return moment.utc(date).format(FORMAT_DATE);
}

export function formatBackendDate(date: string | moment.Moment) {
  return moment.utc(date).format(FORMAT_DATE_BACKEND);
}

export function formatBackendDateTime(datetime: string | moment.Moment) {
  return moment.utc(datetime).format(FORMAT_DATE_TIME_BACKEND);
}

export function formatTime(datetime: string) {
  return moment.utc(datetime).format(FORMAT_TIME);
}

export function formatTimeNoDate(time: string) {
  return moment.utc(time, 'HH:mm:ss').format('ha');
}

export function formatPickerDate(date: string) {
  return moment.utc(date).format(FORMAT_DATE_PICKER);
}

export function parseObjectPropertyString(obj: any, stringFormat: string) {
  return stringFormat.replace(
    /\$\{(.*?)\}/gi,
    (property, propertyName) => obj[propertyName]
  );
}

export function parseImmutablePropertyString(obj: any, stringFormat: string) {
  return stringFormat.replace(/\$\{(.*?)\}/gi, (property, propertyName) =>
    obj.getIn(propertyName.split('.'), '--')
  );
}

export function rangeBounded(value: number, max: number) {
  return (value + max) % max;
}

export function isPending(response?: TResponse) {
  return response && response.get('state') === PENDING;
}

export function hasFailed(response?: TResponse) {
  return response && response.get('state') === FAILED;
}

export function hasSucceeded(response?: TResponse) {
  return response && response.get('state') === SUCCESSFUL;
}
