import { IStatisticsDataQuery } from '../store/data-types/statistics';
import { IAsyncActionSet, makeAsyncActionSet } from '../utils';
import {
  DispatchCallback,
  dispatchGenericRequest,
  GetStateCallback
} from './index';

export const LOAD_DASHBOARD_STUDY_OPTIONS = makeAsyncActionSet('LOAD_DASHBOARD_STUDY_OPTIONS');
export const VOLUNTEER_STATS_ADD_TO_STORE = makeAsyncActionSet('VOLUNTEER_STATS_ADD_TO_STORE');
export const ADVERT_STATS_ADD_TO_STORE = makeAsyncActionSet('ADVERT_STATS_ADD_TO_STORE');
export const REGISTRATION_RATE_STATS_ADD_TO_STORE = makeAsyncActionSet('REGISTRATION_RATE_STATS_ADD_TO_STORE');
export const STUDY_STATS_ADD_TO_STORE = makeAsyncActionSet('STUDY_STATS_ADD_TO_STORE');
export const RECRUITMENT_FLOW_STATS_ADD_TO_STORE = makeAsyncActionSet('RECRUITMENT_FLOW_STATS_ADD_TO_STORE');

export function postGenericAction (
  type: string,
  actionSet: Readonly<IAsyncActionSet>,
  data: IStatisticsDataQuery,
  collectionName?: string,
) {
  const url = `/api/${type}/`;
  return dispatchGenericRequest(actionSet, url, 'POST', data, type, { collectionName });
}

export function getGenericAction (
  type: string,
  actionSet: Readonly<IAsyncActionSet>,
  collectionName?: string,
) {
  const url = `/api/${type}/`;
  return dispatchGenericRequest(actionSet, url, 'GET', {}, type, { collectionName });
}

export const COLLECTION_KEY_STATS: string = 'charts/study-stats';
export function getStudyStats(studyId: string) {
  return getGenericAction(
    `${COLLECTION_KEY_STATS}/${studyId}`,
    STUDY_STATS_ADD_TO_STORE
  );
}

export const COLLECTION_KEY_STUDY_OPTIONS: string = 'charts/study-options/';
export function loadStudyOptionsAndStudyStats() {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return getGenericAction(
      COLLECTION_KEY_STUDY_OPTIONS,
      LOAD_DASHBOARD_STUDY_OPTIONS
    )(dispatch, getState).then(() => {
      const { studyOptions } = getState();
      if (studyOptions && studyOptions.length) {
        dispatch(getStudyStats(studyOptions[0].id))
      }
    });
  };
}
