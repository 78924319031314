import * as React from 'react';
import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

const FieldStatic = ({
  config,
  className
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) =>
  <FieldGroup className={className} config={config}>
    <span className="input">
      {config.text}
    </span>
  </FieldGroup>;

export default FieldStatic;
