import * as React from 'react';
import * as Select from 'react-select';

import { FieldGroup } from './FieldGroup';
import { IChoice, IWrappedFieldProps } from './types';

export const FieldSelect = (
  props: React.HTMLProps<JSX.Element> & IWrappedFieldProps
) => {
  const { readOnly, config, name, errors, value, onChange, className } = props;

  const { choices = [], placeholder, clearable = true } = config;

  if (readOnly) {
    // find matching choice
    const [display = { value: '', display_name: value }] =
      choices && choices.filter((choice: IChoice) => choice.value === value);

    return (
      <FieldGroup className={className} config={config} errors={errors}>
        <span className="input">
          {display && display.display_name}
        </span>
      </FieldGroup>
    );
  }

  return (
    <FieldGroup className={className} config={config} errors={errors}>
      <Select
        clearable={clearable}
        className="select"
        labelKey="display_name"
        options={choices}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={(opt: Select.Option) => onChange(opt.value as any)}
      />
    </FieldGroup>
  );
};

export default FieldSelect;
