import * as moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import { FORMAT_DATE_BACKEND, formatBackendDate } from '../chadmin/utils';

// tslint:disable-next-line:no-unused-variable
interface IFieldDateProps {
  value: string | null;
  onChange?: (value: any) => void;
}

export class DatePickerField extends React.PureComponent<IFieldDateProps, {} > {
  public render() {
    return (
      <span className="input date-field">
        <DatePicker
          fixedHeight={true}
          showYearDropdown={true}
          dateFormat={FORMAT_DATE_BACKEND}
          selected={this.getValue()}
          onChange={(e) => this.handleChange(e)}
          scrollableYearDropdown
          yearDropdownItemNumber={70}
        />
      </span>
    );
  }

  private handleChange(value: moment.Moment | null) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value && formatBackendDate(value) as any);
    }
  }

  private getValue() {
    const value = this.props.value && moment.utc(this.props.value);
    return value && value.isValid() ? value : null;
  }
}

export const DatePickerWrapper = ({meta, input, ...remainingProps}: WrappedFieldProps<any>) => (
  <DatePickerField
    {...input}
    {...remainingProps}
  />
)

export default DatePickerWrapper;
