import * as classNames from 'classnames';
import * as React from 'react';
// tslint:disable-next-line:no-unused-variable
interface IProps {
  header: React.ReactChild | null;
  className?: string;
}

export default class PageHeader extends React.PureComponent<IProps, void> {

  public render () {
    const {
      header,
      children,
      className
    } = this.props;

    return (
      <div className={classNames('clearfix page-header', className)}>
        <h1 className="header">
          {header}
        </h1>
        {children}
      </div>
    );
  }

}
