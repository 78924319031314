import { Column, Row } from '@dabapps/roe';
import * as React from "react";
import * as DocumentTitle from 'react-document-title';
import { FormattedMessage } from "react-intl"
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { DispatchCallback } from '../../actions';
import { closeModal, openSimpleModal } from '../../actions/modals';
import { IStore } from '../../store';
import CtaButtons from '../cta-buttons';
import LoginPage from '../login/';
import ResetPassword from '../login/forgot-password';
import Footer from '../navigation/footer';
import LandingHeader from '../navigation/landing-header';

const getLoginModal = (dispatch: DispatchCallback) => {
  return {
    body: <LoginPage
      onResetPasswordClick={() => dispatch(switchToResetPasswordModal() as any)}
    />,
    title: 'Login to the Volunteer Outreach Programme'
  };
};

const getResetPasswordModal = (dispatch: DispatchCallback) => {
  return {
    body: <ResetPassword
      onLoginClick={() => dispatch(switchToLoginModal() as any)}
    />,
    title: 'Forgotten your password?'
  };
};

export function openLoginModal() {
  return (dispatch: DispatchCallback) => {
    return dispatch(openSimpleModal(getLoginModal(dispatch)));
  };
}

export function switchToResetPasswordModal() {
  return (dispatch: DispatchCallback) => {
    dispatch(closeModal());
    dispatch(openSimpleModal(getResetPasswordModal(dispatch)));
  };
}

export function switchToLoginModal() {
  return (dispatch: DispatchCallback) => {
    dispatch(closeModal());
    dispatch(openSimpleModal(getLoginModal(dispatch)));
  };
}

// tslint:disable-next-line:no-unused-variable
interface IProps {
  isLoggedIn: boolean;
  openLoginModal: typeof openLoginModal;
  openSimpleModal: typeof openSimpleModal;
  pushRoute: typeof routerActions.push;
}

export class LandingPage extends React.PureComponent<IProps, void> {

  public render() {
    const loginLink = this.props.isLoggedIn ?
      this.renderGoToAppLink() : <CtaButtons openLoginModal={this.props.openLoginModal} />;

    return (
      <DocumentTitle title='Volunteer Outreach Programme'>
        <div className="landing" id="top">
          <LandingHeader />
          <hr/>
          <Row className="landing-inset  margin-top-xlarge margin-bottom-xxlarge" role="main" id="main" tabIndex={-1}>
            <Column>
              {loginLink}
            </Column>
          </Row>
          <hr/>
          <Footer/>
        </div>
      </DocumentTitle>
    )
  }

  private renderGoToAppLink() {
    return (
      <span>
        Looks like you are logged in - you can <a onClick={() => this.props.pushRoute('/app/')}>
        <FormattedMessage id="landing-nav.gotoapp" />
      </a>.
      </span>
    );
  }
}

export function mapStateToProps(state: IStore) {
  return {
    isLoggedIn: Boolean(state.userProfile && state.userProfile.has('id'))
  };
}

export default connect(mapStateToProps, {
  openSimpleModal,
  openLoginModal,
  pushRoute: routerActions.push,
})(LandingPage)
