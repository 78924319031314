import { IAction, RecordInstance, TTypeToRecordMapping } from '../store/data-types';

export function setItemStateIfMatchingItem<T>(
  state: RecordInstance<T>,
  action: IAction<any, any>,
  data: any | null,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const itemType = action.meta.tag;
  const { itemId } = action.meta;
  if (itemType in typeToRecordMapping) {
    const newItem = typeToRecordMapping[itemType](data);
    const existing = state.get(itemType);
    return state.set(
      itemType,
      (existing && itemId === (existing as any).id) ?
        newItem :
        state.get(itemType)
    );
  }
  return state;
}

export function setItemState<T>(
  state: RecordInstance<T>,
  action: IAction<any, any>,
  data: any | null,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const itemType = action.meta.tag;
  if (itemType in typeToRecordMapping) {
    const item = typeToRecordMapping[itemType](data);
    return state.set(
      itemType,
      item
    );
  }
  return state;
}
