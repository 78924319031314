import { TableCell, TableRow } from '@dabapps/roe';
import * as React from 'react';

import AdminItemField from '../detail/AdminItemField';
import { TColumn, TColumns, TItem } from '../types';

interface IProps {
  item: TItem;
  fields: TColumns;
  removeItem: (itemId: string | number) => void;
}

export default class AdminListItem extends React.PureComponent<
  IProps,
  undefined
> {
  public render() {
    const { item, fields, removeItem } = this.props;

    return (
      <TableRow>
        {fields.map((field: TColumn, idx) =>
          <TableCell key={idx}>
            <AdminItemField
              key={idx}
              item={item}
              field={field}
              removeItem={removeItem}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }
}
