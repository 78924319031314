import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import {
  Tab,
  Tabs,
} from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ICollection, ICollectionOptions } from '../../../../collections/types';
import { IPermissionsProps } from '../../../../permissions/config/default-permissions';
import { IVolunteerListRecord } from '../../../../store/data-types/';
import { ICircle, IStudyVolunteerRecord } from '../../../../store/data-types/studies';
import CollectionTable from '../../../tables/collection-table';
import { IColumnData } from '../../../tables/simple-table';
import GoogleMap from '../../google-map';

const PAGE_SIZE = 15;

type ListOrMap = 'list' | 'map';

// tslint:disable-next-line:no-unused-variable
interface ISetPropsProps {
  listOrMap: ListOrMap;
}

interface IExternalProps {
  permissions: Partial<IPermissionsProps>;
}

interface IOwnProps extends IExternalProps {
  collection: ICollection<IStudyVolunteerRecord | IVolunteerListRecord>;
  markers: google.maps.LatLng[];
  circles: Array<null | ICircle>;
  polygons: Array<null | google.maps.LatLng[]>;
  loading: boolean;
  matchedMessage: string;
  headers: Array<IColumnData<IStudyVolunteerRecord | IVolunteerListRecord>>;
  getCollection(options: ICollectionOptions): void;
}

type Props = IOwnProps & SetPropsInterface<ISetPropsProps>;

const MESSAGES = {
  noResults: 'generic.no_results',
};

export class StudyResults extends React.PureComponent<Props, {}> {
  private viewList: () => void;
  private viewMap: () => void;

  public constructor (props: Props) {
    super(props);

    this.viewList = () => this.setView('list');
    this.viewMap = () => this.setView('map');
  }
  public render () {
    const {
      collection,
      loading,
      markers,
      circles,
      polygons,
      listOrMap,
      matchedMessage,
      headers,
    } = this.props;

    const loadedWithResults = !loading && Boolean(collection.count);

    return (
      <div>
        <div>
          {
            loadedWithResults && (
              <Tabs className="pills float-right">
                <Tab active={listOrMap === 'list'}>
                  <a onClick={this.viewList}>
                    List
                  </a>
                </Tab>
                <Tab active={listOrMap === 'map'}>
                  <a onClick={this.viewMap}>
                    Map
                  </a>
                </Tab>
              </Tabs>
            )
          }
          <p>
            <strong>
              {
                collection.count ?
                  `${collection.count} ${matchedMessage}` :
                  <FormattedMessage id={MESSAGES.noResults} />
              }
            </strong>
          </p>
        </div>
        {
          loading && (
            <p>
              Loading...
            </p>
          )
        }
        {
          loadedWithResults && (
            listOrMap === 'list' ? (
              <CollectionTable
                headers={
                  headers
                    .filter((header: any) => !this.props.permissions.isAnonymized || header.key !== 'full_name')
                }
                collection={collection}
                loading={this.props.loading}
                pageSize={PAGE_SIZE}
                preventInitialRequest
                getCollection={this.props.getCollection}
              />
            ) : (
              <GoogleMap
                width="100%"
                height={500}
                markers={markers}
                circles={circles}
                polygons={polygons}
                allowCreatingPolygons={false}
              />
            )
          )
        }
      </div>
    );
  }

  private setView (view: ListOrMap) {
    this.props.setProps({
      listOrMap: view
    });
  }
}

const getInitialProps = () => ({
  listOrMap: 'list' as ListOrMap,
});

export default withSetProps<ISetPropsProps, IOwnProps>(getInitialProps)(StudyResults);
