import {fromJS} from 'immutable';
import * as React from 'react';

import GenericDetail from '^/components/chadmin/generic-detail';
import { CALL_OUTCOME_CHOICES } from '^/consts/constants';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-4', 'column sm-4', 'column sm-4']],
    elements: [['name', 'type', 'active']],
  }
]);

const FORM_NAME = 'EDIT_CALL_OUTCOMES';

const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  type: {
    label: 'Call Outcome',
    type: 'choices',
    choices: CALL_OUTCOME_CHOICES,
    clearable: false,
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  }
});

const MESSAGES = {
  edit: 'outcomes.call-outcomes.edit'
};

// tslint:disable-next-line:no-unused-variable
interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

export default class Detail extends React.PureComponent<IExternalProps, void> {
    public render() {
            return (
                    <GenericDetail
                            formName={FORM_NAME}
                            messages={MESSAGES}
                            params={this.props.params}
                            detailFields={DETAIL_FIELDS}
                            detailFieldOptions={DETAIL_FIELD_OPTIONS}
                            onCancelLinkTo={'/app/outcomes/call-outcomes/'}
                            itemsName={'profile-logs/call-outcomes'} />
            );
    }
}
