import * as React from 'react';

export default class Footer extends React.PureComponent<any, void> {
  public render() {
    return (
      <footer className="footer padding-vertical-large">
        <p className="margin-none">&copy; {new Date().getFullYear()} One Research Ltd.All rights reserved.</p>
        <a href="/policy/">Privacy Policy and Terms of Use</a>, <a href="/policy/#cookies">Cookies</a>.
      </footer>
    );
  }
}
