import { Column, Row } from '@dabapps/roe';
import { Location } from 'history';
import { Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { confirmHandoverConsent } from '../../../actions/volunteers';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { collectionsModule } from '../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from "../../../store";
import { IVolunteerListRecord } from '../../../store/data-types';
import {constructHeaders} from '../../../utils';
import { formatQueryParams, formatShortDate } from '../../../utils';
import PollButton from '../../poll-button';
import CollectionTable from '../../tables/collection-table';
import { IFormData } from './list-filter-form';

const { actions: { getCollection } } = collectionsModule;

const COLLECTION_NAME: string = 'VOLUNTEERS_LIST';
const PAGE_SIZE = 15;

const DEFAULT_LOCATION = {
  state: {}
};

interface IExternalProps extends PermissionHOCChildProps {
  defaultFilters?: Map<string, string>;
  filters?: Partial<IFormData> | null;
  location?: Location;
}

interface IProps extends IExternalProps {
  loading: boolean;
  response: IResponseStatus;
  volunteersCollection: ICollection<IVolunteerListRecord>;
  getCollection: typeof getCollection;
  confirmHandoverConsent: typeof confirmHandoverConsent;
  pushRoute: typeof routerActions.push;
}

export class VolunteersList extends React.PureComponent<IProps, void> {

  public constructor(props: IProps) {
    super(props);
    this.getCollection = this.getCollection.bind(this);
    this.getFilterSet = this.getFilterSet.bind(this);
  }

  public componentWillMount() {
    this.getCollection();
  }

  public getHeaders() {
    let headers = this.defineHeaders();
    if (this.props.permissions.isAnonymized) {
      headers = headers.filter((header) => header.key !== 'name')
    }
    if (!this.props.permissions.canConfirmHandoverConsent) {
      headers = headers.filter((header) => header.key !== 'handover_consent')
    }
    return headers;
  }

  public render() {
    const {
      volunteersCollection,
      loading,
      filters
    } = this.props;
    let csvDownloadUrl = '/api/users/volunteers/csv/';
    let adminCsvDownloadUrl = '/api/users/volunteers/admin-csv/';
    if (filters) {
      csvDownloadUrl += formatQueryParams(filters);
      adminCsvDownloadUrl += formatQueryParams(filters);
    } else {
      csvDownloadUrl += formatQueryParams(this.getFilterSet().toJS());
      adminCsvDownloadUrl += formatQueryParams(this.getFilterSet().toJS());
    }

    return (
      <div>
        <Row>
          <Column xs={12}>
            <CollectionTable
              clickable
              linkTo="/app/volunteers/"
              headers={this.getHeaders()}
              collection={volunteersCollection}
              loading={loading}
              pageSize={PAGE_SIZE}
              preventInitialRequest
              getCollection={this.getCollection}
            />
            { (this.props.permissions.canDownloadVolunteerCsv && !!volunteersCollection.results.count()) &&
              <PollButton
               className={'button small secondary margin-top-large float-right'}
               initialMessage={(<FormattedMessage id="volunteer.download_csv" />)}
               headers={constructHeaders()}
               getPollUrl={(id) => `/api/export/status/${id}/`}
               requestUrl={csvDownloadUrl}/>
            }
            {(this.props.permissions.canDownloadVolunteerAdminCsv && !!volunteersCollection.results.count()) &&
              <PollButton
               className={'button small secondary margin-top-large float-right'}
               initialMessage={(<FormattedMessage id="volunteer.download_csv" />)}
               headers={constructHeaders()}
               getPollUrl={(id) => `/api/export/status/${id}/`}
               requestUrl={adminCsvDownloadUrl}
              />
            }
          </Column>
        </Row>
      </div>
    );
  }

  public getCollection(options?: ICollectionOptions) {
    return this.props.getCollection(
      'users/volunteers',
      {
        filters: this.getFilterSet(options),
        page: options && options.page || 1,
        ...options,
      },
      COLLECTION_NAME
    );
  }

  private getFilterSet(options?: ICollectionOptions) {
    const newFilters = (options && options.filters || Map<string, string>());
    const { defaultFilters, location: { state } = DEFAULT_LOCATION } = this.props;
    if (defaultFilters) {
      return newFilters.merge(defaultFilters || Map<string, string>());
    } else if (state) {
      return newFilters.merge(state.statsFilters || Map<string, string>());
    } else {
      return newFilters;
    }
  }

  private defineHeaders = () => [
    {
      content: (record: IVolunteerListRecord) => record.get('id'),
      headerLabel: 'ID',
      key: 'id',
      sortable: false,
    },
    {
      content: (record: IVolunteerListRecord) => {
        const user = record.get('user');
        if (user.get('first_name')) {
          return `${user.get('first_name')} ${user.get('last_name') || ''}`;
        }
        return '';
      },
      headerLabel: 'Name',
      key: 'name',
      sortable: false,
    },
    {
      content: (record: IVolunteerListRecord) => 'Confirm',
      headerLabel: 'Handover consent',
      key: 'handover_consent',
      sortable: false,
      isButton: true,
      isButtonDisabled: (record: IVolunteerListRecord) => record.get('handover_consent_given'),
      onButtonClick: (record: IVolunteerListRecord) => {
        const user = record.get('user');
        const volunteerId = record.get('id');
        const name = `${user.get('first_name')} ${user.get('last_name')}`
        if (window.confirm(`Are you sure you want to confirm handover consent for ${name}?`)) {
          this.props.confirmHandoverConsent(
            volunteerId,
            this.getFilterSet(this.props.volunteersCollection),
            PAGE_SIZE,
            this.props.volunteersCollection
          );
        }
      },
    },
    {
      content: (record: IVolunteerListRecord) => {
        const latestContactLog = record.get('latest_contact_log');
        if (typeof latestContactLog !== 'undefined') {
          return latestContactLog.getIn(['call_outcome_display', 'name']);
        }
        return '-';
      },
      headerLabel: 'Last outcome',
      key: 'last_outcome',
      sortable: false,
    },
    {
      content: (record: IVolunteerListRecord) => {
        const latestContactLog = record.get('latest_contact_log');
        if (typeof latestContactLog !== 'undefined') {
          return formatShortDate(moment.utc(latestContactLog.get('created')));
        }
        return '-';
      },
      headerLabel: 'Last contacted',
      key: 'last_contact',
      sortable: false,
    },
    {
      content: (record: IVolunteerListRecord) => {
        const latestContactLog = record.get('latest_contact_log');
        if (typeof latestContactLog !== 'undefined') {
          return `${latestContactLog
            .getIn(['user_display', 'first_name']) || '--'} ${latestContactLog
            .getIn(['user_display', 'last_name']) || '--'}`;
        }
        return '-';
      },
      headerLabel: 'Contacted by',
      key: 'last_contact_by',
      sortable: false,
    },
  ];
}

function mapStateToProps({ collections, responses }: IStore, props: IExternalProps) {
  const volunteersCollection = getCollectionByName(
    collections['users/volunteers'],
    COLLECTION_NAME
  );

  return {
    ...props,
    volunteersCollection,
    loading: isPending(responses, GET_COLLECTION, 'users/volunteers'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users/volunteers')
  };
}

export default connect(mapStateToProps, {
  getCollection,
  confirmHandoverConsent
})(VolunteersList);
