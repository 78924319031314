import { Map } from 'immutable';
import * as _ from 'underscore';
import { CLEAR_SIMPLE_COMPONENT_STATE, IAction, IPayload, SET_SIMPLE_COMPONENT_STATE } from '../actions/actions';

const defaultState = Map();
export function simpleComponentState(state: Map<any, any> = defaultState, action: IAction<IPayload>): Map<any, any> {
  let path;
  switch (action.type) {
    case SET_SIMPLE_COMPONENT_STATE:
      path = _.isArray(action.payload.pathOrIndex) ? action.payload.pathOrIndex : [action.payload.pathOrIndex];
      return state.setIn(path, action.payload.value);
    case CLEAR_SIMPLE_COMPONENT_STATE:
      path = _.isArray(action.payload.pathOrIndex) ? action.payload.pathOrIndex : [action.payload.pathOrIndex];
      return state.deleteIn(path);
    default:
      return state;
  }
}
