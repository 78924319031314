import { fromJS, Map } from 'immutable';
import * as React from 'react';
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import GenericList from '../chadmin/generic-list';

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS_DETAIL = fromJS([
  { name: ['name'], display_name: 'Name', type: 'detail', route: DETAIL_ROUTE},
  { name: ['site_address'], display_name: 'Address', type: 'text'},
  { name: ['radius'], display_name: 'Territory radius (Miles)', type: 'text'},
]);

const LIST_COLUMNS = fromJS([
  { name: ['name'], display_name: 'Name', type: 'text'},
  { name: ['site_address'], display_name: 'Address', type: 'text'},
  { name: ['radius'], display_name: 'Territory radius (Miles)', type: 'text'},
]);

const MESSAGES = {
  title: 'generic.sites',
  create: 'sites.list.create',
};

const COLLECTION_NAME = 'SITES';

type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, {}> {
  public render() {
    return (
      <GenericList
        canCreate={this.props.permissions.canCreate}
        messages={MESSAGES}
        collectionColumns={this.props.permissions.canEdit ? LIST_COLUMNS_DETAIL : LIST_COLUMNS}
        collectionName={COLLECTION_NAME}
        collectionKey={'sites'}
        defaultFilters={Map({include_inactive: '1'})}
      />
    );
  }
}
