import { TableHeader } from '@dabapps/roe';
import * as React from 'react';

import { ISortProps, TColumn } from '../types';
import SortIcon from './SortIcon';

interface IAdminListHeaderProps extends ISortProps {
  header: TColumn;
}

export default class AdminListHeader extends React.PureComponent<
  IAdminListHeaderProps,
  undefined
> {
  public render() {
    const { header, setSortBy, sortBy, reversed } = this.props;
    const { display_name, sortable, name } = header.toObject();

    return (
      <TableHeader>
        {display_name}
        {sortable &&
          <SortIcon
            sortName={name.join('__')}
            sortBy={sortBy}
            setSortBy={setSortBy}
            reversed={reversed}
          />}
      </TableHeader>
    );
  }
}
