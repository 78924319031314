import * as moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

const DATE_FORMAT = 'YYYY-MM-DD';

interface IDateInputProps {
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  placeholder?: string;
}
export default class DateInput extends React.PureComponent<WrappedFieldProps<void> & IDateInputProps, void> {
  public render() {
    const { minDate, maxDate, placeholder } = this.props;
    return (
      <span className="input">
        <DatePicker
          fixedHeight={true}
          showYearDropdown
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={DATE_FORMAT}
          placeholderText={placeholder}
          selected={this.getValue()}
          onChange={this.handleChange.bind(this)}
        />
      </span>
    );
  }

  private handleChange(value: moment.Moment | null) {
    const { input } = this.props;
    const onChange = input && input.onChange;
    const newValue = value && value.format(DATE_FORMAT);
    if (onChange) {
      // value is case as "any" as redux form accepts values in addition to events but the type definition is wrong
      onChange(newValue as any);
    }
  }

  private getValue() {
    const { input } = this.props;
    const value = input && input.value;
    return (value && moment.utc(value).isValid()) ? moment.utc(value) : null;
  }
}
