import { Column, Container, FormGroup, Row } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Field, FormProps, FormSection } from "redux-form";
import { IUserRecord } from "../../../../store/data-types";
import { FormErrors } from "../../../../utils";
import FormArray from '../../../forms/form-array';
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import { ReactSelectWrapper } from "../../../forms/react-select-wrapper";
import Motivations from "./motivations";

export interface IDataProps {
  value: string;
  label: string;
}

interface IExternalProps {
  advertOptions: IDataProps[];
  advertTypesOptions: IDataProps[];
  errors?: FormErrors | null;
  motivationErrors?: Map<string, List<string>> | null;
}

export default class EditDriversDetailsFormFields extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const { advertOptions, advertTypesOptions, errors, motivationErrors } = this.props;
    return (
      <FormSection name="volunteer">
        <Row>
          <Column xs={12} sm={6} className="padding-large">
            <FormGroup noLabel>
              <p><FormattedMessage id='volunteer.advertisement'/></p>
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id='generic.advertisement_type' /></label>
              <Field
                component={ReactSelectWrapper}
                name='advert_type'
                options={advertTypesOptions}
                simpleValue
                clearable={false}
                className="select"
              />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id='generic.advertisement' /></label>
              <Field
                component={ReactSelectWrapper}
                name='advert_source'
                options={advertOptions}
                simpleValue
                clearable={false}
                className="select"
              />
            </FormGroup>
          </Column>
          <Column xs={12} sm={6} className="padding-large">
            <FormArray
              name="motivations"
              component={Motivations}
            />
            <FormErrorsRenderer formErrors={motivationErrors} errorKey="volunteer.motivations"/>
          </Column>
        </Row>
      </FormSection>
    );
  }
}
