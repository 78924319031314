import { Column, FormGroup, Row } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { IStore } from '../../../../store';
import { ITelephoneRecord } from '../../../../store/data-types';

interface IProps {
  telephoneId: string;
  telephone: ITelephoneRecord;
  removeTelephone(id: string): void;
  updateTelephone(id: string, value: ITelephoneRecord): void;
}

export class TelephoneNumber extends React.PureComponent<IProps, void> {
  public constructor (props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  public render () {
    const { telephone, telephoneId } = this.props;

    return (
      <Row>
        <Column md={10}>
          <FormGroup>
            <label><FormattedMessage id="generic.telephone" /></label>
            <input
              value={telephone.telephone}
              type="number"
              onChange={this.onChange}
            />
          </FormGroup>
        </Column>
        <Column md={2}>
          <FormGroup block>
            <a className="font-size-small" onClick={this.removeTelephone}>
              <FormattedMessage id="generic.remove" />
            </a>
          </FormGroup>
        </Column>
      </Row>
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.props.updateTelephone(
      this.props.telephoneId,
      this.props.telephone.set('telephone', event.target.value)
    );
  }

  private removeTelephone = () => {
    const { telephoneId, removeTelephone } = this.props;
    removeTelephone(telephoneId);
  }
}

export default TelephoneNumber;
