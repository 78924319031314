import * as React from 'react';

import { TError, TErrors } from '../../types';

interface IProps extends React.HTMLProps<JSX.Element> {
  errors?: TErrors;
}

export const AdminFormError = ({ errors }: IProps) =>
  <div className="error">
    {errors &&
      errors.map((error: TError) =>
        <p key={error}>
          {error}
        </p>
      )}
  </div>;

export default AdminFormError;
