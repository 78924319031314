import { Column, Row, Well } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { Link } from 'react-router';
import GoogleCharts from '../google-charts';

interface IExternalProps {
  loading?: boolean;
  icon?: JSX.Element;
  panelTitle: string;
  chartTitle?: string;
  height?: number | string;
  className?: string;
  data: google.visualization.ChartData;
  options?: google.visualization.ChartOptions;
  chartType: string;
}

type IProps = IExternalProps;

class DashboardChartPanel extends React.PureComponent<IProps, void> {

  public render() {
    const { loading, icon, panelTitle, chartTitle, height, className, data, options, chartType } = this.props;
    return (
      <div className={className}>
        <Well className={'clearfix padding-base margin-vertical-large'}>
          <h6 className={'header font-size-medium bold margin-top-base'}>{icon} {panelTitle}</h6>
          <Well className={'padding-left-none padding-right-none inner-well chart-well-inner'}>
            {loading ? <p className={'margin-left-base'}>Loading...</p> :
              <GoogleCharts
                title={chartTitle}
                data={data}
                options={options}
                chartType={chartType}
              />}
          </Well>
        </Well>
      </div>
    );
  }
}

export default DashboardChartPanel;
