'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconThLarge (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconThLarge'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconThLarge'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(320.81, 1791.82) scale(1, -1)',
          d: 'M768 512v-384q0 -52 -38 -90t-90 -38h-512q-52 0 -90 38t-38 90v384q0 52 38 90t90 38h512q52 0 90 -38t38 -90zM768 1280v-384q0 -52 -38 -90t-90 -38h-512q-52 0 -90 38t-38 90v384q0 52 38 90t90 38h512q52 0 90 -38t38 -90zM1664 512v-384q0 -52 -38 -90t-90 -38h-512q-52 0 -90 38t-38 90v384q0 52 38 90t90 38h512q52 0 90 -38t38 -90zM1664 1280v-384q0 -52 -38 -90t-90 -38h-512q-52 0 -90 38t-38 90v384q0 52 38 90t90 38h512q52 0 90 -38t38 -90z'
        }
      )
    );
  };

})();
