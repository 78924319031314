import { SimpleRecord } from '@dabapps/simple-records';
import { List, Record } from 'immutable';
import * as moment from 'moment';
import {
  IMedicationRecord,
  INamedActiveObjectRecord,
  INumericRange,
  IUserRecord,
  IVolunteer,
  IVolunteerRecord,
  MedicationRecord,
  NamedActiveObjectRecord,
  NumericRangeRecord,
  RecordInstance,
  UserRecord,
  VolunteerRecord,
} from './';

interface IStudyVolunteer {
  id: string;
  study_site: string;
  volunteer: IVolunteerRecord
}

export type IStudyVolunteerRecord = RecordInstance<IStudyVolunteer>;
const StudyVolunteerRecordInner = Record<IStudyVolunteer>({
  id: '',
  study_site: '',
  volunteer: VolunteerRecord(),
});
export const StudyVolunteerRecord = (studyVolunteer: Partial<IVolunteer> | Partial<IVolunteerRecord>) => {
  const record = StudyVolunteerRecordInner(studyVolunteer);

  return record.set('volunteer', VolunteerRecord(record.volunteer));
};

interface IStudyMedication {
  id: string | null;
  med_type: string | null;
  medication: string | null;
  generic_name: string | null;
  med_class: string | null;
  min_dosage: number | null;
  max_dosage: number | null;
  include: IncludeOptions | null;
  generic_name_display: INamedActiveObjectRecord;
  medication_display: IMedicationRecord;
  med_class_display: INamedActiveObjectRecord;
  med_type_display: INamedActiveObjectRecord;
}

export type IStudyMedicationRecord = RecordInstance<IStudyMedication>;
export const StudyMedicationRecord = Record<IStudyMedication>({
  id: null,
  med_type: null,
  medication: null,
  generic_name: null,
  med_class: null,
  min_dosage: null,
  max_dosage: null,
  include: null,
  generic_name_display: NamedActiveObjectRecord(),
  medication_display: MedicationRecord({}),
  med_class_display: NamedActiveObjectRecord(),
  med_type_display: NamedActiveObjectRecord(),
});

export type Coordinates = Array<Array<[number, number]>>;

export interface IPoly {
  type: 'Polygon';
  coordinates: Coordinates;
}

export interface ICircle {
  center: google.maps.LatLng;
  radius: number;
}

interface IStudySite {
  id: string | null;
  cra: string | null;
  green_lit: boolean | null;
  study: string | null;
  site_number: number | null;
  researchers: string[];
  researchers_display: string[];
  site: string;
  poly: IPoly | null;
  is_confirmed_site: boolean;
}

const StudySiteRecordInner = Record<IStudySite>({
  cra: 'cra_id',
  green_lit: false,
  id: 'study_site_id',
  researchers: [],
  researchers_display: [],
  site: 'site_id',
  site_number: 12,
  study: 'study_id',
  poly: null,
  is_confirmed_site: false,
})

export type IStudySiteRecord = RecordInstance<IStudySite>;
export const StudySiteRecord = (studySite: Partial<IStudySite>): IStudySiteRecord => StudySiteRecordInner(studySite);

interface IStudy {
  active: boolean,
  study_name: string,
  fpfv_date: moment.Moment | null,
  lpfv_date: moment.Moment | null,
  diabetes_type: string,
  id: string,
  injection_insulin: GeneralOptions,
  injection_other: GeneralOptions,
  tablets: GeneralOptions,
  age_range: INumericRange,
  bmi_range: INumericRange,
  impact_number: string | null,
  project_manager: string | null,
  project_manager_display: IUserRecord | null,
  study_sites: List<IStudySiteRecord> | null;
  study_medications: List<IStudyMedicationRecord> | null;
}

const StudyRecordInner = Record<IStudy>({
  active: false,
  study_name: '',
  age_range: NumericRangeRecord({}),
  bmi_range: NumericRangeRecord({}),
  diabetes_type: 'TYPE_1',
  lpfv_date: null,
  id: '',
  impact_number: null,
  project_manager: null,
  project_manager_display: UserRecord(),
  injection_insulin: 'NOT_FILTERED',
  injection_other: 'NOT_FILTERED',
  fpfv_date: null,
  study_sites: null,
  study_medications: null,
  tablets: 'NOT_FILTERED',
})

export type IStudyRecord = RecordInstance<IStudy>;
export const StudyRecord = (study?: Partial<IStudy>): IStudyRecord => {
  return StudyRecordInner(study)
    .set(
      'lpfv_date',
      study && study.lpfv_date ? moment.utc(study.lpfv_date) : null
    )
    .set(
      'study_name',
      study && study.study_name || 'Draft'
    )
    .set(
      'fpfv_date',
      study && study.fpfv_date ? moment.utc(study.fpfv_date) : null
    )
    .set(
      'age_range',
      study && study.age_range ? NumericRangeRecord(study.age_range) : NumericRangeRecord({})
    )
    .set(
      'bmi_range',
      study && study.bmi_range ? NumericRangeRecord(study.bmi_range) : NumericRangeRecord({})
    )
    .set(
      'study_sites',
      study && study.study_sites ?
        List(study.study_sites).map((site) => StudySiteRecord(site)) :
        null
    )
    .set(
      'study_medications',
      study && study.study_medications ?
        List(study.study_medications).map((medication) => StudyMedicationRecord(medication)) :
        null
    )
    .set(
      'project_manager_display',
      study && study.project_manager_display ? UserRecord(study.project_manager_display) : UserRecord({})
    )
}

export type GeneralOptions = 'YES' | 'NO' | 'NOT_FILTERED';
export type IncludeOptions = 'INCLUDE' | 'EXCLUDE' | 'OPTIONAL';

export type MedicationFormData = Partial<{
  id: string;
  med_type: string;
  medication: string;
  generic_name: string;
  med_class: string;
  min_dosage: string;
  max_dosage: string;
  include: IncludeOptions;
}>;

export type GeneralFormData = Partial<{
  diabetes_type: string;
  tablets: GeneralOptions;
  injection_insulin: GeneralOptions;
  injection_other: GeneralOptions;
  age_range: Partial<{
    lower: string;
    upper: string;
  }>;
  bmi_range: Partial<{
    lower: string;
    upper: string;
  }>;
}>;

export type SiteFormData = Partial<{
  id: string;
  site_number: string;
  green_lit: boolean;
  cra: string;
  researchers: string[];
  researchers_display: string[];
  is_confirmed_site: boolean;
}> & {
  site: string;
};

export type DetailsFormData = Partial<{
  active: boolean;
  study_name: string;
  impact_number: string;
  fpfv_date: string;
  lpfv_date: string;
  project_manager: string;
}>;

export type FormData = Partial<{
  study_medications: MedicationFormData[];
  general: GeneralFormData;
  study_sites: SiteFormData[];
  details: DetailsFormData;
}>

export type FormattedStudyMedication = Partial<{
  id: string;
  med_type: string;
  medication: string;
  generic_name: string;
  med_class: string;
  min_dosage: number;
  max_dosage: number;
  include: IncludeOptions;
}>;

export type FormattedStudySite = Partial<{
  id: string;
  site: string;
  site_number: number | null;
  green_lit: boolean;
  cra: string | null;
  researchers: string[];
  is_confirmed_site: boolean;
}>;

export type FormattedFormData = Partial<{
  active: boolean;
  name: string;
  impact_number: string;
  fpfv_date: string;
  lpfv_date: string;
  project_manager: string;
  diabetes_type: string;
  tablets: GeneralOptions;
  injection_insulin: GeneralOptions;
  injection_other: GeneralOptions;
  age_range: Partial<INumericRange>,
  bmi_range: Partial<INumericRange>,
  study_medications: FormattedStudyMedication[],
  study_sites: FormattedStudySite[]
}>

export interface ICircle {
  center: google.maps.LatLng;
  radius: number;
}

interface IVolunteerLocation {
  volunteer_id: string;
  location: google.maps.LatLng;
  display_full_name: string;
}

export const VolunteerLocationRecord = Record<IVolunteerLocation>({
  volunteer_id: '',
  location: {
    lat: 0,
    lng: 0,
  },
  display_full_name: '',
})

export type IVolunteerLocationRecord = RecordInstance<IVolunteerLocation>;

export type IStudyOptionRecord = Readonly<{
  id: string;
  study_name: string;
}>;
export const StudyOptionRecord = SimpleRecord<IStudyOptionRecord>({
  id: '',
  study_name: '',
});
