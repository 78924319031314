import { FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { TErrors } from '../../../types';
import AdminFormError from '../AdminFormError';
import { IConfig } from './types';

export interface IFieldGroup {
  config: IConfig;
  errors?: TErrors;
}

export const FieldGroup = ({
  children,
  config,
  errors,
  className
}: React.HTMLProps<JSX.Element> & IFieldGroup ) => {
  return (
    <div className={className}>
      <FormGroup block>
        <label>
          {config.label}
        </label>
        { children }
      </FormGroup>
      <AdminFormError errors={errors} />
    </div>
  );
};

export default FieldGroup;
