import { Column, FormGroup, Row } from '@dabapps/roe';
import {fromJS, List, Map} from 'immutable';
import * as React from 'react';
import { DataShape, Field, FormProps, reduxForm } from 'redux-form';
import { MEDICATION_TYPE_CHOICES } from '../../../../consts/constants';
import { IMedicationRecord, INamedActiveObjectRecord} from '../../../../store/data-types';
import { disableSubmitOnEnter } from '../../../../utils';
import Select from '../../../select';

interface IFormData {
  medication: string,
  generic_name: string,
  frequency: string,
  med_type: string,
  total_dosage: string,
  id: string
}

interface IExternalProps extends FormProps<DataShape, {}, {}> {
  onFieldChange: (field: string, value: string) => void;
  formValues: IFormData;
  onRemove: (id: string) => void;
}

interface IProps extends IExternalProps {
  genericnames: List<INamedActiveObjectRecord>;
  types: List<string>;
  frequencies: List<INamedActiveObjectRecord>;
  medications: List<IMedicationRecord>;
}

export class Form extends React.PureComponent<IProps, {}> {
  public componentWillReceiveProps(newProps: IProps) {
    const { change, formValues, genericnames, medications, types } = newProps;

    if (this.props.medications !== medications) {
      if (change && !types.includes(formValues.med_type)) {
          change('med_type', '');
      }
      this.checkValueAvailable('generic_name', formValues.generic_name, genericnames);
      this.checkValueAvailable('medication', formValues.medication, medications);
    }

    this.autoFillTypeAndGenericName(types, genericnames);
  }

  public render() {
    const types = fromJS(MEDICATION_TYPE_CHOICES)
      .filter((option: Map<string, string>) => {
        return this.props.types.includes(option.get('value', ''))});
    return (
      <form className="volunteer-medication" onSubmit={this.props.handleSubmit} onKeyDown={disableSubmitOnEnter}>
        <Field component='input' type='hidden' name="id"/>
        <Row>
          <Column md={3}>
            <FormGroup block>
              <label>
                Medicine type
              </label>
              <Select
                onChange={(event) => this.props.onFieldChange('med_type', event.currentTarget.value)}
                name={"med_type"}
                options={types}
                getName={this.getTypeLabel}
                getValue={this.getTypeValue}/>
            </FormGroup>
          </Column>
          <Column md={3}>
            <FormGroup block>
              <label>
                Medicine name
              </label>
              <Select
                onChange={(event) => this.props.onFieldChange('id', event.currentTarget.value)}
                name={"medication"}
                options={this.props.medications}
                getName={this.getMedicationName}
                getValue={this.getValue}/>
            </FormGroup>
          </Column>
          <Column md={3}>
            <FormGroup block>
              <label>
                Generic name
              </label>
              <Select
                onChange={(event) => this.props.onFieldChange('generic_name', event.currentTarget.value)}
                name={"generic_name"}
                options={this.props.genericnames}
                getName={this.getName}
                getValue={this.getValue}/>
            </FormGroup>
          </Column>
          <Column md={2}>
            <FormGroup block>
              <label>
                Frequency
              </label>
              <Select
                name={"frequency"}
                options={this.props.frequencies}
                getName={this.getName}
                getValue={this.getValue}/>
            </FormGroup>
          </Column>
          <Column md={2} className="dose">
            <FormGroup block>
              <label>
                Total dose
              </label>
              <Field
                name="total_dosage"
                component="input"
                type="number"
                min="0"
              />
              <div className="unit">
                {this.getMedicationUnit()}
              </div>
            </FormGroup>
          </Column>
          <Column md={1}>
            <a className="remove font-size-small" onClick={() => this.props.onRemove(this.props.formValues.id)}>
              remove
            </a>
          </Column>
        </Row>
      </form>
    );
  }

  private autoFillTypeAndGenericName(newTypes: List<string>, newGenericNames: List<INamedActiveObjectRecord>) {
    const { change, genericnames, types } = this.props;
    if (change) {
      if (types.count() !== newTypes.count() && newTypes.count() === 1) {
        change('med_type', newTypes.get(0));
      }
      if (genericnames.count() !== newGenericNames.count() && newGenericNames.count() === 1) {
        const genericName = newGenericNames.get(0);
        if (genericName) {
          change('generic_name', genericName.get('id'));
        }
      }
    }
  }

  private getTypeLabel(option: Map<string, string>) {
    return option.get('label', '');
  }
  private getTypeValue(option: Map<string, string>) {
    return option.get('value', '');
  }
  private getMedicationUnit() {
    const medication = this.props.formValues.medication;
    if (medication) {
      const selectedMedication = this.props.medications.find((med) => med.get('id') === medication);
      return selectedMedication ? selectedMedication.unit_display.short_name : '--';
    }
    return '--';
  }

  private checkValueAvailable(
    field: string, value: string,
    options: List<IMedicationRecord | INamedActiveObjectRecord>) {

    if (this.props.change && value !== undefined) {
      if (options.find((option) => this.getValue(option) === value) === undefined ) {
        this.props.change(field, '');
      }
    }
  }

  private getMedicationName(option: IMedicationRecord) {
    return option.get('trade_name');
  }

  private getName(option: INamedActiveObjectRecord) {
    return option.get('name');
  }
  private getValue(option: INamedActiveObjectRecord | IMedicationRecord) {
    return option.get('id');
  }
}

export default reduxForm({
  enableReinitialize: true
})(Form);
