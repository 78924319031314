import * as React from 'react';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

export const FieldNumber = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) => {
  return (
    <FieldGroup className={className} config={config} errors={errors}>
      { readOnly ?
        <span className="input">
          {value}
        </span>
      :
        <input
          type="number"
          name={name}
          step={config.step}
          placeholder={config.placeholder}
          onChange={(event) => onChange(event)}
          value={value}
        />
      }
    </FieldGroup>
   );
};

export default FieldNumber;
