// Default Permissions

export type IPermissionsProps = Readonly<{
  canCreate: boolean;
  canEdit: boolean;
  canRemove: boolean;
  canSeeAvailabilityAndComments: boolean;
  isAnonymized: boolean;
  canConfirmHandoverConsent: boolean;
  canDownloadVolunteerCsv: boolean;
  canDownloadVolunteerAdminCsv: boolean;
  canDownloadVolunteerDiallerCsv: boolean;
  canViewStudyPanel: boolean;
  canViewQaStatsPanel: boolean;
  canViewSResearcherStats: boolean;
  canViewAdminStats: boolean;
  canViewVolunteerDashboard: boolean;
}>

export const customProps: IPermissionsProps = {
  canCreate: true,
  canEdit: true,
  canRemove: false,
  canSeeAvailabilityAndComments: true,
  isAnonymized: false,
  canConfirmHandoverConsent: false,
  canDownloadVolunteerCsv: false,
  canDownloadVolunteerAdminCsv: false,
  canDownloadVolunteerDiallerCsv: false,
  canViewStudyPanel: false,
  canViewQaStatsPanel: false,
  canViewSResearcherStats: false,
  canViewAdminStats: false,
  canViewVolunteerDashboard: false,
}

export type IPermission = Readonly<{
  hasAccess: boolean;
  redirect?: string;
  customProps: Partial<IPermissionsProps>;
}>

export type PageName = 'home' |
 'volunteers' |
 'volunteers_detail' |
 'volunteers_create' |
 'sites' |
 'sites_create' |
 'sites_edit' |
 'studies' |
 'studies_search' |
 'studies_view' |
 'studies_save' |
 'studies_edit' |
 'reports' |
 'reports_csv' |
 'reports_recruitment_flow' |
 'lookups' |
 'drivers' |
 'adverts' |
 'adverts_create' |
 'adverts_edit' |
 'adverts_types' |
 'adverts_types_create' |
 'adverts_types_edit' |
 'motivations' |
 'motivations_create' |
 'motivations_edit' |
 'call_outcomes' |
 'call_outcomes_create' |
 'call_outcomes_edit' |
 'outcomes' |
 'study_outcomes' |
 'study_outcomes_create' |
 'study_outcomes_edit' |
 'medication' |
 'medications' |
 'medications_create' |
 'medications_edit' |
 'frequency' |
 'frequency_create' |
 'frequency_edit' |
 'manufacturer' |
 'manufacturer_create' |
 'manufacturer_edit' |
 'class' |
 'class_create' |
 'class_edit' |
 'generic_name' |
 'generic_name_create' |
 'generic_name_edit' |
 'units' |
 'units_create' |
 'units_edit' |
 'titles' |
 'titles_create' |
 'titles_edit' |
 'contact_types' |
 'contact_types_create' |
 'contact_types_edit' |
 'other' |
 'users' |
 'users_create' |
 'users_edit' |
 'profile_edit_drivers' |
 'profile_edit_medical' |
 'profile_edit_personal' |
 'profile_edit_medication';

export type IAllPermission = Readonly<{
  [key in PageName]: IPermission;
}>

const Permissions: IAllPermission = {
  home: {
    hasAccess: true,
    customProps: (customProps),
  },
  volunteers: {
    hasAccess: true,
    customProps: (customProps),
  },
  volunteers_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  volunteers_detail: {
    hasAccess: true,
    customProps: (customProps),
  },
  sites: {
    hasAccess: true,
    customProps: (customProps),
  },
  sites_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  sites_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  studies: {
    hasAccess: true,
    customProps: (customProps),
  },
  studies_search: {
    hasAccess: true,
    customProps: (customProps),
  },
  studies_view: {
    hasAccess: true,
    customProps: (customProps),
  },
  studies_save: {
    hasAccess: true,
    customProps: (customProps),
  },
  studies_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  reports: {
    hasAccess: true,
    customProps: (customProps),
  },
  reports_csv: {
    hasAccess: false,
    customProps: {}
  },
  reports_recruitment_flow: {
    hasAccess: false,
    customProps: {}
  },
  lookups: {
    hasAccess: true,
    customProps: {},
  },
  drivers: {
    hasAccess: true,
    customProps: {},
  },
  adverts: {
    hasAccess: true,
    customProps: (customProps),
  },
  adverts_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  adverts_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  adverts_types: {
    hasAccess: true,
    customProps: (customProps),
  },
  adverts_types_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  adverts_types_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  motivations: {
    hasAccess: true,
    customProps: (customProps),
  },
  motivations_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  motivations_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  call_outcomes: {
    hasAccess: true,
    customProps: (customProps),
  },
  call_outcomes_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  call_outcomes_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  outcomes: {
    hasAccess: true,
    customProps: {},
  },
  study_outcomes: {
    hasAccess: true,
    customProps: (customProps),
  },
  study_outcomes_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  study_outcomes_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  medication: {
    hasAccess: true,
    customProps: {},
  },
  medications: {
    hasAccess: true,
    customProps: (customProps),
  },
  medications_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  medications_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  frequency: {
    hasAccess: true,
    customProps: (customProps),
  },
  frequency_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  frequency_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  manufacturer: {
    hasAccess: true,
    customProps: (customProps),
  },
  manufacturer_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  manufacturer_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  class: {
    hasAccess: true,
    customProps: (customProps),
  },
  class_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  class_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  generic_name: {
    hasAccess: true,
    customProps: (customProps),
  },
  generic_name_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  generic_name_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  units: {
    hasAccess: true,
    customProps: (customProps),
  },
  units_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  units_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  titles: {
    hasAccess: true,
    customProps: (customProps),
  },
  titles_create: {
    hasAccess: true,
    customProps: (customProps),
  },
  titles_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
   contact_types: {
    hasAccess: true,
    customProps: (customProps),
  },
   contact_types_create: {
    hasAccess: true,
    customProps: (customProps),
  },
   contact_types_edit: {
    hasAccess: true,
    customProps: (customProps),
  },
  other: {
    hasAccess: true,
    customProps: {},
  },
  users: {
    hasAccess: false,
    customProps: {},
  },
  users_create: {
    hasAccess: false,
    customProps: {},
  },
  users_edit: {
    hasAccess: false,
    customProps: {},
  },
  profile_edit_drivers: {
    hasAccess: false,
    customProps: {},
  },
  profile_edit_medical: {
    hasAccess: false,
    customProps: {},
  },
  profile_edit_personal: {
    hasAccess: false,
    customProps: {},
  },
  profile_edit_medication: {
    hasAccess: false,
    customProps: {},
  }
}

export default Permissions;
