import 'core-js/shim';

import '^/css-livereload';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import { IntlState, Provider, updateIntl} from 'react-intl-redux';
import { browserHistory, IndexRoute, Route, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import { checkLoggedIn } from '^/actions/auth';
import store from '^/store/';

import * as en from 'react-intl/locale-data/en';

import ChangeEmail from '^/components/accounts/change-email';
import ChangePassword from '^/components/accounts/change-password';
import AppContainer from '^/components/app-container';
import Dashboard from '^/components/dashboard';
import AdvertTypesCreate from '^/components/lookups/drivers/adverts-types/adverts-types-create';
import AdvertTypesDetail from '^/components/lookups/drivers/adverts-types/adverts-types-detail';
import AdvertTypesList from '^/components/lookups/drivers/adverts-types/adverts-types-list';
import AdvertsCreate from '^/components/lookups/drivers/adverts/adverts-create';
import AdvertsDetail from '^/components/lookups/drivers/adverts/adverts-detail';
import AdvertsList from '^/components/lookups/drivers/adverts/adverts-list';
import MotivationsCreate from '^/components/lookups/drivers/motivations/motivations-create';
import MotivationsDetail from '^/components/lookups/drivers/motivations/motivations-detail';
import MotivationsList from '^/components/lookups/drivers/motivations/motivations-list';
import MedicationClassCreate from '^/components/lookups/medication/class/class-create';
import MedicationClassDetail from '^/components/lookups/medication/class/class-detail';
import MedicationClassList from '^/components/lookups/medication/class/class-list';
import MedicationFrequencyCreate from '^/components/lookups/medication/frequency/frequency-create';
import MedicationFrequencyDetail from '^/components/lookups/medication/frequency/frequency-detail';
import MedicationFrequencyList from '^/components/lookups/medication/frequency/frequency-list';
import MedicationGenericNameCreate from '^/components/lookups/medication/generic-name/generic-name-create';
import MedicationGenericNameDetail from '^/components/lookups/medication/generic-name/generic-name-detail';
import MedicationGenericNameList from '^/components/lookups/medication/generic-name/generic-name-list';
import MedicationManufacturerCreate from '^/components/lookups/medication/manufacturer/manufacturer-create';
import MedicationManufacturerDetail from '^/components/lookups/medication/manufacturer/manufacturer-detail';
import MedicationManufacturerList from '^/components/lookups/medication/manufacturer/manufacturer-list';
import MedicationCreate from '^/components/lookups/medication/medication/medication-create';
import MedicationDetail from '^/components/lookups/medication/medication/medication-detail';
import MedicationList from '^/components/lookups/medication/medication/medication-list';
import MedicationUnitCreate from '^/components/lookups/medication/unit/unit-create';
import MedicationUnitDetail from '^/components/lookups/medication/unit/unit-detail';
import MedicationUnitList from '^/components/lookups/medication/unit/unit-list';
import ContactTypeCreate from '^/components/lookups/other/contact-types/contact-type-create';
import ContactTypeDetail from '^/components/lookups/other/contact-types/contact-type-detail';
import ContactTypeList from '^/components/lookups/other/contact-types/contact-type-list';
import TitlesCreate from '^/components/lookups/other/titles/titles-create';
import TitlesDetail from '^/components/lookups/other/titles/titles-detail';
import TitlesList from '^/components/lookups/other/titles/titles-list';
import CallOutcomesCreate from '^/components/lookups/outcomes/call-outcomes/call-outcomes-create';
import CallOutcomesDetail from '^/components/lookups/outcomes/call-outcomes/call-outcomes-detail';
import CallOutcomesList from '^/components/lookups/outcomes/call-outcomes/call-outcomes-list';
import StudyOutcomesCreate from '^/components/lookups/outcomes/study-outcomes/study-outcomes-create';
import StudyOutcomesDetail from '^/components/lookups/outcomes/study-outcomes/study-outcomes-detail';
import StudyOutcomesList from '^/components/lookups/outcomes/study-outcomes/study-outcomes-list';
import LandingPage from '^/components/pages/landing-page';
import PolicyPage from '^/components/pages/policy-page';
import StyleGuide from '^/components/pages/styleguide';
import ReportsCsv from '^/components/reports/csv';
import RecruitmentFlow from '^/components/reports/recruitment-flow';
import SetPasswordPage from '^/components/set-password';
import CreateSitePage from '^/components/sites/sites-create';
import SiteDetailPage from '^/components/sites/sites-detail-page';
import SitesPage from '^/components/sites/sites-list';
import StudyIndex from '^/components/studies/';
import StudySave from '^/components/studies/save';
import StudySearch from '^/components/studies/search';
import StudyView from '^/components/studies/view';
import CreateUser from '^/components/user/create-user/users-create';
import UserDetail from '^/components/user/list-user/users-detail';
import UserList from '^/components/user/list-user/users-list';
import CreateVolunteer from '^/components/volunteers/create-volunteer/create-volunteer';
import EditDriversDetails from '^/components/volunteers/edit-volunteer-details/edit-self/edit-drivers-details';
import EditMedicalDetails from '^/components/volunteers/edit-volunteer-details/edit-self/edit-medical-details';
import EditPersonalDetails from '^/components/volunteers/edit-volunteer-details/edit-self/edit-personal-details';
// tslint:disable-next-line:max-line-length
import EditVolunteerMedication from '^/components/volunteers/edit-volunteer-details/edit-self/edit-volunteer-medication';
import VolunteerDetails from '^/components/volunteers/volunteer-details/volunteer-details';
import VolunteerList from '^/components/volunteers/volunteers-list/volunteers-filter';
export const historyObject = syncHistoryWithStore(browserHistory, store);
import mapPermissionsToProps from '^/permissions/permissions-hoc';

addLocaleData([...en]);

declare const LANG_DATA_STRING: string;
const LANG_DATA: IntlState = JSON.parse(LANG_DATA_STRING);

store.dispatch(updateIntl(LANG_DATA));

const IGNORED_PATHS = /^\/(set-password|policy)/;

const StudySearchWithPermissions = mapPermissionsToProps(StudySearch, 'studies_search');

ReactDOM.render(
  <Provider store={store}>
    <Router history={historyObject}>
      <Route
        path="/"
        component={AppContainer}
        onEnter={() => store.dispatch(checkLoggedIn(IGNORED_PATHS))}
      >
        <IndexRoute component={LandingPage} />
        <Route path="policy/" component={PolicyPage} />
        <Route path="set-password/:uid/:token/" component={SetPasswordPage} />
        <Route path="styleguide/" component={StyleGuide} />
        <Route path="app/">
          <IndexRoute component={mapPermissionsToProps(Dashboard, 'home')} />
          <Route path="studies/">
            <IndexRoute component={mapPermissionsToProps(StudyIndex, 'studies')} />
            <Route path="search/" component={StudySearchWithPermissions} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(StudyView, 'studies_view')} />
              <Route path="edit/" component={StudySearchWithPermissions} />
              <Route path="save/" component={mapPermissionsToProps(StudySave, 'studies_save')} />
            </Route>
          </Route>
          <Route path="sites/">
            <IndexRoute component={mapPermissionsToProps(SitesPage, 'sites')} />
            <Route path="create/" component={mapPermissionsToProps(CreateSitePage, 'sites_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(SiteDetailPage, 'sites_edit')}/>
            </Route>
          </Route>
          <Route path="drivers/motivations/">
            <IndexRoute component={mapPermissionsToProps(MotivationsList, 'motivations')} />
            <Route path="create/" component={mapPermissionsToProps(MotivationsCreate, 'motivations_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MotivationsDetail, 'motivations_edit')}/>
            </Route>
          </Route>
          <Route path="drivers/adverts/">
            <IndexRoute component={mapPermissionsToProps(AdvertsList, 'adverts')} />
            <Route path="create/" component={mapPermissionsToProps(AdvertsCreate, 'adverts_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(AdvertsDetail, 'adverts_edit')}/>
            </Route>
          </Route>
          <Route path="drivers/adverts-types/">
            <IndexRoute component={mapPermissionsToProps(AdvertTypesList, 'adverts_types')} />
            <Route path="create/" component={mapPermissionsToProps(AdvertTypesCreate, 'adverts_types_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(AdvertTypesDetail, 'adverts_types_edit')}/>
            </Route>
          </Route>
          <Route path="outcomes/call-outcomes/">
            <IndexRoute component={mapPermissionsToProps(CallOutcomesList, 'call_outcomes')} />
            <Route path="create/" component={mapPermissionsToProps(CallOutcomesCreate, 'call_outcomes_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(CallOutcomesDetail, 'call_outcomes_edit')}/>
            </Route>
          </Route>
          <Route path="outcomes/study-outcomes/">
            <IndexRoute component={mapPermissionsToProps(StudyOutcomesList, 'study_outcomes')} />
            <Route path="create/" component={mapPermissionsToProps(StudyOutcomesCreate, 'study_outcomes_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(StudyOutcomesDetail, 'study_outcomes_edit')}/>
            </Route>
          </Route>
          <Route path="titles/">
            <IndexRoute component={mapPermissionsToProps(TitlesList, 'titles')} />
            <Route path="create/" component={mapPermissionsToProps(TitlesCreate, 'titles_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(TitlesDetail, 'titles_edit')}/>
            </Route>
          </Route>
          <Route path="volunteers/">
            <IndexRoute component={mapPermissionsToProps(VolunteerList, 'volunteers')} />
            <Route path="create/">
              <IndexRoute component={mapPermissionsToProps(CreateVolunteer, 'volunteers_create')}/>
            </Route>
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(VolunteerDetails, 'volunteers_detail')}/>
            </Route>
          </Route>
          <Route path="profile/edit/">
            <Route
              path="drivers-details/"
              component={mapPermissionsToProps(EditDriversDetails, 'profile_edit_drivers')}
            />
            <Route
              path="medical-details/"
              component={mapPermissionsToProps(EditMedicalDetails, 'profile_edit_medical')}
            />
            <Route
              path="personal-details/"
              component={mapPermissionsToProps(EditPersonalDetails, 'profile_edit_personal')}
            />
            <Route
              path=":id/medication/"
              component={mapPermissionsToProps(EditVolunteerMedication, 'profile_edit_medication')}
            />
          </Route>
          <Route path="medications/class/">
            <IndexRoute component={mapPermissionsToProps(MedicationClassList, 'class')} />
            <Route path="create/" component={mapPermissionsToProps(MedicationClassCreate, 'class_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MedicationClassDetail, 'class_edit')}/>
            </Route>
          </Route>
          <Route path="medications/medication/">
            <IndexRoute component={mapPermissionsToProps(MedicationList, 'medications')} />
            <Route path="create" component={mapPermissionsToProps(MedicationCreate, 'medications_create')} />
            <Route path=":id">
              <IndexRoute component={mapPermissionsToProps(MedicationDetail, 'medications_edit')}/>
            </Route>
          </Route>
          <Route path="medications/frequency/">
            <IndexRoute component={mapPermissionsToProps(MedicationFrequencyList, 'frequency')} />
            <Route path="create/" component={mapPermissionsToProps(MedicationFrequencyCreate, 'frequency_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MedicationFrequencyDetail, 'frequency_edit')}/>
            </Route>
          </Route>
          <Route path="medications/unit/">
            <IndexRoute component={mapPermissionsToProps(MedicationUnitList, 'units')} />
            <Route path="create/" component={mapPermissionsToProps(MedicationUnitCreate, 'units_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MedicationUnitDetail, 'units_edit')}/>
            </Route>
          </Route>
          <Route path="medications/manufacturer/">
            <IndexRoute component={mapPermissionsToProps(MedicationManufacturerList, 'manufacturer')} />
            <Route path="create/"
              component={mapPermissionsToProps(MedicationManufacturerCreate, 'manufacturer_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MedicationManufacturerDetail, 'manufacturer_edit')}/>
            </Route>
          </Route>
          <Route path="medications/generic-name/">
            <IndexRoute component={mapPermissionsToProps(MedicationGenericNameList, 'generic_name')} />
            <Route path="create/"
              component={mapPermissionsToProps(MedicationGenericNameCreate, 'generic_name_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(MedicationGenericNameDetail, 'generic_name_edit')}/>
            </Route>
          </Route>
          <Route path="contact-types/">
            <IndexRoute component={mapPermissionsToProps(ContactTypeList, 'contact_types')} />
            <Route path="create/" component={mapPermissionsToProps(ContactTypeCreate, 'contact_types_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(ContactTypeDetail, 'contact_types_edit')}/>
            </Route>
          </Route>
          <Route path="reports/">
            <Route path="csv/" component={mapPermissionsToProps(ReportsCsv, 'reports_csv')} />
            <Route path="recruitment-flow/"
              component={mapPermissionsToProps(RecruitmentFlow, 'reports_recruitment_flow')} />
          </Route>
          <Route path="users/">
            <IndexRoute component={mapPermissionsToProps(UserList, 'users')} />
            <Route path="create/" component={mapPermissionsToProps(CreateUser, 'users_create')} />
            <Route path=":id/">
              <IndexRoute component={mapPermissionsToProps(UserDetail, 'users_edit')}/>
            </Route>
           </Route>
          <Route
            path="change-password/"
            component={ChangePassword}/>
          <Route
            path="change-email/"
            component={ChangeEmail}/>
        </Route>
      </Route>
    </Router>
  </Provider>,
  document.getElementById('app')
);
