// tslint:disable-next-line:no-unused-variable
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { IAction } from '../actions';
import {
  ADVERT_STATS_ADD_TO_STORE,
  RECRUITMENT_FLOW_STATS_ADD_TO_STORE,
  REGISTRATION_RATE_STATS_ADD_TO_STORE,
  STUDY_STATS_ADD_TO_STORE,
  VOLUNTEER_STATS_ADD_TO_STORE,
} from '../actions/statistics';

import { RECRUITMENT_FLOW_NAME } from '../components/reports/recruitment-flow';
// tslint:disable-next-line:no-unused-variable
import { SankeyData } from '../components/reports/recruitment-flow/sankey-chart';

function statistics (
  state = Map<string, Map<string, string | number> | List<number> | SankeyData>(),
  action: IAction<any, string>
) {
  switch (action.type) {

    case VOLUNTEER_STATS_ADD_TO_STORE.SUCCESS:
      return state.merge(fromJS(action.payload.filter_sets).toMap().mapEntries(([key, item]: any) => [
        item.get('name'), Map({
          name: item.get('name'),
          percentage: item.getIn(['results', 'percentage']),
          value: item.getIn(['results', 'value']),
          filters: item.get('filters').toMap().mapEntries(([nope, value]: any) =>
            [value.get('filter'), value.get('value')]
          )
        })
      ]))

    case ADVERT_STATS_ADD_TO_STORE.SUCCESS:
      return state.set(
        'ADVERT_SOURCES',
        fromJS(action.payload).toMap().mapEntries(([key, item]: any) => [
        item.get('name'), Map({
          id: item.get('id'),
          name: item.get('name'),
          average_age: item.get('average_age'),
          count: item.get('count'),
          percentage: item.get('percentage'),
        })
      ])
    )

    case REGISTRATION_RATE_STATS_ADD_TO_STORE.SUCCESS:
      return state.set(
        'REGISTRATION_RATE',
        fromJS(action.payload).toMap().mapEntries(([key, item]: any) =>
          [key, [item.get('completion_month'), item.get('count')]]
        ).toList()
    )

    case STUDY_STATS_ADD_TO_STORE.SUCCESS:
      return state.set(
        'STUDY_STATISTICS',
        fromJS(action.payload)
      )

    case RECRUITMENT_FLOW_STATS_ADD_TO_STORE.SUCCESS:
      return state.set(
        RECRUITMENT_FLOW_NAME,
        action.payload
    )

    case VOLUNTEER_STATS_ADD_TO_STORE.FAILURE:
    case ADVERT_STATS_ADD_TO_STORE.FAILURE:
    case REGISTRATION_RATE_STATS_ADD_TO_STORE.FAILURE:
    case STUDY_STATS_ADD_TO_STORE.FAILURE:
    case RECRUITMENT_FLOW_STATS_ADD_TO_STORE.FAILURE:
      return state;

    default:
      return state;
  }
}

export default statistics;
