'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconEject (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconEject'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconEject'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(383.81, 1791.82) scale(1, -1)',
          d: 'M14 557l710 710q19 19 45 19t45 -19l710 -710q19 -19 13 -32t-32 -13h-1472q-26 0 -32 13t13 32zM1473 0h-1408q-26 0 -45 19t-19 45v256q0 26 19 45t45 19h1408q26 0 45 -19t19 -45v-256q0 -26 -19 -45t-45 -19z'
        }
      )
    );
  };

})();
