import { Container, ModalRenderer } from '@dabapps/roe';
import * as classNames from 'classnames';
import { List} from 'immutable';
// tslint:disable-next-line:no-unused-variable
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { GET_USER_INFO } from '../actions/auth';
import { MATCHES_APP_PAGE, shouldRedirectToLanding } from '../consts/redirect-rules';
import { USER_TYPES } from '../consts/user-types';
// tslint:disable-next-line:no-unused-variable
import { getResponseStatus, IResponseStatus, isPending } from '../responses';
import { IStore } from '../store/';
import { IUserRecord } from "../store/data-types"
import Loading from './content/loading'
import Navigation from './navigation';
import Footer from './navigation/footer';
import SkipToMainContent from './skip-to-main-content';

interface IExternalProps {
  location: {
    pathname: string;
  }
}

interface IOwnProps {
  modals: List<React.ReactNode>;
  isAppPage: boolean;
  loading: boolean;
  userProfile: IUserRecord | null;
}

interface IDispatchProps {
  replaceRoute: typeof routerActions.replace;
}

type Props = IExternalProps & IDispatchProps & IOwnProps;

export class AppContainer extends React.PureComponent<Props, void> {

  public componentWillMount() {
    this.checkPathAndRedirect()
  }

  public checkPathAndRedirect() {
    const { loading, userProfile, location: { pathname } } = this.props;

    const isLoggedIn = Boolean(userProfile && userProfile.has('id'));

    if (!loading && shouldRedirectToLanding(isLoggedIn, pathname)) {
      this.props.replaceRoute('/');
    }
  }

  public render() {
    const { modals, isAppPage, userProfile, location: { pathname } } = this.props;
    return (
      <div className={classNames('wrapper', isAppPage && 'in-app')}>
        <SkipToMainContent />
        {!this.props.loading && isAppPage && userProfile &&
          <Navigation
            pathname={pathname}
            userType={userProfile.type}
            showTabBar={userProfile.type !== USER_TYPES.VOLUNTEER}
          />
        }
        <Container solid>
          {
            this.props.loading ? (
              <Loading/>
            ) : (
              <div>
                {this.props.children}
                {isAppPage && <Footer />}
                <ModalRenderer modals={modals.toArray()} />
              </div>
            )
          }
        </Container>
      </div>
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps):
  IOwnProps & IExternalProps {
  return {
    ...props,
    isAppPage: MATCHES_APP_PAGE.test(props.location.pathname),
    loading: isPending(state.responses, GET_USER_INFO),
    modals: state.modals,
    userProfile: state.userProfile
  };
}

export default connect(mapStateToProps, {
  replaceRoute: routerActions.replace
})(AppContainer);
