import { Button, Column, Row, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { FormErrors } from '../../../../utils';
import Collapse from '../../../collapse';
import FormArray from '../../../forms/form-array';
import FormErrorsRenderer from '../../../forms/form-errors-renderer';
import Sites from './sites';

const { IconChevronDown, IconChevronUp } = FontAwesome;

const MESSAGES = {
  sites: 'generic.sites',
  notSpecified: 'generic.not_specified'
};

interface IOwnProps {
  formErrors: FormErrors;
  openCollapse: string | null;
  siteSummaryText: string;
  toggleCollapse(event: React.MouseEvent<HTMLElement>): void;
}

type Props = IOwnProps;

export default class SitesSelection extends React.PureComponent<Props, void> {
  public render () {
    const { openCollapse, toggleCollapse, formErrors, siteSummaryText } = this.props;

    return (
      <div>
        <div className="collapse-header">
          <span className="collapse-icon" onClick={toggleCollapse}>
            {openCollapse === 'sites' ? <IconChevronUp /> : <IconChevronDown />}
          </span>
          <SpacedGroup block large className="margin-vertical-large">
            <h3 className="font-size-base font-weight-bold display-inline">
              <a className="primary" onClick={toggleCollapse}>
                <FormattedMessage id={MESSAGES.sites} />
              </a>
            </h3>
          </SpacedGroup>
          {openCollapse !== 'sites' && (<p className="italic">{siteSummaryText}</p>)}
        </div>

        <Collapse open={openCollapse === 'sites'}>
          <FormArray
            name="study_sites"
            component={Sites}
          />

          <FormErrorsRenderer formErrors={formErrors} errorKey="errors" />

          <Row className="criteria-footer">
            <Column>
              <SpacedGroup>
                <Button className="primary">
                  Update search results
                </Button>
                <Button>
                  Done
                </Button>
              </SpacedGroup>
            </Column>
          </Row>
        </Collapse>
      </div>
    );
  }

}
