import PageHeader from "^/components/page-header";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { USER_TYPES } from "../../../consts/user-types";
// tslint:disable-next-line:no-unused-variable
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import CsvDateRangeFilterForm from './csv-date-range-filter-form';

const MESSAGES = {
  adverts: "reports.adverts_csv_title",
  studies: "reports.studies_csv_title",
  volunteer_registration: "reports.volunteer_registration_csv_title",
};

export class ReportsCsv extends React.PureComponent<PermissionHOCChildProps, {}> {
  public render() {
    return (
      <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.adverts} />} />
        <CsvDateRangeFilterForm
          baseUrl="/api/drivers/adverts/csv/"
          buttonFormattedMessageId="reports.adverts_csv_button"
          formId="advert-csv-filter-form"
          instructionFormattedMessageId="reports.adverts_csv_instruction"
        />
        <PageHeader className="padding-top-large" header={<FormattedMessage id={MESSAGES.studies} />} />
        <CsvDateRangeFilterForm
          baseUrl="/api/studies/csv/"
          buttonFormattedMessageId="reports.studies_csv_button"
          formId="study-csv-filter-form"
          instructionFormattedMessageId="reports.studies_csv_instruction"
        />
        {this.props.user.type === USER_TYPES.MARKETING && (
          <div>
            <PageHeader
              className="padding-top-large"
              header={<FormattedMessage
              id={MESSAGES.volunteer_registration} />}
            />
            <CsvDateRangeFilterForm
              baseUrl="/api/users/volunteers/registration-csv/"
              buttonFormattedMessageId="reports.volunteer_registration_csv_button"
              formId="volunteer-registration-csv-filter-form"
              instructionFormattedMessageId="reports.adverts_csv_instruction"
            />
          </div>
        )}
      </div>
    );
  }
}

export default ReportsCsv;
