import { USER_TYPES } from '../consts/user-types';
import adminPermissions from './config/admin-permissions';
import callHandlerPermissions from './config/call-handler-permissions';
import craPermissions from './config/cra-permissions';
import { IAllPermission, IPermissionsProps, PageName } from './config/default-permissions';
import marketingPermissions from './config/marketing-permissions';
import projectManagerPermissions from './config/project-manager-permissions';
import qaPermissions from './config/qa-permissions';
import researcherPermissions from './config/researcher-permissions';
import volunteerPermissions from './config/volunteer-permissions';

export const Permissions: {[key in keyof typeof USER_TYPES]: IAllPermission} = {
  VOLUNTEER: volunteerPermissions,
  CALL_HANDLER: callHandlerPermissions,
  QA: qaPermissions,
  CRA: craPermissions,
  RESEARCHER: researcherPermissions,
  PROJECT_MANAGER: projectManagerPermissions,
  ADMIN: adminPermissions,
  MARKETING: marketingPermissions,
};

export const hasPageAccess = (user: string, page: PageName) => {
  const {
    [user]: {
      [page]: {
        hasAccess = false
      } = {hasAccess: false}
    }
  } = Permissions;

  return hasAccess;
};

export const redirectTo = (user: string, page: PageName) => {
  const {
    [user]: {
      [page]: {
        redirect = '/app/'
      } = { redirect: '/app/' }
    } = {}
  } = Permissions;

  return redirect as string;
};

export const getCustomProps = (user: string, page: PageName): IPermissionsProps | {} => {
  const {
    [user]: {
      [page]: {
        customProps = {}
      } = { customProps: {}}
    } = {}
  } = Permissions;

  return customProps as IPermissionsProps | {};
};
