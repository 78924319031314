import {
  Button,
  SpacedGroup,
} from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { loadMarkers } from '../../../actions/studies';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName, getCollectionItems } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { DIABETES_TYPE, HBA1C_RESULT_UNIT } from '../../../consts/constants';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { collectionsModule } from '../../../reducers/collections';
import { itemsModule } from '../../../reducers/items';
import { isPending } from '../../../responses';
import { IStore } from '../../../store';
import { ISiteRecord } from '../../../store/data-types';
import {
  ICircle,
  IPoly,
  IStudyRecord,
  IStudySiteRecord,
  IStudyVolunteerRecord,
} from '../../../store/data-types/studies';
import { formatDate } from '../../../utils';
import PageHeader from '../../page-header';
import { IColumnData } from '../../tables/simple-table';
import StudyDetails from '../details';
import StudyResults from '../search/results';
import { getCircles, getPolygons } from '../utils';

const { actions: { loadItem } } = itemsModule;
const { actions: { getAllCollection, getCollection } } = collectionsModule;

const PAGE_NAME = 'VIEW_STUDY';

interface IOwnProps {
  params: {
    id?: string;
  }
}

interface IStateProps extends PermissionHOCChildProps {
  markers: google.maps.LatLng[];
  circles: Array<null | ICircle>;
  polygons: Array<null | google.maps.LatLng[]>;
  volunteersCollection: ICollection<IStudyVolunteerRecord>;
  study: IStudyRecord | null;
  sites: List<ISiteRecord> | null;
  resultsLoading: boolean;
}

interface IDispatchProps {
  loadMarkers: typeof loadMarkers;
  loadItem: typeof loadItem;
  push: typeof push;
  getCollection: typeof getCollection;
  getAllCollection: typeof getAllCollection;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

const HEADERS: Array<IColumnData<IStudyVolunteerRecord>> = [
  {
    content: (record: IStudyVolunteerRecord) => {
      const user = record.getIn(['volunteer', 'user']);
      return user ? user.get('id') : '--';
    },
    headerLabel: 'VOP ID',
    key: 'id',
    sortable: false,
  },
  {
    content: (record: IStudyVolunteerRecord) => {
      const user = record.getIn(['volunteer', 'user']);
      return user ? user.get('display_full_name') : '--';
    },
    headerLabel: 'Full name',
    key: 'full_name',
    sortable: false,
  },
  {
    content: (record: IStudyVolunteerRecord) => DIABETES_TYPE.get(
      record.getIn(['volunteer', 'diabetes_type'])
    ) || '--',
    headerLabel: 'Diabetes type',
    key: 'diabetes_type',
    sortable: false,
  },
  {
    content: (record: IStudyVolunteerRecord) => (
      record.getIn(['volunteer', 'display_hbA1c'])) || '' +
    (HBA1C_RESULT_UNIT.get(record.getIn(['volunteer', 'hbA1c_result_display_unit'])) || '--'),
    headerLabel: 'HbA1c result',
    key: 'hbA1c_result',
    sortable: false,
  },
  {
    content: (record: IStudyVolunteerRecord) => record.getIn(['volunteer', 'medication_count']) || 0,
    headerLabel: 'Medications',
    key: 'medications',
    sortable: false,
  },
];

const MESSAGES = {
  edit: 'generic.edit',
};

export class StudyView extends React.PureComponent<Props, {}> {
  public componentWillMount () {
    const { params: { id }, study } = this.props;

    if (!study) {
      this.props.loadItem('studies', id);
    }

    this.props.getAllCollection('sites');

    this.getResults();
  }

  public componentDidUpdate (prevProps: Props) {
    if (
      (!prevProps.study || (prevProps.study && !prevProps.study.active)) &&
      (this.props.study && this.props.study.active)
    ) {
      this.getResults();
    }
  }

  public render () {
    const {
      study,
      volunteersCollection,
      markers,
      circles,
      polygons,
      resultsLoading,
    } = this.props;

    return (
      <div>
        <PageHeader header={
          <div className="font-size-base">
            {!study && 'Loading...'}
            {
              study && (
                <SpacedGroup block large className="float-none">
                  <span className="font-size-large">
                    {study.study_name}
                  </span>
                  <span className="font-weight-normal">
                    {study.impact_number || 'No IMPACT Number'}
                  </span>
                  <span className={`bold ${study.active ? 'success' : 'error'}`}>
                    {study.active ? 'ACTIVE' : 'INACTIVE'}
                  </span>
                </SpacedGroup>
              )
            }
            {
              study && (
                <SpacedGroup block large className="font-weight-normal float-none margin-top-base">
                  <SpacedGroup className="float-none">
                    <strong>
                      FPFV Date
                    </strong>
                    <span>
                      {study.fpfv_date ? formatDate(study.fpfv_date) : 'not specified'}
                    </span>
                  </SpacedGroup>
                  <SpacedGroup className="float-none">
                    <strong>
                      LPFV Date
                    </strong>
                    <span>
                      {study.lpfv_date ? formatDate(study.lpfv_date) : 'not specified'}
                    </span>
                  </SpacedGroup>
                  <SpacedGroup className="float-none">
                    <strong>
                      Project manager
                    </strong>
                    <span>
                      {study.project_manager_display && study.project_manager_display.display_full_name ?
                        study.project_manager_display.display_full_name : 'not specified'}
                    </span>
                  </SpacedGroup>
                </SpacedGroup>
              )
            }
          </div>
        }>
        { this.props.permissions.canEdit &&
          <Button className="primary" onClick={this.onClickEdit}>
            <FormattedMessage id={MESSAGES.edit} />
          </Button>
        }
        </PageHeader>
        {
          study && <StudyDetails study={study} />
        }
        {
          study && study.active && (
            <div>
              <hr />
              <StudyResults
                headers={HEADERS}
                collection={volunteersCollection}
                loading={resultsLoading}
                getCollection={this.getResults}
                markers={markers}
                circles={circles}
                polygons={polygons}
                permissions={this.props.permissions}
                matchedMessage="volunteers matched"
              />
            </div>
          )
        }
      </div>
    );
  }

  private onClickEdit = () => {
    const { study, push } = this.props;

    if (study) {
      push(`/app/studies/${study.id}/edit/`);
    }
  }

  private getResults = (options?: ICollectionOptions) => {
    const { study } = this.props;

    if (study && study.active) {
      this.props.getCollection('studies/<id>/volunteers', options, PAGE_NAME, `studies/${study.id}/volunteers`);
      this.props.loadMarkers(study.id);
    }
  }
}

export const mapStateToProps = (state: IStore, props: IOwnProps & PermissionHOCChildProps): IOwnProps & IStateProps => {
  const study = state.items.studies;

  const { collections, markers, responses } = state;
  const volunteersCollection = getCollectionByName(
    collections['studies/<id>/volunteers'],
    PAGE_NAME
  );

  const sites = getCollectionItems(collections.sites);
  const studySites = study && study.study_sites || List<IStudySiteRecord>();

  const circles = getCircles(studySites, sites);
  const polygons = getPolygons(studySites, sites);

  return {
    ...props,
    study,
    sites,
    volunteersCollection,
    markers,
    circles,
    polygons,
    resultsLoading: isPending(responses, GET_COLLECTION, 'users/volunteers')
  };
}

export default connect(mapStateToProps, {loadMarkers, loadItem, push, getCollection, getAllCollection})(StudyView);
