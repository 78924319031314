const apiKey = process.env.GOOGLE_MAPS_API_KEY;

if (typeof apiKey !== 'string') {
  throw new Error('Google maps API key must be provided as environment variable "GOOGLE_MAPS_API_KEY"');
}

let script: HTMLScriptElement | undefined;

const callbacks: Array<() => any> = [];

const resolvePromises = () => {
  callbacks.forEach((callback) => {
    callback();
  });
};

export const initGoogleMaps = () => {
  return new Promise((resolve) => {
    if (!script) {
      script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=drawing`;

      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);

      script.onload = resolvePromises;
    }

    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      callbacks.push(resolve);
    } else {
      resolve();
    }
  });
};
