import { makeAsyncActionSet } from '../utils';
import { IAction, IdPayload } from './actions'
import { dispatchGenericRequest } from './index';

export interface IAddPayload {
  user: any;
}

export const ADD_RESEARCHER = 'ADD_RESEARCHER';
export function addResearchersToSite(user: any, siteId: string ): IAction<IAddPayload> {
  return { type: ADD_RESEARCHER, payload: { user }, meta: { siteId } };
}

export const REMOVE_RESEARCHER = 'REMOVE_RESEARCHER';
export function removeResearcher( id: string, siteId: string ): IAction<IdPayload> {
  return { type: REMOVE_RESEARCHER, payload: { id }, meta: { siteId } };
}

export const SITES_DETAIL = makeAsyncActionSet('SITES_DETAIL');
export function editSitesDetail(
  id: string, name: string, radius: number, active: boolean, address1: string,
  address2: string, postcode: string, city: string, county: string, siteResearchers: string []) {
  return dispatchGenericRequest(
    SITES_DETAIL,
    `/api/sites/${id}/`,
    'PUT',
    {
      id,
      name,
      radius,
      active,
      address_1: address1,
      address_2: address2,
      postcode,
      city,
      county,
      researchers: siteResearchers,
    }
  );
}

export const GET_SITES_INFO = makeAsyncActionSet('GET_SITES_INFO');
export function getSitesDetail() {
 return dispatchGenericRequest(
   GET_SITES_INFO,
  `/api/sites/`,
   'GET',
 );
}
