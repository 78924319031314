'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var createHasSubmitSucceeded = function createHasSubmitSucceeded(_ref) {
  var getIn = _ref.getIn;
  return function (form) {
    var getFormState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (state) {
      return getIn(state, 'form');
    };
    return function (state) {
      var formState = getFormState(state);
      return getIn(formState, form + '.submitSucceeded') || false;
    };
  };
};

exports.default = createHasSubmitSucceeded;