import { Button, Column, Row } from '@dabapps/roe';
import { DataShape, FormProps } from '@types/redux-form';
import { List, Map } from 'immutable';
import * as React from 'react';
import { Link } from 'react-router';
import { Field, reduxForm } from 'redux-form';

import ChadminField from './Field';
import Messages from './Messages';

interface ISubmitButtonProps {
  readOnly?: boolean;
  isPending?: boolean;
  saveButtonText?: string;
  onCancelLinkTo?: string;
}

const normalizeBoolean = (value: any) => Boolean(value);

export const SubmitButton = ({
  readOnly,
  isPending,
  saveButtonText,
  onCancelLinkTo
}: ISubmitButtonProps) =>
  !readOnly && !isPending
    ?
      <Row>
        <Column xs={12}>
          <div className="form-controls">
            <Button
              type="submit"
              className="primary float-left margin-vertical-base">
              {saveButtonText || 'Save'}
            </Button>
            { onCancelLinkTo &&
              <Link className="cancel-link chadmin-cancel-link" to={onCancelLinkTo}>Cancel</Link>
            }
          </div>
        </Column>
      </Row>
    : <noscript />;

interface IFormProps extends FormProps<DataShape, void, void> {
  title?: string;
  fieldConfig: List<any>;
  fieldClassName?: string;
  hideMessages?: boolean;
  isPending?: boolean;
  hasSucceeded?: boolean;
  hasFailed?: boolean;
  errors?: Map<string, any>;
  setPendingUploadInForm?: (file: any) => void;
  formName: string;
  isEditing?: boolean;
  saveButtonText?: string;
  fields: List<string>;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
  onCancelLinkTo?: string;
}

export const Form = ({
  handleSubmit,
  fieldConfig,
  readOnly,
  hideMessages,
  saveButtonText,
  className,
  fieldClassName,
  title,
  setPendingUploadInForm,
  formName,
  isEditing,
  fields,
  isPending,
  hasSucceeded,
  hasFailed,
  errors,
  additionalSuccessMessage,
  additionalFailureMessage,
  onCancelLinkTo
}: React.HTMLProps<JSX.Element> & IFormProps) =>
  <form className={className} onSubmit={handleSubmit}>
    {!hideMessages &&
      <Messages
        isPending={isPending}
        hasSucceeded={hasSucceeded}
        hasFailed={hasFailed}
        isEditing={isEditing}
        errors={errors && errors.get('non_field_errors')}
        additionalSuccessMessage={additionalSuccessMessage}
        additionalFailureMessage={additionalFailureMessage}
      />}

      {fieldConfig.map((fieldGroups: any, key: number) =>
        <Row key={key}>
          {fieldGroups.get('elements').map((group: any, index: number) =>
            <div className={fieldGroups.get('wrapperClassName')} key={index}>
              {group.map((field: any, groupKey: number) =>
                  field.map((config: any, name: string) => {
                    return (
                      <Field
                        key={name}
                        component={ChadminField}
                        name={name}
                        fieldConfig={config}
                        readOnly={readOnly}
                        className={fieldGroups.getIn(['fieldClassNames', `${index}`, `${groupKey}`])}
                        setUpload={setPendingUploadInForm}
                        handleSubmit={handleSubmit}
                        formName={formName}
                        isEditing={isEditing}
                        errors={errors}
                        normalize={config.get('type') === 'boolean' ? normalizeBoolean : undefined}
                      />
                    )
                  }).toList()
              )}
            </div>
          )}
        </Row>
      )}

    <SubmitButton
      readOnly={readOnly}
      isPending={isPending}
      saveButtonText={saveButtonText}
      onCancelLinkTo={onCancelLinkTo}
    />
  </form>

export default reduxForm({})(Form);
