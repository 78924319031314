import { List } from 'immutable';
import * as React from 'react';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

export const PASSWORD_FIELDS = List.of('password', 'password_1', 'password_2');

export const FieldChar = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) => {
  return <FieldGroup className={className} config={config} errors={errors}>
    { readOnly ? (
        <span className="input">
          {value}
        </span>
      ) : (
      config.max_length
        ? <input
            type={PASSWORD_FIELDS.contains(name) ? 'password' : 'text'}
            name={name}
            placeholder={config.placeholder}
            onChange={(event) => onChange(event)}
            value={value}
          />
        : <input
            className="valign-top"
            type="text"
            name={name}
            placeholder={config.placeholder}
            onChange={(event) => onChange(event)}
            value={value}
          />
    )}
  </FieldGroup>
};

export default FieldChar;
