import * as React from 'react';

import {
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List } from 'immutable';

import Loading from '../loading';

export interface IColumnData<TData> {
  headerLabel: string;
  key: string;
  sortable: boolean;
  width?: number;
  isButton?: boolean;
  content(data: TData): React.ReactNode;
  isButtonDisabled?(data: TData): boolean;
  onButtonClick?(data: TData): void;
}

export interface IBaseTableProps<TData> {
  loading?: boolean;
  headers: Array<IColumnData<TData>>;
  fixRowHeaders?: boolean;
}

interface IProps<TData> extends IBaseTableProps<TData> {
  items: List<TData>;
  className?: string;
}

export default class SimpleTable<
  TData extends { id: string }
> extends React.PureComponent<IProps<TData>, void> {
  public render() {
    const firstColumnWidth = this.props.headers[0].width || 100;
    const tableProps = this.props.fixRowHeaders
      ? {
          fixRowHeaders: this.props.fixRowHeaders,
          rowHeaderWidth: firstColumnWidth,
        }
      : {};
    return (
      <Row>
        <Table className={this.props.className} fill {...tableProps}>
          {this.renderHeaders()}
          <TableBody>
            {this.props.loading
              ? <tr>
                  <td colSpan={this.props.headers.length}>
                    <Loading />
                  </td>
                </tr>
              : this.props.items.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      {this.props.headers.map((header) =>
                        <TableCell
                          key={header.headerLabel}
                          width={header.width}
                        >
                          {header.content(item)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Row>
    );
  }

  private renderHeaders() {
    return (
      <TableHead>
        <TableRow>
          {this.props.headers.map((header, index) =>
            <TableHeader key={header.headerLabel} width={header.width}>
              {header.headerLabel}
            </TableHeader>
          )}
        </TableRow>
      </TableHead>
    );
  }
}
