import { Button } from '@dabapps/roe';
import * as React from 'react';
import * as Dropzone from 'react-dropzone';

import { ACTIVE_STYLE, REJECT_STYLE, STYLE } from './dropzone';

interface IProps {
  className?: string;
  display: (file: any) => any;
  file?: any;
  onSubmit?: () => void;
  setPendingUpload: (file: any) => void;
  src?: string;
}

export default class FileUpload extends React.PureComponent<IProps, void> {
  public render() {
    const { src, file, className } = this.props;
    return (
      <div className={className}>
        <Dropzone
          ref="dropzone"
          multiple={false}
          disableClick={true}
          onDrop={this.onDrop.bind(this)}
          style={STYLE}
          activeStyle={ACTIVE_STYLE}
          rejectStyle={REJECT_STYLE}
        >
          {this.props.display(file ? file.preview : src)}
        </Dropzone>

        <Button
          type="button"
          className="primary"
          onClick={this.onOpenClick.bind(this)}
        >
          Select a {src || file ? 'new' : ''} file
        </Button>

        {file &&
          <Button
            type="button"
            className="primary"
            onClick={() => this.props.onSubmit && this.props.onSubmit()}
          >
            Upload File
          </Button>}
      </div>
    );
  }

  private onOpenClick() {
    (this.refs.dropzone as any).open();
  }

  private onDrop(files: any) {
    if (files.length) {
      this.props.setPendingUpload(files[0]);
    }
  }
}
