import { Button, FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { defineMessages } from "react-intl"
import { FormattedMessage } from "react-intl"
import { FormProps, reduxForm } from 'redux-form';
import { IResponseStatus } from '../../responses';
import FormErrorsRenderer from "../forms/form-errors-renderer";
import GroupField from '../forms/group-field';

export interface IFormData {
  email: string;
  password: string;
  token: string;
}

interface IProps {
  response: IResponseStatus;
  onSubmit(data: IFormData): void;
}

export class LoginForm extends React.PureComponent<FormProps<IFormData, {}, {}> & IProps, void> {
  public render() {
    const messages = defineMessages({
      email: {
          description: 'Login email label',
          id: 'login.email',
      },
      password: {
          description: 'Login password label',
          id: 'generic.password',
      },
    });
    return (
      <form onSubmit={this.props.handleSubmit}>
        <GroupField
          block
          label={messages.email}
          name="email"
          type="email"
          disabled={this.props.response.isLoading}
          formErrors={this.props.response.formErrors}
        />
        <GroupField
          block
          label={messages.password}
          name="password"
          type="password"
          disabled={this.props.response.isLoading}
          formErrors={this.props.response.formErrors}
        />
        <FormGroup block>
          <Button className="primary" type="submit" disabled={this.props.response.isLoading}>
            <FormattedMessage id='login.login'/>
          </Button>
        </FormGroup>
        <FormErrorsRenderer formErrors={this.props.response.formErrors} errorKey="non_field_errors"/>
      </form>
    );
  }
}

export default reduxForm({
  form: 'login',
})(LoginForm)
