import * as actions from '../actions';
import { IAsyncActionSet, ImmutableJson, RequestStates } from '../utils';

import { fromJS, Map, Record } from 'immutable';

interface IResponseState {
  requestState: RequestStates | null,
  data: ImmutableJson | null
}
export interface IResponseStateRecord extends Record.Instance<IResponseState>, Readonly<IResponseState> {}
export const ResponseStateRecord = Record<IResponseState>({
  data: null,
  requestState: null,
});

export type ResponsesReducerState = Map<IAsyncActionSet, Map<string | undefined, IResponseStateRecord>>

function setResponsesReducerState(
  state: ResponsesReducerState,
  actionSet: IAsyncActionSet,
  requestState: RequestStates,
  data: ImmutableJson,
  tag?: string
): ResponsesReducerState {
  const existing = state.get(actionSet, Map<string | undefined, IResponseStateRecord>());
  const item: IResponseStateRecord = existing.get(tag, ResponseStateRecord());
  return state.set(
    actionSet,
    existing.set(
      tag,
      item
        .set('requestState', requestState)
        .set('data', data)
    )
  );
}

function resetResponsesReducerState(
  state: ResponsesReducerState,
  actionSet: IAsyncActionSet,
  tag?: string
): ResponsesReducerState {
  const existing = state.get(actionSet, Map<string | undefined, IResponseStateRecord>());
  return state.set(actionSet, existing.remove(tag));
}

export default function responsesReducer (
  state: ResponsesReducerState = Map<IAsyncActionSet, Map<string | undefined, IResponseStateRecord>>(),
  action: actions.IAction<any, any>
) {
  switch (action.type) {
    case actions.REQUEST_STATE:
      const actionSet = action.payload.actionSet;
      const requestState = action.payload.state;
      const tag = action.payload.tag;
      const data = fromJS(action.payload.data);
      return setResponsesReducerState(state, actionSet, requestState, data, tag);
    case actions.RESET_REQUEST_STATE:
      return resetResponsesReducerState(state, action.payload.actionSet, action.payload.tag);
    default:
      return state;
  }
}
