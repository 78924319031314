import { FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from "react-intl"
import { Field } from 'redux-form';

interface IProps {
  boolFieldName: string;
  label?: FormattedMessage.MessageDescriptor;
  fieldNames: string[];
  formName: string;
  shouldShow: boolean;
  reduxChangeAction(form: string, field: string, value: any): void;
}

const OptionalFields: React.SFC<IProps> = (props) => {
  const { boolFieldName, reduxChangeAction, label, fieldNames, formName, shouldShow, children} = props;
  return (
    <div className="optional-form-group">
      <FormGroup>
        <Field
          id={boolFieldName}
          name={boolFieldName}
          component="input"
          type="checkbox"
          onChange={(event: any, value: boolean) => {
            if (!value) {
              for (const fieldName of fieldNames) {
                reduxChangeAction(formName, fieldName, '');
              }
            }
          }}/>
        <label className="optional" htmlFor={boolFieldName}>
          {label ? <FormattedMessage {...label}/> : null}
        </label>
      </FormGroup>
      <div className="optional-form-fields">
        { shouldShow ? children : null }
      </div>
    </div>
  );
}

export default OptionalFields
