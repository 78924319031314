import { destroy } from 'redux-form';
import { collectionsModule, ICollectionsState } from '../reducers/collections';
import { DispatchCallback, GetStateCallback } from './index';

const { actions: { addItem } } = collectionsModule;

export function addItemToCollectionAndClearForm(
  type: keyof ICollectionsState,
  data: any,
  formName: string,
  tag?: string
) {
  return (dispatch: DispatchCallback, getState: GetStateCallback) => {
    return addItem(type, data, tag)(dispatch, getState)
    .then((response) => {
      return dispatch(destroy(formName));
    });
  };
}
