import { Table, TableBody, TableCell, TableHead, TableRow } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';

import { TColumn, TColumns, TRow, TRows } from '../types';

interface IProps {
  className?: string;
  columns?: TColumns;
  emptyMessage?: string;
  noHeader?: boolean;
  renderHeaders?: (columns: TColumns) => any;
  renderRow: (row: TRow, index: number, columns?: TColumns) => React.ReactNode;
  rows: TRows;
}

export default class CustomTable extends React.Component<IProps, undefined> {
  public render() {
    const {
      className,
      columns,
      rows,
      emptyMessage,
      renderHeaders,
      renderRow,
    } = this.props;

    return (
      <Table fill className={className}>
        {columns &&
          renderHeaders &&
          <TableHead>
            <TableRow>
              {renderHeaders(columns)}
            </TableRow>
          </TableHead>}
        <TableBody>
          {rows.map((row: TRow, idx: number) => renderRow(row, idx, columns))}
          {rows.isEmpty() &&
            emptyMessage &&
            <TableRow>
              <TableCell colSpan={columns ? columns.size : 1}>
                {emptyMessage}
              </TableCell>
            </TableRow>}
        </TableBody>
      </Table>
    );
  }
}
