import * as React from 'react';

interface IExternalProps {
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

export class InactiveToggle extends React.PureComponent<IExternalProps, void> {
  public render() {
    const { onChange } = this.props;

    return (
      <div className='inactive-toggle display-inline-block'>
        <span>Display inactive</span>
        <input type="checkbox" name="is_active" onChange={onChange} />
      </div>
    )
  }
}

export default InactiveToggle;
