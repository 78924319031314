import {
  Column,
  FormGroup,
  Row
} from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';
import { BLANK_NOT_FILTERED, DIABETES_TYPE_CHOICES, YES_NO_CHOICES } from '../../../../consts/constants';
import Select from '../../../forms/select';

const MESSAGES = {
  tablets: 'generic.tablets',
  age: 'generic.age',
  bmi: 'generic.bmi',
  diabetesType: 'generic.diabetes_type',
  insulinInjections: 'studies.insulin_injections',
  otherInjections: 'studies.other_injections',
};

export default class GeneralCriteria extends React.PureComponent<{}, {}> {
  public render () {
    return (
      <div>
        <Row>
          <Column md={6}>
            <FormGroup>
              <label>
                <FormattedMessage id={MESSAGES.diabetesType} />
              </label>
              <Field
                name="diabetes_type"
                placeholder="Select diabetes type"
                options={DIABETES_TYPE_CHOICES}
                component={Select}
              />
            </FormGroup>
          </Column>
          <Column md={6}>
            <FormGroup>
              <label>
                <FormattedMessage id={MESSAGES.tablets} />
              </label>
              <Field
                name="tablets"
                placeholder={BLANK_NOT_FILTERED.label}
                blankLabel={BLANK_NOT_FILTERED.label}
                blankValue={BLANK_NOT_FILTERED.value}
                options={YES_NO_CHOICES}
                component={Select}
              />
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormGroup>
              <label>
                <FormattedMessage id={MESSAGES.insulinInjections} />
              </label>
              <Field
                name="injection_insulin"
                placeholder={BLANK_NOT_FILTERED.label}
                blankLabel={BLANK_NOT_FILTERED.label}
                blankValue={BLANK_NOT_FILTERED.value}
                options={YES_NO_CHOICES}
                component={Select}
              />
            </FormGroup>
            <FormGroup>
              <label>
                <FormattedMessage id={MESSAGES.otherInjections} />
              </label>
              <Field
                name="injection_other"
                placeholder={BLANK_NOT_FILTERED.label}
                blankLabel={BLANK_NOT_FILTERED.label}
                blankValue={BLANK_NOT_FILTERED.value}
                options={YES_NO_CHOICES}
                component={Select}
              />
            </FormGroup>
          </Column>
          <Column md={6}>
            <FormSection name="age_range">
              <FormGroup className="multi">
                <label>
                  <FormattedMessage id={MESSAGES.age} />
                </label>
                <Field
                  name="lower"
                  component="input"
                  type="number"
                  min="0"
                  placeholder="from"
                />
                <Field
                  name="upper"
                  component="input"
                  type="number"
                  min="0"
                  placeholder="to"
                />
              </FormGroup>
            </FormSection>
            <FormSection name="bmi_range">
              <FormGroup className="multi">
                <label>
                  <FormattedMessage id={MESSAGES.bmi} />
                </label>
                <Field
                  name="lower"
                  component="input"
                  type="number"
                  min="0"
                  placeholder="from"
                />
                <Field
                  name="upper"
                  component="input"
                  min="0"
                  type="number"
                  placeholder="to"
                />
              </FormGroup>
            </FormSection>
          </Column>
        </Row>
      </div>
    );
  }
}
