import * as classNames from 'classnames';
import { List, Map } from 'immutable';
import * as React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { IOption } from '../../store/data-types';

interface IProps {
  options: string[] | IOption[] | List<string> | List<IOption>| Map<string, List<IOption>> ;
  loading?: boolean;
  loadingText?: string;
  placeholder?: string;
  disallowBlank?: boolean;
  blankValue?: string;
  blankLabel?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => any;
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps<any>;
}

// tslint:disable-next-line:no-unused-variable
type Props = React.HTMLProps<HTMLSelectElement> & IProps;

export default class Select extends React.PureComponent<Props, {}> {

  public renderOptionList(options: List<string | IOption>) {
    return options.map((option: string | IOption) => {
      const optionValue = typeof option === 'object' ? option.value : option;
      const optionLabel = typeof option === 'object' ? option.label : option;

      return (
        <option key={optionValue} value={optionValue}>
          {optionLabel}
        </option>
      );
    });
  }

  public renderGroupedOptionList(optGroups: Map<string, List<IOption>>) {
    return optGroups.map((options, optgroup) => {
      return (
        <optgroup label={optgroup}>
        {this.renderOptionList(options)}
        </optgroup>);
    })
  }

  public render () {
    const {
      options,
      loading,
      loadingText,
      placeholder,
      disallowBlank,
      blankValue = '',
      blankLabel,
      className,
      // Redux form props
      meta, // Do nothing with this
      input,
      ...remainingProps
    } = this.props;

    const value = input && input.value || this.props.value;
    const displayPlaceholder = !value || value === blankValue;

    return (
      <select
        {...input}
        {...remainingProps}
        className={classNames(displayPlaceholder && placeholder ? 'placeholder' : null, className)}
        disabled={loading}
      >
        {
          (!disallowBlank || displayPlaceholder || loading) && (
            <option value={blankValue}>
              {loading && (loadingText ? loadingText : 'Loading...')}
              {!loading && displayPlaceholder && placeholder}
              {!loading && !displayPlaceholder && blankLabel}
            </option>
          )
        }
        { Map.isMap(options) ?
          this.renderGroupedOptionList(options) :
          this.renderOptionList(List<string | IOption>(options))}
      </select>
    );
  }
}
