import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import * as classNames from 'classnames';
import * as React from 'react';

const ENOUGH_TIME_FOR_RERENDER = 50;
const ANIMATION_DURATION = 200;

interface IExternalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
}

interface ISetProps { // tslint:disable-line:no-unused-variable
  height: number;
  opened: boolean;
}

type Props = IExternalProps & SetPropsInterface<ISetProps>;

export class Collapse extends React.PureComponent<Props, void> {
  private element: Element;
  private timeout: number;

  public componentDidUpdate (previousProps: Props) {
    if (this.props.open !== previousProps.open) {
      window.clearTimeout(this.timeout);

      this.props.setProps({
        opened: false,
        height: this.props.open ? 0 : this.element.scrollHeight
      });

      this.timeout = window.setTimeout(() => {
        this.props.setProps({
          opened: false,
          height: this.props.open ? this.element.scrollHeight : 0
        });

        this.timeout = window.setTimeout(() => {
          this.props.setProps({
            opened: this.props.open
          });
        }, ANIMATION_DURATION);
      }, ENOUGH_TIME_FOR_RERENDER);
    }
  }

  public componentDidMount () {
    this.props.setProps({
      height: this.props.open ? this.element.scrollHeight : 0
    });
  }

  public componentWillMount () {
    window.clearTimeout(this.timeout);
  }

  public render () {
    const {
      setProps,
      dispatch,
      height,
      children,
      opened,
      className,
      ...remainingProps
    } = this.props;

    return (
      <div
        ref={(element: HTMLDivElement) => this.element = element}
        {...remainingProps}
        className={classNames('clearfix collapse', height ? 'open' : null, className)}
        style={{height: opened ? 'auto' : height}}
      >
        {children}
      </div>
    );
  }
}

function getInitialProps () {
  return {
    height: 0,
    opened: false
  };
}

export default withSetProps<ISetProps, IExternalProps>(getInitialProps)(Collapse);
