import { Column, Row } from '@dabapps/roe';
import { fromJS, Map } from 'immutable';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  ICollection,
  ICollectionOptions,
} from '../../../collections/types';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';

import GenericList from '../../chadmin/generic-list';

import { impersonate } from '../../../actions/auth';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { USER_STATUS_NAMES } from '../../../consts/constants';
import { USER_TYPES } from '../../../consts/user-types';
import { collectionsModule, ICollectionsState, } from '../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from "../../../store";
import { IUserRecord } from '../../../store/data-types';
import UserListFilterForm, { IFormData } from './list-filter-form';

const { actions: { getCollection } } = collectionsModule;

const DETAIL_ROUTE = '${id}/';
const COLLECTION_NAME: string = 'USERS_LIST'

const PAGE_SIZE = 15;

const MESSAGES = {
    title: 'generic.users',
    create: 'create.user',
};

type IExternalProps = PermissionHOCChildProps;

interface IProps extends InjectedIntlProps, IExternalProps {
  impersonate: typeof impersonate;
  userCollection: ICollection<IUserRecord>;
  loading: boolean;
  getCollection: typeof getCollection;
  response: IResponseStatus;
}

export class UsersList extends React.PureComponent<IProps, void> {

  public listColumns: any;

  public constructor(props: IProps) {
    super(props);
    this.getCollection = this.getCollection.bind(this);

    this.listColumns = fromJS([
      { name: ['display_full_name'], display_name: 'Name', type: 'detail', route: DETAIL_ROUTE},
      { name: ['last_login'], display_name: 'Last Login Date', type: 'datetime'},
      { name: ['type_display'], display_name: 'Roles', type: 'text'},
      { name: ['id'], display_name: 'Actions', type: 'remove'},
    ])

    if (props.user.type === USER_TYPES.ADMIN) {
      this.listColumns = this.listColumns.push(Map({
        name: ['id'],
        display_name: 'Impersonate',
        link_text: 'Impersonate',
        type: 'function',
        display_rule: (user: IUserRecord) => user.type !== USER_TYPES.ADMIN && user.status === USER_STATUS_NAMES.ACTIVE,
        handler: (user: IUserRecord) => this.props.impersonate(user.email)
      }))
    }
  }

  public render() {
    return (
      <Row>
        <Column xs={12} sm={4} md={3}>
        <div className="side-panel">
         <UserListFilterForm
          response={this.props.response}
          onSubmit={this.changeFilters.bind(this)}
          userType={this.props.user.type}
        />
       </div>
       </Column>
       <Column xs={12} sm={8} md={9}>
         <GenericList
          canCreate={this.props.permissions.canCreate}
          collectionColumns={this.listColumns}
          collectionName={COLLECTION_NAME}
          collectionKey={'users'}
          messages={MESSAGES}
          hideInactiveToggle={true}
          defaultFilters={fromJS({exclude_inactive: true})}
        />
       </Column>
      </Row>
    );
  }

  public getCollection(options: ICollectionOptions) {
    const newFilters = (options.filters || Map<string, string>())
    return this.props.getCollection(
      'users',
      {
        filters: newFilters,
        page: options.page || 1,
        ...options,
      },
      COLLECTION_NAME
    );
  }

  private changeFilters(data: IFormData) {
       this.getCollection({
       filters: fromJS(data),
       pageSize: PAGE_SIZE
    });
  }
}

function mapStateToProps({ collections, responses }: IStore, props: InjectedIntlProps & IExternalProps) {
  const userCollection = getCollectionByName(
/* tslint:disable:no-string-literal */
    collections['users'],
/* tslint:enable:no-string-literal */
    COLLECTION_NAME
  );

  return {
    ...props,
    userCollection,
    loading: isPending(responses, GET_COLLECTION, 'users'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users')
  };
}

const UserListIntl = injectIntl(UsersList);
export default connect(mapStateToProps, { getCollection, impersonate })(UserListIntl);
