import {
  Alert,
  Button,
  Column,
  FormGroup,
  Row,
} from '@dabapps/roe';
import * as React from 'react';
import { defineMessages, FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import {
  change,
  DataShape,
  Field,
  FormProps,
  formValueSelector,
  reduxForm,
  WrappedFieldProps
} from 'redux-form';
import { ADMIN_USER_TYPES, EMAIL_VERIFIED, OR_USER_TYPES, USER_STATUS_LIST_FILTER } from '../../../consts/constants';
import { USER_TYPES } from "../../../consts/user-types";
import { IResponseStatus } from '../../../responses';
import { IStore } from "../../../store";
import { IUserRecord } from '../../../store/data-types';
import DateInput from '../../date-input';
import FormErrorsRenderer from "../../forms/form-errors-renderer";
import GroupField from '../../forms/group-field';
import OptionalFields from '../../volunteers/volunteers-list/optional-field';

export const USERS_FORM = 'users';

const messages = defineMessages({
    status: {
      id: 'generic.inactive',
    },
    email_verified: {
      id: 'sites.email_verified',
    },
    email: {
      id: 'generic.email'
    },
    email_placeholder: {
      id: 'volunteer.filter_email_placeholder'
    },
    first_name: {
      id: 'generic.first_name',
    },
    first_name_placeholder: {
      id: 'volunteer.filter_first_name_placeholder',
    },
    last_login: {
      id: 'volunteer.filter_last_login_date',
    },
    last_name: {
      id: 'generic.last_name',
    },
    last_name_placeholder: {
      id: 'volunteer.filter_last_name_placeholder',
    },
    type: {
      id: 'user.filter_type',
    },
    type_placeholder: {
      id: 'user.filter_type_placeholder',
    }
});

export interface IFormData {
  status: string;
  email_verified: string;
  email: string;
  type_display: string;
  display_full_name: string
  first_name: string;
  last_name: string;
  last_login_from: string;
  last_login_to: string;
  last_login: string;
}

interface IExternalProps extends FormProps<DataShape, void, void> {
  response: IResponseStatus;
  userType: string;
  onSubmit(data: IFormData): void;
}

interface IProps extends IExternalProps {
  formValues: {
    show_last_login: any;
  };
  reduxChangeAction: typeof change;
}
export class UserListFilterForm extends React.PureComponent<IProps, void> {

  public render() {
    const { isLoading, formErrors } = this.props.response;
    const userTypeChoices = this.props.userType === USER_TYPES.ADMIN ? ADMIN_USER_TYPES : OR_USER_TYPES;
    return (
      <form onSubmit={this.props.handleSubmit} className="sidebar-filter-form">
        <h2><FormattedMessage id="search.search" /></h2>
        <GroupField
          label={messages.first_name}
          name="first_name"
          placeholder={messages.first_name_placeholder}
          type="text"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        />
        <GroupField
          label={messages.last_name}
          name="last_name"
          placeholder={messages.last_name_placeholder}
          type="text"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        />
        <GroupField
          label={messages.email}
          name="email"
          placeholder={messages.email_placeholder}
          type="text"
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
        />
        <GroupField
          label={messages.type}
          name="type"
          placeholder={messages.type_placeholder}
          type='text'
          disabled={isLoading}
          formErrors={formErrors}
          optional
          block
          component="select"
          >
            <option value=''>Choose...</option>
              {userTypeChoices.map((value, key) => {
                return <option value={ key } key={ key }>{ value }</option>
              })}
        </GroupField>
        <GroupField
          block
          label={messages.status}
          name="exclude_inactive"
          type="text"
          component="select"
          disabled={this.props.response.isLoading}
          formErrors={this.props.response.formErrors}
          optional
          >
            {USER_STATUS_LIST_FILTER.keySeq().map((key) => {
              return <option value={ key } key={ key }>{ USER_STATUS_LIST_FILTER.get(key) }</option>
            })}
        </GroupField>
       <GroupField
          block
          label={messages.email_verified}
          name="email_verified"
          type="text"
          component="select"
          disabled={this.props.response.isLoading}
          formErrors={this.props.response.formErrors}
          optional
          >
            <option value=''>Choose...</option>
              {EMAIL_VERIFIED.keySeq().map((key) => {
                return <option value={ key } key={ key }>{ EMAIL_VERIFIED.get(key) }</option>
              })}
        </GroupField>
        <OptionalFields
          boolFieldName='show_last_login'
          reduxChangeAction={this.props.reduxChangeAction}
          label={messages.last_login}
          fieldNames={['last_login_from', 'last_login_to']}
          formName={USERS_FORM}
          shouldShow={this.props.formValues.show_last_login === true}
        >
          <Field
            name='last_login_from'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select from date'
              />
            }
          />
          <Field
            name='last_login_to'
            component={(field: WrappedFieldProps<void>) =>
              <DateInput
                {...field}
                placeholder='Select to date'
              />
            }
          />
        </OptionalFields>
        <FormGroup>
          <Button className="primary" type="submit" block disabled={isLoading}>Search</Button>
        </FormGroup>
        <FormErrorsRenderer formErrors={formErrors} errorKey="non_field_errors"/>
      </form>
    );
  }
}

const ConnectedUserListFilterForm = reduxForm({
   form: USERS_FORM,
})(UserListFilterForm)

const selector = formValueSelector(USERS_FORM);

function mapStateToProps(store: IStore, props: IExternalProps) {
  const showLastLoginValue = selector(
    store,
    'show_last_login',
  )

  return {
    ...props,
    formValues: {
      show_last_login: showLastLoginValue,
    },
  };
}

const connectedUserListFilterForm = connect(
  mapStateToProps,
  {
    reduxChangeAction: change,
  }
)(ConnectedUserListFilterForm);

export default connectedUserListFilterForm;
