'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconLongArrowUp (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconLongArrowUp'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconLongArrowUp'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(768.81, 1791.82) scale(1, -1)',
          d: 'M765 1043q-9 -19 -29 -19h-224v-1248q0 -14 -9 -23t-23 -9h-192q-14 0 -23 9t-9 23v1248h-224q-21 0 -29 19t5 35l350 384q10 10 23 10q14 0 24 -10l355 -384q13 -16 5 -35z'
        }
      )
    );
  };

})();
