import { Button } from '@dabapps/roe';
import { fromJS, Map } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import { collectionsModule } from '../../reducers/collections';
import { itemsModule } from '../../reducers/items';
import { IStore } from "../../store";
import GenericList from '../chadmin/generic-list';
import PageHeader from '../page-header';
import StudiesFilters, { IDataShape } from './filters';

const { actions: { getCollection } } = collectionsModule;
const { actions: { clearItem } } = itemsModule;

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS = fromJS([
  {
    name: ['impact_number'],
    display_name: 'IMPACT number',
    type: 'text',
    sortable: false,
  },
  {
    name: ['study_name'],
    display_name: 'Name',
    type: 'detail',
    route: DETAIL_ROUTE,
    sortable: false,
  },
  {
    name: ['fpfv_date'],
    display_name: 'Start date',
    type: 'date',
    sortable: false,
  },
  {
    name: ['lpfv_date'],
    display_name: 'End date',
    type: 'date',
    sortable: false,
  },
  { name: ['id'], display_name: 'Actions', type: 'remove', sortable: false },
]);

const MESSAGES = {
  title: 'generic.studies',
  search: 'studies.list.search',
};

const COLLECTION_NAME = 'STUDY_LIST';
const COLLECTION_KEY = 'studies';

type IExternalProps = PermissionHOCChildProps;

interface IProps extends IExternalProps {
  getCollection: typeof getCollection;
  push: typeof push;
  clearItem: typeof clearItem;
}

export class StudiesIndex extends React.PureComponent<IProps, {}> {
  public render() {
    return (
      <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.title} />}>
          {this.props.permissions.canCreate &&
            <Button onClick={this.onClickSearch} className="primary">
              <FormattedMessage id={MESSAGES.search} />
            </Button>
          }
        </PageHeader>
        <StudiesFilters onSubmit={this.onFiltersSubmit} />
        <GenericList
          canCreate={this.props.permissions.canCreate}
          collectionColumns={this.props.permissions.canRemove ? LIST_COLUMNS :
            LIST_COLUMNS.filter((column: any) => column.get('type') !== 'remove')}
          collectionName={COLLECTION_NAME}
          collectionKey={COLLECTION_KEY}
        />
      </div>
    );
  }

  private onFiltersSubmit = (data: IDataShape) => {
    const filters = Map({
      impact_number: data.impactNumber || '',
      study_name: data.study_name || '',
      active: data.active ? 'True' : '',
    });

    this.props.getCollection(
      COLLECTION_KEY,
      {
        filters
      },
      COLLECTION_NAME
    );
  }

  private onClickSearch = () => {
    this.props.clearItem('studies');
    this.props.push(`/app/${COLLECTION_KEY}/search/`);
  }
}

function mapStateToProps(state: IStore, props: IExternalProps) {
  return {
    ...props,
  };
}

export default connect(mapStateToProps, { getCollection, push, clearItem })(StudiesIndex);
