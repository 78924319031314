import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Well,
} from '@dabapps/roe';
import { List } from 'immutable';
import * as moment from 'moment';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IProfileLogRecord } from "../../../store/data-types";
import { formatDateTime } from '../../../utils';
import SimplePopover from '../../popovers/simple-popover';
import AuditLog from './audit-log';

const MESSAGES = {
  date: "generic.date",
  details: "generic.details",
  user: "generic.user",
  type: "generic.type",
  outcome: "generic.outcome"
};

interface IExternalProps {
  profileLogsList: List<IProfileLogRecord>
}

export default class ProfileLogList extends React.PureComponent<IExternalProps, {}> {
  public renderRow(entry: IProfileLogRecord, index: number) {
    return(
      <TableRow key={index}>
        <TableCell>{formatDateTime(moment.utc(entry.get('created')))}</TableCell>
        <TableCell>
          <SimplePopover
            key={entry.get('created')}
            bottom
            maxWidth={500}
            toggleComponent={<a onClick={() => undefined}>{entry.get('detail')}</a>}
          >
            <div className="audit-popover">
              <AuditLog profilelog={entry}/>
            </div>
          </SimplePopover>
        </TableCell>
        <TableCell>{entry.getIn(['user_display', 'display_full_name'])}</TableCell>
        <TableCell>{entry.getIn(['contact_type_display', 'name'])}</TableCell>
        <TableCell>{entry.getIn(['call_outcome_display', 'name'])}</TableCell>
      </TableRow>
    )
  }

  public render() {

    const { profileLogsList } = this.props;

    return(
      <Well className="margin-vertical-base primary-border">
        <Table fill hover>
          <TableHead>
            <TableRow>
              <TableHeader><FormattedMessage id={MESSAGES.date} /></TableHeader>
              <TableHeader><FormattedMessage id={MESSAGES.details} /></TableHeader>
              <TableHeader><FormattedMessage id={MESSAGES.user} /></TableHeader>
              <TableHeader><FormattedMessage id={MESSAGES.type} /></TableHeader>
              <TableHeader><FormattedMessage id={MESSAGES.outcome} /></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            { profileLogsList.count() ? profileLogsList.map(
                (entry: IProfileLogRecord, index: number) => this.renderRow(entry, index)
              ) : <TableRow><TableHeader>No profile logs</TableHeader></TableRow>
            }
          </TableBody>
        </Table>
      </Well>
    );
  }
}
