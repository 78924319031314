import { Container, Tab, Tabs } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { PageName } from '../../permissions/config/default-permissions';
import { hasPageAccess } from '../../permissions/permissions-config';
import Dropdown from '../dropdown';
import TabItem from './tab-item';

const { IconCog } = FontAwesome;

interface IProps {
  userType: string;
  pathname: string;
}

interface IPermitted {
  user: string;
  page: string;
}

interface ILink {
  text: string;
  to: string;
  page: PageName;
  permitted: (user: string, page: PageName) => boolean;
}

type TLinks = Array<{
  header: string;
  permitted: (user: string, page: PageName) => boolean;
  page: PageName;
  links: ILink[];
}>;

const REPORTS_LINKS: ILink[] = [
  {
    text: 'CSV downloads',
    to: '/app/reports/csv/',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'reports_csv'
  },
  {
    text: 'Recruitment Flow',
    to: '/app/reports/recruitment-flow/',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'reports_recruitment_flow'
  }
]

const LOOKUP_LINKS: TLinks = [
  {
    header: 'Drivers',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'drivers',
    links: [
      {
        text: 'Advertisements',
        to: '/app/drivers/adverts/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'adverts'
      },
      {
        text: 'Advertisement Types',
        to: '/app/drivers/adverts-types/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'adverts_types'
      },
      {
        text: 'Motivations',
        to: '/app/drivers/motivations/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'motivations'
      },
    ]
  },
  {
    header: 'Outcomes',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'outcomes',
    links: [
      {
        text: 'Call Outcomes',
        to: '/app/outcomes/call-outcomes/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'call_outcomes'
      },
      {
        text: 'Study Outcomes',
        to: '/app/outcomes/study-outcomes/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'study_outcomes'
      },
    ]
  },
  {
    header: 'Medication',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'medication',
    links: [
      {
        text: 'Medications',
        to: '/app/medications/medication/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'medications'
      },
      {
        text: 'Medication Frequencies',
        to: '/app/medications/frequency/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'frequency'
      },
      {
        text: 'Medication Manufacturers',
        to: '/app/medications/manufacturer/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'manufacturer'
      },
      {
        text: 'Medication Classes',
        to: '/app/medications/class/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'class'
      },
      {
        text: 'Medication Generic Names',
        to: '/app/medications/generic-name/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'generic_name'
      },
      {
        text: 'Medication Units',
        to: '/app/medications/unit/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'units'
      },
    ]
  },
  {
    header: 'Other',
    permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
    page: 'other',
    links: [
      {
        text: 'Titles',
        to: '/app/titles/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'titles'
      },
      {
        text: 'Contact Types',
        to: '/app/contact-types/',
        permitted: (user: string, page: PageName): boolean => hasPageAccess(user, page),
        page: 'contact_types'
      }
    ]
  }
];

export class TabBar extends React.PureComponent<IProps, void> {
  public render() {
    const { userType, pathname } = this.props;
    return (
      <div className="nav-tabbar-wrapper">
        <Container className="padding-none">
          <div className="nav-tabbar">
            <Tabs className="nav-tabs">
              <TabItem
                pathname={pathname}
                permitted={hasPageAccess(userType, 'home')}
                label={'tabbar.home'} path="/" exactPath={true}/>
              <TabItem
                pathname={pathname}
                permitted={hasPageAccess(userType, 'volunteers')}
                label={'generic.volunteers'} path="/volunteers/" />
              <TabItem
                pathname={pathname}
                permitted={hasPageAccess(userType, 'sites')}
                label={'generic.sites'} path="/sites/"/>
              <TabItem
                pathname={pathname}
                permitted={hasPageAccess(userType, 'studies')}
                label={'generic.studies'}path="/studies/" />
              {hasPageAccess(userType, 'reports') &&
                <Tab>
                  <Dropdown
                    className="lookup-dropdown"
                    message="tabbar.reports"
                    componentKey="reports"
                  >
                    <ul className="lookups-menu">
                      {REPORTS_LINKS.map(({text, permitted: subPermitted, to, page: subPage}) => (
                        subPermitted(userType, subPage) &&
                          <li key={text}>
                            <Link to={to}>
                              {text}
                            </Link>
                          </li>
                      ))}
                    </ul>
                  </Dropdown>
                </Tab>
              }
              {hasPageAccess(userType, 'lookups') &&
              (<Tab>
                <Dropdown
                  className="lookup-dropdown"
                  message="tabbar.lookups"
                  componentKey="lookups"
                >
                  <ul className="lookups-menu">
                    {LOOKUP_LINKS.map(({header, permitted, page, links}) => (
                      permitted(userType, page) && (<li key={header}>
                        <p>
                          <strong>
                            {header}
                          </strong>
                        </p>
                        <ul>
                          {links.map(({text, permitted: subPermitted, to, page: subPage}) => (
                            subPermitted(userType, subPage) && (<li key={text}>
                              <Link to={to}>
                                {text}
                              </Link>
                            </li>)
                          ))}
                        </ul>
                      </li>)
                    ))}
                  </ul>
                </Dropdown>
              </Tab>)}
            </Tabs>
          </div>
          {
            hasPageAccess(userType, 'users') && (
            <div className="float-right nav-right">
              <Link to="/app/users/">Users & Settings
                <IconCog className="icon-large margin-left-small" />
              </Link>
            </div>)
          }
        </Container>
      </div>
    );
  }
}

export default TabBar;
