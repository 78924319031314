import {fromJS, List, Map} from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { collectionsModule } from '../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from "../../../store";
import { ITitleRecord } from "../../../store/data-types";
import GenericCreate from '../../chadmin/generic-create';

import {
  ADMIN_USER_TYPES_CHADMIN_CHOICES,
  OR_USER_TYPES_CHADMIN_CHOICES,
  USER_STATUS_CHADMIN_CHOICES
} from '../../../consts/constants';

import { USER_TYPES } from "../../../consts/user-types";

const COLLECTION_URI = 'users';
const FORM_NAME = 'USERS_FORM'

const { actions : {getCollection } } = collectionsModule

const MESSAGES = {
  title: 'driver.user',
  create: 'create.user'
};

interface IValueCollections {
  titles: List<ITitleRecord>;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
}

interface IStateProps extends IValueCollections {
  response: IResponseStatus;
  loading: boolean;
}

type IExternalProps = PermissionHOCChildProps;

// tslint:disable-next-line:no-unused-variable
type Props = IStateProps & IDispatchProps & IExternalProps;

export const DETAIL_FIELDS = fromJS([
  {
    wrapperClassName: 'column sx-12 md-6',
    elements: [['title', 'first_name', 'last_name'],
      ['email', 'type', 'status']]
  }
]);

const DETAIL_FIELD_OPTIONS = fromJS({
  title: {
    label: 'Title',
    type: 'choices',
    choices: [],
  },
  first_name: {
    label: 'First Name',
    max_length: 255,
    placeholder: 'First Name',
    type: 'text',
  },
  last_name: {
    label: 'Last Name',
    max_length: 255,
    placeholder: 'Last Name',
    type: 'text',
  },
  email: {
    label: 'Email',
    max_length: 255,
    placeholder: 'Email',
    type: 'text',
  },
  type: {
    label: 'Role',
    type: 'choices',
    choices: ADMIN_USER_TYPES_CHADMIN_CHOICES,
    clearable: false,
  },
  status: {
    label: 'Is Active?',
    type: 'choices',
    choices: USER_STATUS_CHADMIN_CHOICES,
    clearable: false,
  }
});

export class Create extends React.PureComponent<Props, void> {
  public constructor() {
    super();
    this.transformFieldOptions = this.transformFieldOptions.bind(this);
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public render() {
    return (
      <GenericCreate
        messages={MESSAGES}
        detailFields={DETAIL_FIELDS}
        detailFieldOptions={this.transformFieldOptions()}
        formName={FORM_NAME}
        collectionKey={COLLECTION_URI}
        onCancelLinkTo={'/app/users/'} />
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      'users/titles',
      { pageSize: 150 },
      'users/titles'
    );
  }

  private transformFieldOptions() {
    const fieldOptions = DETAIL_FIELD_OPTIONS.set('title', Map({
      label: 'Title',
      type: 'choices',
      choices: this.props.titles.map((entry: ITitleRecord) => {
        return Map({value: entry.get('display'), display_name: entry.get('name')})})
    }));
    if (this.props.user.type === USER_TYPES.QA) {
      return fieldOptions.set('type', Map({
        label: 'Role',
        type: 'choices',
        choices: OR_USER_TYPES_CHADMIN_CHOICES,
        clearable: false,
      }))
    }
    return fieldOptions;
  }
}

function mapStateToProps({ collections, responses }: IStore, props: IExternalProps) {

  const valueCollections: IValueCollections = {
    titles: getCollectionByName(collections['users/titles'], 'users/titles').results,
  }

  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, 'users'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users'),
    ...valueCollections
  };
}
export default connect(mapStateToProps, {getCollection})(Create);
