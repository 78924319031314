'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconCaretDown (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconCaretDown'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconCaretDown'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(640.81, 1791.82) scale(1, -1)',
          d: 'M1024 832q0 -26 -19 -45l-448 -448q-19 -19 -45 -19t-45 19l-448 448q-19 19 -19 45t19 45t45 19h896q26 0 45 -19t19 -45z'
        }
      )
    );
  };

})();
