import { Column, Row, Well } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { Link } from 'react-router';

interface IExternalProps {
  loading?: boolean;
  icon?: JSX.Element;
  title: string;
  data: List<Map<string, any>>;
  className?: string;
}

type IProps = IExternalProps;

class DashboardStatsPanel extends React.PureComponent<IProps, void> {

  public render() {
    const { loading, icon, title, data, className } = this.props;
    return (
      <div className={className}>
        <Well className='clearfix padding-base margin-vertical-large'>
          <h6 className={'header font-size-medium bold margin-top-base'}>{icon} {title}</h6>
          <Well className='padding-base inner-well chart-well-inner'>
            {loading ? <p>Loading...</p> :
              <Row className={'margin-left-base margin-right-base text-align-center'}>
                {
                  data.map((value: Map<string, any>, index: number) =>
                    <Column key={index} xs={12} md={12 / data.size}>
                      <p className={'bold'}>
                        <span className={'font-size-xlarge'}>{value.get('value')}</span>
                        <span className={'dark-gray font-size-large'}> &nbsp;{value.get('percentage')} %</span>
                      </p>
                      {
                        value.get('pathname') ?
                          <Link
                            to={{
                              pathname: value.get('pathname'),
                              state: { statsFilters: value.get('filters') }
                            }}><p>{value.get('text')}</p>
                          </Link>
                          : <p>{value.get('text')}</p>
                      }
                    </Column>
                  )}
              </Row>}
          </Well>
        </Well>
      </div>
    );
  }
}

export default DashboardStatsPanel;
