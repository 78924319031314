import { IStore } from '^/store';
import * as React from "react";
import { FormattedMessage } from "react-intl"
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { FormProps, formValueSelector, reduxForm } from 'redux-form';
import { DisplayUnit, Hba1cUnit, IFormMeasurements, IUserRecord } from "../../../../store/data-types";
import { disableSubmitOnEnter, FormErrors } from "../../../../utils";
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import { getHba1cOrPercentage } from '../../utils';
import EditMedicalDetailsFormFields from '../form-fields/edit-medical-details-form-fields';

import { Button, FormGroup } from '@dabapps/roe';

export interface IFormData {
  volunteer: {
    diabetes_type: string;
    month_diagnosed: string;
    year_diagnosed: string | null;
    hbA1c_result: number | null;
    hbA1c_result_display_unit: Hba1cUnit;
    height: IFormMeasurements;
    weight: IFormMeasurements;
  }
}

interface IExternalProps {
  item: IUserRecord;
  itemIsPending: boolean;
  errors?: FormErrors | null;
  initialValues: IFormData;
  onSubmit(data: IFormData): void;
}

interface IOwnProps extends IExternalProps {
  hba1cResultValue: number;
  selectedHba1cUnit: Hba1cUnit;
  selectedHeightUnit: DisplayUnit;
  selectedWeightUnit: DisplayUnit;
}

type IProps = FormProps<IFormData, void, void> & IOwnProps

class MedicalDetailsForm extends React.PureComponent<IProps, {}> {
  public componentWillReceiveProps(nextProps: IProps) {
    if (this.props.selectedHba1cUnit && this.props.selectedHba1cUnit !== nextProps.selectedHba1cUnit) {
      this.changeHba1cResultFormValue();
    }
  }

  public render() {
    const {
      item,
      handleSubmit,
      errors,
      itemIsPending,
      selectedHba1cUnit,
      selectedHeightUnit,
      selectedWeightUnit
    } = this.props;
    return (
      <form onSubmit={handleSubmit} onKeyDown={disableSubmitOnEnter} >
        <EditMedicalDetailsFormFields
          item={item}
          errors={errors}
          selectedHba1cUnit={selectedHba1cUnit}
          selectedHeightUnit={selectedHeightUnit}
          selectedWeightUnit={selectedWeightUnit}
        />
        <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors"/>
        <FormGroup className="form-controls">
          <Button type="submit" className="button primary" disabled={itemIsPending}>
            <FormattedMessage id='generic.save' />
          </Button>
          <Link to="/app/" className="cancel-link">
            <FormattedMessage id='generic.cancel'/>
          </Link>
        </FormGroup>
      </form>
    )
  }

  private changeHba1cResultFormValue() {
    const { change, hba1cResultValue, selectedHba1cUnit } = this.props;
    const convertedValue = getHba1cOrPercentage(hba1cResultValue, selectedHba1cUnit);
    if (change && convertedValue) {
      change('volunteer.hbA1c_result', convertedValue.toFixed(1));
    }
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IProps {
  const selector = formValueSelector('medical-details-form');
  return {
    ...props,
    selectedHba1cUnit: selector(state, 'volunteer.hbA1c_result_display_unit'),
    selectedHeightUnit: selector(state, 'volunteer.height.display_unit'),
    selectedWeightUnit: selector(state, 'volunteer.weight.display_unit'),
    hba1cResultValue: selector(state, 'volunteer.hbA1c_result'),
  };
}

const ConnectedMedicalDetailsForm = reduxForm({
  form: 'medical-details-form'
})(MedicalDetailsForm)

export default connect(mapStateToProps, {})(ConnectedMedicalDetailsForm);
