import * as React from 'react';

export class SkipToMainContent extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <a className="skip-navigation" href="#main">Skip to main content</a>
    )
  }
}

export default SkipToMainContent
