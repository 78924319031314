'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconCoffee (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconCoffee'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconCoffee'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(192.81, 1791.82) scale(1, -1)',
          d: 'M1664 896q0 80 -56 136t-136 56h-64v-384h64q80 0 136 56t56 136zM0 128h1792q0 -106 -75 -181t-181 -75h-1280q-106 0 -181 75t-75 181zM1856 896q0 -159 -112.5 -271.5t-271.5 -112.5h-64v-32q0 -92 -66 -158t-158 -66h-704q-92 0 -158 66t-66 158v736q0 26 19 45t45 19h1152q159 0 271.5 -112.5t112.5 -271.5z'
        }
      )
    );
  };

})();
