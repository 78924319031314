'use strict';

(function () {

  module.exports = {
    'Icon500px': require('./icon-500px'),
    'IconAddressBook': require('./icon-address-book'),
    'IconAddressBookO': require('./icon-address-book-o'),
    'IconAddressCard': require('./icon-address-card'),
    'IconAddressCardO': require('./icon-address-card-o'),
    'IconAdjust': require('./icon-adjust'),
    'IconAdn': require('./icon-adn'),
    'IconAlignCenter': require('./icon-align-center'),
    'IconAlignJustify': require('./icon-align-justify'),
    'IconAlignLeft': require('./icon-align-left'),
    'IconAlignRight': require('./icon-align-right'),
    'IconAmazon': require('./icon-amazon'),
    'IconAmbulance': require('./icon-ambulance'),
    'IconAmericanSignLanguageInterpreting': require('./icon-american-sign-language-interpreting'),
    'IconAnchor': require('./icon-anchor'),
    'IconAndroid': require('./icon-android'),
    'IconAngellist': require('./icon-angellist'),
    'IconAngleDoubleDown': require('./icon-angle-double-down'),
    'IconAngleDoubleLeft': require('./icon-angle-double-left'),
    'IconAngleDoubleRight': require('./icon-angle-double-right'),
    'IconAngleDoubleUp': require('./icon-angle-double-up'),
    'IconAngleDown': require('./icon-angle-down'),
    'IconAngleLeft': require('./icon-angle-left'),
    'IconAngleRight': require('./icon-angle-right'),
    'IconAngleUp': require('./icon-angle-up'),
    'IconApple': require('./icon-apple'),
    'IconArchive': require('./icon-archive'),
    'IconAreaChart': require('./icon-area-chart'),
    'IconArrowCircleDown': require('./icon-arrow-circle-down'),
    'IconArrowCircleLeft': require('./icon-arrow-circle-left'),
    'IconArrowCircleODown': require('./icon-arrow-circle-o-down'),
    'IconArrowCircleOLeft': require('./icon-arrow-circle-o-left'),
    'IconArrowCircleORight': require('./icon-arrow-circle-o-right'),
    'IconArrowCircleOUp': require('./icon-arrow-circle-o-up'),
    'IconArrowCircleRight': require('./icon-arrow-circle-right'),
    'IconArrowCircleUp': require('./icon-arrow-circle-up'),
    'IconArrowDown': require('./icon-arrow-down'),
    'IconArrowLeft': require('./icon-arrow-left'),
    'IconArrowRight': require('./icon-arrow-right'),
    'IconArrowUp': require('./icon-arrow-up'),
    'IconArrows': require('./icon-arrows'),
    'IconArrowsAlt': require('./icon-arrows-alt'),
    'IconArrowsH': require('./icon-arrows-h'),
    'IconArrowsV': require('./icon-arrows-v'),
    'IconAssistiveListeningSystems': require('./icon-assistive-listening-systems'),
    'IconAsterisk': require('./icon-asterisk'),
    'IconAt': require('./icon-at'),
    'IconAudioDescription': require('./icon-audio-description'),
    'IconBackward': require('./icon-backward'),
    'IconBalanceScale': require('./icon-balance-scale'),
    'IconBan': require('./icon-ban'),
    'IconBandcamp': require('./icon-bandcamp'),
    'IconBarChart': require('./icon-bar-chart'),
    'IconBarcode': require('./icon-barcode'),
    'IconBars': require('./icon-bars'),
    'IconBath': require('./icon-bath'),
    'IconBatteryEmpty': require('./icon-battery-empty'),
    'IconBatteryFull': require('./icon-battery-full'),
    'IconBatteryHalf': require('./icon-battery-half'),
    'IconBatteryQuarter': require('./icon-battery-quarter'),
    'IconBatteryThreeQuarters': require('./icon-battery-three-quarters'),
    'IconBed': require('./icon-bed'),
    'IconBeer': require('./icon-beer'),
    'IconBehance': require('./icon-behance'),
    'IconBehanceSquare': require('./icon-behance-square'),
    'IconBell': require('./icon-bell'),
    'IconBellO': require('./icon-bell-o'),
    'IconBellSlash': require('./icon-bell-slash'),
    'IconBellSlashO': require('./icon-bell-slash-o'),
    'IconBicycle': require('./icon-bicycle'),
    'IconBinoculars': require('./icon-binoculars'),
    'IconBirthdayCake': require('./icon-birthday-cake'),
    'IconBitbucket': require('./icon-bitbucket'),
    'IconBitbucketSquare': require('./icon-bitbucket-square'),
    'IconBlackTie': require('./icon-black-tie'),
    'IconBlind': require('./icon-blind'),
    'IconBluetooth': require('./icon-bluetooth'),
    'IconBluetoothB': require('./icon-bluetooth-b'),
    'IconBold': require('./icon-bold'),
    'IconBolt': require('./icon-bolt'),
    'IconBomb': require('./icon-bomb'),
    'IconBook': require('./icon-book'),
    'IconBookmark': require('./icon-bookmark'),
    'IconBookmarkO': require('./icon-bookmark-o'),
    'IconBraille': require('./icon-braille'),
    'IconBriefcase': require('./icon-briefcase'),
    'IconBtc': require('./icon-btc'),
    'IconBug': require('./icon-bug'),
    'IconBuilding': require('./icon-building'),
    'IconBuildingO': require('./icon-building-o'),
    'IconBullhorn': require('./icon-bullhorn'),
    'IconBullseye': require('./icon-bullseye'),
    'IconBus': require('./icon-bus'),
    'IconBuysellads': require('./icon-buysellads'),
    'IconCalculator': require('./icon-calculator'),
    'IconCalendar': require('./icon-calendar'),
    'IconCalendarCheckO': require('./icon-calendar-check-o'),
    'IconCalendarMinusO': require('./icon-calendar-minus-o'),
    'IconCalendarO': require('./icon-calendar-o'),
    'IconCalendarPlusO': require('./icon-calendar-plus-o'),
    'IconCalendarTimesO': require('./icon-calendar-times-o'),
    'IconCamera': require('./icon-camera'),
    'IconCameraRetro': require('./icon-camera-retro'),
    'IconCar': require('./icon-car'),
    'IconCaretDown': require('./icon-caret-down'),
    'IconCaretLeft': require('./icon-caret-left'),
    'IconCaretRight': require('./icon-caret-right'),
    'IconCaretSquareODown': require('./icon-caret-square-o-down'),
    'IconCaretSquareOLeft': require('./icon-caret-square-o-left'),
    'IconCaretSquareORight': require('./icon-caret-square-o-right'),
    'IconCaretSquareOUp': require('./icon-caret-square-o-up'),
    'IconCaretUp': require('./icon-caret-up'),
    'IconCartArrowDown': require('./icon-cart-arrow-down'),
    'IconCartPlus': require('./icon-cart-plus'),
    'IconCc': require('./icon-cc'),
    'IconCcAmex': require('./icon-cc-amex'),
    'IconCcDinersClub': require('./icon-cc-diners-club'),
    'IconCcDiscover': require('./icon-cc-discover'),
    'IconCcJcb': require('./icon-cc-jcb'),
    'IconCcMastercard': require('./icon-cc-mastercard'),
    'IconCcPaypal': require('./icon-cc-paypal'),
    'IconCcStripe': require('./icon-cc-stripe'),
    'IconCcVisa': require('./icon-cc-visa'),
    'IconCertificate': require('./icon-certificate'),
    'IconChainBroken': require('./icon-chain-broken'),
    'IconCheck': require('./icon-check'),
    'IconCheckCircle': require('./icon-check-circle'),
    'IconCheckCircleO': require('./icon-check-circle-o'),
    'IconCheckSquare': require('./icon-check-square'),
    'IconCheckSquareO': require('./icon-check-square-o'),
    'IconChevronCircleDown': require('./icon-chevron-circle-down'),
    'IconChevronCircleLeft': require('./icon-chevron-circle-left'),
    'IconChevronCircleRight': require('./icon-chevron-circle-right'),
    'IconChevronCircleUp': require('./icon-chevron-circle-up'),
    'IconChevronDown': require('./icon-chevron-down'),
    'IconChevronLeft': require('./icon-chevron-left'),
    'IconChevronRight': require('./icon-chevron-right'),
    'IconChevronUp': require('./icon-chevron-up'),
    'IconChild': require('./icon-child'),
    'IconChrome': require('./icon-chrome'),
    'IconCircle': require('./icon-circle'),
    'IconCircleO': require('./icon-circle-o'),
    'IconCircleONotch': require('./icon-circle-o-notch'),
    'IconCircleThin': require('./icon-circle-thin'),
    'IconClipboard': require('./icon-clipboard'),
    'IconClockO': require('./icon-clock-o'),
    'IconClone': require('./icon-clone'),
    'IconCloud': require('./icon-cloud'),
    'IconCloudDownload': require('./icon-cloud-download'),
    'IconCloudUpload': require('./icon-cloud-upload'),
    'IconCode': require('./icon-code'),
    'IconCodeFork': require('./icon-code-fork'),
    'IconCodepen': require('./icon-codepen'),
    'IconCodiepie': require('./icon-codiepie'),
    'IconCoffee': require('./icon-coffee'),
    'IconCog': require('./icon-cog'),
    'IconCogs': require('./icon-cogs'),
    'IconColumns': require('./icon-columns'),
    'IconComment': require('./icon-comment'),
    'IconCommentO': require('./icon-comment-o'),
    'IconCommenting': require('./icon-commenting'),
    'IconCommentingO': require('./icon-commenting-o'),
    'IconComments': require('./icon-comments'),
    'IconCommentsO': require('./icon-comments-o'),
    'IconCompass': require('./icon-compass'),
    'IconCompress': require('./icon-compress'),
    'IconConnectdevelop': require('./icon-connectdevelop'),
    'IconContao': require('./icon-contao'),
    'IconCopyright': require('./icon-copyright'),
    'IconCreativeCommons': require('./icon-creative-commons'),
    'IconCreditCard': require('./icon-credit-card'),
    'IconCreditCardAlt': require('./icon-credit-card-alt'),
    'IconCrop': require('./icon-crop'),
    'IconCrosshairs': require('./icon-crosshairs'),
    'IconCss3': require('./icon-css3'),
    'IconCube': require('./icon-cube'),
    'IconCubes': require('./icon-cubes'),
    'IconCutlery': require('./icon-cutlery'),
    'IconDashcube': require('./icon-dashcube'),
    'IconDatabase': require('./icon-database'),
    'IconDeaf': require('./icon-deaf'),
    'IconDelicious': require('./icon-delicious'),
    'IconDesktop': require('./icon-desktop'),
    'IconDeviantart': require('./icon-deviantart'),
    'IconDiamond': require('./icon-diamond'),
    'IconDigg': require('./icon-digg'),
    'IconDotCircleO': require('./icon-dot-circle-o'),
    'IconDownload': require('./icon-download'),
    'IconDribbble': require('./icon-dribbble'),
    'IconDropbox': require('./icon-dropbox'),
    'IconDrupal': require('./icon-drupal'),
    'IconEdge': require('./icon-edge'),
    'IconEercast': require('./icon-eercast'),
    'IconEject': require('./icon-eject'),
    'IconEllipsisH': require('./icon-ellipsis-h'),
    'IconEllipsisV': require('./icon-ellipsis-v'),
    'IconEmpire': require('./icon-empire'),
    'IconEnvelope': require('./icon-envelope'),
    'IconEnvelopeO': require('./icon-envelope-o'),
    'IconEnvelopeOpen': require('./icon-envelope-open'),
    'IconEnvelopeOpenO': require('./icon-envelope-open-o'),
    'IconEnvelopeSquare': require('./icon-envelope-square'),
    'IconEnvira': require('./icon-envira'),
    'IconEraser': require('./icon-eraser'),
    'IconEtsy': require('./icon-etsy'),
    'IconEur': require('./icon-eur'),
    'IconExchange': require('./icon-exchange'),
    'IconExclamation': require('./icon-exclamation'),
    'IconExclamationCircle': require('./icon-exclamation-circle'),
    'IconExclamationTriangle': require('./icon-exclamation-triangle'),
    'IconExpand': require('./icon-expand'),
    'IconExpeditedssl': require('./icon-expeditedssl'),
    'IconExternalLink': require('./icon-external-link'),
    'IconExternalLinkSquare': require('./icon-external-link-square'),
    'IconEye': require('./icon-eye'),
    'IconEyeSlash': require('./icon-eye-slash'),
    'IconEyedropper': require('./icon-eyedropper'),
    'IconFacebook': require('./icon-facebook'),
    'IconFacebookOfficial': require('./icon-facebook-official'),
    'IconFacebookSquare': require('./icon-facebook-square'),
    'IconFastBackward': require('./icon-fast-backward'),
    'IconFastForward': require('./icon-fast-forward'),
    'IconFax': require('./icon-fax'),
    'IconFemale': require('./icon-female'),
    'IconFighterJet': require('./icon-fighter-jet'),
    'IconFile': require('./icon-file'),
    'IconFileArchiveO': require('./icon-file-archive-o'),
    'IconFileAudioO': require('./icon-file-audio-o'),
    'IconFileCodeO': require('./icon-file-code-o'),
    'IconFileExcelO': require('./icon-file-excel-o'),
    'IconFileImageO': require('./icon-file-image-o'),
    'IconFileO': require('./icon-file-o'),
    'IconFilePdfO': require('./icon-file-pdf-o'),
    'IconFilePowerpointO': require('./icon-file-powerpoint-o'),
    'IconFileText': require('./icon-file-text'),
    'IconFileTextO': require('./icon-file-text-o'),
    'IconFileVideoO': require('./icon-file-video-o'),
    'IconFileWordO': require('./icon-file-word-o'),
    'IconFilesO': require('./icon-files-o'),
    'IconFilm': require('./icon-film'),
    'IconFilter': require('./icon-filter'),
    'IconFire': require('./icon-fire'),
    'IconFireExtinguisher': require('./icon-fire-extinguisher'),
    'IconFirefox': require('./icon-firefox'),
    'IconFirstOrder': require('./icon-first-order'),
    'IconFlag': require('./icon-flag'),
    'IconFlagCheckered': require('./icon-flag-checkered'),
    'IconFlagO': require('./icon-flag-o'),
    'IconFlask': require('./icon-flask'),
    'IconFlickr': require('./icon-flickr'),
    'IconFloppyO': require('./icon-floppy-o'),
    'IconFolder': require('./icon-folder'),
    'IconFolderO': require('./icon-folder-o'),
    'IconFolderOpen': require('./icon-folder-open'),
    'IconFolderOpenO': require('./icon-folder-open-o'),
    'IconFont': require('./icon-font'),
    'IconFontAwesome': require('./icon-font-awesome'),
    'IconFonticons': require('./icon-fonticons'),
    'IconFortAwesome': require('./icon-fort-awesome'),
    'IconForumbee': require('./icon-forumbee'),
    'IconForward': require('./icon-forward'),
    'IconFoursquare': require('./icon-foursquare'),
    'IconFreeCodeCamp': require('./icon-free-code-camp'),
    'IconFrownO': require('./icon-frown-o'),
    'IconFutbolO': require('./icon-futbol-o'),
    'IconGamepad': require('./icon-gamepad'),
    'IconGavel': require('./icon-gavel'),
    'IconGbp': require('./icon-gbp'),
    'IconGenderless': require('./icon-genderless'),
    'IconGetPocket': require('./icon-get-pocket'),
    'IconGg': require('./icon-gg'),
    'IconGgCircle': require('./icon-gg-circle'),
    'IconGift': require('./icon-gift'),
    'IconGit': require('./icon-git'),
    'IconGitSquare': require('./icon-git-square'),
    'IconGithub': require('./icon-github'),
    'IconGithubAlt': require('./icon-github-alt'),
    'IconGithubSquare': require('./icon-github-square'),
    'IconGitlab': require('./icon-gitlab'),
    'IconGlass': require('./icon-glass'),
    'IconGlide': require('./icon-glide'),
    'IconGlideG': require('./icon-glide-g'),
    'IconGlobe': require('./icon-globe'),
    'IconGoogle': require('./icon-google'),
    'IconGooglePlus': require('./icon-google-plus'),
    'IconGooglePlusOfficial': require('./icon-google-plus-official'),
    'IconGooglePlusSquare': require('./icon-google-plus-square'),
    'IconGoogleWallet': require('./icon-google-wallet'),
    'IconGraduationCap': require('./icon-graduation-cap'),
    'IconGratipay': require('./icon-gratipay'),
    'IconGrav': require('./icon-grav'),
    'IconHSquare': require('./icon-h-square'),
    'IconHackerNews': require('./icon-hacker-news'),
    'IconHandLizardO': require('./icon-hand-lizard-o'),
    'IconHandODown': require('./icon-hand-o-down'),
    'IconHandOLeft': require('./icon-hand-o-left'),
    'IconHandORight': require('./icon-hand-o-right'),
    'IconHandOUp': require('./icon-hand-o-up'),
    'IconHandPaperO': require('./icon-hand-paper-o'),
    'IconHandPeaceO': require('./icon-hand-peace-o'),
    'IconHandPointerO': require('./icon-hand-pointer-o'),
    'IconHandRockO': require('./icon-hand-rock-o'),
    'IconHandScissorsO': require('./icon-hand-scissors-o'),
    'IconHandSpockO': require('./icon-hand-spock-o'),
    'IconHandshakeO': require('./icon-handshake-o'),
    'IconHashtag': require('./icon-hashtag'),
    'IconHddO': require('./icon-hdd-o'),
    'IconHeader': require('./icon-header'),
    'IconHeadphones': require('./icon-headphones'),
    'IconHeart': require('./icon-heart'),
    'IconHeartO': require('./icon-heart-o'),
    'IconHeartbeat': require('./icon-heartbeat'),
    'IconHistory': require('./icon-history'),
    'IconHome': require('./icon-home'),
    'IconHospitalO': require('./icon-hospital-o'),
    'IconHourglass': require('./icon-hourglass'),
    'IconHourglassEnd': require('./icon-hourglass-end'),
    'IconHourglassHalf': require('./icon-hourglass-half'),
    'IconHourglassO': require('./icon-hourglass-o'),
    'IconHourglassStart': require('./icon-hourglass-start'),
    'IconHouzz': require('./icon-houzz'),
    'IconHtml5': require('./icon-html5'),
    'IconICursor': require('./icon-i-cursor'),
    'IconIdBadge': require('./icon-id-badge'),
    'IconIdCard': require('./icon-id-card'),
    'IconIdCardO': require('./icon-id-card-o'),
    'IconIls': require('./icon-ils'),
    'IconImdb': require('./icon-imdb'),
    'IconInbox': require('./icon-inbox'),
    'IconIndent': require('./icon-indent'),
    'IconIndustry': require('./icon-industry'),
    'IconInfo': require('./icon-info'),
    'IconInfoCircle': require('./icon-info-circle'),
    'IconInr': require('./icon-inr'),
    'IconInstagram': require('./icon-instagram'),
    'IconInternetExplorer': require('./icon-internet-explorer'),
    'IconIoxhost': require('./icon-ioxhost'),
    'IconItalic': require('./icon-italic'),
    'IconJoomla': require('./icon-joomla'),
    'IconJpy': require('./icon-jpy'),
    'IconJsfiddle': require('./icon-jsfiddle'),
    'IconKey': require('./icon-key'),
    'IconKeyboardO': require('./icon-keyboard-o'),
    'IconKrw': require('./icon-krw'),
    'IconLanguage': require('./icon-language'),
    'IconLaptop': require('./icon-laptop'),
    'IconLastfm': require('./icon-lastfm'),
    'IconLastfmSquare': require('./icon-lastfm-square'),
    'IconLeaf': require('./icon-leaf'),
    'IconLeanpub': require('./icon-leanpub'),
    'IconLemonO': require('./icon-lemon-o'),
    'IconLevelDown': require('./icon-level-down'),
    'IconLevelUp': require('./icon-level-up'),
    'IconLifeRing': require('./icon-life-ring'),
    'IconLightbulbO': require('./icon-lightbulb-o'),
    'IconLineChart': require('./icon-line-chart'),
    'IconLink': require('./icon-link'),
    'IconLinkedin': require('./icon-linkedin'),
    'IconLinkedinSquare': require('./icon-linkedin-square'),
    'IconLinode': require('./icon-linode'),
    'IconLinux': require('./icon-linux'),
    'IconList': require('./icon-list'),
    'IconListAlt': require('./icon-list-alt'),
    'IconListOl': require('./icon-list-ol'),
    'IconListUl': require('./icon-list-ul'),
    'IconLocationArrow': require('./icon-location-arrow'),
    'IconLock': require('./icon-lock'),
    'IconLongArrowDown': require('./icon-long-arrow-down'),
    'IconLongArrowLeft': require('./icon-long-arrow-left'),
    'IconLongArrowRight': require('./icon-long-arrow-right'),
    'IconLongArrowUp': require('./icon-long-arrow-up'),
    'IconLowVision': require('./icon-low-vision'),
    'IconMagic': require('./icon-magic'),
    'IconMagnet': require('./icon-magnet'),
    'IconMale': require('./icon-male'),
    'IconMap': require('./icon-map'),
    'IconMapMarker': require('./icon-map-marker'),
    'IconMapO': require('./icon-map-o'),
    'IconMapPin': require('./icon-map-pin'),
    'IconMapSigns': require('./icon-map-signs'),
    'IconMars': require('./icon-mars'),
    'IconMarsDouble': require('./icon-mars-double'),
    'IconMarsStroke': require('./icon-mars-stroke'),
    'IconMarsStrokeH': require('./icon-mars-stroke-h'),
    'IconMarsStrokeV': require('./icon-mars-stroke-v'),
    'IconMaxcdn': require('./icon-maxcdn'),
    'IconMeanpath': require('./icon-meanpath'),
    'IconMedium': require('./icon-medium'),
    'IconMedkit': require('./icon-medkit'),
    'IconMeetup': require('./icon-meetup'),
    'IconMehO': require('./icon-meh-o'),
    'IconMercury': require('./icon-mercury'),
    'IconMicrochip': require('./icon-microchip'),
    'IconMicrophone': require('./icon-microphone'),
    'IconMicrophoneSlash': require('./icon-microphone-slash'),
    'IconMinus': require('./icon-minus'),
    'IconMinusCircle': require('./icon-minus-circle'),
    'IconMinusSquare': require('./icon-minus-square'),
    'IconMinusSquareO': require('./icon-minus-square-o'),
    'IconMixcloud': require('./icon-mixcloud'),
    'IconMobile': require('./icon-mobile'),
    'IconModx': require('./icon-modx'),
    'IconMoney': require('./icon-money'),
    'IconMoonO': require('./icon-moon-o'),
    'IconMotorcycle': require('./icon-motorcycle'),
    'IconMousePointer': require('./icon-mouse-pointer'),
    'IconMusic': require('./icon-music'),
    'IconNeuter': require('./icon-neuter'),
    'IconNewspaperO': require('./icon-newspaper-o'),
    'IconObjectGroup': require('./icon-object-group'),
    'IconObjectUngroup': require('./icon-object-ungroup'),
    'IconOdnoklassniki': require('./icon-odnoklassniki'),
    'IconOdnoklassnikiSquare': require('./icon-odnoklassniki-square'),
    'IconOpencart': require('./icon-opencart'),
    'IconOpenid': require('./icon-openid'),
    'IconOpera': require('./icon-opera'),
    'IconOptinMonster': require('./icon-optin-monster'),
    'IconOutdent': require('./icon-outdent'),
    'IconPagelines': require('./icon-pagelines'),
    'IconPaintBrush': require('./icon-paint-brush'),
    'IconPaperPlane': require('./icon-paper-plane'),
    'IconPaperPlaneO': require('./icon-paper-plane-o'),
    'IconPaperclip': require('./icon-paperclip'),
    'IconParagraph': require('./icon-paragraph'),
    'IconPause': require('./icon-pause'),
    'IconPauseCircle': require('./icon-pause-circle'),
    'IconPauseCircleO': require('./icon-pause-circle-o'),
    'IconPaw': require('./icon-paw'),
    'IconPaypal': require('./icon-paypal'),
    'IconPencil': require('./icon-pencil'),
    'IconPencilSquare': require('./icon-pencil-square'),
    'IconPencilSquareO': require('./icon-pencil-square-o'),
    'IconPercent': require('./icon-percent'),
    'IconPhone': require('./icon-phone'),
    'IconPhoneSquare': require('./icon-phone-square'),
    'IconPictureO': require('./icon-picture-o'),
    'IconPieChart': require('./icon-pie-chart'),
    'IconPiedPiper': require('./icon-pied-piper'),
    'IconPiedPiperAlt': require('./icon-pied-piper-alt'),
    'IconPiedPiperPp': require('./icon-pied-piper-pp'),
    'IconPinterest': require('./icon-pinterest'),
    'IconPinterestP': require('./icon-pinterest-p'),
    'IconPinterestSquare': require('./icon-pinterest-square'),
    'IconPlane': require('./icon-plane'),
    'IconPlay': require('./icon-play'),
    'IconPlayCircle': require('./icon-play-circle'),
    'IconPlayCircleO': require('./icon-play-circle-o'),
    'IconPlug': require('./icon-plug'),
    'IconPlus': require('./icon-plus'),
    'IconPlusCircle': require('./icon-plus-circle'),
    'IconPlusSquare': require('./icon-plus-square'),
    'IconPlusSquareO': require('./icon-plus-square-o'),
    'IconPodcast': require('./icon-podcast'),
    'IconPowerOff': require('./icon-power-off'),
    'IconPrint': require('./icon-print'),
    'IconProductHunt': require('./icon-product-hunt'),
    'IconPuzzlePiece': require('./icon-puzzle-piece'),
    'IconQq': require('./icon-qq'),
    'IconQrcode': require('./icon-qrcode'),
    'IconQuestion': require('./icon-question'),
    'IconQuestionCircle': require('./icon-question-circle'),
    'IconQuestionCircleO': require('./icon-question-circle-o'),
    'IconQuora': require('./icon-quora'),
    'IconQuoteLeft': require('./icon-quote-left'),
    'IconQuoteRight': require('./icon-quote-right'),
    'IconRandom': require('./icon-random'),
    'IconRavelry': require('./icon-ravelry'),
    'IconRebel': require('./icon-rebel'),
    'IconRecycle': require('./icon-recycle'),
    'IconReddit': require('./icon-reddit'),
    'IconRedditAlien': require('./icon-reddit-alien'),
    'IconRedditSquare': require('./icon-reddit-square'),
    'IconRefresh': require('./icon-refresh'),
    'IconRegistered': require('./icon-registered'),
    'IconRenren': require('./icon-renren'),
    'IconRepeat': require('./icon-repeat'),
    'IconReply': require('./icon-reply'),
    'IconReplyAll': require('./icon-reply-all'),
    'IconRetweet': require('./icon-retweet'),
    'IconRoad': require('./icon-road'),
    'IconRocket': require('./icon-rocket'),
    'IconRss': require('./icon-rss'),
    'IconRssSquare': require('./icon-rss-square'),
    'IconRub': require('./icon-rub'),
    'IconSafari': require('./icon-safari'),
    'IconScissors': require('./icon-scissors'),
    'IconScribd': require('./icon-scribd'),
    'IconSearch': require('./icon-search'),
    'IconSearchMinus': require('./icon-search-minus'),
    'IconSearchPlus': require('./icon-search-plus'),
    'IconSellsy': require('./icon-sellsy'),
    'IconServer': require('./icon-server'),
    'IconShare': require('./icon-share'),
    'IconShareAlt': require('./icon-share-alt'),
    'IconShareAltSquare': require('./icon-share-alt-square'),
    'IconShareSquare': require('./icon-share-square'),
    'IconShareSquareO': require('./icon-share-square-o'),
    'IconShield': require('./icon-shield'),
    'IconShip': require('./icon-ship'),
    'IconShirtsinbulk': require('./icon-shirtsinbulk'),
    'IconShoppingBag': require('./icon-shopping-bag'),
    'IconShoppingBasket': require('./icon-shopping-basket'),
    'IconShoppingCart': require('./icon-shopping-cart'),
    'IconShower': require('./icon-shower'),
    'IconSignIn': require('./icon-sign-in'),
    'IconSignLanguage': require('./icon-sign-language'),
    'IconSignOut': require('./icon-sign-out'),
    'IconSignal': require('./icon-signal'),
    'IconSimplybuilt': require('./icon-simplybuilt'),
    'IconSitemap': require('./icon-sitemap'),
    'IconSkyatlas': require('./icon-skyatlas'),
    'IconSkype': require('./icon-skype'),
    'IconSlack': require('./icon-slack'),
    'IconSliders': require('./icon-sliders'),
    'IconSlideshare': require('./icon-slideshare'),
    'IconSmileO': require('./icon-smile-o'),
    'IconSnapchat': require('./icon-snapchat'),
    'IconSnapchatGhost': require('./icon-snapchat-ghost'),
    'IconSnapchatSquare': require('./icon-snapchat-square'),
    'IconSnowflakeO': require('./icon-snowflake-o'),
    'IconSort': require('./icon-sort'),
    'IconSortAlphaAsc': require('./icon-sort-alpha-asc'),
    'IconSortAlphaDesc': require('./icon-sort-alpha-desc'),
    'IconSortAmountAsc': require('./icon-sort-amount-asc'),
    'IconSortAmountDesc': require('./icon-sort-amount-desc'),
    'IconSortAsc': require('./icon-sort-asc'),
    'IconSortDesc': require('./icon-sort-desc'),
    'IconSortNumericAsc': require('./icon-sort-numeric-asc'),
    'IconSortNumericDesc': require('./icon-sort-numeric-desc'),
    'IconSoundcloud': require('./icon-soundcloud'),
    'IconSpaceShuttle': require('./icon-space-shuttle'),
    'IconSpinner': require('./icon-spinner'),
    'IconSpoon': require('./icon-spoon'),
    'IconSpotify': require('./icon-spotify'),
    'IconSquare': require('./icon-square'),
    'IconSquareO': require('./icon-square-o'),
    'IconStackExchange': require('./icon-stack-exchange'),
    'IconStackOverflow': require('./icon-stack-overflow'),
    'IconStar': require('./icon-star'),
    'IconStarHalf': require('./icon-star-half'),
    'IconStarHalfO': require('./icon-star-half-o'),
    'IconStarO': require('./icon-star-o'),
    'IconSteam': require('./icon-steam'),
    'IconSteamSquare': require('./icon-steam-square'),
    'IconStepBackward': require('./icon-step-backward'),
    'IconStepForward': require('./icon-step-forward'),
    'IconStethoscope': require('./icon-stethoscope'),
    'IconStickyNote': require('./icon-sticky-note'),
    'IconStickyNoteO': require('./icon-sticky-note-o'),
    'IconStop': require('./icon-stop'),
    'IconStopCircle': require('./icon-stop-circle'),
    'IconStopCircleO': require('./icon-stop-circle-o'),
    'IconStreetView': require('./icon-street-view'),
    'IconStrikethrough': require('./icon-strikethrough'),
    'IconStumbleupon': require('./icon-stumbleupon'),
    'IconStumbleuponCircle': require('./icon-stumbleupon-circle'),
    'IconSubscript': require('./icon-subscript'),
    'IconSubway': require('./icon-subway'),
    'IconSuitcase': require('./icon-suitcase'),
    'IconSunO': require('./icon-sun-o'),
    'IconSuperpowers': require('./icon-superpowers'),
    'IconSuperscript': require('./icon-superscript'),
    'IconTable': require('./icon-table'),
    'IconTablet': require('./icon-tablet'),
    'IconTachometer': require('./icon-tachometer'),
    'IconTag': require('./icon-tag'),
    'IconTags': require('./icon-tags'),
    'IconTasks': require('./icon-tasks'),
    'IconTaxi': require('./icon-taxi'),
    'IconTelegram': require('./icon-telegram'),
    'IconTelevision': require('./icon-television'),
    'IconTencentWeibo': require('./icon-tencent-weibo'),
    'IconTerminal': require('./icon-terminal'),
    'IconTextHeight': require('./icon-text-height'),
    'IconTextWidth': require('./icon-text-width'),
    'IconTh': require('./icon-th'),
    'IconThLarge': require('./icon-th-large'),
    'IconThList': require('./icon-th-list'),
    'IconThemeisle': require('./icon-themeisle'),
    'IconThermometerEmpty': require('./icon-thermometer-empty'),
    'IconThermometerFull': require('./icon-thermometer-full'),
    'IconThermometerHalf': require('./icon-thermometer-half'),
    'IconThermometerQuarter': require('./icon-thermometer-quarter'),
    'IconThermometerThreeQuarters': require('./icon-thermometer-three-quarters'),
    'IconThumbTack': require('./icon-thumb-tack'),
    'IconThumbsDown': require('./icon-thumbs-down'),
    'IconThumbsODown': require('./icon-thumbs-o-down'),
    'IconThumbsOUp': require('./icon-thumbs-o-up'),
    'IconThumbsUp': require('./icon-thumbs-up'),
    'IconTicket': require('./icon-ticket'),
    'IconTimes': require('./icon-times'),
    'IconTimesCircle': require('./icon-times-circle'),
    'IconTimesCircleO': require('./icon-times-circle-o'),
    'IconTint': require('./icon-tint'),
    'IconToggleOff': require('./icon-toggle-off'),
    'IconToggleOn': require('./icon-toggle-on'),
    'IconTrademark': require('./icon-trademark'),
    'IconTrain': require('./icon-train'),
    'IconTransgender': require('./icon-transgender'),
    'IconTransgenderAlt': require('./icon-transgender-alt'),
    'IconTrash': require('./icon-trash'),
    'IconTrashO': require('./icon-trash-o'),
    'IconTree': require('./icon-tree'),
    'IconTrello': require('./icon-trello'),
    'IconTripadvisor': require('./icon-tripadvisor'),
    'IconTrophy': require('./icon-trophy'),
    'IconTruck': require('./icon-truck'),
    'IconTry': require('./icon-try'),
    'IconTty': require('./icon-tty'),
    'IconTumblr': require('./icon-tumblr'),
    'IconTumblrSquare': require('./icon-tumblr-square'),
    'IconTwitch': require('./icon-twitch'),
    'IconTwitter': require('./icon-twitter'),
    'IconTwitterSquare': require('./icon-twitter-square'),
    'IconUmbrella': require('./icon-umbrella'),
    'IconUnderline': require('./icon-underline'),
    'IconUndo': require('./icon-undo'),
    'IconUniversalAccess': require('./icon-universal-access'),
    'IconUniversity': require('./icon-university'),
    'IconUnlock': require('./icon-unlock'),
    'IconUnlockAlt': require('./icon-unlock-alt'),
    'IconUpload': require('./icon-upload'),
    'IconUsb': require('./icon-usb'),
    'IconUsd': require('./icon-usd'),
    'IconUser': require('./icon-user'),
    'IconUserCircle': require('./icon-user-circle'),
    'IconUserCircleO': require('./icon-user-circle-o'),
    'IconUserMd': require('./icon-user-md'),
    'IconUserO': require('./icon-user-o'),
    'IconUserPlus': require('./icon-user-plus'),
    'IconUserSecret': require('./icon-user-secret'),
    'IconUserTimes': require('./icon-user-times'),
    'IconUsers': require('./icon-users'),
    'IconVenus': require('./icon-venus'),
    'IconVenusDouble': require('./icon-venus-double'),
    'IconVenusMars': require('./icon-venus-mars'),
    'IconViacoin': require('./icon-viacoin'),
    'IconViadeo': require('./icon-viadeo'),
    'IconViadeoSquare': require('./icon-viadeo-square'),
    'IconVideoCamera': require('./icon-video-camera'),
    'IconVimeo': require('./icon-vimeo'),
    'IconVimeoSquare': require('./icon-vimeo-square'),
    'IconVine': require('./icon-vine'),
    'IconVk': require('./icon-vk'),
    'IconVolumeControlPhone': require('./icon-volume-control-phone'),
    'IconVolumeDown': require('./icon-volume-down'),
    'IconVolumeOff': require('./icon-volume-off'),
    'IconVolumeUp': require('./icon-volume-up'),
    'IconWeibo': require('./icon-weibo'),
    'IconWeixin': require('./icon-weixin'),
    'IconWhatsapp': require('./icon-whatsapp'),
    'IconWheelchair': require('./icon-wheelchair'),
    'IconWheelchairAlt': require('./icon-wheelchair-alt'),
    'IconWifi': require('./icon-wifi'),
    'IconWikipediaW': require('./icon-wikipedia-w'),
    'IconWindowClose': require('./icon-window-close'),
    'IconWindowCloseO': require('./icon-window-close-o'),
    'IconWindowMaximize': require('./icon-window-maximize'),
    'IconWindowMinimize': require('./icon-window-minimize'),
    'IconWindowRestore': require('./icon-window-restore'),
    'IconWindows': require('./icon-windows'),
    'IconWordpress': require('./icon-wordpress'),
    'IconWpbeginner': require('./icon-wpbeginner'),
    'IconWpexplorer': require('./icon-wpexplorer'),
    'IconWpforms': require('./icon-wpforms'),
    'IconWrench': require('./icon-wrench'),
    'IconXing': require('./icon-xing'),
    'IconXingSquare': require('./icon-xing-square'),
    'IconYCombinator': require('./icon-y-combinator'),
    'IconYahoo': require('./icon-yahoo'),
    'IconYelp': require('./icon-yelp'),
    'IconYoast': require('./icon-yoast'),
    'IconYoutube': require('./icon-youtube'),
    'IconYoutubePlay': require('./icon-youtube-play'),
    'IconYoutubeSquare': require('./icon-youtube-square')
  };

})();
