'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconInfo (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconInfo'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconInfo'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(832.81, 1791.82) scale(1, -1)',
          d: 'M640 192v-128q0 -26 -19 -45t-45 -19h-512q-26 0 -45 19t-19 45v128q0 26 19 45t45 19h64v384h-64q-26 0 -45 19t-19 45v128q0 26 19 45t45 19h384q26 0 45 -19t19 -45v-576h64q26 0 45 -19t19 -45zM512 1344v-192q0 -26 -19 -45t-45 -19h-256q-26 0 -45 19t-19 45v192q0 26 19 45t45 19h256q26 0 45 -19t19 -45z'
        }
      )
    );
  };

})();
