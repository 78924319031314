import {fromJS} from 'immutable';
import * as React from 'react';
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import GenericCreate from '../chadmin/generic-create';
import { AUTOFILL_FIELDS } from './sites-detail-fields';

const COLLECTION_URI = 'sites';
const FORM_NAME = 'CREATE_SITES'

const MESSAGES = {
  title: 'generic.sites',
  create: 'sites.list.create'
};

export const DETAIL_FIELDS = fromJS([
  {
    wrapperClassName: 'column md-6',
    fieldClassNames: [['column md-12', 'column md-12', 'column md-8', 'column md-12'],
    ['column md-12', 'column md-12', 'column md-12', 'column md-12', 'column md-12']],
    elements: [
      [
        'name',
        'radius',
        'active',
        'postcode_lookup'
      ],
      [
        'address_1',
        'address_2',
        'county',
        'city',
        'postcode'
      ]
    ]
  }
]);

export const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    placeholder: 'Name',
    type: 'text',
    },
  active: {
    label: 'Is Active?',
    type: 'boolean',
  },
  address_1: {
    label: 'Address line 1',
    max_length: 255,
    placeholder: 'Address line 1',
    type: 'text',
  },
  address_2: {
    label: 'Address line 2',
    max_length: 255,
    placeholder: 'Address line 2',
    type: 'text',
  },
  city: {
    label: 'City',
    max_length: 255,
    placeholder: 'City',
    type: 'text',
  },
  county: {
    label: 'County',
    max_length: 255,
    placeholder: 'County',
    type: 'text',
  },
  postcode: {
    label: 'Postcode',
    max_length: 255,
    placeholder: 'Postcode',
    type: 'text',
  },
  postcode_lookup: {
    type: 'postcode-lookup',
    autofill_fields: AUTOFILL_FIELDS
  },
  radius: {
    label: 'Territory radius (Miles)',
    type: 'number',
    placeholder: 'Radius (Miles)',
  }
});

type IExternalProps = PermissionHOCChildProps;

export default class Create extends React.PureComponent<IExternalProps, void> {
    public render() {
        return (
            <GenericCreate
                messages={MESSAGES}
                detailFields={DETAIL_FIELDS}
                detailFieldOptions={DETAIL_FIELD_OPTIONS}
                formName={FORM_NAME}
                collectionKey={COLLECTION_URI} />
        );
    }
}
