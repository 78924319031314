import { Record } from 'immutable';
import { itemsFunctor } from '../items/';
import {
  IAdvertRecord,
  IAdvertTypeRecord,
  ICallOutcomeRecord,
  IContactTypeRecord,
  IMedicationRecord,
  IMotivationRecord,
  INamedActiveObjectRecord,
  ISiteRecord,
  IStudyOutcomeRecord,
  ITitleRecord,
  IUserRecord,
} from '../store/data-types';
import {
  IStudyRecord,
} from '../store/data-types/studies';
import { typeToRecordMapping } from './collections';

export interface IItemsState {
  'drivers/motivations': IMotivationRecord | null;
  'drivers/adverts': IAdvertRecord | null;
  'drivers/adverts-types': IAdvertTypeRecord | null;
  'profile-logs/call-outcomes': ICallOutcomeRecord | null;
  'profile-logs/contact-types': IContactTypeRecord | null;
  'studies/study-outcomes': IStudyOutcomeRecord | null;
  'sites': ISiteRecord | null;
  'studies': IStudyRecord | null;
  'users': IUserRecord | null;
  'users/volunteers/user': IUserRecord | null;
  'users/me': IUserRecord | null;
  'medications/class': INamedActiveObjectRecord | null;
  'medications/frequency': INamedActiveObjectRecord | null;
  'medications/manufacturer': INamedActiveObjectRecord | null;
  'medications/unit': INamedActiveObjectRecord | null;
  'medications/generic-name': INamedActiveObjectRecord | null;
  'medications/type': INamedActiveObjectRecord | null;
  'medications/medication': IMedicationRecord | null;
  'users/titles': ITitleRecord | null;
}

export type ItemType = keyof IItemsState;

export interface IItemsStateRecord
  extends Record.Instance<IItemsState>,
    Readonly<IItemsState> {}

export const ItemsStateRecord = Record<IItemsState>({
  'drivers/motivations': null,
  'drivers/adverts': null,
  'drivers/adverts-types': null,
  'profile-logs/call-outcomes': null,
  'profile-logs/contact-types': null,
  'studies/study-outcomes': null,
  'medications/class': null,
  'medications/frequency': null,
  'medications/manufacturer': null,
  'medications/unit': null,
  'medications/generic-name': null,
  'medications/type': null,
  'medications/medication': null,
  'sites': null,
  'studies': null,
  'users/titles': null,
  'users': null,
  'users/volunteers/user': null,
  'users/me': null,
});

export const itemsModule = itemsFunctor(typeToRecordMapping, ItemsStateRecord);
