import { fromJS, List, Map } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from "react-router";
import { routerActions } from 'react-router-redux';
import { formValueSelector } from 'redux-form';
import { UPDATE_USER_DETAILS, updateUserDetails } from "../../../../actions/auth";
import { PermissionHOCChildProps } from '../../../../permissions/permissions-hoc';
import { hasSucceeded } from "../../../../responses";
import { getResponseStatus, IResponseStatus } from '../../../../responses';
import { IStore } from "../../../../store";
import {
  IUserRecord,
  IUserVolunteerMedicationRecord,
  IVolunteerMedicationRecord,
  UserVolunteerMedicationRecord
} from "../../../../store/data-types";
import {
  FormErrors,
  INestedFormErrorsResponse,
  NestedFormErrorsList,
} from '../../../../utils';
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import PageHeader from "../../../page-header";
import EditVolunteerMedicationComponent, { COMPONENT_KEY } from "../form-fields/edit-volunteer-medication-component";

const MESSAGES = {
  edit: "generic.medications"
};

interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

interface IProps extends IExternalProps {
  pushRoute: typeof routerActions.push;
  volunteerId: string;
  userProfile: IUserRecord;
  formErrors: Map<string, List<string>>;
  itemHasSucceeded: boolean;
  updateUserDetails: typeof updateUserDetails;
  medicationEdits: List<IUserVolunteerMedicationRecord>;
}

export class EditVolunteerMedication extends React.PureComponent<IProps, {}> {
  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.itemHasSucceeded && !this.props.itemHasSucceeded) {
      this.props.pushRoute(`/app/`);
    }
  }

  public render() {
    const { formErrors, itemHasSucceeded, volunteerId } = this.props;

    return (
      <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.edit} />} />
        <FormErrorsRenderer formErrors={formErrors} errorKey="medications"/>
        <EditVolunteerMedicationComponent
          volunteerId={volunteerId}
        />
        <button onClick={this.updateUserDetails} className="button primary">
          Save
        </button>
        <Link
          to={`/app/`}
          className="cancel-link"
        >
          Cancel
        </Link>
      </div>
    );
  }

  private updateUserDetails = () => {
    const { userProfile } = this.props;
    const medications = this.props.medicationEdits;
    this.props.updateUserDetails(userProfile.set('medications', medications));
  }
}

function mapStateToProps( store: IStore, props: IExternalProps) {
  const { responses, userProfile } = store;
  const volunteerId = props.params.id;
  const volunteerMedications = store.simpleComponentState.get(COMPONENT_KEY, List());

  const medicationEdits = volunteerMedications.map((med: IVolunteerMedicationRecord) => {
    const selector = formValueSelector(med.id);
    const formValues = selector(
      store,
      'id',
      'total_dosage',
      'medication',
      'frequency',
    );
    return UserVolunteerMedicationRecord(formValues);
  });

  const { formErrors = Map<string, NestedFormErrorsList>() } =
    getResponseStatus(responses, UPDATE_USER_DETAILS) as any as INestedFormErrorsResponse;

  return {
    ...props,
    formErrors: fromJS(formErrors),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_USER_DETAILS),
    userProfile,
    volunteerId,
    medicationEdits
  };
}

export default connect(
  mapStateToProps,
  {
    updateUserDetails,
    pushRoute: routerActions.push
  }
)(EditVolunteerMedication);
