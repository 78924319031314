import { Tab } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from "react-router";
import { IStore } from "../../store";
import { startsWith } from "../../utils";

export interface IExternalProps {
  label: string;
  path: string;
  exactPath?: boolean;
  permitted: boolean;
  pathname: string;
}

export default class TabItem extends React.PureComponent<IExternalProps, void> {
  public render() {
    const { permitted } = this.props;
    return (
      permitted ? (<Tab active={this.isActive()}>
        <Link to={`/app${this.props.path}`}>
          <FormattedMessage id={this.props.label}/>
        </Link>
      </Tab>) : null
    );
  }

  private isActive() {
    if (this.props.exactPath) {
      return this.props.pathname === `/app${this.props.path}`;
    } else {
      return startsWith(this.props.pathname, `/app${this.props.path}`);
    }
  }
}
