import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../actions/modals';
import { saveStudyAndView } from '../../../actions/studies';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { itemsModule } from '../../../reducers/items';
import { IStore } from '../../../store';
import { FormData, IStudyRecord } from '../../../store/data-types/studies';
import { FormErrors } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import PageHeader from '../../page-header';
import StudyDetails from '../details';
import { convertFormDataForServer, convertServerDataForForm } from '../search/utils';
import { getStudyErrors } from '../utils';
import SaveForm from './form';

const { IconTimes } = FontAwesome;
const { actions: { loadItem } } = itemsModule;

interface IStateProps {
  formErrors: FormErrors;
  initialValues: FormData | undefined;
  study: IStudyRecord | null;
}

interface IDispatchProps {
  saveStudyAndView: typeof saveStudyAndView;
  loadItem: typeof loadItem;
  openModal: typeof openModal;
  closeModal: typeof closeModal;
}

type IExternalProps = PermissionHOCChildProps;

interface IOwnProps extends IExternalProps {
  params: {
    id: string;
  };
}

// tslint:disable-next-line:no-unused-variable
type Props = IOwnProps & IStateProps & IDispatchProps;

const MESSAGES = {
  title: 'studies.search_title',
};

export class StudySave extends React.PureComponent<Props, {}> {
  public componentWillMount () {
    const { params: { id }, study } = this.props;

    if (!study) {
      this.props.loadItem('studies', id);
    }
  }

  public render () {
    const { initialValues, study, formErrors, params } = this.props;

    return (
      <div>
        <PageHeader header={study ? study.study_name : <FormattedMessage id={MESSAGES.title} />} />

        <FormErrorsRenderer formErrors={formErrors} errorKey="errors" />

        {
          study && (
            <SaveForm
              params={params}
              onSubmit={this.onSubmit}
              initialValues={initialValues}
              study={study}
            />
          )
        }

        {
          study && (
            <div>
              <hr />
              <StudyDetails study={study} />
            </div>
          )
        }
      </div>
    );
  }

  private onSubmit = (formData: FormData) => {
    const { params: { id }, study } = this.props;
    const formattedData = convertFormDataForServer(formData);

    if (formattedData.active && (!study || !study.active)) {
      this.props.openModal(
        <Modal small onClickOutside={this.props.closeModal}>
          <ModalHeader>
            <ModalCloseIcon>
              <IconTimes className="icon" onClick={this.props.closeModal} />
            </ModalCloseIcon>
            <h1>
              Activate study?
            </h1>
          </ModalHeader>

          <p>
            Are you sure you want to activate this study? Once active you will no longer be able to edit the study
            criteria or medications.
          </p>

          <ModalFooter>
            <SpacedGroup block className="margin-vertical-base">
              <Button onClick={this.props.closeModal}>
                Cancel
              </Button>
              <Button className="primary" onClick={() => {
                this.props.closeModal();
                this.props.saveStudyAndView(id, formattedData);
              }}>
                Activate study
              </Button>
            </SpacedGroup>
          </ModalFooter>
        </Modal>
      );
    } else {
      this.props.saveStudyAndView(id, formattedData);
    }
  }
}

export const mapStateToProps = (state: IStore, props: IOwnProps): IStateProps & IOwnProps => {
  const study = state.items.studies;

  const formErrors = getStudyErrors(state.responses);

  return {
    ...props,
    formErrors,
    study,
    initialValues: study ? convertServerDataForForm(study) : undefined,
  };
}

export default connect(mapStateToProps, {loadItem, saveStudyAndView, openModal, closeModal})(StudySave);
