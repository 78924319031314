import { Well } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from "react";
import { connect, } from 'react-redux';
import ResearcherLogActionsForm, {IFormData} from './researcher-action-form';

import {
  getVolunteerStudyOutcome,
  setVolunteerStudyOutcomeAndFetchProfileLogs,
} from "../../../actions/volunteers";
import { getCollectionByName } from '../../../collections/reducers';
import { STUDY_OUTCOMES_RESEARCHER } from '../../../consts/constants';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IStudyOutcomeRecord, IUserRecord } from '../../../store/data-types';

const { actions: { getCollection } } = collectionsModule;

const STUDY_OUTCOMES_KEY = 'studies/study-outcomes';
const STUDY_OUTCOMES_NAME = 'study_outcomes-researcher';

interface IExternalProps {
  volunteerId: string;
  userProfile: IUserRecord;
}

interface IProps extends IExternalProps {
  getCollection: typeof getCollection;
  getVolunteerStudyOutcome: typeof getVolunteerStudyOutcome;
  setVolunteerStudyOutcomeAndFetchProfileLogs: typeof setVolunteerStudyOutcomeAndFetchProfileLogs;
  canSetOutcome: boolean;
  studyOutcomes: List<IStudyOutcomeRecord>;
}

export class ResearcherLogActions extends React.PureComponent<IProps, {}> {

  public componentWillMount() {
    this.props.getVolunteerStudyOutcome(this.props.volunteerId);
    return this.props.getCollection(
      STUDY_OUTCOMES_KEY,
      {filters: Map({type: STUDY_OUTCOMES_RESEARCHER.join()})},
      STUDY_OUTCOMES_NAME,
    );
  }

  public render() {
    const {canSetOutcome, studyOutcomes, userProfile} = this.props;
    if (!canSetOutcome) {
      return null;
    }
    return(
      <Well className="dark">
        <ResearcherLogActionsForm onSubmit={this.onSubmit} studyOutcomes={studyOutcomes}/>
      </Well>
    );
  }

  public onSubmit = (data: IFormData) => {
    this.props.setVolunteerStudyOutcomeAndFetchProfileLogs(this.props.volunteerId, data.study_outcome);
  }
}

function mapStateToProps (state: IStore, props: IExternalProps) {
  const { collections, researcherStudyOutcome } = state;

  const { results } = getCollectionByName<any>(
    collections[STUDY_OUTCOMES_KEY],
    STUDY_OUTCOMES_NAME
  );

  return {
    ...props,
    studyOutcomes: results,
    canSetOutcome: researcherStudyOutcome.get('can_set_study_outcome', false)
  }
}

export default connect(mapStateToProps, {
  getCollection,
  getVolunteerStudyOutcome,
  setVolunteerStudyOutcomeAndFetchProfileLogs
})(ResearcherLogActions);
