import {fromJS} from 'immutable';
import * as React from 'react';

import GenericList from '^/components/chadmin/generic-list';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_ROUTE = '${id}';

const LIST_COLUMNS = fromJS([
  { name: ['trade_name'], display_name: 'Trade name', type: 'detail', route: DETAIL_ROUTE, sortable: false },
  { name: ['manufacturer_display', 'name'], display_name: 'Manufacturer', type: 'string', sortable: false },
  { name: ['generic_name_display', 'name'], display_name: 'Generic Name', type: 'string', sortable: false },
  { name: ['active'], display_name: 'Is active?', type: 'boolean', sortable: false }
]);

const MESSAGES = {
  title: 'medication.list.title',
  create: 'medication.list.create'
};

const COLLECTION_NAME = 'MEDICINE_MEDICATION_LIST';

// tslint:disable-next-line:no-unused-variable
type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, void> {
  public render() {
    return (
      <GenericList
        canCreate={this.props.permissions.canCreate}
        messages={MESSAGES}
        collectionColumns={LIST_COLUMNS}
        collectionName={COLLECTION_NAME}
        collectionKey={'medications/medication'}
        defaultFilters={fromJS({include_inactive: false})}/>
    );
  }
}
