import { List, Map, Record } from 'immutable';

import * as collections from '../collections/actions';
import {
  IAction,
  RecordInstance,
  TTypeToRecordMapping,
} from '../store/data-types';
import {
  Collection,
} from './constructors';
import {
  ICollection,
  IGenericCollectionState,
  TCollectionConstructor,
  TStateRecord,
} from './types';

export function getCollectionByName<T>(
  stateRecord: TStateRecord<T>,
  collectionName?: string
): ICollection<T> {
  return stateRecord.get(collectionName) || {
    count: 0,
    page: 1,
    results: List<T>()
  };
}

export function getCollectionItems<T> (
  stateRecord: TStateRecord<T>,
  collectionName?: string
): List<T> {
  return getCollectionByName(stateRecord, collectionName).results;
}

function updateCollectionItemsFromResponse<T>(
  collectionData: TStateRecord<T>,
  action: IAction<any, any>,
  itemConstructor: (data: {}) => T,
): TStateRecord<T> {
    const { collectionName, filters, shouldAppend, ordering, reverseOrdering, page } = action.meta;
    const { count, next, results } = action.payload;

    const oldCollectionItems = (getCollectionItems(collectionData, collectionName) || List()).toJS();
    const newCollectionItems = (results && shouldAppend && oldCollectionItems) ?
      oldCollectionItems.concat(results) :
      results;
    const newCollectionResults = List(newCollectionItems).map((each) => itemConstructor(each));
    const newCollection = Collection({
      count,
      filters,
      next,
      ordering,
      page,
      results: newCollectionResults,
      reverseOrdering
    });

    return collectionData.set(collectionName, newCollection);
}

export function setCollectionFromResponseAction<T extends IGenericCollectionState> (
  state: RecordInstance<T>,
  action: IAction<any, any>,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const collectionType = action.meta.tag;
  if (collectionType in typeToRecordMapping) {
    const recordBuilder = typeToRecordMapping[collectionType];
    return state.set(
      collectionType,
      updateCollectionItemsFromResponse(state.get(collectionType), action, recordBuilder)
    );
  }
  return state;
}

function _addCollectionItem<T>(
  stateRecord: TStateRecord<T>,
  collectionName: string | undefined,
  item: T
): TStateRecord<T> {
  const oldItems = getCollectionItems(stateRecord, collectionName) || List();
  const newItems = oldItems.push(item);
  const oldCollection = getCollectionByName(stateRecord, collectionName);
  const newCollection = Collection({
    count: oldCollection ? oldCollection.count + 1 : 1,
    next: oldCollection && oldCollection.next,
    results: newItems
  });
  return stateRecord.set(collectionName, newCollection);
}

export function addCollectionItem<T extends IGenericCollectionState>(
  state: RecordInstance<T>,
  action: IAction<any, any>,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const collectionType = action.meta.tag;
  const data = action.payload;
  const { collectionName } = action.meta;
  if (collectionType in typeToRecordMapping) {
    const recordBuilder = typeToRecordMapping[collectionType];
    return state.set(
      collectionType,
      _addCollectionItem(state.get(collectionType), collectionName, recordBuilder(data))
    );
  }
  return state;
}

function _deleteCollectionItem(
  stateRecord: TStateRecord<any>,
  collectionName: string | undefined,
  itemId: string
): TStateRecord<any> {
    const oldItems = getCollectionItems(stateRecord, collectionName) || List();
    const newItems = oldItems.filterNot((each) => each.id === itemId);

    const oldCollection = getCollectionByName(stateRecord, collectionName);
    const newCollection = Collection({
      count: oldCollection ? oldCollection.count - 1 : 0,
      next: oldCollection && oldCollection.next,
      results: newItems
    });
    return stateRecord.set(collectionName, newCollection);
}

export function deleteCollectionItem<T extends IGenericCollectionState>(
  state: RecordInstance<T>,
  action: IAction<any, any>,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const collectionType = action.meta.tag;
  const { collectionName, itemId } = action.meta;

  if (collectionType in typeToRecordMapping) {
    return state.set(
      collectionType,
      _deleteCollectionItem(state.get(collectionType), collectionName, itemId)
    );
  }
  return state;
}

function _clearCollection(
  stateRecord: TStateRecord<any>,
  collectionName: string | undefined,
): TStateRecord<any> {
  const newCollection = Collection({
    count: 0,
    next: undefined,
    results: List()
  });
  return stateRecord.set(collectionName, newCollection);
}

export function clearCollection<T extends IGenericCollectionState>(
  state: RecordInstance<T>,
  action: IAction<any, any>,
  typeToRecordMapping: TTypeToRecordMapping<T>
): RecordInstance<T> {
  const collectionType = action.payload.type;
  const { collectionName } = action.payload;
  const newState = state.get(collectionType);

  if (collectionType in typeToRecordMapping) {
    return state.set(
      collectionType,
      _clearCollection(newState, collectionName)
    );
  }
  return state;
}
