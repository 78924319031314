import { Button, FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';

interface IProps {
  handleSubmit: React.EventHandler<React.FormEvent<HTMLFormElement>>;
  isLoading: boolean;
  fields: object;
}

export class AdminListSearch extends React.PureComponent<IProps, undefined> {
  public render() {
    const { isLoading, handleSubmit } = this.props;
    return (
      <div>
        {
          <FormGroup block className="float-right">
            <form onSubmit={handleSubmit}>
              <span className="admin-list-search-container pull-right">
                <Field
                  name="search"
                  component="input"
                  type="search"
                  placeholder="Search for..."
                  disabled={isLoading}
                />
                <Button className="primary" type="submit">
                  Search
                </Button>
              </span>
            </form>
          </FormGroup>
        }
      </div>
    );
  }
}

export default reduxForm({
  form: 'search',
})(AdminListSearch as any);
