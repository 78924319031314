import { Button, Column, Row } from '@dabapps/roe';
import { fromJS, List, Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { addItemToCollectionAndClearForm } from '../../actions/collections';
import { ADD_TO_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../collections/types';
import {
  LOAD_ITEM,
  UPDATE_ITEM,
} from '../../items/actions';
import { collectionsModule, ICollectionsState, } from '../../reducers/collections';
import { IItemsState, itemsModule, ItemType } from '../../reducers/items';
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  IResponseStatus,
  isPending
} from '../../responses';
import { IStore } from "../../store";
import {IChadminCreateMessages, IMotivation, IMotivationRecord} from '../../store/data-types/index';
import AdminEditCreate from '../chadmin/edit-create/AdminEditCreate';
import PageHeader from '../page-header';
import CollectionTable from '../tables/collection-table';
import { TItem } from './types';

const { actions: { addToCollection, addItem } } = collectionsModule;

interface IExternalProps {
  messages: IChadminCreateMessages;
  collectionKey: keyof ICollectionsState;
  formName: string;
  detailFields: List<string>;
  onCancelLinkTo?: string;
  detailFieldOptions: Map<string, any>;
  defaultValues?: {[i: string]: any};
}

interface IProps extends IExternalProps {
  addItemToCollectionAndClearForm: typeof addItemToCollectionAndClearForm;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Map<string, any> | undefined;
}

export class GenericCreate extends React.PureComponent<IProps, void> {

  public constructor(props: IProps) {
    super(props);
    this.createItem = this.createItem.bind(this);
  }

  public render () {
    const {
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      onCancelLinkTo,
    } = this.props;

    return (
      <div>
        <PageHeader header={<FormattedMessage id={this.props.messages.create}/>} />
        <AdminEditCreate
          defaultValues={this.props.defaultValues}
          createItem={this.createItem}
          fields={this.props.detailFields}
          itemOptions={this.props.detailFieldOptions}
          formName={this.props.formName}
          itemErrors={errors}
          itemHasFailed={itemHasFailed}
          itemHasSucceeded={itemHasSucceeded}
          itemIsPending={itemIsPending}
          onCancelLinkTo={onCancelLinkTo}
        />
      </div>
    );
  }

  private createItem(data: Partial<IMotivation>) {
    this.props.addItemToCollectionAndClearForm(
      this.props.collectionKey,
      data,
      this.props.formName,
      this.props.collectionKey);
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { responses } = state;
  return {
    errors: getFormErrors(responses, ADD_TO_COLLECTION, props.collectionKey),
    itemHasFailed: hasFailed(responses, ADD_TO_COLLECTION, props.collectionKey),
    itemHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, props.collectionKey),
    itemIsPending: isPending(responses, ADD_TO_COLLECTION, props.collectionKey),
  };
}

export default connect(mapStateToProps, { addItemToCollectionAndClearForm })(GenericCreate);
