import {
  Button,
  Column,
  Container,
  DabIpsum,
  FormGroup,
  Row,
  SpacedGroup,
} from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';

export default class StyleGuide extends React.PureComponent<void, void> {
  public render() {
    return (
      <Container>
        <Row>
          <Column xs={12}>
            <SpacedGroup>
              <Button className="primary">New Volunteer</Button>
              <Button disabled className="primary">New Volunteer</Button>
            </SpacedGroup>
          </Column>
          <Column xs={12}>
            <SpacedGroup>
              <Button className="secondary">New Volunteer</Button>
              <Button disabled className="secondary">New Volunteer</Button>
            </SpacedGroup>
          </Column>
        </Row>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <p>Standard Text</p>
        <p>
          <FontAwesome.IconCalendar className="icon-small"/>
          <FontAwesome.IconCalendar className="icon-large"/>
        </p>
        <Row>
          <Column xs={12}>
            <FormGroup block>
              <label>Label</label>
              <input type="text" />
            </FormGroup>
            <FormGroup>
              <label>Label</label>
              <input type="text" />
              <input type="text" />
            </FormGroup>
          </Column>
        </Row>
        <div className="panel">
          <h2>Panel</h2>
          <DabIpsum component="p" />
        </div>
        <div className="card">
          <DabIpsum component="p" />
        </div>
      </Container>
    );
  }
}
