import {
  TableCell,
  TableRow,
} from '@dabapps/roe';
import * as classNames from 'classnames';
import { Set } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { openModal } from '^/actions/modals';
import Select from '^/components/forms/select';
import ResearchersModal from '^/components/studies/search/sites-selection/researchers-modal';
import { VolunteerCounts } from '^/reducers/studies';
import { LabelValueOptions } from '^/store/data-types';
import { SiteFormData } from '^/store/data-types/studies';

interface IDispatchProps {
  openModal: typeof openModal;
}

interface IOwnProps {
  siteKey: string;
  siteName: string;
  site: SiteFormData;
  volunteerCounts: VolunteerCounts;
  clinicalResearchersChoices: LabelValueOptions;
  removeSite(id: string): void;
  updateSite(id: string, value: any): void;
}

type Props = IOwnProps & IDispatchProps;

export class Site extends React.PureComponent<Props, {}> {
  private onChangeGreenLit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  private onChangeCRA: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  private onChangeSiteNumber: (event: React.ChangeEvent<HTMLInputElement>) => void;

  public constructor (props: Props) {
    super(props);

    this.onChangeGreenLit = (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeCheckbox('green_lit', event);
    this.onChangeCRA = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('cra', event);
    this.onChangeSiteNumber = (event: React.ChangeEvent<HTMLInputElement>) => this.onChange('site_number', event);
  }

  public render () {
    const {
      siteName,
      site,
      site: {
        researchers = [],
        site_number = '',
        is_confirmed_site: disabled
      },
      clinicalResearchersChoices,
      volunteerCounts
    } = this.props;

    const clinicalResearchersChoice = clinicalResearchersChoices.filter((item) => item.value === site.cra)[0];

    return (
      <TableRow>
        <TableCell>
          <span className={classNames({disabled})}>{siteName}</span>
        </TableCell>
        <TableCell>
          <input
            disabled={disabled}
            style={{width: 60}}
            type="number"
            min="0"
            value={site_number}
            onChange={this.onChangeSiteNumber}
          />
        </TableCell>
        <TableCell>
          <input
            disabled={disabled}
            type="checkbox"
            checked={site.green_lit || false}
            onChange={this.onChangeGreenLit} />
        </TableCell>
        <TableCell>
          {disabled ?
            <span className={classNames({disabled})}>
              {clinicalResearchersChoice && clinicalResearchersChoice.label}
            </span>
            :
            <Select
              placeholder="Select a CRA..."
              value={site.cra}
              onChange={this.onChangeCRA}
              options={clinicalResearchersChoices}
            />
          }
        </TableCell>
        <TableCell>
          {typeof researchers.length === 'number' &&
            <span>{researchers.length}{' '}</span>
          }
          <a onClick={this.onClickEditSiteResearchers}>
            Edit
          </a>
        </TableCell>
        <TableCell>
          <span className={classNames({disabled})}>{volunteerCounts.get(site.id, '--')}</span>
        </TableCell>
        <TableCell>
          {!disabled &&
            <a
              onClick={this.onClickRemove}
              className="font-size-small"
            >
              Remove
            </a>
          }
        </TableCell>
      </TableRow>
    );
  }

  private onClickEditSiteResearchers = () => {
    const {
      openModal,
      siteName,
      site: {
        site,
        researchers = Set<string>()
      },
    } = this.props;

    openModal(
      <ResearchersModal
        selectedResearchers={Set(researchers)}
        onDone={this.onChangeResearchers}
        siteName={siteName}
        siteId={site}
      />
    );
  }

  private onChangeResearchers = (researchers: string[]) => {
    this.props.updateSite(
      this.props.siteKey,
      {
        ...this.props.site,
        researchers,
      }
    )
  }

  private onChangeCheckbox = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateSite(
      this.props.siteKey,
      {
        ...this.props.site,
        [key]: event.target.checked
      }
    )
  }

  private onChange = (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.props.updateSite(
      this.props.siteKey,
      {
        ...this.props.site,
        [key]: event.target.value
      }
    );
  }

  private onClickRemove = () => {
    const { siteKey } = this.props;

    this.props.removeSite(siteKey);
  }
}

export { Site as UnconnectedSite };

export default connect<{}, IDispatchProps, IOwnProps>(undefined, { openModal })(Site);
