'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconVolumeOff (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconVolumeOff'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconVolumeOff'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(768.81, 1791.82) scale(1, -1)',
          d: 'M768 1184v-1088q0 -26 -19 -45t-45 -19t-45 19l-333 333h-262q-26 0 -45 19t-19 45v384q0 26 19 45t45 19h262l333 333q19 19 45 19t45 -19t19 -45z'
        }
      )
    );
  };

})();
