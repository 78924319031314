import defaultPermissions, { IAllPermission } from './default-permissions'
// QA Permissions
const permissions: IAllPermission = {
  ...defaultPermissions,
  sites: {
    hasAccess: true,
    customProps: {
      canCreate: false,
      canEdit: false
    }
  },
  sites_create: {
    hasAccess: false,
    customProps: {}
  },
  sites_edit: {
    hasAccess: false,
    customProps: {}
  },
  studies: {
    hasAccess: true,
    customProps: {
      canCreate: false,
      canRemove: false
    }
  },
  studies_search: {
    hasAccess: false,
    customProps: {}
  },
  studies_view: {
    hasAccess: true,
    customProps: {
      canEdit: false,
      isAnonymized: true
    }
  },
  studies_save: {
    hasAccess: false,
    customProps: {}
  },
  studies_edit: {
    hasAccess: false,
    customProps: {}
  },
  home: {
    hasAccess: true,
    customProps: {
      canDownloadVolunteerDiallerCsv: true,
      canViewQaStatsPanel: true,
    }
  },
  volunteers: {
    hasAccess: true,
    customProps: {
      canDownloadVolunteerCsv: true,
      canConfirmHandoverConsent: true,
    }
  },
  users: {
    hasAccess: true,
    customProps: {
      canCreate: true,
    }
  },
  users_create: {
    hasAccess: true,
    customProps: {}
  },
  users_edit: {
    hasAccess: true,
    customProps: {}
  },
  reports_recruitment_flow: {
    hasAccess: true,
    customProps: {}
  },
};

export default permissions;
