import { Action, Dispatch, Store } from 'redux';
import { ThunkAction } from 'redux-thunk';

export interface IAnyAction extends Action {
  [i: string]: any;
}

type AnyOrThunkAction<T> = IAnyAction | ThunkAction<any, T, any>;

type Callback<T> = (action: IAnyAction, state: T) => any;

interface ISubscriber<T> {
  type: any;
  callback: Callback<T>;
}

const subscribers: Array<ISubscriber<any>> = [];

export const subscribe = <T>(type: any, callback: Callback<T>) => {
  const subscriber = {
    type,
    callback,
  };

  subscribers.push(subscriber);

  return () => {
    const index = subscribers.indexOf(subscriber);

    if (index >= 0) {
      subscribers.splice(index, 1);
    }
  };
};

export const middleware = <T>(store: Store<T>) =>
  (next: Dispatch<T>) =>
  (action: AnyOrThunkAction<T>) => {

  const resolvedAction: IAnyAction = typeof action === 'function' ?
    next(action(store.dispatch, store.getState, undefined)) :
    next(action);

  subscribers.forEach((subscriber) => {
    if (subscriber.type === resolvedAction.type) {
      subscriber.callback(resolvedAction, store.getState());
    }
  });
};
