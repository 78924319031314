import { List } from 'immutable';
import * as React from 'react';
import { Field } from 'redux-form';

interface IExternalProps {
  name: string;
  className?: string;
  value?: string;
  placeholder?: string;
  options: List<any>;
  getName: (option: any) => string;
  getValue: (option: any) => string;
  onChange?: (inputEvent: any) => void;
}

export default class Select extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const {
      value = null,
      placeholder
    } = this.props;
    return (
      <Field
        onChange={this.props.onChange}
        component="select"
        name={this.props.name}
        value={value}
        className={this.props.className}>
        <option key="placeholder" value="">{placeholder}</option>
        {this.props.options.map((option: any) => {
          const optVal = this.props.getValue(option);
          return (<option key={optVal} value={optVal}>{this.props.getName(option)}</option>);
        })}
      </Field>
    )
  }
}
