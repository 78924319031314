import { Column, FormGroup, Row } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { Field } from 'redux-form';
import { getCollectionByName } from "../../../../collections/reducers";
import { collectionsModule } from "../../../../reducers/collections";
import { IStore } from '../../../../store';
import { transformIdNameToValueLabel } from "../../../../utils";
import Select from '../../../forms/select';

const { actions: { getCollection } } = collectionsModule;

interface IExternalProps {
  id: string;
  motivation: string;
  removeMotivation(id: string): void;
  updateMotivation(id: string, value: string): void;
}

interface IProps extends IExternalProps {
  motivationOptions: any;
  getCollection: typeof getCollection;
}

export class Motivation extends React.PureComponent<IProps, void> {
  public constructor (props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public render () {
    const { motivation, id, motivationOptions } = this.props;

    return (
      <Row>
        <Column md={10}>
          <FormGroup>
            <label><FormattedMessage id="generic.motivation" /></label>
            <Select
              value={motivation}
              placeholder="Select..."
              options={motivationOptions}
              onChange={this.onChange}
            />
          </FormGroup>
        </Column>
        <Column md={2}>
          <FormGroup block>
            <a className="font-size-small" onClick={this.removeMotivation}>
              <FormattedMessage id="generic.remove" />
            </a>
          </FormGroup>
        </Column>
      </Row>
    );
  }

  private onChange = (event: any) => {
    this.props.updateMotivation(
      this.props.id,
      event.target.value
    );
  }

  private removeMotivation = () => {
    const { id, removeMotivation } = this.props;
    removeMotivation(id);
  }

  private loadAllOptions() {
    this.props.getCollection(
      "drivers/motivations",
      { pageSize: 150 },
      "drivers/motivations"
    );
  }
}

export const mapStateToProps = (state: IStore, props: IExternalProps) => {
  const { id } = props;
  const { collections, responses } = state;

  const motivations = getCollectionByName(
    collections["drivers/motivations"],
    "drivers/motivations"
  ).results

  return {
    ...props,
    motivationOptions: transformIdNameToValueLabel(motivations),
  };
}

export default connect(
  mapStateToProps,
  {
    getCollection
  }
)(Motivation);
