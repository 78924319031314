import { Alert } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { FontAwesome } from "react-inline-icons";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import * as Select from "react-select";
import { UPDATE_USER_DETAILS, updateUserDetails } from "../../../../actions/auth";
import { getCollectionByName } from "../../../../collections/reducers";
import { PermissionHOCChildProps } from '../../../../permissions/permissions-hoc';
import { collectionsModule } from "../../../../reducers/collections";
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  IResponseStatus,
  isPending
} from "../../../../responses";
import { IStore } from "../../../../store";
import {
  IAdvertTypeRecord,
  IUserRecord,
  IVolunteerRecord,
  VolunteerRecord
} from "../../../../store/data-types";
import { filterEmptyStringsFromList, transformIdNameToValueLabel } from "../../../../utils";
import {
  flattedNestedFormErrors,
  FormErrors,
  INestedFormErrorsResponse,
  NestedFormErrorsList
} from '../../../../utils';
import FieldSelect from "../../../chadmin/edit-create/dynamic-forms/field-types/FieldSelect";
import GenericDetail from "../../../chadmin/generic-detail";
import PageHeader from "../../../page-header";
import { IDataProps } from "../form-fields/edit-drivers-details-form-fields";
import AdvertForm, { IFormData } from './edit-drivers-details-form';
const { actions: { getCollection } } = collectionsModule;
const { IconLightbulbO } = FontAwesome;

const MESSAGES = {
  edit: "me.drivers"
};

type IExternalProps = PermissionHOCChildProps;

interface IProps extends IExternalProps {
  advertsTypes: IDataProps[];
  volunteer: IVolunteerRecord;
  item: IUserRecord;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Map<string, List<string>>;
  motivationErrors: Map<string, List<string>>;
  updateUserDetails: typeof updateUserDetails;
  getCollection: typeof getCollection;
  initialValues: IFormData;
  pushRoute: typeof routerActions.push;
}

export class Detail extends React.PureComponent<IProps, {}> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this)
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.itemHasSucceeded && !this.props.itemHasSucceeded) {
      this.props.pushRoute(`/app/`);
    }
  }

  public render() {
    const {advertsTypes, itemHasSucceeded, itemHasFailed, ...rest} = this.props;
    return (
      <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.edit} />} />
        { itemHasFailed && <Alert className="error">
          <p><FormattedMessage id='generic.update_fail' /></p>
        </Alert>}
        <AdvertForm
          {...rest}
          advertTypesOptions={advertsTypes}
          onSubmit={this.submit}
        />
        <p>
          <IconLightbulbO className="icon-medium-large" />Reason that makes
          sense to user why we store this.......
        </p>
      </div>
    );
  }

  private submit(data: IFormData) {
    const { motivations, ...remainingVolunteer } = data.volunteer;
    const { item } = this.props;
    let { volunteer } = this.props;
    if (motivations) {
      volunteer = volunteer.set('motivations', filterEmptyStringsFromList(motivations));
    }
    this.props.updateUserDetails(
      item.set('volunteer', volunteer.merge({...remainingVolunteer}))
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      "drivers/adverts-types",
      { pageSize: 150 },
      "drivers/adverts-types"
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { collections, items, responses, userProfile } = state;
  const volunteer = userProfile && userProfile.volunteer ? userProfile.volunteer : VolunteerRecord();
  const { advert_source, motivations } = volunteer;

  const { formErrors = Map<string, NestedFormErrorsList>() } =
  getResponseStatus(responses, UPDATE_USER_DETAILS) as any as INestedFormErrorsResponse;

  return {
    ...props,
    advertsTypes: transformIdNameToValueLabel(getCollectionByName(
      collections["drivers/adverts-types"],
      "drivers/adverts-types"
    ).results),
    errors: getFormErrors(responses, UPDATE_USER_DETAILS),
    motivationErrors: flattedNestedFormErrors(formErrors, 'motivations'),
    item: userProfile,
    itemHasFailed: hasFailed(responses, UPDATE_USER_DETAILS),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_USER_DETAILS),
    itemIsPending: isPending(responses, UPDATE_USER_DETAILS),
    initialValues: {
      volunteer: {
        advert_type: (volunteer && volunteer.advert_source_display &&
          volunteer.advert_source_display.ad_type_display &&
          volunteer.advert_source_display.ad_type_display.id) || '',
        advert_source,
        motivations
      }
    },
    volunteer
  };
}

export default connect(mapStateToProps, {
  getCollection,
  updateUserDetails,
  pushRoute: routerActions.push
})(Detail);
