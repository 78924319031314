import {
  convertValueAndUnitToString,
} from '^/components/volunteers/utils';
import { OrderedMap } from "immutable";
import * as moment from "moment";
import * as React from "react";
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import {
  DIABETES_TYPE,
  GENDER,
  HBA1C_RESULT_UNIT,
  transformMonthAndYearToDateDisplay,
} from '../../../consts/constants';
import { LOAD_ITEM } from '../../../items/actions';
import { IPermissionsProps } from '../../../permissions/config/default-permissions';
import { itemsModule } from '../../../reducers/items';
import { isPending } from '../../../responses';
import { IStore } from "../../../store";
import { IUserRecord, IVolunteerRecord } from '../../../store/data-types';
import {
  formatAddress,
  formatDate,
} from '../../../utils';
import Loading from '../../chadmin/common/Loading';
import VolunteerMedicationsList from './volunteer-medications-list';
import ProfileSection from "./volunteer-profile-section";

const { actions: { loadItem } } = itemsModule;
const { IconUserCircleO, IconCommentO, IconPlusSquare } = FontAwesome;

interface IExternalProps {
  params: {
    id: string;
  };
  permissions: Partial<IPermissionsProps>;
}

interface IProps extends IExternalProps {
  item: IUserRecord;
  loading: boolean;
  loadItem: typeof loadItem;
}

export function createDriversMap(volunteer: IVolunteerRecord, includeMotivations: boolean) {
  let driversMap = OrderedMap({'Advertisement type': '-', 'Advertisement': '-'});
  if (volunteer.advert_source_display && volunteer.advert_source_display.ad_type_display) {
    driversMap = driversMap.set("Advertisement type", volunteer.advert_source_display.ad_type_display.name || '-');
  }
  if (volunteer.advert_source_display) {
    driversMap = driversMap.set("Advertisement", volunteer.advert_source_display.name || '-');
  }
  if (includeMotivations && volunteer.motivations_display) {
    driversMap = driversMap.set("Motivations",
      volunteer.motivations_display.map((record) => record.name).join(', ') || '-');
  }
  return driversMap;
}

export function createPersonalDetailsMap(item: IUserRecord, isAnonymized: boolean | undefined) {
  if (item.volunteer) {
    return (isAnonymized ?
      OrderedMap({
        "Availability (not visible to volunteer)": item.volunteer.availability || '-',
        "Comments (not visible to volunteer)": item.volunteer.comments || '-'
      }) :
      OrderedMap({
        "Name": `${item.first_name} ${item.last_name}` || '-',
        "Email": item.email || '-',
        "Address": formatAddress([
          item.volunteer.address_1,
          item.volunteer.address_2,
          item.volunteer.address_3,
          item.volunteer.city,
          item.volunteer.postcode,
          item.volunteer.county,
        ]) || '-',
        "Telephone": item.volunteer.telephone_numbers.map((telephone) => telephone.telephone).join(', ') || '-',
        "Date of birth": item.volunteer.birth_date ? formatDate(moment.utc(item.volunteer.birth_date)) : '-',
        "Gender": GENDER.get(item.volunteer.gender, '-'),
        "Availability (not visible to volunteer)": item.volunteer.availability || '-',
        "Comments (not visible to volunteer)": item.volunteer.comments || '-'
      })
    );
  } else {
    return OrderedMap({
      Name: `${item.first_name} ${item.last_name}` || '-',
      Email: item.email || '-',
    });
  }
}

export class ViewVolunteerDetails extends React.PureComponent<IProps, void> {
  public componentWillMount() {
    const { id } = this.props.params;
    this.props.loadItem('users/volunteers/user', id, false, `users/volunteers/${id}/user`);
  }

  public render() {
    const { loading } = this.props;

    if (loading) {
      return <Loading />;
    } else {
      return this.renderItem();
    }
  }

  private renderItem() {
    const { item, permissions } = this.props;

    if (item && item.volunteer) {
      return <div>
        <ProfileSection
          title="Personal Details"
          icon={<IconUserCircleO className="icon-medium-large"/>}
          data={createPersonalDetailsMap(item, permissions.isAnonymized)}
        />
        < ProfileSection
          title="Drivers"
          icon={<IconCommentO className="icon-medium-large"/>}
          data={createDriversMap(item.volunteer, true)}
        />
        <ProfileSection
          title="Medical Details"
          icon={<IconPlusSquare className="icon-medium-large"/>}
          data={OrderedMap({
            "Diabetes type": DIABETES_TYPE.get(item.volunteer.diabetes_type, '-'),
            "Diagnosed": transformMonthAndYearToDateDisplay(
              item.volunteer.month_diagnosed, item.volunteer.year_diagnosed
            ),
            "Last HbA1c result": `${item.volunteer.display_hbA1c}
              ${HBA1C_RESULT_UNIT.get(item.volunteer.hbA1c_result_display_unit, '')}`,
            "Height": convertValueAndUnitToString(item.volunteer.display_height, item.volunteer.height_display_unit),
            "Weight": convertValueAndUnitToString(item.volunteer.display_weight, item.volunteer.weight_display_unit),
            "BMI": `${item.volunteer.bmi}`
          })}
        />
        <VolunteerMedicationsList id={item.volunteer.id}/>
      </div>;
    }
    return <div>Failed to load item.</div>;
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { items, responses } = state;

  return {
    item: items.get("users/volunteers/user"),
    loading: isPending(responses, LOAD_ITEM, "users/volunteers/user"),
  };
}

export default connect(mapStateToProps, { loadItem })(ViewVolunteerDetails);
