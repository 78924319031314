const MATCHES_TRUTHY_ENV_VARIABLE = /^true$/i;

function cssLivereload() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'http://localhost:35729/livereload.js?snipver=1';

  document.getElementsByTagName('head')[0].appendChild(script);
}

const { CSS_LIVERELOAD } = process.env;

if (CSS_LIVERELOAD && MATCHES_TRUTHY_ENV_VARIABLE.test(CSS_LIVERELOAD)) {
  cssLivereload();
}
