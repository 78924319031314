import { List } from "immutable";
import * as React from "react";
import { FormattedMessage } from "react-intl"
import { Link } from 'react-router';
import { FormProps, reduxForm } from 'redux-form';
import { PermissionHOCChildProps } from '../../../../permissions/permissions-hoc';
import { ITelephoneRecord, IUserRecord } from "../../../../store/data-types";
import { disableSubmitOnEnter, FormErrors } from "../../../../utils";
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import EditPersonalDetailsFormFields from '../form-fields/edit-personal-details-form-fields';

import { Button, FormGroup } from '@dabapps/roe';

const FORM_NAME = 'personal-details-form';

export interface IFormData {
  title: string,
  first_name: string,
  last_name: string,
  email: string,
  volunteer: {
    gender: string,
    birth_date: string,
    telephone_numbers: List<ITelephoneRecord>,
    address_1: string,
    address_2: string,
    address_3: string,
    city: string,
    county: string,
    postcode: string,
  }
}

interface IExternalProps extends PermissionHOCChildProps {
  item: IUserRecord;
  itemIsPending: boolean;
  errors?: FormErrors | null;
  initialValues: IFormData;
  titleChoices: Array<{value: string, label: string}>;
  telephoneErrors: any;
  onSubmit(data: IFormData): void;
}

type IProps = FormProps<IFormData, void, void> & IExternalProps

class EditPersonalDetailsForm extends React.PureComponent<IProps, {}> {
  public render() {
    const { item, handleSubmit, errors, itemIsPending, titleChoices, telephoneErrors, permissions } = this.props;
    return (
      <form onSubmit={handleSubmit} onKeyDown={disableSubmitOnEnter}>
        <EditPersonalDetailsFormFields
          item={item}
          telephoneErrors={telephoneErrors}
          errors={errors}
          titleChoices={titleChoices}
          permissions={permissions}
          formName={FORM_NAME}
        />
        <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors"/>
        <FormGroup className="form-controls">
          <Button type="submit" className="button primary" disabled={itemIsPending}>
            <FormattedMessage id='generic.save' />
          </Button>
          <Link to="/app/" className="cancel-link">
            <FormattedMessage id='generic.cancel'/>
          </Link>
        </FormGroup>
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME
})(EditPersonalDetailsForm);
