import { FormGroup } from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { ITelephoneRecord, TelephoneRecord } from '../../../../store/data-types';
import { IFormArrayComponentProps } from '../../../forms/form-array';
import TelephoneNumber from './telephone-number';

export default class TelephoneNumbers extends React.PureComponent<IFormArrayComponentProps<ITelephoneRecord> , {} > {
  public componentDidMount() {
    const { items } = this.props;
    if (!items.length) {
      this.props.addItem(null, TelephoneRecord({id: uuid()}));
    }
  }

  public render () {
    const { items } = this.props;
    return (
      <div>
        {
          items.map(({id, value}) => (
            <TelephoneNumber
              key={id}
              telephoneId={id}
              telephone={value}
              updateTelephone={this.onUpdateItem}
              removeTelephone={this.onRemoveItemClick}
            />
          ))
        }
        <FormGroup noLabel className="font-size-small margin-top-none margin-bottom-large">
          <a className='button small primary' onClick={this.onAddItemClick}>
            <FormattedMessage id="generic.add_telephone" />
          </a>
        </FormGroup>
      </div>
    );
  }

  private onAddItemClick = () => {
    this.props.addItem(null, TelephoneRecord({id: uuid()}));
  }

  private onUpdateItem = (id: string, value: ITelephoneRecord) => {
    this.props.onChange(id, value);
  }

  private onRemoveItemClick = (id: string) => {
    this.props.removeItem(id);
  }
}
