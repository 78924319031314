import { Column, Container, FormGroup, Row } from '@dabapps/roe';
import { List, Map } from "immutable";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Field, FormProps, FormSection } from "redux-form";

import { GENDER_CHOICES } from "../../../../consts/constants";
import { IPermissionsProps } from "../../../../permissions/config/default-permissions";
import { IUserRecord } from "../../../../store/data-types";
import { FormErrors } from "../../../../utils";
import DateInput from "../../../forms/date-input";
import FormArray from '../../../forms/form-array';
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import PostcodeLookup, { TAddressAutoFillFieldMap } from '../../../forms/postcode-lookup';
import { ReactSelectWrapper } from "../../../forms/react-select-wrapper";
import TelephoneNumbers from "./telephone-numbers";

const AUTOFILL_FIELDS: TAddressAutoFillFieldMap = {
  address_1: 'volunteer.address_1',
  address_2: 'volunteer.address_2',
  address_3: 'volunteer.address_3',
  city: 'volunteer.city',
  county: 'volunteer.county',
  postcode: 'volunteer.postcode',
};

interface IExternalProps {
  item: IUserRecord;
  errors?: FormErrors | null;
  titleChoices: Array<{value: string, label: string}>;
  telephoneErrors?: Map<string, List<string>> | null;
  permissions: Partial<IPermissionsProps>;
  formName: string;
}

export default class EditPersonalDetailsFormFields extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const { item, errors, titleChoices, telephoneErrors, permissions, formName } = this.props;
    return (
      <div>
        <Row>
          <Column xs={12} sm={6} className="padding-large">
            <FormGroup>
              <label><FormattedMessage id="generic.title" /></label>
              <Field
                component={ReactSelectWrapper}
                simpleValue
                clearable={false}
                className="select"
                options={titleChoices}
                placeholder="Select..."
                name='title'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='title' />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id="generic.first_name" /></label>
              <Field
                component="input"
                name='first_name'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='first_name' />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id="generic.last_name" /></label>
              <Field
                component="input"
                name='last_name'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='last_name' />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id="generic.email" /></label>
              <Field
                component="input"
                name='email'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='email' />
            </FormGroup>
            <FormSection name="volunteer">
              <FormGroup>
                <label><FormattedMessage id="generic.gender" /></label>
                <Field
                  component={ReactSelectWrapper}
                  simpleValue
                  clearable={false}
                  className="select"
                  options={GENDER_CHOICES}
                  name='gender'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.gender' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.birth_date" /></label>
                <Field
                  component={DateInput}
                  name='birth_date'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.birth_date' />
              </FormGroup>
              <FormErrorsRenderer formErrors={telephoneErrors} errorKey="volunteer.telephone_numbers"/>
              <FormArray
                name="telephone_numbers"
                component={TelephoneNumbers}
              />
            </FormSection>
          </Column>
          <Column xs={12} sm={6} className="padding-large">
            <FormSection name="volunteer">
              <PostcodeLookup formName={formName} autofillFields={AUTOFILL_FIELDS} />
              <FormGroup>
                <label><FormattedMessage id="generic.address_1" /></label>
                <Field
                  component="input"
                  name='address_1'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.address_1' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.address_2" /></label>
                <Field
                  component="input"
                  name='address_2'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.address_2' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.address_3" /></label>
                <Field
                  component="input"
                  name='address_3'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.address_3' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.city" /></label>
                <Field
                  component="input"
                  name='city'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.city' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.county" /></label>
                <Field
                  component="input"
                  name='county'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.county' />
              </FormGroup>
              <FormGroup>
                <label><FormattedMessage id="generic.postcode" /></label>
              <Field
                component="input"
                name='postcode'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='volunteer.postcode' />
            </FormGroup>
          </FormSection>
        </Column>
      </Row>
      {permissions.canSeeAvailabilityAndComments &&
        <div>
          <div className="form-break"/>
          <Row>
            <Column xs={12} sm={6} className="padding-large">
              <FormSection name="volunteer">
                <FormGroup block>
                  <label><FormattedMessage id="volunteer_profile.availability" /></label>
                  <Field
                    component="textarea"
                    name='availability'
                  />
                  <FormErrorsRenderer formErrors={errors} errorKey='volunteer.availability' />
                </FormGroup>
              </FormSection>
            </Column>
            <Column xs={12} sm={6} className="padding-large">
              <FormSection name="volunteer">
                <FormGroup block>
                  <label><FormattedMessage id="volunteer_profile.comments" /></label>
                  <Field
                    component="textarea"
                    name='comments'
                  />
                  <FormErrorsRenderer formErrors={errors} errorKey='volunteer.comments' />
                </FormGroup>
              </FormSection>
            </Column>
          </Row>
        </div>
        }
      </div>
    );
  }
}
