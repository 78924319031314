import {
  TableCell,
  TableRow,
} from '@dabapps/roe';
import * as React from 'react';

import { ISiteResearcherRecord } from '^/store/data-types';

// tslint:disable-next-line:no-unused-variable
interface IProps {
  selected: boolean;
  researcher: ISiteResearcherRecord;
  onChange(id: string, selected: boolean): any;
}

export default class ResearcherRow extends React.PureComponent<IProps, {}> {
  public render () {
    const { researcher, selected } = this.props;

    return (
      <TableRow key={researcher.id}>
        <TableCell>
          <input type="checkbox" checked={selected} onChange={this.onChange} />
        </TableCell>
        <TableCell>
          {researcher.display_full_name || '--'}
        </TableCell>
        <TableCell>
          {researcher.email}
        </TableCell>
      </TableRow>
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.researcher.id, event.target.checked);
  }
}
