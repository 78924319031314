import { Column, Row } from '@dabapps/roe';
import { Location } from 'history';
import { List, Map } from 'immutable';
import * as moment from 'moment';
import * as React from "react";
import { connect, } from 'react-redux';
import { Link } from "react-router";
import { getGenericAction, postGenericAction } from '../../actions/statistics';
import { ADVERT_STATS_ADD_TO_STORE, VOLUNTEER_STATS_ADD_TO_STORE } from '../../actions/statistics';
import { getCollectionByName } from '../../collections/reducers';
import { PROFILE_STATUS_NAMES } from '../../consts/constants';
import { USER_TYPES } from '../../consts/user-types';
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import { getResponseStatus, isPending } from '../../responses';
import { IUserRecord } from "../../store/data-types";
import { IStatisticsDataQuery } from '../../store/data-types/statistics';
import { IStatsStore } from '../../types/statistics';
import VolunteersList from '../volunteers/volunteers-list/volunteers-list';
import DashboardChartPanel from './dashboard-chart-panel';
import DashboardStatsPanel from './dashboard-stats-panel';

import { IStore } from '../../store';

const QA_STATS: IStatisticsDataQuery = {
  filter_sets: [
    {
      name: PROFILE_STATUS_NAMES.NEED_QA,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
    {
      name: PROFILE_STATUS_NAMES.NEED_CALL,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
      ]
    },
    {
      name: PROFILE_STATUS_NAMES.ARCHIVED,
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.ARCHIVED
        },
      ]
    },
    {
      name: 'LAST_3_MONTHS_REVIEWED',
      filters: [
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(3, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().format('YYYY-MM-DD')
        },
      ]
    },
    {
      name: 'LAST_6_MONTHS_NEED_CALL',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
      ]
    },
    {
      name: 'LAST_6_MONTHS_NEED_QA',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
    {
      name: 'LAST_6_MONTHS_REVIEWED',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
      ]
    },
    {
      name: 'LAST_3_6_MONTHS_NEED_CALL',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().subtract(3, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_CALL
        },
      ]
    },
    {
      name: 'LAST_3_6_MONTHS_NEED_QA',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().subtract(3, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.NEED_QA
        },
      ]
    },
    {
      name: 'LAST_3_6_MONTHS_REVIEWED',
      filters: [
        {
          filter: 'last_updated_date_from',
          value: moment.utc().subtract(6, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'last_updated_date_to',
          value: moment.utc().subtract(3, 'month').format('YYYY-MM-DD')
        },
        {
          filter: 'profile_status',
          value: PROFILE_STATUS_NAMES.REVIEWED
        },
      ]
    },
  ]
}

interface IExternalProps extends PermissionHOCChildProps {
  loading: boolean;
}

interface IProps extends IExternalProps {
  loading: boolean;
  location: Location;
  postGenericAction: typeof postGenericAction;
  getGenericAction: typeof getGenericAction;
  needQaValue: IStatsStore,
  needQaPerc: IStatsStore,
  needQaFilters: IStatsStore,
  archivedValue: IStatsStore,
  archivedPerc: IStatsStore,
  archivedFilters: IStatsStore,
  needCallValue: IStatsStore,
  needCallPerc: IStatsStore,
  needCallFilters: IStatsStore,
  reviewedLast3MonthsValue: IStatsStore,
  reviewedLast3MonthsPerc: IStatsStore,
  reviewedLast3MonthsFilters: IStatsStore,
  lastSixMonthNotArchivedValue: IStatsStore,
  lastSixMonthNotArchivedPerc: IStatsStore,
  lastSixMonthNotArchivedFilters: IStatsStore,
  last6To3MonthNotArchivedValue: IStatsStore,
  last6To3MonthNotArchivedPerc: IStatsStore,
  last6To3MonthNotArchivedFilters: IStatsStore,
  advertSourcesStats: Array<Array<string | number>>,
  mostPopularAdvertSources: Map<string, string | number>,
}

const COLLECTION_KEY: string = 'charts/volunteers';
const ADVERT_COLLECTION_KEY: string = 'charts/advert-sources';

export class DashboardQAview extends React.PureComponent<IProps, void> {

  public componentWillMount() {
    this.props.postGenericAction(COLLECTION_KEY, VOLUNTEER_STATS_ADD_TO_STORE, QA_STATS);
    this.props.getGenericAction(ADVERT_COLLECTION_KEY, ADVERT_STATS_ADD_TO_STORE);
  }

  public render() {
    const {
      loading,
      user,
      location,
      needQaValue,
      needQaPerc,
      needQaFilters,
      archivedValue,
      archivedPerc,
      archivedFilters,
      needCallValue,
      needCallPerc,
      needCallFilters,
      reviewedLast3MonthsValue,
      reviewedLast3MonthsPerc,
      reviewedLast3MonthsFilters,
      lastSixMonthNotArchivedValue,
      lastSixMonthNotArchivedPerc,
      lastSixMonthNotArchivedFilters,
      last6To3MonthNotArchivedValue,
      last6To3MonthNotArchivedPerc,
      last6To3MonthNotArchivedFilters,
      advertSourcesStats,
      mostPopularAdvertSources,
    } = this.props;

    return (
      <div>
        <DashboardStatsPanel
          loading={loading}
          title={'Quality Assurance Officer'}
          data={List.of(
            Map({
              text: 'Needs QA',
              value: needQaValue,
              percentage: needQaPerc,
              filters: needQaFilters,
              pathname: '/app/volunteers/',
            }),
            Map({
              text: 'Not archived, last 6 months',
              value: lastSixMonthNotArchivedValue,
              percentage: lastSixMonthNotArchivedPerc,
              filters: lastSixMonthNotArchivedFilters,
              pathname: '/app/volunteers/',
            }),
            Map({
              text: 'Not archived, 3-6 months',
              value: last6To3MonthNotArchivedValue,
              percentage: last6To3MonthNotArchivedPerc,
              filters: last6To3MonthNotArchivedFilters,
              pathname: '/app/volunteers/',
            }),
            Map({
              text: 'Needs call',
              value: needCallValue,
              percentage: needCallPerc,
              filters: needCallFilters,
              pathname: '/app/volunteers/',
            }),
          )}
        />

        <Row>
          <DashboardChartPanel
            loading={loading}
            panelTitle={'Record Quality'}
            className={'column xs-12 md-4'}
            chartType={'PieChart'}
            data={[
              ['Type', 'Stats'],
              ['Needs QA', needQaValue],
              ['Not archived, last 6 months', lastSixMonthNotArchivedValue],
              ['Not archived, 3-6 months', last6To3MonthNotArchivedValue],
              ['Needs call', needCallValue],
              ['Up to date, last 3 months', reviewedLast3MonthsValue]
            ]}
          />

          <DashboardStatsPanel
            loading={loading}
            className={'column xs-12 md-4'}
            title={'Complete & Up to date'}
            data={List.of(
              Map({
                text: 'Reviewed',
                value: reviewedLast3MonthsValue,
                percentage: reviewedLast3MonthsPerc,
                filters: reviewedLast3MonthsFilters,
                pathname: '/app/volunteers/',
              }),
            )}
          />

          <DashboardStatsPanel
            loading={loading}
            className={'column xs-12 md-4'}
            title={'Archived'}
            data={List.of(
              Map({
                text: 'Archived',
                value: archivedValue,
                percentage: archivedPerc,
                filters: archivedFilters,
                pathname: '/app/volunteers/',
              }),
            )}
          />
        </Row>

        <Row>
          <DashboardChartPanel
            loading={loading}
            panelTitle={'Advertising Sources'}
            className={'column xs-12 md-4'}
            chartType={'PieChart'}
            data={[['Type', 'Stats'], ...advertSourcesStats]}
          />

          <DashboardStatsPanel
            loading={loading}
            className={'column xs-12 md-4'}
            title={'Most Popular Source'}
            data={List.of(
              Map({
                text: mostPopularAdvertSources.get('name'),
                value: mostPopularAdvertSources.get('count'),
                percentage: mostPopularAdvertSources.get('percentage'),
                filters: '',
              }),
            )}
          />
        </Row>

        <div className='clearfix margin-vertical-large'>
          <h6 className='header padding-bottom-large margin-bottom-none font-size-medium bold border-bottom-base'>
            Volunteers needing QC review</h6>
          <VolunteersList
            permissions={this.props.permissions}
            pushRoute={this.props.pushRoute}
            user={user}
            location={location}
            defaultFilters={Map({
              profile_status: PROFILE_STATUS_NAMES.NEED_QA
            })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ statistics, responses }: IStore, props: IExternalProps) => {
  const advertSources = statistics.get('ADVERT_SOURCES', Map()) as Map<string, Map<string, string | number>>;

  return {
    ...props,
    loading: isPending(responses, VOLUNTEER_STATS_ADD_TO_STORE, COLLECTION_KEY),
    needQaValue: statistics.getIn([PROFILE_STATUS_NAMES.NEED_QA, 'value']),
    needQaPerc: statistics.getIn([PROFILE_STATUS_NAMES.NEED_QA, 'percentage']),
    needQaFilters: statistics.getIn([PROFILE_STATUS_NAMES.NEED_QA, 'filters']),
    archivedValue: statistics.getIn([PROFILE_STATUS_NAMES.ARCHIVED, 'value']),
    archivedPerc: statistics.getIn([PROFILE_STATUS_NAMES.ARCHIVED, 'percentage']),
    archivedFilters: statistics.getIn([PROFILE_STATUS_NAMES.ARCHIVED, 'filters']),
    needCallValue: statistics.getIn([PROFILE_STATUS_NAMES.NEED_CALL, 'value']),
    needCallPerc: statistics.getIn([PROFILE_STATUS_NAMES.NEED_CALL, 'percentage']),
    needCallFilters: statistics.getIn([PROFILE_STATUS_NAMES.NEED_CALL, 'filters']),
    reviewedLast3MonthsValue: statistics.getIn(['LAST_3_MONTHS_REVIEWED', 'value']),
    reviewedLast3MonthsPerc: statistics.getIn(['LAST_3_MONTHS_REVIEWED', 'percentage']),
    reviewedLast3MonthsFilters: statistics.getIn(['LAST_3_MONTHS_REVIEWED', 'filters']),

    lastSixMonthNotArchivedValue: (
      statistics.getIn(['LAST_6_MONTHS_NEED_CALL', 'value'])
      + statistics.getIn(['LAST_6_MONTHS_NEED_QA', 'value'])
      + statistics.getIn(['LAST_6_MONTHS_REVIEWED', 'value'])
    ),

    lastSixMonthNotArchivedPerc: (
      statistics.getIn(['LAST_6_MONTHS_NEED_CALL', 'percentage'])
      + statistics.getIn(['LAST_6_MONTHS_NEED_QA', 'percentage'])
      + statistics.getIn(['LAST_6_MONTHS_REVIEWED', 'percentage'])
    ),

    lastSixMonthNotArchivedFilters: (
      statistics.getIn(['LAST_6_MONTHS_NEED_CALL', 'filters']) &&
      statistics.getIn(['LAST_6_MONTHS_NEED_CALL', 'filters'])
      .merge(Map({ profile_status: PROFILE_STATUS_NAMES.NOT_ARCHIVED }))
    ),

    last6To3MonthNotArchivedValue: (
      statistics.getIn(['LAST_3_6_MONTHS_NEED_CALL', 'value'])
      + statistics.getIn(['LAST_3_6_MONTHS_NEED_QA', 'value'])
      + statistics.getIn(['LAST_3_6_MONTHS_REVIEWED', 'value'])
    ),

    last6To3MonthNotArchivedPerc: (
      statistics.getIn(['LAST_3_6_MONTHS_NEED_CALL', 'percentage'])
      + statistics.getIn(['LAST_3_6_MONTHS_NEED_QA', 'percentage'])
      + statistics.getIn(['LAST_3_6_MONTHS_REVIEWED', 'percentage'])
    ),

    last6To3MonthNotArchivedFilters: (
      statistics.getIn(['LAST_3_6_MONTHS_NEED_CALL', 'filters']) &&
      statistics.getIn(['LAST_3_6_MONTHS_NEED_CALL', 'filters'])
      .merge({ profile_status: PROFILE_STATUS_NAMES.NOT_ARCHIVED })
    ),

    advertSourcesStats: advertSources.toList()
      .map((item) => [item.get('name', ''), item.get('count', 0)]).toArray(),

    mostPopularAdvertSources: advertSources.toMap()
      .reduce((acc: any, curr: any) => {
        if (curr.get('percentage') > acc.get('percentage', 0)) {
          return acc.merge(curr);
        }
        return acc;
      }, Map()),
  }
}

const mapDispatchToProps = {
  postGenericAction,
  getGenericAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardQAview);
