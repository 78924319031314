import { fromJS } from 'immutable';
import * as React from 'react';

import GenericList from '^/components/chadmin/generic-list';
import { STUDY_OUTCOME } from '^/consts/constants';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS = fromJS([
  {
    name: ['name'],
    display_name: 'Name',
    type: 'detail',
    route: DETAIL_ROUTE,
    sortable: false,
  },
  {
    name: ['type'],
    display_name: 'Study Outcome',
    type: 'lookup',
    lookups: STUDY_OUTCOME,
    sortable: false,
  },
  {
    name: ['active'],
    display_name: 'Is active?',
    type: 'boolean',
    sortable: false,
  }
]);

const MESSAGES = {
  title: 'outcomes.study-outcomes.title',
  create: 'outcomes.study-outcomes.create',
};

const COLLECTION_NAME = 'STUDY_OUTCOMES';

// tslint:disable-next-line:no-unused-variable
type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, void> {
  public render() {
      return (
          <GenericList
              canCreate={this.props.permissions.canCreate}
              messages={MESSAGES}
              collectionColumns={LIST_COLUMNS}
              collectionName={COLLECTION_NAME}
              createUrl='outcomes/study-outcomes'
              collectionKey={'studies/study-outcomes'}
              defaultFilters={fromJS({include_inactive: false})}
            />
      );
  }
}
