import { Column, Container, FormGroup, Row } from '@dabapps/roe';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Field, FormProps, FormSection } from "redux-form";
import {
  DIABETES_TYPE_CHOICES,
  HBA1C_RESULT_UNIT,
  HBA1C_RESULT_UNIT_CHOICES,
  HEIGHT_UNIT_CHOICES,
  MONTHS_CHOICES,
  WEIGHT_UNIT_CHOICES,
  YEARS_CHOICES
} from "../../../../consts/constants";
import { DisplayUnit, Hba1cUnit, IUserRecord } from "../../../../store/data-types";
import { FormErrors } from "../../../../utils";
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import { ReactSelectWrapper } from "../../../forms/react-select-wrapper";

interface IExternalProps {
  item: IUserRecord;
  errors?: FormErrors | null;
  selectedHba1cUnit: Hba1cUnit;
  selectedHeightUnit: DisplayUnit;
  selectedWeightUnit: DisplayUnit;
}

export default class EditMedicalDetailsFormFields extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const { item, errors, selectedHeightUnit, selectedWeightUnit, selectedHba1cUnit } = this.props;
    return (
      <FormSection name="volunteer">
        <Row>
          <Column xs={12} sm={6}>
            <FormGroup>
              <label><FormattedMessage id='generic.diabetes_type' /></label>
              <Field
                component={ReactSelectWrapper}
                simpleValue
                clearable={false}
                className="select"
                options={DIABETES_TYPE_CHOICES}
                name='diabetes_type'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='volunteer.diabetes_type' />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id='generic.month_diagnosed' /></label>
              <Field
                component={ReactSelectWrapper}
                simpleValue
                clearable={false}
                className="select"
                options={MONTHS_CHOICES}
                placeholder="Select..."
                name='month_diagnosed'
              />
            </FormGroup>
            <FormGroup>
              <label><FormattedMessage id='generic.year_diagnosed' /></label>
              <Field
                component={ReactSelectWrapper}
                simpleValue
                clearable={false}
                className="select"
                options={YEARS_CHOICES}
                placeholder="Select..."
                name='year_diagnosed'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='volunteer.year_diagnosed' />
            </FormGroup>
          </Column>
          <Column xs={12} sm={6}>
            <FormGroup className="triple">
              <label><FormattedMessage id='generic.hbA1c_result' /></label>
              <Field
                component="input"
                type="number"
                min="0"
                name='hbA1c_result'
                step="any"
                placeholder={HBA1C_RESULT_UNIT.get(selectedHba1cUnit)}
              />
              <Field
                component={ReactSelectWrapper}
                simpleValue
                clearable={false}
                className="select"
                options={HBA1C_RESULT_UNIT_CHOICES}
                name='hbA1c_result_display_unit'
              />
              <FormErrorsRenderer formErrors={errors} errorKey='volunteer.hbA1c_result' />
            </FormGroup>
            <FormSection name="height">
              <FormGroup className="triple">
                <label><FormattedMessage id="generic.height" /></label>
                <Field
                  component="input"
                  type="number"
                  min="0"
                  name='large_unit_value'
                  placeholder={selectedHeightUnit === 'INCHES' ? 'feet' : 'cm'}
                />
                { selectedHeightUnit === 'INCHES' &&
                  <Field
                    component="input"
                    type="number"
                    min="0"
                    placeholder="inches"
                    name='small_unit_value'
                  />
                }
                <Field
                  component={ReactSelectWrapper}
                  simpleValue
                  clearable={false}
                  className="select"
                  options={HEIGHT_UNIT_CHOICES}
                  name='display_unit'
                />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.height' />
              </FormGroup>
            </FormSection>
            <FormSection name="weight">
              <FormGroup className="triple">
                <label><FormattedMessage id='generic.weight' /></label>
                <Field
                  component="input"
                  type="number"
                  min="0"
                  name='large_unit_value'
                  placeholder={selectedWeightUnit === 'POUND' ? 'stone' : 'kg'}
                />
                { selectedWeightUnit === 'POUND' &&
                  <Field
                    component="input"
                    type="number"
                    min="0"
                    placeholder="pounds"
                    name='small_unit_value'
                  />
                }
                <Field
                  component={ReactSelectWrapper}
                  simpleValue
                  clearable={false}
                  className="select"
                  options={WEIGHT_UNIT_CHOICES}
                  name='display_unit'
                  />
                <FormErrorsRenderer formErrors={errors} errorKey='volunteer.weight' />
              </FormGroup>
            </FormSection>
            <FormGroup noLabel className="margin-top-none margin-bottom-large">
              <p><FormattedMessage id='generic.bmi' />: {item && item.volunteer && item.volunteer.bmi}</p>
            </FormGroup>
          </Column>
        </Row>
      </FormSection>
    );
  }
}
