import defaultPermissions, { IAllPermission } from './default-permissions'
// Volunteer Permissions
const permissions: IAllPermission = {
    ...defaultPermissions,
    home: {
      hasAccess: true,
      customProps: {
        canViewVolunteerDashboard: true,
      }
    },
    sites: {
      hasAccess: false,
      customProps: {}
    },
    sites_create: {
      hasAccess: false,
      customProps: {}
    },
    sites_edit: {
      hasAccess: false,
      customProps: {}
    },
    studies: {
      hasAccess: false,
      customProps: {},
    },
    studies_search: {
      hasAccess: false,
      customProps: {},
    },
    studies_view: {
      hasAccess: false,
      customProps: {},
    },
    studies_save: {
      hasAccess: false,
      customProps: {},
    },
    studies_edit: {
      hasAccess: false,
      customProps: {},
    },
    volunteers: {
      hasAccess: false,
      customProps: {},
    },
    volunteers_create: {
      hasAccess: false,
      customProps: {},
    },
    volunteers_detail: {
      hasAccess: false,
      customProps: {},
    },
    profile_edit_drivers: {
      hasAccess: true,
      customProps: {},
    },
    profile_edit_medical: {
      hasAccess: true,
      customProps: {},
    },
    profile_edit_personal: {
      hasAccess: true,
      customProps: {
        canSeeAvailabilityAndComments: false
      },
    },
    profile_edit_medication: {
      hasAccess: true,
      customProps: {},
    },
    reports: {
      hasAccess: false,
      customProps: {},
    },
};

export default permissions;
