import { List } from 'immutable';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect, } from 'react-redux';
import { getCollectionByName } from '../../../collections/reducers';
import { NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE } from '../../../consts/constants';
import { collectionsModule } from '../../../reducers/collections';
import { IStore } from '../../../store';
import { IProfileLogRecord } from "../../../store/data-types";
import PageHeader from "../../page-header";
import ProfileLogList from './profile-log-list';

const { actions: { getCollection } } = collectionsModule;

const MESSAGES = {
  history: "generic.history",
};

interface IExternalProps {
  volunteerId: string;
}

interface IProps extends IExternalProps {
  profileLogsList: List<IProfileLogRecord>;
  getCollection: typeof getCollection;
}

export class ProfileLogs extends React.PureComponent<IProps, {}> {
  public render() {
    return(
      <div>
        <PageHeader className="margin-top-base" header={<FormattedMessage id={MESSAGES.history} />} />
        <ProfileLogList profileLogsList={this.props.profileLogsList}/>
      </div>
    );
  }

  public componentWillMount() {
    const {volunteerId} = this.props;
    this.props.getCollection(
      'profile-logs/volunteer',
      { pageSize: NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE },
      `profile-logs-volunteer-${volunteerId}`,
      `profile-logs/volunteer/${volunteerId}`
    );
  }
}

function mapStateToProps ({ collections }: IStore, props: IExternalProps) {
  const {volunteerId} = props;
  const profileLogsList = getCollectionByName(
    collections['profile-logs/volunteer'],
    `profile-logs-volunteer-${volunteerId}`).results;
  return {
    profileLogsList
  };
}

export default connect(mapStateToProps, {getCollection})(ProfileLogs);
