import { fromJS, List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { getCollectionByName } from '^/collections/reducers';
import GenericDetail from '^/components/chadmin/generic-detail';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';
import { collectionsModule } from '^/reducers/collections';
import { IResponseStatus } from '^/responses';
import { IStore } from '^/store';
import { IAdvertTypeRecord } from '^/store/data-types';

const { actions : { getCollection } } = collectionsModule;

const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['ad_type', 'name']]
  },
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['start_date', 'end_date']]
  },
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['cost', 'active']]
  }
]);

const FORM_NAME = 'EDIT_ADVERTS';

const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  ad_type: {
    label: 'Type',
    type: 'choices',
    choices: []
  },
  start_date: {
    label: 'Start date',
    type: 'date',
    sortable: false,
  },
  end_date: {
    label: 'End date',
    type: 'date',
    sortable: false,
  },
  cost: {
    label: 'Cost',
    type: 'float',
    max_length: 10,
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  }
});

const MESSAGES = {
  edit: 'drivers.adverts.edit'
};

interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

interface IValueCollections {
  adTypes: List<IAdvertTypeRecord>;
}

// tslint:disable-next-line:no-unused-variable
interface IProps extends IValueCollections, IExternalProps {
  detailFieldOptions: Map<string, any>;
  response: IResponseStatus;
  getCollection: typeof getCollection;
  loading: boolean;
  params: { id: string };
}

export class Detail extends React.PureComponent<IProps, {}> {

  public componentWillMount() {
    this.loadAllOptions();
  }

  public render() {
    const { detailFieldOptions } = this.props;

    return (
        <GenericDetail
            patch
            formName={FORM_NAME}
            messages={MESSAGES}
            params={this.props.params}
            detailFields={DETAIL_FIELDS}
            detailFieldOptions={detailFieldOptions}
            onCancelLinkTo={'/app/drivers/adverts/'}
            itemsName={'drivers/adverts'} />
      );
  }

  private loadAllOptions() {
    this.props.getCollection(
      'drivers/adverts-types',
      { pageSize: 150 },
      'drivers/adverts-types'
    );
  }
}

const transformFieldOptions = (adTypes: List<IAdvertTypeRecord>): Map<string, any> => {
  return DETAIL_FIELD_OPTIONS.set('ad_type', Map({
    label: 'Type',
    type: 'choices',
    clearable: false,
    choices: adTypes.map((entry: IAdvertTypeRecord) => {
      return Map({
        value: entry.get('id'),
        display_name: entry.get('name')})
      })
  }));
}

function mapStateToProps({ collections }: IStore, props: IExternalProps) {
  const adTypes = getCollectionByName(collections['drivers/adverts-types'], 'drivers/adverts-types').results;

  return {
    ...props,
    adTypes,
    detailFieldOptions: transformFieldOptions(adTypes)
  };
}

export default connect(mapStateToProps, { getCollection })(Detail);
