import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Button } from '@dabapps/roe';
import * as React from 'react';
import { HTMLAttributes } from 'react';

import { ADD_POLYGON } from '^/actions/studies';
import { getErrorClass } from '^/components/studies/google-map/error';
import { initGoogleMaps } from '^/components/studies/google-map/init';
import {
  circlesChanged,
  convertPolygonForServer,
  markersChanged,
  polygonsChanged,
} from '^/components/studies/google-map/utils';
import * as reduxActionSubscriber from '^/middleware/redux-action-subscriber';
import { IStore } from '^/store/';
import { ICircle, IPoly } from '^/store/data-types/studies';

const MILE_TO_METER = 1609.33;
const MIN_MAP_BOUNDS_DIF = 1;

const CONTROL_BAR_STYLES: HTMLAttributes<HTMLDivElement>['style'] = {
  width: '100%',
  height: 0,
  overflow: 'visible',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 100,
  textAlign: 'center',
  marginTop: 10,
};

// tslint:disable-next-line:no-unused-variable
interface ISetProps {
  selectedCircleIndex: number | null;
  selectedPolygonIndex: number | null;
  drawing: boolean;
}

interface IEditableProps {
  allowCreatingPolygons: true;
  onCreatePolygon(index: number, polygon: IPoly): any;
  onRemovePolygon(index: number): any;
}

interface INotEditableProps {
  allowCreatingPolygons?: false;
  onCreatePolygon?: never;
  onRemovePolygon?: never;
}

type IOwnProps = {
  width?: number | string;
  height?: number | string;
  center?: google.maps.LatLng;
  zoom?: number;
  markers: google.maps.LatLng[];
  circles: Array<null | ICircle>;
  polygons: Array<null | google.maps.LatLng[]>;
} & (IEditableProps | INotEditableProps)

type Props = IOwnProps & SetPropsInterface<ISetProps>;

const SELECTED_CIRCLE_STROKE_COLOR = '#00FF00';
const SELECTED_POLYGON_STROKE_COLOR = SELECTED_CIRCLE_STROKE_COLOR;
const CIRCLE_STROKE_COLOR = '#FF0000';
const CIRCLE_FILL_COLOR = CIRCLE_STROKE_COLOR;
const POLYGON_STROKE_COLOR = CIRCLE_STROKE_COLOR;
const POLYGON_FILL_COLOR = POLYGON_STROKE_COLOR;

class GoogleMap extends React.PureComponent<Props, {}> {
  private element: HTMLDivElement | undefined;
  private map: google.maps.Map | undefined;
  private drawingManager: google.maps.drawing.DrawingManager | undefined;
  private markers: google.maps.Marker[] = [];
  private circles: Array<null | google.maps.Circle> = [];
  private polygons: Array<null | google.maps.Polygon> = [];
  private unsubscribe: () => any;

  public componentDidMount () {
    initGoogleMaps().then(this.mapsLoaded);

    this.unsubscribe = reduxActionSubscriber.subscribe(ADD_POLYGON.FAILURE, this.onError);
  }

  public componentWillUnmount () {
    this.unsubscribe();
  }

  public componentDidUpdate (prevProps: Props) {
    const { selectedCircleIndex, selectedPolygonIndex } = this.props;

    // Initialize map
    if (!this.map) {
      this.mapsLoaded();
    }

    let somethingChanged = false;

    // Update markers
    if (markersChanged(prevProps.markers, this.props.markers)) {
      this.clearMarkers();
      this.addMarkers();
      somethingChanged = true;
    }

    // Update circles
    if (circlesChanged(prevProps.circles, this.props.circles)) {
      this.clearCircles();
      this.addCircles();
      somethingChanged = true;
    }

    // Update polygons
    if (polygonsChanged(prevProps.polygons, this.props.polygons)) {
      this.clearPolygons();
      this.addPolygons();
      somethingChanged = true;
    }

    // Update drawing state
    if (typeof this.drawingManager !== 'undefined') {
      if (this.props.drawing) {
        if (typeof this.map !== 'undefined') {
          this.drawingManager.setMap(this.map);
        }
      } else {
        this.drawingManager.setMap(null);
      }
    }

    // Set selected circle
    this.circles.forEach((circle, index) => {
      if (circle) {
        if (selectedCircleIndex === index) {
          circle.setOptions({strokeColor: SELECTED_CIRCLE_STROKE_COLOR});
        } else {
          circle.setOptions({strokeColor: CIRCLE_STROKE_COLOR});
        }
      }
    });

    // Set selected polygon
    this.polygons.forEach((polygon, index) => {
      if (polygon) {
        if (selectedPolygonIndex === index) {
          polygon.setOptions({strokeColor: SELECTED_POLYGON_STROKE_COLOR});
          polygon.setEditable(true);
        } else {
          polygon.setOptions({strokeColor: POLYGON_STROKE_COLOR});
          polygon.setEditable(false);
        }
      }
    });

    if (somethingChanged) {
      this.fitBounds();
    }
  }

  public render () {
    const {
      width,
      height,
      selectedCircleIndex,
      selectedPolygonIndex,
      drawing,
    } = this.props;

    return (
      <div style={{width, height, position: 'relative'}}>
        {
          typeof selectedCircleIndex === 'number' && !drawing && (
            <div style={CONTROL_BAR_STYLES} onClick={this.onClickDraw}>
              <Button>Draw custom area</Button>
            </div>
          )
        }
        {
          typeof selectedCircleIndex === 'number' && drawing && (
            <div style={CONTROL_BAR_STYLES} onClick={this.onClickCancel}>
              <Button>Cancel</Button>
            </div>
          )
        }
        {
          typeof selectedPolygonIndex === 'number' && (
            <div style={CONTROL_BAR_STYLES} onClick={this.onClickRemove}>
              <Button>Remove custom area</Button>
            </div>
          )
        }
        <div ref={this.storeElement} style={{width, height}} />
      </div>
    );
  }

  private onError = (action: reduxActionSubscriber.IAnyAction, state: IStore) => {
    const error = action.payload.poly[0];
    const location = action.meta.location;

    const GoogleMapError = getErrorClass();

    if (GoogleMapError && this.map) {
      const myError = new GoogleMapError(location, error);
      myError.setMap(this.map);
    }
  }

  private onClickDraw = () => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    this.props.setProps({
      drawing: true,
    });
  }

  private onClickCancel = () => {
    if (typeof this.drawingManager !== 'undefined') {
      this.props.setProps({
        drawing: false,
      });
    }
  }

  private onClickRemove = () => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    const { selectedPolygonIndex } = this.props;

    if (typeof selectedPolygonIndex === 'number') {
      const polygon = this.polygons[selectedPolygonIndex];

      if (polygon) {
        polygon.setMap(null);
        delete this.polygons[selectedPolygonIndex];
      }

      this.props.onRemovePolygon(selectedPolygonIndex);

      this.props.setProps({
        selectedCircleIndex: null,
        selectedPolygonIndex: null,
      });
    }
  }

  private storeElement = (element: HTMLDivElement) => {
    this.element = element;
  }

  private deselectAll = () => {
    this.props.setProps({
      selectedCircleIndex: null,
      selectedPolygonIndex: null,
    });
  }

  private onClickMap = () => {
    this.deselectAll();
  }

  private mapsLoaded = () => {
    if (
      typeof google === 'undefined' ||
      typeof google.maps === 'undefined' ||
      typeof this.element === 'undefined'
    ) {
      return;
    }

    const { center = {lat: 0, lng: 0}, zoom = 1 } = this.props;

    this.map = new google.maps.Map(this.element, {
      zoom,
      center,
    });

    google.maps.event.addListener(this.map, 'click', this.onClickMap);

    this.addDrawingManager();
    this.addMarkers();
    this.addCircles();
    this.addPolygons();
    this.fitBounds();
  }

  private addDrawingManager = () => {
    if (
      typeof google === 'undefined' ||
      typeof google.maps === 'undefined' ||
      typeof google.maps.drawing === 'undefined'
    ) {
      return;
    }

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      markerOptions: {
        draggable: true
      },
      polygonOptions: {
        strokeColor: POLYGON_STROKE_COLOR,
        fillColor: POLYGON_FILL_COLOR,
      }
    });

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.onDrawPolygon);
  }

  private onEditPolygon = () => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    const { selectedPolygonIndex } = this.props;

    if (typeof selectedPolygonIndex !== 'number') {
      return;
    }

    const polygon = this.polygons[selectedPolygonIndex];

    if (polygon) {
      const serverPolygon = convertPolygonForServer(polygon);

      this.props.onCreatePolygon(selectedPolygonIndex, serverPolygon);
    }
  }

  private onDrawPolygon = (polygon: google.maps.Polygon) => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    const { selectedCircleIndex } = this.props;

    if (typeof this.drawingManager !== 'undefined') {
      this.props.setProps({
        drawing: false,
      });
    }

    if (typeof selectedCircleIndex !== 'number') {
      return;
    }

    const serverPolygon = convertPolygonForServer(polygon);

    // Must be at least a triangle (that goes back to the first point)
    if (serverPolygon.coordinates[0].length >= 4) {
      this.deselectAll();

      google.maps.event.addListener(polygon, 'click', () => this.onClickPolygon(polygon, selectedCircleIndex));
      google.maps.event.addListener(polygon.getPath(), 'set_at', this.onEditPolygon);
      google.maps.event.addListener(polygon.getPath(), 'insert_at', this.onEditPolygon);

      this.polygons[selectedCircleIndex] = polygon;

      this.props.onCreatePolygon(selectedCircleIndex, serverPolygon);

      this.props.setProps({
        selectedPolygonIndex: selectedCircleIndex,
        selectedCircleIndex: null,
      });
    } else {
      polygon.setMap(null);
    }
  }

  private addMarkers = () => {
    const { map } = this;

    if (map && this.props.markers) {
      this.markers = this.props.markers.map((position) => new google.maps.Marker({
        position,
        map
      }));
    }
  }

  private clearMarkers = () => {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });

    this.markers = [];
  }

  private addCircles = () => {
    const { map } = this;

    if (map && this.props.circles) {
      this.circles = this.props.circles.map((circle) => {
        if (circle) {
          return new google.maps.Circle({
            strokeColor: CIRCLE_STROKE_COLOR,
            fillColor: CIRCLE_FILL_COLOR,
            map,
            center: circle.center,
            radius: circle.radius * MILE_TO_METER,
          });
        }
        return null;
      });

      this.circles.forEach((circle, index) => {
        if (circle) {
          google.maps.event.addListener(circle, 'click', () => this.onClickCircle(circle, index));
        }
      });
    }
  }

  private onClickCircle = (circle: google.maps.Circle, index: number) => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    const polygon = this.polygons[index];
    const newIndex = index === this.props.selectedCircleIndex ? null : index;

    this.props.setProps({
      selectedCircleIndex: polygon ? null : newIndex,
      selectedPolygonIndex: polygon ? newIndex : null,
    });
  }

  private onClickPolygon = (polygon: google.maps.Polygon, index: number) => {
    if (!this.props.allowCreatingPolygons) {
      return;
    }

    const newIndex = index === this.props.selectedPolygonIndex ? null : index;

    this.props.setProps({
      selectedPolygonIndex: newIndex,
      selectedCircleIndex: null,
    });
  }

  private clearCircles = () => {
    this.circles.forEach((circle) => {
      if (circle) {
        circle.setMap(null);
      }
    });

    this.circles = [];
  }

  private addPolygons = () => {
    const { map } = this;

    if (map && this.props.polygons) {
      this.polygons = this.props.polygons.map((polygon) => {
        if (polygon) {
          return new google.maps.Polygon({
            paths: [polygon],
            strokeColor: POLYGON_STROKE_COLOR,
            fillColor: POLYGON_FILL_COLOR,
          });
        }

        return null;
      });

      this.polygons.forEach((polygon, index) => {
        if (polygon) {
          google.maps.event.addListener(polygon, 'click', () => this.onClickPolygon(polygon, index));
          google.maps.event.addListener(polygon.getPath(), 'set_at', this.onEditPolygon);
          google.maps.event.addListener(polygon.getPath(), 'insert_at', this.onEditPolygon);
          polygon.setMap(map);
        }
      });
    }
  }

  private clearPolygons = () => {
    this.polygons.forEach((polygon) => {
      if (polygon) {
        polygon.setMap(null);
      }
    });

    this.polygons = [];
  }

  private fitBounds = () => {
    const { map } = this;

    if (map && (this.markers || this.circles)) {
      const bounds = new google.maps.LatLngBounds();

      if (this.markers) {
        this.markers.forEach((marker) => {
          bounds.extend(marker.getPosition());
        });
      }

      if (this.circles) {
        this.circles.forEach((circle) => {
          if (circle) {
            const {lat, lng} = circle.getCenter();

            bounds.extend({lat: lat(), lng: lng()});
            bounds.extend({lat: lat(), lng: lng()});
          }
        });
      }

      map.fitBounds(this.preventExtremeZoom(bounds));
    }
  }

  private preventExtremeZoom = (bounds: google.maps.LatLngBounds) => {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    if (Math.abs(northEast.lat() - southWest.lat()) < MIN_MAP_BOUNDS_DIF) {
      bounds.extend(new google.maps.LatLng(
        bounds.getCenter().lat() + MIN_MAP_BOUNDS_DIF / 2,
        bounds.getCenter().lng()
      ));

      bounds.extend(new google.maps.LatLng(
        bounds.getCenter().lat() - MIN_MAP_BOUNDS_DIF / 2,
        bounds.getCenter().lng()
      ));
    }

    if (Math.abs(northEast.lng() - southWest.lng()) < MIN_MAP_BOUNDS_DIF) {
      bounds.extend(new google.maps.LatLng(
        bounds.getCenter().lat(),
        bounds.getCenter().lng() + MIN_MAP_BOUNDS_DIF / 2
      ));

      bounds.extend(new google.maps.LatLng(
        bounds.getCenter().lat(),
        bounds.getCenter().lng() - MIN_MAP_BOUNDS_DIF / 2
      ));
    }

    return bounds;
  }
}

const getInitialProps = () => ({
  selectedCircleIndex: null,
  selectedPolygonIndex: null,
  drawing: false,
});

export default withSetProps<ISetProps, IOwnProps>(getInitialProps)(GoogleMap);
