import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { getCollectionByName } from '^/collections/reducers';
import { collectionsModule } from '^/reducers/collections';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { DataShape, FormProps, reduxForm } from 'redux-form';
const { actions: { getCollection } } = collectionsModule;
import { GET_COLLECTION } from '^/collections/actions';
import { ICollection } from '^/collections/types';
import { IStore } from '^/store';
// tslint:disable-next-line:no-unused-variable
import { IStudyRecord } from '^/store/data-types/studies';
import { isPending } from '../../../responses';
import GroupField from '../../forms/group-field';

const messages = defineMessages({
  study: {
    id: 'volunteer.filter_study'
  },
});

export const FORM_NAME = 'recruitment-flow';
export const FORM_VALUE_NAME: string = 'study';
const STUDY_COLLECTION_NAME: string = 'studies';

export interface IFormData {
  study: string | undefined;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
}

interface IStateProps {
  studiesCollection: ICollection<IStudyRecord>;
  loading: boolean;
}

interface IOwnProps extends FormProps<IFormData, void, void> {
  study: string | undefined;
  onSubmit(data: IFormData): void;
}

type Props = IStateProps & IDispatchProps & IOwnProps;

export class RecruitmentFlowForm extends React.PureComponent<Props & IFormData, {}> {

  public componentWillMount() {
    this.props.getCollection('studies', { pageSize: 150 }, STUDY_COLLECTION_NAME);
  }

  public render() {

    const {
      loading,
      studiesCollection,
      study,
    } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Row>
          <Column xs={12} sm={8} md={6} lg={4}>
            <GroupField
              label={messages.study}
              name='study'
              component='select'
              disabled={loading}
              className={'recruitment-form-group'}
            >
              <option value=''>Select</option>
              {studiesCollection.results.map((value) => {
                return <option value={value.id} key={value.id}>{value.study_name}</option>
              })}
            </GroupField>
          </Column>
          <Column xs={12} sm={4} md={3} lg={3}>
            <FormGroup>
              <Button className="primary" type="submit" block disabled={loading || !study}>Search</Button>
            </FormGroup>
          </Column>
          </Row>
      </form>
    );
  }
}

const mapStateToProps = ({ responses, collections }: IStore, props: IOwnProps): IStateProps & IOwnProps => {

  const studiesCollection = getCollectionByName(
    collections.studies,
    STUDY_COLLECTION_NAME
  );

  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, STUDY_COLLECTION_NAME),
    studiesCollection,
  }
}

const mapDispatchToProps = {
  getCollection,
}

const ConnectedRecruitmentFlowForm = reduxForm({
  form: FORM_NAME,
})(RecruitmentFlowForm)

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedRecruitmentFlowForm);
