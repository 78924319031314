'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.update = exports.updateIntl = exports.UPDATE = exports.Provider = exports.IntlProvider = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.intlReducer = intlReducer;

var _warning = require('warning');

var _warning2 = _interopRequireDefault(_warning);

var _IntlProvider2 = require('./components/IntlProvider');

var _IntlProvider3 = _interopRequireDefault(_IntlProvider2);

var _Provider2 = require('./components/Provider');

var _Provider3 = _interopRequireDefault(_Provider2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.IntlProvider = _IntlProvider3.default;
exports.Provider = _Provider3.default;
var UPDATE = exports.UPDATE = '@@intl/UPDATE';

var updateIntl = exports.updateIntl = function updateIntl(_ref) {
  var locale = _ref.locale,
      formats = _ref.formats,
      messages = _ref.messages;
  return {
    type: UPDATE,
    payload: { locale: locale, formats: formats, messages: messages }
  };
};

var update = exports.update = function update(intl) {
  process.env.NODE_ENV !== 'production' ? (0, _warning2.default)(false, '[react-intl-redux] `update` is going to be removed, use `updateIntl` instead') : void 0;
  return updateIntl(intl);
};

var initialState = exports.initialState = {
  locale: 'en',
  messages: {}
};

function intlReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  if (action.type !== UPDATE) {
    return state;
  }

  return _extends({}, state, action.payload);
}