import {List, Map} from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  clearSimpleComponentState,
  getVolunteerMedicationOptions,
  setSimpleComponentState,
} from '../../../../actions/actions';
import { GET_COLLECTION } from '../../../../collections/actions';
import { getCollectionByName } from '../../../../collections/reducers';
import { collectionsModule, ICollectionsState, } from '../../../../reducers/collections';
import { isPending } from '../../../../responses';
import { IStore } from "../../../../store";
import {
  IMedicationRecord,
  INamedActiveObjectRecord,
  IUserRecord,
  IUserVolunteerMedicationRecord,
  IVolunteerMedicationRecord,
  UserVolunteerMedicationRecord,
  VolunteerMedicationRecord
} from "../../../../store/data-types";
import Loading from '../../../loading';
import VolunteerMedicationFormWrapper from './edit-volunteer-medication-form-wrapper';

const { actions: { getCollection } } = collectionsModule;

interface IExternalProps {
  volunteerId: string;
}

interface IStateProps {
  loading: boolean;
  genericnames: List<INamedActiveObjectRecord>;
  types: List<string>;
  frequencies: List<INamedActiveObjectRecord>;
  medications: List<IMedicationRecord>;
  volunteerMedicationsCollection: List<IVolunteerMedicationRecord>;
  volunteerMedications: List<IVolunteerMedicationRecord>;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
  getVolunteerMedicationOptions: typeof getVolunteerMedicationOptions;
  setSimpleComponentState: typeof setSimpleComponentState;
  clearSimpleComponentState: typeof clearSimpleComponentState;
}

type Props = IExternalProps & IStateProps & IDispatchProps;

export const COMPONENT_KEY = 'volunteer-medication-list';

export class EditVolunteerMedicationComponent extends React.PureComponent<Props, {}> {
  public componentWillMount() {
    this.loadAllOptions();
    this.loadMedications();
  }

  public componentWillUnmount() {
    this.props.clearSimpleComponentState(COMPONENT_KEY);
  }

  public componentWillReceiveProps(newProps: Props) {
    if (newProps.volunteerMedicationsCollection !== this.props.volunteerMedicationsCollection) {
      this.props.setSimpleComponentState(COMPONENT_KEY, newProps.volunteerMedicationsCollection);
    }
  }
  public addVolunteerMedication = () => {
    const medication = VolunteerMedicationRecord({id: uuid()});
    const medications = this.props.volunteerMedications.push(medication);
    this.props.setSimpleComponentState(COMPONENT_KEY, medications);
  }

  public removeVolunteerMedication = (id: string) => {
    const index = this.props.volunteerMedications.findIndex((med) => med.get('id') === id);
    const medications = this.props.volunteerMedications.remove(index);
    this.props.setSimpleComponentState(COMPONENT_KEY, medications);
  }

  public render() {
    if (this.props.loading) {
      return (
        <Loading />
      );
    }

    return (
      <div>
        {this.props.volunteerMedications.map((med: IVolunteerMedicationRecord) => (
          <VolunteerMedicationFormWrapper
            onRemove={() => this.removeVolunteerMedication(med.id)}
            key={med.id}
            volunteerMedication={med}
            medications={this.props.medications}
            frequencies={this.props.frequencies}
            genericnames={this.props.genericnames}
            types={this.props.types}
            form={med.id}
          />
        ))}
        <p className="text-align-left font-size-small">
          <a className="button small primary" onClick={this.addVolunteerMedication}>
            {this.props.volunteerMedications.count() ? "Add another medication..." : "Add medication..."}
          </a>
        </p>
      </div>
    );
  }

  private loadAllOptions() {
    this.props.getVolunteerMedicationOptions();
  }

  private loadMedications() {
    const { volunteerId } = this.props;

    this.props.getCollection(
      'users/volunteers/medications',
      { pageSize: 150 },
      `volunteer-${volunteerId}-medications`,
      `users/volunteers/${volunteerId}/medications`
    );
  }
}

function mapStateToProps( state: IStore, props: IExternalProps) {
  const { collections } = state;
  const { volunteerId } = props;
  const medications =
    getCollectionByName(collections['medications/medication'], 'VALUES-medications/medication').results;
  const types = medications.map((med: IMedicationRecord) => med.get('med_type')).toSet().toList();
  let genericnames = medications.map((med: IMedicationRecord) => med.get('generic_name_display'));
  genericnames = genericnames.filter((a, i) => genericnames.findIndex((b) => b.id === a.id) === i);
  const volunteerMedications = state.simpleComponentState.get(COMPONENT_KEY, List());
  const volunteerMedicationsCollection = getCollectionByName(
    collections['users/volunteers/medications'],
    `volunteer-${volunteerId}-medications`
  ).results;

  return {
    ...props,
    loading: isPending(state.responses, GET_COLLECTION, 'users/volunteers/medications'),
    volunteerMedicationsCollection,
    medications: getCollectionByName(collections['medications/medication'], 'VALUES-medications/medication').results,
    frequencies:
      getCollectionByName(collections['medications/frequency'], 'VALUES-medications/frequency').results,
    volunteerMedications,
    types,
    genericnames,
  };
}
export default connect(
  mapStateToProps,
  {
    setSimpleComponentState,
    clearSimpleComponentState,
    getCollection,
    getVolunteerMedicationOptions,
  }
)(EditVolunteerMedicationComponent);
