import { Button, Column, FormGroup, Row } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DataShape, FormProps, reduxForm } from 'redux-form';
import { STUDY_OUTCOME } from '../../../consts/constants';
import { IStudyOutcomeRecord } from '../../../store/data-types'; // tslint:disable-line: no-unused-variable
import Select from '../../select';

const MESSAGES = {
  studyOutcome: 'profile-log.study_outcome',
}

export interface IFormData {
  study_outcome: string,
}

interface IExternalProps extends FormProps<DataShape, {}, {}> {
  studyOutcomes: List<IStudyOutcomeRecord>;
  onSubmit(data: IFormData): void;
}

class ResearcherLogActionsForm extends React.PureComponent<IExternalProps, {}> {
  public transformStudyOutcomes() {
    return this.props.studyOutcomes
      .map((outcome) => {
        return Map({
          value: outcome.id,
          label: `${STUDY_OUTCOME.get(outcome.type)} - ${outcome.name}`
        })
      });
  }

  public render() {
    return(
      <form onSubmit={this.props.handleSubmit}>
        <Row className="margin-vertical-base">
          <Column md={5} mdFill={1}>
            <FormGroup>
              <label className="bold">
                <FormattedMessage id={MESSAGES.studyOutcome} />
              </label>
              <Select
                onChange={(this.noop)}
                name={"study_outcome"}
                options={this.transformStudyOutcomes()}
                getName={(item) => item.get('label')}
                getValue={(item) => item.get('value')}
              />
            </FormGroup>
          </Column>
          <Column md={6}>
            <Button className="primary float-right" type="submit">
              <FormattedMessage id='generic.save'/>
            </Button>
          </Column>
        </Row>
      </form>
    )
  }

  // tslint:disable-next-line:no-empty
  private noop = () => {}
}

export default reduxForm({
  form: 'researcher-log-actions'
})(ResearcherLogActionsForm);
