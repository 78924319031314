import { Dispatch } from 'redux';
import { collectionsModule} from '../reducers/collections';
const { actions: { getCollection } } = collectionsModule;
import { IUserRecord } from '../store/data-types';
import { makeAsyncActionSet } from '../utils';
import { dispatchGenericRequest } from './index';

export interface IPayload {
  pathOrIndex: string | [string];
  value: any;
}

export interface IAction<PayloadT> {
  type: string;
  payload: PayloadT;
  meta?: any;
}
export interface IdPayload {
  id: string;
}

export const SET_SIMPLE_COMPONENT_STATE = 'SET_SIMPLE_COMPONENT_STATE';
export function setSimpleComponentState(pathOrIndex: string | [string], value: any): IAction<IPayload> {
  return { type: SET_SIMPLE_COMPONENT_STATE, payload: { pathOrIndex, value }};
}

export const CLEAR_SIMPLE_COMPONENT_STATE = 'CLEAR_SIMPLE_COMPONENT_STATE';
export function clearSimpleComponentState(pathOrIndex: string | [string]): IAction<IPayload> {
  return { type: CLEAR_SIMPLE_COMPONENT_STATE, payload: { pathOrIndex, value: null }};
}

export function getVolunteerMedicationOptions() {
  return (dispatch: Dispatch<any>) => {

    dispatch(getCollection(
      'medications/frequency',
      { pageSize: 150 },
      'VALUES-medications/frequency'
    ));

    dispatch(getCollection(
      'medications/medication',
      { pageSize: 150 },
      'VALUES-medications/medication'
    ));
  }
}

export const SET_PRESTUDY_CHECK = makeAsyncActionSet('SET_PRESTUDY_CHECK');
export function volunteerPrestudyCheck(volunteerId: string, prestudyCheckOutcomeId: string) {
  return dispatchGenericRequest(
    SET_PRESTUDY_CHECK,
    `/api/users/volunteers/${volunteerId}/pre-study-check/`,
    'POST',
    {
      study_outcome: prestudyCheckOutcomeId
    },
    undefined,
    {itemId: volunteerId}
  );
}

export const CAN_SET_PRESTUDY_CHECK = makeAsyncActionSet('CAN_SET_PRESTUDY_CHECK');
export function canSetVolunteerPrestudyCheck(volunteerId: string) {
  return dispatchGenericRequest(
    CAN_SET_PRESTUDY_CHECK,
    `/api/users/volunteers/${volunteerId}/pre-study-check/`,
    'GET',
    undefined,
    undefined,
    {itemId: volunteerId}
  );
}
