'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconLongArrowDown (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconLongArrowDown'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconLongArrowDown'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(768.81, 1791.82) scale(1, -1)',
          d: 'M765 237q8 -19 -5 -35l-350 -384q-10 -10 -23 -10q-14 0 -24 10l-355 384q-13 16 -5 35q9 19 29 19h224v1248q0 14 9 23t23 9h192q14 0 23 -9t9 -23v-1248h224q21 0 29 -19z'
        }
      )
    );
  };

})();
