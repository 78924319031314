import { getGenericAction, RECRUITMENT_FLOW_STATS_ADD_TO_STORE } from '^/actions/statistics';
import PageHeader from '^/components/page-header';
import { getFormErrors, getResponseStatus, IResponseStatus } from '^/responses';
import { IStore } from '^/store';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { FormErrors } from '../../../utils';
import FormErrorsRenderer from '../../forms/form-errors-renderer';
import GoogleCharts from '../../google-charts';
import RecruitmentFlowForm, { FORM_NAME, FORM_VALUE_NAME, IFormData } from './recruitment-flow-form';
import { SankeyChart, SankeyData } from './sankey-chart';

const MESSAGES = { title: 'reports.recruitment_flow_title' };
const RECRUITMENT_FLOW_KEY: string = 'charts/recruitment-flow';
export const RECRUITMENT_FLOW_NAME: string = 'RECRUITMENT_FLOW';

interface IStateProps extends IStore {
  response: IResponseStatus;
  formErrors: FormErrors;
  study: string | undefined;
  recruitment?: SankeyData;
}

interface IDispatchProps {
  getGenericAction: typeof getGenericAction;
}

type Props = IStateProps & IDispatchProps;

export class RecruitmentFlow extends React.PureComponent<Props, {}> {

  public render() {

    const {
      study,
      response,
      formErrors,
      recruitment,
    } = this.props;

    return (
      <div>
        <PageHeader header={<FormattedMessage id={MESSAGES.title} />} />
        <div className={'margin-top-large margin-bottom-large'}>
          <RecruitmentFlowForm onSubmit={this.handleSubmit.bind(this)} study={study} />
        </div>
        <div className={'margin-top-large'}>
          {response.hasSucceeded &&
            <SankeyChart
              responsive
              height={800}
              data={recruitment}
            />
          }
          <FormErrorsRenderer formErrors={formErrors} errorKey="non_field_errors" />
        </div>
      </div>
    );
  }

  private handleSubmit(data: IFormData) {
    this.props.getGenericAction(`${RECRUITMENT_FLOW_KEY}/${data.study}`, RECRUITMENT_FLOW_STATS_ADD_TO_STORE);
  }
}

const mapStateToProps = (store: IStateProps, props: any): IStateProps => {

  const selector = formValueSelector(FORM_NAME);

  const study: IFormData = selector(
    store,
    FORM_VALUE_NAME,
  );

  const recruitment = store.statistics.get(RECRUITMENT_FLOW_NAME) as SankeyData | undefined;

  return {
    ...props,
    study,
    response: getResponseStatus(store.responses, RECRUITMENT_FLOW_STATS_ADD_TO_STORE,
      `${RECRUITMENT_FLOW_KEY}/${study}`),
    recruitment,
    formErrors: getFormErrors(store.responses, RECRUITMENT_FLOW_STATS_ADD_TO_STORE,
      `${RECRUITMENT_FLOW_KEY}/${study}`),
  }
}

const mapDispatchToProps = {
  getGenericAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentFlow);
