import * as React from "react"
import { FormattedMessage } from "react-intl"
import { connect, } from 'react-redux';
import { resetRequestState } from '../../actions';
import { getResponseStatus, IResponseStatus } from '../../responses';

import { CHANGE_EMAIL, changeEmail} from '../../actions/auth';
import { IStore } from '../../store';
import ChangeEmailForm, {IFormData} from './change-email-form';

interface IProps {
  response: IResponseStatus;
  loading: boolean;
  resetRequestState: typeof resetRequestState;
  changeEmail: typeof changeEmail;
}

export class ChangeEmail extends React.PureComponent<IProps, void> {

  public componentWillMount() {
    this.props.resetRequestState(CHANGE_EMAIL);
  }

  public render() {
    return(
     <div>
       <h1><FormattedMessage id="pages.change-email.header"/></h1>
       <hr/>
       <ChangeEmailForm onSubmit={this.changeEmail.bind(this)} response={this.props.response} />
     </div>
    )
  }

  private changeEmail(data: IFormData) {
    this.props.changeEmail(data.email);
  }
}

export function mapStateToProps({responses}: IStore, existing: Partial<IProps>) {
  return {
    ...existing,
    response: getResponseStatus(responses, CHANGE_EMAIL)
  }
}

export default connect(mapStateToProps, {changeEmail, resetRequestState})(ChangeEmail);
