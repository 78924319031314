import { Modal, ModalCloseIcon, ModalHeader } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal(modal: React.ReactNode) {
  return {
    payload: modal,
    type: OPEN_MODAL
  };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

export interface ISimpleModalConfig {
  title?: string;
  body: React.ReactNode,
}

export interface IModalWrapperExternalProps {
  config: ISimpleModalConfig;
}

export interface IModalWrapperProps extends IModalWrapperExternalProps {
  closeModal(): void
}

const ModalWrapperComponent = ({config, closeModal}: IModalWrapperProps) => (
  <Modal onClickOutside={closeModal}>
    <ModalHeader>
      <ModalCloseIcon onClick={closeModal}>
        <FontAwesome.IconTimes className="icon" />
      </ModalCloseIcon>
      <h1>{config.title || ''}</h1>
    </ModalHeader>
    {config.body}
  </Modal>
);

export const ModalWrapper = connect<{}, {}, IModalWrapperExternalProps>(undefined, {closeModal})(ModalWrapperComponent);

export function openSimpleModal(config: ISimpleModalConfig) {
  return openModal(<ModalWrapper config={config} />);
}
