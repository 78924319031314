import { Map, Record } from 'immutable';
import { collectionsFunctor } from '../collections/';
import {
  ICollection, TStateRecord // tslint:disable-line: no-unused-variable
} from '../collections/types';
import {
  AdvertRecord,
  AdvertTypeRecord,
  CallOutcomeRecord,
  ContactTypeRecord,
  IAdvertRecord,
  IAdvertTypeRecord,
  ICallOutcomeRecord,
  IContactTypeRecord,
  IMedicationRecord,
  IMotivationRecord,
  INamedActiveObjectRecord,
  IProfileLogRecord,
  ISiteRecord,
  IStudyCraOptionRecord,
  IStudyOutcomeRecord,
  IStudySiteOptionRecord,
  ITitleRecord,
  IUserRecord,
  IVolunteerListRecord,
  IVolunteerMedication,
  MedicationRecord,
  MotivationRecord,
  NamedActiveObjectRecord,
  ProfileLogRecord,
  SiteRecord,
  StudyCraOptionRecord,
  StudyOutcomeRecord,
  StudySiteOptionRecord,
  TitleRecord,
  UserRecord,
  VolunteerListRecord,
  VolunteerMedicationRecord,
} from '../store/data-types';
import {
  IStudyRecord,
  IStudySiteRecord,
  IStudyVolunteerRecord,
  IVolunteerLocationRecord,
  StudyRecord,
  StudySiteRecord,
  StudyVolunteerRecord,
  VolunteerLocationRecord,
} from '../store/data-types/studies';

export const typeToRecordMapping = {
  'drivers/adverts': AdvertRecord,
  'drivers/adverts-types': AdvertTypeRecord,
  'drivers/motivations': MotivationRecord,
  'medications/class': NamedActiveObjectRecord,
  'medications/unit': NamedActiveObjectRecord,
  'medications/manufacturer': NamedActiveObjectRecord,
  'medications/frequency': NamedActiveObjectRecord,
  'medications/type': NamedActiveObjectRecord,
  'medications/generic-name': NamedActiveObjectRecord,
  'medications/medication': MedicationRecord,
  'profile-logs/call-outcomes': CallOutcomeRecord,
  'profile-logs/contact-types': ContactTypeRecord,
  'profile-logs/volunteer': ProfileLogRecord,
  'studies/study-outcomes': StudyOutcomeRecord,
  'sites': SiteRecord,
  'sites/study-site-options': StudySiteOptionRecord,
  'studies': StudyRecord,
  'studies/sites': StudySiteRecord,
  'users': UserRecord,
  'users/study-cra-options': StudyCraOptionRecord,
  'users/me': UserRecord,
  'users/titles': TitleRecord,
  'users/volunteers': VolunteerListRecord,
  'users/volunteers/user': UserRecord,
  'users/volunteers/medications': VolunteerMedicationRecord,
  'studies/<id>/volunteers': StudyVolunteerRecord,
  'users/volunteers/locations': VolunteerLocationRecord,
}

export interface ICollectionsState {
  'drivers/adverts': TStateRecord<IAdvertRecord>;
  'drivers/adverts-types': TStateRecord<IAdvertTypeRecord>;
  'drivers/motivations': TStateRecord<IMotivationRecord>;
  'medications/class': TStateRecord<INamedActiveObjectRecord>;
  'medications/unit': TStateRecord<INamedActiveObjectRecord>;
  'medications/manufacturer': TStateRecord<INamedActiveObjectRecord>;
  'medications/frequency': TStateRecord<INamedActiveObjectRecord>;
  'medications/type': TStateRecord<INamedActiveObjectRecord>;
  'medications/generic-name': TStateRecord<INamedActiveObjectRecord>;
  'medications/medication': TStateRecord<IMedicationRecord>;
  'profile-logs/call-outcomes': TStateRecord<ICallOutcomeRecord>;
  'profile-logs/contact-types': TStateRecord<IContactTypeRecord>;
  'profile-logs/volunteer': TStateRecord<IProfileLogRecord>;
  'studies/study-outcomes': TStateRecord<IStudyOutcomeRecord>;
  'sites': TStateRecord<ISiteRecord>;
  'sites/study-site-options': TStateRecord<IStudySiteOptionRecord>;
  'studies': TStateRecord<IStudyRecord>;
  'studies/sites': TStateRecord<IStudySiteRecord>;
  'users/titles': TStateRecord<ITitleRecord>;
  'users/volunteers': TStateRecord<IVolunteerListRecord>;
  'users': TStateRecord<IUserRecord>;
  'users/study-cra-options': TStateRecord<IStudyCraOptionRecord>;
  'users/volunteers/medications': TStateRecord<IVolunteerMedication>;
  'studies/<id>/volunteers': TStateRecord<IStudyVolunteerRecord>;
  'users/volunteers/locations': TStateRecord<IVolunteerLocationRecord>;
}

export interface ICollectionsStateRecord extends Record.Instance<ICollectionsState>, Readonly<ICollectionsState> {}
export const CollectionStateRecord = Record<ICollectionsState>({
  'drivers/adverts': Map<string, ICollection<IAdvertRecord>>(),
  'drivers/adverts-types': Map<string, ICollection<IAdvertTypeRecord>>(),
  'drivers/motivations': Map<string, ICollection<IMotivationRecord>>(),
  'medications/class': Map<string, ICollection<INamedActiveObjectRecord>>(),
  'medications/unit': Map<string , ICollection<INamedActiveObjectRecord>>(),
  'medications/manufacturer': Map<string, ICollection<INamedActiveObjectRecord>>(),
  'medications/frequency': Map<string, ICollection<INamedActiveObjectRecord>>(),
  'medications/type': Map<string, ICollection<INamedActiveObjectRecord>>(),
  'medications/generic-name': Map<string, ICollection<INamedActiveObjectRecord>>(),
  'medications/medication': Map<string | undefined, ICollection<IMedicationRecord>>(),
  'profile-logs/call-outcomes': Map<string, ICollection<ICallOutcomeRecord>>(),
  'profile-logs/contact-types': Map<string, ICollection<IContactTypeRecord>>(),
  'profile-logs/volunteer': Map<string, ICollection<IProfileLogRecord>>(),
  'studies/study-outcomes': Map<string, ICollection<IStudyOutcomeRecord>>(),
  'sites': Map<string, ICollection<ISiteRecord>>(),
  'sites/study-site-options': Map<string, ICollection<IStudySiteOptionRecord>>(),
  'studies': Map<string, ICollection<IStudyRecord>>(),
  'studies/sites': Map<string, ICollection<IStudySiteRecord>>(),
  'users/titles': Map<string, ICollection<ITitleRecord>>(),
  'users/volunteers': Map<string, ICollection<IVolunteerListRecord>>(),
  'users': Map<string, ICollection<IUserRecord>>(),
  'users/study-cra-options': Map<string, ICollection<IStudyCraOptionRecord>>(),
  'users/volunteers/medications': Map<string, ICollection<IVolunteerMedication>>(),
  'studies/<id>/volunteers': Map<string, ICollection<IStudyVolunteerRecord>>(),
  'users/volunteers/locations': Map<string, ICollection<IVolunteerLocationRecord>>(),
});

export const collectionsModule = collectionsFunctor(
  typeToRecordMapping,
  CollectionStateRecord
);
