import { Map } from 'immutable';
import { IAction } from '../actions';
import { CAN_SET_PRESTUDY_CHECK, SET_PRESTUDY_CHECK } from '../actions/actions';
import { LOAD_DASHBOARD_STUDY_OPTIONS } from '../actions/statistics';
import { GET_VOLUNTEER_COUNTS, LOAD_MARKERS } from '../actions/studies';
import { GET_STUDY_OUTCOME, SET_STUDY_OUTCOME } from '../actions/volunteers';
import { IStudyOptionRecord } from '../store/data-types/studies';

export type VolunteerCounts = Map<string | undefined, number>;

export const volunteerCounts = (
  state = Map<string, number>(),
  action: IAction<any, any>
): Map<string, number> => {
  switch (action.type) {
    case GET_VOLUNTEER_COUNTS.SUCCESS:
      return Map<string, number>(action.payload);
    default:
      return state;
  }
};

export const canSetPreStudyOutcome = (
  state = Map<string, boolean>(),
  action: IAction<any, any>
): Map<string, boolean> => {
  switch (action.type) {
    case CAN_SET_PRESTUDY_CHECK.SUCCESS:
    case SET_PRESTUDY_CHECK.SUCCESS:
      return state.set(action.meta.itemId, action.payload.can_set_prestudy_check);
     default:
       return state;
  }
}

export const researcherStudyOutcome = (
  state = Map<string, string>(),
  action: IAction<any, any>
): Map<string, string> => {
  switch (action.type) {
    case SET_STUDY_OUTCOME.SUCCESS:
    case GET_STUDY_OUTCOME.SUCCESS:
      return Map<string, string>(action.payload);
    default:
      return state;
  }
}

export const markers = (
  state = [],
  action: IAction<any, any>
): google.maps.LatLng[] => {
  switch (action.type) {
    case LOAD_MARKERS.SUCCESS:
      return action.payload.map((volunteer: {location: google.maps.LatLng}) => volunteer.location);
    default:
      return state;
  }
}

export const studyOptions = (
  state = [],
  action: IAction<any, any>
): ReadonlyArray<IStudyOptionRecord> => {
  switch (action.type) {
    case LOAD_DASHBOARD_STUDY_OPTIONS.SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
}
