import { Alert, Column } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';

import Loading from '../../common/Loading';

interface IMessagesProps extends React.HTMLProps<JSX.Element> {
  isEditing?: boolean;
  hasSucceeded?: boolean;
  hasFailed?: boolean;
  isPending?: boolean;
  errors?: List<string>;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
}

export const Messages = ({
  isPending,
  hasSucceeded,
  hasFailed,
  errors,
  isEditing,
  additionalSuccessMessage,
  additionalFailureMessage,
}: IMessagesProps) =>
  <div>
    {isPending && <Loading />}
    {hasSucceeded &&
      <Column>
        <Alert className="success">
          <strong>
            <p>
              {isEditing ? 'Successfully updated.' : 'Successfully created.'}
            </p>
          </strong>
        </Alert>
        {additionalSuccessMessage}
      </Column>}
    {hasFailed &&
      <Column>
        <Alert className="error">
          <strong>
            <p>Failed to update.</p>
            {(errors || List()).map((error: any) =>
              <p>
                {error}
              </p>
            )}
          </strong>
        </Alert>
        {additionalFailureMessage}
      </Column>}
  </div>;

export default Messages;
