import { List, Map } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from "react-router";

import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../collections/types';
import { collectionsModule, ICollectionsState} from '../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../responses';
import { IStore } from "../../store";
import { IChadminListMessages} from '../../store/data-types';
import AdminList from '../chadmin/list/AdminList';
import { TColumns } from '../chadmin/types';
import InactiveToggle from '../inactive-toggle';
import PageHeader from '../page-header';

const { actions: { getCollection, deleteItem } } = collectionsModule;

const PAGE_SIZE = 15;

interface IExternalProps {
  collectionColumns: List<TColumns>;
  collectionName: string;
  collectionKey: keyof ICollectionsState;
  collectionOverrideUrl?: string;
  messages?: IChadminListMessages;
  pageSize?: number;
  defaultFilters?: Map<string, string>;
  createUrl?: string;
  canCreate?: boolean;
  hideInactiveToggle?: boolean;
}

interface IProps<T> extends IExternalProps {
  items: List<T>;
  pageSize: number;
  count: number;
  page: number;
  loading: boolean;
  response: IResponseStatus;
  getCollection: typeof getCollection;
  deleteItem: typeof deleteItem;
}

interface IState {
  displayInactiveIsChecked: boolean;
}

export class GenericList<T> extends React.PureComponent<IProps<T>, IState> {

  public constructor(props: IProps<T>) {
    super(props);
    this.state = {displayInactiveIsChecked: false};
    this.onChangeInactiveToggle = this.onChangeInactiveToggle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.getCollection = this.getCollection.bind(this);
  }

  public componentWillMount() {
    this.getCollection({});
  }

  public render () {
    const {
      items,
      count,
      page,
      collectionName,
      collectionKey,
      collectionColumns,
      pageSize,
      hideInactiveToggle,
   } = this.props;

    const { createUrl = collectionKey } = this.props;

    return (
      <div>
        { this.props.messages &&
          <PageHeader header={<FormattedMessage id={this.props.messages.title} />} className="text-align-right">
            { !hideInactiveToggle &&
              <InactiveToggle onChange={this.onChangeInactiveToggle} />
            }
            { this.props.canCreate &&
              <Link
                to={`/app/${createUrl}/create/`}
                className="button primary">
                <FormattedMessage id={this.props.messages.create}/>
              </Link>
            }
          </PageHeader>
        }
        <AdminList
          centered={true}
          items={items}
          columns={collectionColumns}
          defaultFilters={Map({})}
          changePage={(pageNumber: number) => this.onChangePage(pageNumber)}
          listName={collectionName}
          page={page}
          pageSize={pageSize}
          itemCount={count}
          noSearch={true}
          deleteItem={(id) => this.props.deleteItem(collectionKey, id.toString(), collectionName)}
        />
      </div>
    );
  }

  public getCollection(options: ICollectionOptions) {
    const newFilters = (options.filters || Map<string, string>())
    const {collectionName, collectionKey, defaultFilters, pageSize, collectionOverrideUrl} = this.props;

    return this.props.getCollection(
      collectionKey,
      {
        filters: defaultFilters ? newFilters.merge(defaultFilters || Map<string, string>()) : newFilters,
        page: options.page || 1,
        pageSize: options.pageSize || pageSize,
        ...options,
      },
      collectionName,
      collectionOverrideUrl
    );
  }

  private onChangeInactiveToggle(event: React.FormEvent<HTMLInputElement>) {
    const { page } = this.props;

    const displayInactiveIsChecked = event.currentTarget.checked;
    this.setState({displayInactiveIsChecked});

    this.getCollection({
      page: 1,
      filters: Map({include_inactive: displayInactiveIsChecked})
    })
  }

  private onChangePage(page: number) {
    const { hideInactiveToggle } = this.props;
    const { displayInactiveIsChecked } = this.state;

    if (hideInactiveToggle) {
      this.getCollection({ page })
    } else {
      this.getCollection({
        page,
        filters: Map({ include_inactive: displayInactiveIsChecked })
      })
    }
  }
}

export function mapStateToProps({ collections, responses }: IStore, props: IExternalProps) {
  const {collectionName, collectionKey, pageSize = PAGE_SIZE} = props;

  const c = collections[collectionKey];
  const { results: items, count, page } = getCollectionByName(
    c as Map<string | undefined, ICollection<{}>>,
    collectionName
  );

  return {
    pageSize,
    items,
    count,
    page,
    loading: isPending(responses, GET_COLLECTION, collectionKey),
    response: getResponseStatus(responses, GET_COLLECTION, collectionKey)
  };
}

export default connect(mapStateToProps, { getCollection, deleteItem })(GenericList);
