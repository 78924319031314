import * as React from 'react';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

import PictureUpload from '../../../common/PictureUpload';

export const FieldImage = ({
  readOnly,
  config,
  errors,
  value,
  className,
  setUpload,
  formName,
  name,
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) => {
  return (
    <FieldGroup className={className} config={config} errors={errors}>
      { readOnly ?
        <span className="input">
          <img
            className="image-field"
            src={value || '/static/images/placeholder-image.png'}
          />
        </span>
      :
        <PictureUpload
          className="input image-field"
          src={value && (value.preview || value)}
          display={(src: any) =>
            <img src={src || '/static/images/placeholder-image.png'} />}
          setPendingUpload={(file: any) => setUpload && setUpload(file)}
          onSubmit={() => null}
        />
      }
    </FieldGroup>
  );
};

export default FieldImage;
