import * as React from 'react';

import { Column, Row } from '@dabapps/roe';

import { TColumn, TColumns, TItem, TItems } from '../types';

import Pagination from '../../content/pagination';
import Loading from '../common/Loading';
import Table from '../common/Table';
import AdminListHeader from './AdminListHeader';
import AdminListItem from './AdminListItem';
import AdminListSearch from './AdminListSearch';

interface IAdminListItemsProps {
  items: TItems;
  columns: TColumns;
  hidePagination?: boolean;
  isLoading?: boolean;
  listName: string;
  changePage: (pageNumber: number) => void;
  noItemsMessage?: string;
  noSearch?: boolean;
  centered?: boolean;
  pageSize: number;
  removeItem: (itemId: string | number) => void;
  search: (searchString: string) => void;
  setSortBy: (sortPath: string) => void;
  sortBy?: string;
  sortByReversed?: boolean;
  title?: string;
  searchString?: string;
  page: number;
  itemCount: number;
}

export default class AdminListItems extends React.PureComponent<
  IAdminListItemsProps,
  undefined
> {
  public render() {
    const {
      title,
      items,
      centered,
      noItemsMessage,
      columns,
      listName,
      noSearch,
      sortBy,
      setSortBy,
      sortByReversed,
      hidePagination,
      pageSize,
      isLoading,
      removeItem,
      search,
      changePage,
      searchString,
      itemCount,
      page,
    } = this.props;

    return (
      <div className={centered ? 'admin-list-container admin-list-container-centered' : 'admin-list-container'}>
        <Row>
          <Column xs={12}>
            {title &&
              <div className="col-1-2">
                <h3>
                  {title}
                </h3>
              </div>}
            <div className={title ? 'col-1-2' : 'col-1'}>
              {!noSearch &&
                <AdminListSearch
                  onSubmit={(data: any) => search(data.search)}
                  form={listName + '_search'}
                  loading={isLoading}
                  initialValues={{ searchString }}
                />}
            </div>
          </Column>
        </Row>

        {isLoading
          ? <Loading />
          : <Table
              renderRow={(item: TItem, idx: number, fields: TColumns) =>
                <AdminListItem
                  key={idx}
                  item={item}
                  fields={fields}
                  removeItem={removeItem}
                />}
              renderHeaders={(headers: TColumns) =>
                headers.map((header: TColumn, idx: number) =>
                  <AdminListHeader
                    key={idx}
                    header={header}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    reversed={sortByReversed}
                  />
                )}
              rows={items}
              columns={columns}
              emptyMessage={noItemsMessage || 'No entries'}
            />}

        {!hidePagination && items.count() ?
          <Pagination
            pageSize={pageSize}
            changePage={changePage}
            currentPage={page}
            itemCount={itemCount}
          /> : null }
      </div>
    );
  }
}
