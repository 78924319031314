import { Alert } from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { resetRequestState } from '../../actions';
import { editSitesDetail, SITES_DETAIL } from '../../actions/sites';
import { UPDATE_ITEM } from "../../items/actions";
import { PermissionHOCChildProps } from '../../permissions/permissions-hoc';
import { collectionsModule, ICollectionsState, } from '../../reducers/collections';
import { itemsModule, ItemType } from '../../reducers/items';
import { TResearchersList, TResearcherState } from '../../reducers/sites';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import { ISiteRecord, IUserRecord } from '../../store/data-types';
import FormErrorsRenderer from '../forms/form-errors-renderer';
import SitesDetailFields, { ISitesDetail } from './sites-detail-fields';
import SitesDetailForm, { IFormData } from './sites-detail-form';

import Messages from '../chadmin/edit-create/dynamic-forms/Messages';

const { actions: { loadItem, updateItem } } = itemsModule;
const { actions: { getAllCollection } } = collectionsModule;

interface IStateProps {
  response: IResponseStatus;
  siteResearcher: Map<string, TResearchersList>;
}

interface IExternalProps extends PermissionHOCChildProps {
  initialValues: ISitesDetail;
  params: { id: string; };
  sites: ISiteRecord;
  item: ISiteRecord;
  itemsName: ItemType;
  siteResearcherIds: string[];
}

interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
  resetRequestState: typeof resetRequestState;
  editSitesDetail: typeof editSitesDetail;
  loadItem: typeof loadItem;
  onSubmit(data: IFormData): void;
}

type Props = IDispatchProps & IStateProps & IExternalProps;
export class SiteDetailPage extends React.PureComponent<Props, {}> {
  public render () {
    const { hasSucceeded, isLoading, formErrors } = this.props.response;
    return (
      <div>
        { hasSucceeded && <Alert className="success">
          <p><FormattedMessage id='me.success'/></p>
        </Alert>}
        <FormErrorsRenderer formErrors={formErrors} errorKey="medications"/>
        <SitesDetailForm {...this.props} onSubmit={this.onSubmit}
          params={this.props.params}
        />
      </div >
    );
  }
  private onSubmit = (data: IFormData) => {
    this.props.editSitesDetail(
    this.props.params.id,
      data.name,
      data.radius,
      data.active,
      data.address_1,
      data.address_2,
      data.postcode,
      data.city,
      data.county,
      this.props.siteResearcherIds
    );
  }
}

export function mapStateToProps
({responses, siteResearcher}: IStore, existing: IExternalProps): IExternalProps | IStateProps {
  return {
    siteResearcher,
    response: getResponseStatus(responses, SITES_DETAIL),
    siteResearcherIds: siteResearcher.get(
      existing.params.id, List<IUserRecord>()
    ).map((researcher) => researcher.get('id'))
  }
}

export default connect(mapStateToProps, {editSitesDetail, resetRequestState})(SiteDetailPage);
