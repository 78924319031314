'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconSpoon (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconSpoon'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconSpoon'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(768.81, 1791.82) scale(1, -1)',
          d: 'M704 1008q0 -145 -57 -243.5t-152 -135.5l45 -821q2 -26 -16 -45t-44 -19h-192q-26 0 -44 19t-16 45l45 821q-95 37 -152 135.5t-57 243.5q0 128 42.5 249.5t117.5 200t160 78.5t160 -78.5t117.5 -200t42.5 -249.5z'
        }
      )
    );
  };

})();
