import { List, Map } from "immutable";
import * as React from "react";
import { FontAwesome } from "react-inline-icons";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from 'react-router';
import { Field, FormProps, formValueSelector, reduxForm } from 'redux-form';
import { getCollectionByName } from "../../../../collections/reducers";
import { collectionsModule } from "../../../../reducers/collections";
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  IResponseStatus,
  isPending
} from "../../../../responses";
import { IStore } from "../../../../store";
import { ReactSelectWrapper } from '../../../forms/react-select-wrapper';

import {
  IAdvertRecord,
  IAdvertTypeRecord,
  IUserRecord
} from "../../../../store/data-types";
import { disableSubmitOnEnter, FormErrors } from "../../../../utils";
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import EditDriversDetailsFormFields, { IDataProps } from "../form-fields/edit-drivers-details-form-fields";

const { actions: { getCollection } } = collectionsModule;

import { Alert, Button, FormGroup } from '@dabapps/roe';

export interface IFormData {
  volunteer: {
    advert_type: string;
    advert_source: string;
    motivations: List<string>;
  }
}

interface IExternalProps {
  advertTypesOptions: IDataProps[];
  item: IUserRecord;
  itemIsPending: boolean;
  errors?: FormErrors | null;
  getCollection: typeof getCollection;
  initialValues: IFormData;
  motivationErrors: Map<string, List<string>>;
  onSubmit(data: IFormData): void;
}

interface IFormProps extends IExternalProps {
  advertOptions: IDataProps[];
  selectedAdvertType: IDataProps | string;
}
type IProps = FormProps<IFormData, void, void> & IFormProps

class AdvertForm extends React.PureComponent<IProps, {}> {
  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.selectedAdvertType !== this.props.selectedAdvertType) {
      let adType;
      if (typeof nextProps.selectedAdvertType === 'string') {
        adType = nextProps.selectedAdvertType;
      } else {
        adType = nextProps.selectedAdvertType.value;
      }
      this.props.getCollection(
        "drivers/adverts",
        {
          pageSize: 150,
          filters: adType ? Map({ ad_type: adType }) : Map<string, string>(),
        },
        "drivers/adverts"
      );
    }
  }

  public render() {
    const {
      handleSubmit,
      advertTypesOptions,
      errors,
      itemIsPending,
      advertOptions,
      motivationErrors
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit} onKeyDown={disableSubmitOnEnter}>
          <EditDriversDetailsFormFields
            advertOptions={advertOptions}
            advertTypesOptions={advertTypesOptions}
            errors={errors}
            motivationErrors={motivationErrors}
          />
          <FormErrorsRenderer formErrors={errors} errorKey="non_field_errors"/>
          <FormGroup className="form-controls">
            <Button type="submit" className="button primary" disabled={itemIsPending}>
              <FormattedMessage id='generic.save' />
            </Button>
            <Link to="/app/" className="cancel-link">
              <FormattedMessage id='generic.cancel'/>
            </Link>
          </FormGroup>
        </form>
      </div>
    )
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IProps {
  const { collections } = state;
  const selector = formValueSelector('advert-form');

  const adverts = getCollectionByName(
    collections["drivers/adverts"],
    "drivers/adverts"
  ).results

  return {
    ...props,
    advertOptions: adverts.map((entry: IAdvertRecord) => {
      return {
        value: entry.id,
        label: entry.name
      };
    }).toArray(),
    selectedAdvertType: selector(state, 'volunteer.advert_type')
  };
}

const ConnectedAdvertForm = reduxForm({
  form: 'advert-form'
})(AdvertForm)

export default connect(
  mapStateToProps,
  {
    getCollection
  }
)(ConnectedAdvertForm);
