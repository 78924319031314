import { Column, Row } from '@dabapps/roe';
import * as React from "react";
import { Link } from 'react-router';

export default class LandingHeader extends React.PureComponent<{}, void> {
  public render() {
    return (
      <Row>
        <Column xs={12} sm={3}>
          <Link to="/" className="landing-logo">
            <img src={'/static/images/logo.jpg'} alt="Volunteer outreach programme logo" />
          </Link>
        </Column>
        <Column xs={12} sm={9}>
          <h1 className="landing-strapline">
            One Research<br/>
            Voluntee
            <span className="light-text">
              r{' '}
              <span className="lightest-text">O</span>
              <span className="lighter-text">u</span>
              t
            </span>
            reach Programme
          </h1>
        </Column>
      </Row>
    );
  }
}
