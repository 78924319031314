import {
  Button,
  Column,
  Row,
  SpacedGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ReactSelect from 'react-select';
import { formValueSelector } from 'redux-form';
import { ADD_TO_COLLECTION } from '../../../../collections/actions';
import { getCollectionItems } from '../../../../collections/reducers';
import { collectionsModule } from '../../../../reducers/collections';
import { VolunteerCounts } from '../../../../reducers/studies';
import { IStore } from '../../../../store/';
import { IStudySiteOptionRecord, LabelValueOptions } from '../../../../store/data-types';
import { SiteFormData } from '../../../../store/data-types/studies';
import { transformIdNameToValueLabel } from '../../../../utils';
import { IFormArrayComponentProps } from '../../../forms/form-array';
import Site from './site';

const { actions: { getCollection, getAllCollection } } = collectionsModule;

const SITE_OPTIONS_ROUTE = 'sites/study-site-options';
const CTA_OPTIONS_ROUTE = 'users/study-cra-options'
const FORM_NAME = 'study-search-form';

const selector = formValueSelector(FORM_NAME);

interface IStateProps {
  volunteerCounts: VolunteerCounts;
  sites: List<IStudySiteOptionRecord>;
  siteChoices: LabelValueOptions;
  clinicalResearchersChoices: LabelValueOptions;
}

interface IDispatchProps {
  getAllCollection: typeof getAllCollection;
  getCollection: typeof getCollection;
}

type IOwnProps = IFormArrayComponentProps<SiteFormData>;

type Props = IOwnProps & IStateProps & IDispatchProps;

export class Sites extends React.PureComponent<Props, {}> {
  public componentWillMount () {
    this.props.getAllCollection(SITE_OPTIONS_ROUTE);
    this.props.getAllCollection(CTA_OPTIONS_ROUTE);
  }

  public render () {
    const {
      items,
      removeItem,
      siteChoices,
      onChange,
      clinicalResearchersChoices,
      volunteerCounts,
    } = this.props;

    return (
      <div>
        <p>
          <strong>
            Add site
          </strong>
        </p>

        <ReactSelect
          simpleValue
          className="input"
          placeholder="Enter site name..."
          clearable={false}
          options={siteChoices}
          onChange={this.onSelectSite}
        />

        <hr />

        <p>
          <strong>
            Selected sites
          </strong>
        </p>

        <Table fill>
          <TableHead>
            <TableRow>
              <TableHeader>
                Name
              </TableHeader>
              <TableHeader>
                Site number
              </TableHeader>
              <TableHeader>
                Green lit
              </TableHeader>
              <TableHeader>
                CRA
              </TableHeader>
              <TableHeader>
                Researchers
              </TableHeader>
              <TableHeader>
                Volunteers
              </TableHeader>
              <TableHeader />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              !items.length && (
                <TableRow>
                  <TableCell colSpan={6}>
                    No sites selected
                  </TableCell>
                </TableRow>
              )
            }
            {
              items.map(({id, value}) => (
                <Site
                  key={id}
                  siteKey={id}
                  siteName={this.getSiteName(value.site)}
                  site={value}
                  removeSite={removeItem}
                  updateSite={onChange}
                  clinicalResearchersChoices={clinicalResearchersChoices}
                  volunteerCounts={volunteerCounts}
                />
              ))
            }
          </TableBody>
        </Table>
      </div>
    );
  }

  private onSelectSite = (value: string) => {
    this.props.addItem(null, {site: value});
  }

  private getSiteName = (siteId: string | undefined) => {
    const matchingSite = this.props.sites
      .find((site) => site.id === siteId);

    if (!matchingSite) {
      return '--';
    }

    return matchingSite.name;
  }
}

export const mapStateToProps = (state: IStore, props: IOwnProps): IStateProps & IOwnProps => {
  const { collections, volunteerCounts, responses } = state;

  const selectedSites: string[] = (selector(state, 'study_sites') || [])
    .map((site: SiteFormData) => site.site);

  const sites = getCollectionItems(collections[SITE_OPTIONS_ROUTE]);
  const clinicalResearchers = getCollectionItems(collections[CTA_OPTIONS_ROUTE]);

  return {
    ...props,
    volunteerCounts,
    clinicalResearchersChoices: clinicalResearchers.map((researcher) => ({
      label: researcher.display_full_name,
      value: researcher.id,
    })).toArray(),
    sites,
    siteChoices: transformIdNameToValueLabel(sites)
      .filter((site) => selectedSites.indexOf(site.value) < 0)
  };
}

export default connect(mapStateToProps, { getAllCollection, getCollection })(Sites);
