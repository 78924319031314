import { Column, FormGroup, Row } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { GET_COLLECTION } from '../../../../collections/actions';
import { getCollectionItems } from '../../../../collections/reducers';
import { INCLUDE_CHOICES, MEDICATION_TYPE, NBSP } from '../../../../consts/constants';
import { isPending } from '../../../../responses';
import { IStore } from '../../../../store';
import { IMedicationRecord } from '../../../../store/data-types';
import { MedicationFormData } from '../../../../store/data-types/studies';
import Select from '../../../forms/select';

export const FORM_NAME = 'study-search-form';

const medicationToMedicationOption = (medication: IMedicationRecord) => ({
  dosage: {
    min: parseFloat(medication.min_dosage),
    max: parseFloat(medication.max_dosage),
  },
  medication: {
    value: medication.id,
    label: medication.trade_name
  },
  generic_name: {
    value: medication.generic_name_display.id,
    label: medication.generic_name_display.name,
  },
  med_type: {
    value: medication.med_type,
    label: MEDICATION_TYPE.get(medication.med_type),
  },
  med_class: {
    value: medication.med_class_display.id,
    label: medication.med_class_display.name,
  },
})

interface IMedicationOptionDisplay {
  value: string;
  label: string;
}

// tslint:disable-next-line:no-unused-variable
interface IMedicationOption {
  dosage: {
    min: number;
    max: number;
  };
  medication: IMedicationOptionDisplay;
  generic_name: IMedicationOptionDisplay;
  med_type: IMedicationOptionDisplay;
  med_class: IMedicationOptionDisplay;
}

interface IStateProps {
  med_types: List<IMedicationOptionDisplay>;
  medications: List<IMedicationOptionDisplay>;
  generic_names: List<IMedicationOptionDisplay>;
  med_classes: List<IMedicationOptionDisplay>;
  medicationOptions: List<IMedicationOption>;
  loadingMedicationOptions: boolean;
}

interface IOwnProps {
  medicationId: string;
  medication: MedicationFormData;
  removeMedication(id: string): void;
  updateMedication(id: string, value: MedicationFormData): void;
}

type Props = IStateProps & IOwnProps;

const MESSAGES = {
  remove: 'generic.remove',
  include: 'generic.include',
  min_dosage: 'generic.min_dosage',
  max_dosage: 'generic.max_dosage',
  medicationTypeName: 'studies.medication_type_name',
  genericNameClass: 'studies.generic_name_class',
};

export class Medication extends React.PureComponent<Props, void> {
  private onChangeMedType: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  private onChangeMedication: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  private onChangeGenericName: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  private onChangeMedClass: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  private onChangeMinDose: (event: React.ChangeEvent<HTMLInputElement>) => void;
  private onChangeMaxDose: (event: React.ChangeEvent<HTMLInputElement>) => void;
  private onChangeInclude: (event: React.ChangeEvent<HTMLSelectElement>) => void;

  public constructor (props: Props) {
    super(props);

    this.onChangeMedType = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('med_type', event);
    this.onChangeMedication = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('medication', event);
    this.onChangeGenericName = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('generic_name', event);
    this.onChangeMedClass = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('med_class', event);
    this.onChangeMinDose = (event: React.ChangeEvent<HTMLInputElement>) => this.onChange('min_dosage', event);
    this.onChangeMaxDose = (event: React.ChangeEvent<HTMLInputElement>) => this.onChange('max_dosage', event);
    this.onChangeInclude = (event: React.ChangeEvent<HTMLSelectElement>) => this.onChange('include', event);
  }
  public render () {
    const {
      medication,
      loadingMedicationOptions,
      med_types,
      medications,
      generic_names,
      med_classes,
    } = this.props;

    return (
      <Row className="medication">
        <Column md={3}>
          <FormGroup block>
            <label>
              <FormattedMessage id={MESSAGES.medicationTypeName} />
            </label>
            <Select
              value={medication.med_type}
              loading={loadingMedicationOptions}
              placeholder="Select a medication type"
              options={med_types}
              onChange={this.onChangeMedType}
            />
            <Select
              value={medication.medication}
              className="margin-top-small"
              loading={loadingMedicationOptions}
              placeholder="Select a medication name"
              options={medications}
              onChange={this.onChangeMedication}
            />
          </FormGroup>
        </Column>
        <Column md={3}>
          <FormGroup block>
            <label>
              <FormattedMessage id={MESSAGES.genericNameClass} />
            </label>
            <Select
              value={medication.generic_name}
              loading={loadingMedicationOptions}
              placeholder="Select a generic name"
              options={generic_names}
              onChange={this.onChangeGenericName}
            />
            <Select
              value={medication.med_class}
              className="margin-top-small"
              loading={loadingMedicationOptions}
              placeholder="Select a generic class"
              options={med_classes}
              onChange={this.onChangeMedClass}
            />
          </FormGroup>
        </Column>
        <Column xs={4} md={1}>
          <FormGroup block>
            <label>
              <FormattedMessage id={MESSAGES.min_dosage} />
            </label>
            <input
              value={typeof medication.min_dosage === 'undefined' ? '' : medication.min_dosage}
              type="number"
              min="0"
              onChange={this.onChangeMinDose}
            />
          </FormGroup>
        </Column>
        <Column xs={4} md={1}>
          <FormGroup block>
            <label>
              <FormattedMessage id={MESSAGES.max_dosage} />
            </label>
            <input
              value={typeof medication.max_dosage === 'undefined' ? '' : medication.max_dosage}
              type="number"
              min="0"
              onChange={this.onChangeMaxDose}
            />
          </FormGroup>
        </Column>
        <Column xs={8} md={2}>
          <FormGroup block>
            <label>
              <FormattedMessage id={MESSAGES.include} />
            </label>
            <Select
              value={medication.include}
              placeholder="Please select.."
              options={INCLUDE_CHOICES}
              onChange={this.onChangeInclude}
            />
          </FormGroup>
        </Column>
        <Column md={2}>
          <FormGroup block>
            <label>
              {NBSP}
            </label>
            <a className="font-size-small" onClick={this.removeMedication}>
              <FormattedMessage id={MESSAGES.remove} />
            </a>
          </FormGroup>
        </Column>
      </Row>
    );
  }

  private onChange = (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.props.updateMedication(
      this.props.medicationId,
      {
        ...this.props.medication,
        [key]: event.target.value
      }
    );
  }

  private removeMedication = () => {
    const { medicationId, removeMedication } = this.props;

    removeMedication(medicationId);
  }
}

const uniqueOptions = (a: IMedicationOptionDisplay, index: number, list: List<IMedicationOptionDisplay>) =>
  list.findIndex((b: IMedicationOptionDisplay) => b.value === a.value) === index;

export const mapStateToProps = (state: IStore, props: IOwnProps) => {
  const { medication } = props;
  const { collections, responses } = state;

  const selectedMedType: string | undefined = medication.med_type;
  const selectedMedication: string | undefined = medication.medication;
  const selectedGenericName: string | undefined = medication.generic_name;
  const selectedMedClass: string | undefined = medication.med_class;

  const medications = getCollectionItems(collections.get('medications/medication'));

  const medicationOptions = medications
    .filter((med) => {
      return (!selectedMedType || selectedMedType === med.med_type) &&
        (!selectedMedication || selectedMedication === med.id) &&
        (!selectedGenericName || selectedGenericName === med.generic_name) &&
        (!selectedMedClass || selectedMedClass === med.med_class)
    })
    .map(medicationToMedicationOption);

  return {
    ...props,
    loadingMedicationOptions: isPending(responses, GET_COLLECTION, 'medications/medication'),
    medicationOptions,
    med_types: medicationOptions
      .map((option) => option.med_type)
      .filter(uniqueOptions),
    medications: medicationOptions
      .map((option) => option.medication)
      .filter(uniqueOptions),
    generic_names: medicationOptions
      .map((option) => option.generic_name)
      .filter(uniqueOptions),
    med_classes: medicationOptions
      .map((option) => option.med_class)
      .filter(uniqueOptions),
  };
}

export default connect(mapStateToProps)(Medication);
