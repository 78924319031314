import defaultPermissions, { IAllPermission } from './default-permissions'
// Admin Permissions
const permissions: IAllPermission = {
  ...defaultPermissions,
  volunteers: {
    hasAccess: true,
    customProps: {
      canCreate: false,
      canEdit: false,
      isAnonymized: false,
      canDownloadVolunteerAdminCsv: true
    }
  },
  volunteers_create: {
    hasAccess: false,
    customProps: {},
  },
  volunteers_detail: {
    hasAccess: true,
    customProps: {
      canCreate: false,
      canEdit: false,
      isAnonymized: false,
    }
  },
  studies: {
    hasAccess: true,
    customProps: {
      canCreate: true,
      canRemove: true,
    }
  },
  reports_csv: {
    hasAccess: true,
    customProps: {}
  },
  reports_recruitment_flow: {
    hasAccess: true,
    customProps: {}
  },
  users: {
    hasAccess: true,
    customProps: {
      canCreate: true,
    }
  },
  users_create: {
    hasAccess: true,
    customProps: {}
  },
  users_edit: {
    hasAccess: true,
    customProps: {}
  },
  home: {
    hasAccess: true,
    customProps: {
      canViewAdminStats: true,
    }
  },
};

export default permissions;
