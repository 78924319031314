import { Alert } from '@dabapps/roe';
import {
  convertMeasurementsToValue,
  convertValueToMeasurements
} from '^/components/volunteers/utils';
import { List, Map } from 'immutable';
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { formValueSelector } from 'redux-form';
import { resetRequestState } from "../../../../actions";
import { ADD_TO_COLLECTION, GET_COLLECTION } from '../../../../collections/actions';
import { getCollectionByName } from "../../../../collections/reducers";
import { LOAD_ITEM, UPDATE_ITEM } from "../../../../items/actions";
import { collectionsModule } from "../../../../reducers/collections";
import { itemsModule, ItemType } from "../../../../reducers/items";
import {
  anyPending,
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  isPending
} from "../../../../responses";
import { IStore } from "../../../../store";
import {
  IAdvertTypeRecord,
  IUserRecord,
  IUserVolunteerMedicationRecord,
  IVolunteerMedicationRecord,
  IVolunteerRecord,
  UserRecord,
  UserVolunteerMedicationRecord,
  VolunteerRecord
} from "../../../../store/data-types";
import {
  filterEmptyStringsFromList,
  flattedNestedFormErrors,
  formatMonthNumber,
  formatYear,
  FormErrors,
  generateDate,
  INestedFormErrorsResponse,
  NestedFormErrorsList,
  transformDisplayNameToValueLabel,
  transformIdNameToValueLabel
} from '../../../../utils';
import FormErrorsRenderer from "../../../forms/form-errors-renderer";
import Loading from "../../../loading";
import PageHeader from "../../../page-header";
import { PROFILE_LOGS_FORM_ID } from '../../profile-logs/profile-log-actions';
import EditVolunteerDetailsForm, { IFormData } from "./edit-volunteer-details-form";
const { actions: { getCollection } } = collectionsModule;
import { patchItemAndFetchProfileLogs } from '../../../../actions/volunteers';
import { IPermissionsProps } from '../../../../permissions/config/default-permissions';
import { IDataProps } from "../form-fields/edit-drivers-details-form-fields";
import { COMPONENT_KEY } from '../form-fields/edit-volunteer-medication-component';

const { actions: { loadItem } } = itemsModule;

interface IExternalProps {
  params: {
    id: string;
  };
  permissions: Partial<IPermissionsProps>;
  userProfile?: IUserRecord;
}

interface IProps extends IExternalProps {
  resetRequestState: typeof resetRequestState;
  profileLogEdits: Map<string, string>,
  advertsTypes: IDataProps[];
  volunteer: IVolunteerRecord;
  item: IUserRecord;
  creationHasSucceeded: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  nonFieldErrors: Map<string, List<string>>;
  telephoneErrors: Map<string, List<string>>;
  motivationErrors: Map<string, List<string>>;
  errors: FormErrors;
  patchItemAndFetchProfileLogs: typeof patchItemAndFetchProfileLogs;
  initialValues: IFormData;
  loading: boolean;
  loadItem: typeof loadItem;
  itemsName: ItemType;
  getCollection: typeof getCollection;
  titleChoices: Array<{value: string, label: string}>;
  medicationEdits: List<IUserVolunteerMedicationRecord>;
}

export class EditVolunteerDetails extends React.PureComponent<IProps, {}> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this)
  }

  public componentWillMount() {
    const { params, itemsName } = this.props;
    this.loadAllOptions();
    this.props.loadItem(
      itemsName,
      params.id,
      false,
      `users/volunteers/${params.id}/user`
    );
  }

  public componentWillUnmount() {
    const { itemsName } = this.props;
    this.props.resetRequestState(UPDATE_ITEM, itemsName);
    this.props.resetRequestState(ADD_TO_COLLECTION, 'users/volunteers');
  }

  public render() {
    const {
      advertsTypes,
      creationHasSucceeded,
      itemHasSucceeded,
      itemHasFailed,
      loading,
      ...rest
    } = this.props;

    if (loading) {
      return (
        <Loading />
      );
    }

    if (!rest.item || !rest.item.volunteer) {
      return (
        <div>
          Failed to load item.
        </div>
      );
    }

    return (
      <div>
        {creationHasSucceeded &&
          <Alert className="success">
            <p><FormattedMessage id='volunteer_profile.created'/></p>
          </Alert>
        }
        {itemHasSucceeded &&
          <Alert className="success">
            <p><FormattedMessage id='volunteer_profile.success' /></p>
          </Alert>
        }
        {itemHasFailed &&
          <Alert className="error">
            <p><FormattedMessage id='generic.update_fail' /></p>
          </Alert>
        }
        <EditVolunteerDetailsForm
          {...rest}
          advertTypesOptions={advertsTypes}
          onSubmit={this.submit}
          userProfile={rest.userProfile}
        />
      </div>
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      'drivers/adverts-types',
      { pageSize: 150 },
      'drivers/adverts-types'
    );
    this.props.getCollection(
      'users/titles',
      { pageSize: 150 },
      'users/titles'
    );
  }

  private submit(data: IFormData) {
    const {
      volunteer: {
        motivations,
        height,
        weight,
        ...remainingVolunteer
      },
      ...user
    } = data;
    let { volunteer } = this.props;
    const { item, params, itemsName, medicationEdits } = this.props;

    if (motivations) {
      volunteer = volunteer.set('motivations', filterEmptyStringsFromList(motivations));
    }

    if (height) {
      volunteer = volunteer
        .set('height', convertMeasurementsToValue(height))
        .set('height_display_unit', height.display_unit)
    }

    if (weight) {
      volunteer = volunteer
        .set('weight', convertMeasurementsToValue(weight))
        .set('weight_display_unit', weight.display_unit)
    }

    this.props.patchItemAndFetchProfileLogs(
      itemsName,
      item.id,
      params.id,
      item.merge(user)
        .set('profile_logs', Map<string, string>(this.props.profileLogEdits))
        .set('medications', medicationEdits)
        .set('volunteer', volunteer.merge({...remainingVolunteer})),
      `users/volunteers/${params.id}/user`
    );
  }
}

export function mapStateToProps(store: IStore, props: IExternalProps) {
  const { collections, items, responses } = store;
  const itemsName = 'users/volunteers/user';
  const item = items.get(itemsName) || UserRecord();
  const volunteer = item.volunteer ? item.volunteer : VolunteerRecord();
  const volunteerMedications = store.simpleComponentState.get(COMPONENT_KEY, List());

  const medicationEdits = volunteerMedications.map((med: IVolunteerMedicationRecord) => {
      const selector = formValueSelector(med.id);
      const formValues = selector(
        store,
        'id',
        'total_dosage',
        'medication',
        'frequency',
      );
      return UserVolunteerMedicationRecord(formValues);
    });
  const profileLogSelector = formValueSelector(PROFILE_LOGS_FORM_ID);

  const profileLogEdits = profileLogSelector(
    store,
    'contact_type',
    'call_outcome',
    'has_passed_qa'
  );

  const { formErrors = Map<string, NestedFormErrorsList>() } =
    getResponseStatus(responses, UPDATE_ITEM, itemsName) as any as INestedFormErrorsResponse;

  const {
    availability,
    comments,
    gender,
    birth_date,
    telephone_numbers,
    address_1,
    address_2,
    address_3,
    city,
    county,
    postcode,
    advert_source,
    diabetes_type,
    display_hbA1c,
    hbA1c_result_display_unit,
    display_height,
    height_display_unit,
    display_weight,
    weight_display_unit,
    motivations,
  } = volunteer;
  const {
    title,
    first_name,
    last_name,
    email
  } = item;

  return {
    profileLogEdits,
    advertsTypes: transformIdNameToValueLabel(getCollectionByName(
      collections['drivers/adverts-types'],
      'drivers/adverts-types'
    ).results),
    titleChoices: transformDisplayNameToValueLabel(getCollectionByName(
      collections['users/titles'],
      'users/titles'
    ).results),
    item,
    itemsName,
    errors: getFormErrors(responses, UPDATE_ITEM, itemsName),
    loading: anyPending(
      responses, [
        [LOAD_ITEM, itemsName],
        [GET_COLLECTION, 'drivers/adverts-types'],
        [GET_COLLECTION, 'users/titles']
      ]
    ),
    creationHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, 'users/volunteers'),
    itemHasFailed: hasFailed(responses, UPDATE_ITEM, itemsName),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_ITEM, itemsName),
    itemIsPending: isPending(responses, UPDATE_ITEM, itemsName),
    initialValues: {
      title,
      first_name,
      last_name,
      email,
      volunteer: {
        availability,
        comments,
        gender,
        birth_date,
        telephone_numbers,
        address_1,
        address_2,
        address_3,
        city,
        county,
        postcode,
        advert_source,
        diabetes_type,
        hbA1c_result: display_hbA1c,
        hbA1c_result_display_unit,
        height: convertValueToMeasurements(display_height, height_display_unit),
        weight: convertValueToMeasurements(display_weight, weight_display_unit),
        motivations,
        advert_type: (volunteer && volunteer.advert_source_display &&
          volunteer.advert_source_display.ad_type_display &&
          volunteer.advert_source_display.ad_type_display.id) || '',
        month_diagnosed: volunteer && volunteer.month_diagnosed,
        year_diagnosed: volunteer && volunteer.year_diagnosed,
      }
    },
    nonFieldErrors: flattedNestedFormErrors(formErrors, 'non_field_errors'),
    telephoneErrors: flattedNestedFormErrors(formErrors, 'telephone'),
    motivationErrors: flattedNestedFormErrors(formErrors, 'motivation'),
    volunteer,
    medicationEdits
  };
}

export default connect(mapStateToProps, {
  loadItem,
  patchItemAndFetchProfileLogs,
  getCollection,
  resetRequestState
})(EditVolunteerDetails);
