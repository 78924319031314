
import * as classNames from 'classnames';
import * as React from 'react';

export interface IProps {
  className?: string;
  disabled?: boolean;
  onClick(event: React.MouseEvent<HTMLSpanElement>): void;
}

class Link extends React.PureComponent<IProps, {}> {
  public render () {
    const {
      children,
      className,
      disabled,
      ...remainingProps
    } = this.props;

    const myClassNames = [
      'link',
      disabled ? 'disabled' : '',
      className
    ];

    return (
      <span {...remainingProps} className={classNames(myClassNames)} onClick={this.onClick}>
        {children}
      </span>
    );
  }

  private onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!this.props.disabled) {
      this.props.onClick(event);
    }
  }
}

export default Link;
