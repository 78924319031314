'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reactRedux = require('react-redux');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _IntlProvider = require('./IntlProvider');

var _IntlProvider2 = _interopRequireDefault(_IntlProvider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// eslint-disable-next-line react/prop-types
var Provider = function Provider(_ref) {
  var store = _ref.store,
      children = _ref.children;
  return _react2.default.createElement(
    _reactRedux.Provider,
    { store: store },
    _react2.default.createElement(
      _IntlProvider2.default,
      null,
      children
    )
  );
};

Provider.propTypes = process.env.NODE_ENV !== "production" ? {
  children: _propTypes2.default.element.isRequired
} : {};

exports.default = Provider;