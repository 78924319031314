'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconLineChart (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconLineChart'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconLineChart'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(128.81, 1791.82) scale(1, -1)',
          d: 'M2048 0v-128h-2048v1536h128v-1408h1920zM1920 1248v-435q0 -21 -19.5 -29.5t-35.5 7.5l-121 121l-633 -633q-10 -10 -23 -10t-23 10l-233 233l-416 -416l-192 192l585 585q10 10 23 10t23 -10l233 -233l464 464l-121 121q-16 16 -7.5 35.5t29.5 19.5h435q14 0 23 -9t9 -23z'
        }
      )
    );
  };

})();
