'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getFormInitialValues = require('./selectors/getFormInitialValues');

var _getFormInitialValues2 = _interopRequireDefault(_getFormInitialValues);

var _plain = require('./structure/plain');

var _plain2 = _interopRequireDefault(_plain);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _getFormInitialValues2.default)(_plain2.default);