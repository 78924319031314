import { Alert } from '@dabapps/roe'
import { List, Map } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormErrors } from '../../utils';

const { IconExclamationTriangle } = FontAwesome;

interface IProps {
  formErrors?: FormErrors | null;
  errorKey: string;
}

export default class FormErrorsRenderer extends React.PureComponent<IProps, {}> {
  public render () {
    const {
      formErrors,
      errorKey,
      ...remainingProps
    } = this.props;

    if (!formErrors) {
      return null;
    }

    const errors: List<string> = formErrors.getIn(errorKey.split('.'), List()).filter((error: Map<string, any>) => {
      return Map.isMap(error) ? !error.isEmpty() : Boolean(error);
    });

    if (!errors.count()) {
      return null;
    }

    return (
      <div {...remainingProps}>
        {
          errors.map((error) => (

            <Alert className="error" key={error}>
              <IconExclamationTriangle className={'icon-small'} />
              {' '}
              {Map.isMap(error) ? error.toList().flatten(1).join(' ') : error}
            </Alert>
          ))
        }
      </div>
    );
  }
}
