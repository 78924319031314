import * as React from "react";
import { connect } from 'react-redux';
import { USER_TYPES } from '../../../consts/user-types';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { IUserRecord } from "../../../store/data-types";
import EditVolunteerDetails from "../edit-volunteer-details/edit-as-callhandler/edit-volunteer-details";
import PreStudyCheckBanner from "../pre-study-check";
import ProfileLogs from "../profile-logs";
import ResearcherLogActions from "../profile-logs/researcher-actions";
import ViewVolunteerDetails from "./view-volunteer-details";

interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

export class VolunteerDetails extends React.PureComponent<IExternalProps, {}> {
  public render() {
    const { params, permissions, user} = this.props;
    return (
      <div>
        {([USER_TYPES.QA, USER_TYPES.CALL_HANDLER] as string[]).indexOf(user.type) !== -1 && (
          <PreStudyCheckBanner userProfile={user} volunteerId={this.props.params.id}/>
        )}
        {user.type === USER_TYPES.RESEARCHER && (
          <ResearcherLogActions userProfile={user} volunteerId={this.props.params.id}/>
        )}
        <ProfileLogs volunteerId={params.id}/>
        {
          permissions.canEdit ?
          <EditVolunteerDetails userProfile={user} params={params} permissions={permissions}/> :
          <ViewVolunteerDetails params={params} permissions={permissions} />
        }
      </div>
    );
  }
}

export default VolunteerDetails;
