import { setPropsReducer } from '@dabapps/react-set-props';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { FormReducer, reducer as formReducer } from 'redux-form';

import { userProfile } from './auth';
import { collectionsModule } from './collections';
import { itemsModule } from './items';
import modals from './modals';
import responses from './responses';
import siteResearcher from './sites';
import statistics from './statistics';
import {
  canSetPreStudyOutcome,
  markers,
  researcherStudyOutcome,
  studyOptions,
  volunteerCounts
} from './studies';
import { simpleComponentState } from './util';

export default combineReducers({
  setPropsReducer,
  canSetPreStudyOutcome,
  collections: collectionsModule.reducers.collections,
  form: (formReducer as any as FormReducer).plugin({}), // FIXME: Typings are slightly off
  intl: intlReducer,
  items: itemsModule.reducers.items,
  routing: routerReducer,
  modals,
  userProfile,
  responses,
  simpleComponentState,
  volunteerCounts,
  researcherStudyOutcome,
  siteResearcher,
  markers,
  statistics,
  studyOptions,
});
