import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
const { IconSort, IconSortAsc, IconSortDesc } = FontAwesome;

export interface ISortProps {
  orderingKey: string;
  ordering: string | undefined;
  reverseOrdering: boolean | undefined;
  onClick?(event: React.MouseEvent<SVGSVGElement>): void;
}

export default (props: ISortProps & React.SVGAttributes<SVGSVGElement>) => {
  const { orderingKey, ordering, reverseOrdering, ...remainingProps } = props;

  const sortedByThis = ordering === orderingKey;

  const constructedProps = {
    ...remainingProps,
    className: sortedByThis ? 'active' : '',
  };

  if (!sortedByThis) {
    return <IconSort {...constructedProps} />;
  }

  if (reverseOrdering) {
    return <IconSortDesc {...constructedProps} />;
  }

  return <IconSortAsc {...constructedProps} />;
};
