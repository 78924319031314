import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseIcon,
  ModalFooter,
  ModalHeader,
  SpacedGroup,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { List, Set } from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';

import { closeModal } from '^/actions/modals';
import ResearcherRow from '^/components/studies/search/sites-selection/researcher-row';
import { LOAD_ITEM } from '^/items/actions';
import { itemsModule, } from '^/reducers/items';
import { isPending } from '^/responses';
import { IStore } from '^/store';
// tslint:disable-next-line:no-unused-variable
import { ISiteResearcherRecord } from '^/store/data-types';

const { IconTimes } = FontAwesome;
const { actions: { loadItem } } = itemsModule;

interface IOwnProps {
  selectedResearchers: Set<string>;
  siteName: string;
  siteId: string;
  onDone(researchers: string[]): any;
}

type ISetProps = Pick<IOwnProps, 'selectedResearchers'>
type SetProps = SetPropsInterface<ISetProps>;

interface IStateProps {
  loadingResearchers: boolean;
  researchers: List<ISiteResearcherRecord>;
}

interface IDispatchProps {
  closeModal: typeof closeModal;
  loadItem: typeof loadItem;
}

// tslint:disable-next-line:no-unused-variable
type Props = SetProps & IOwnProps & IStateProps & IDispatchProps;

export class ResearchersModal extends React.PureComponent<Props, {}> {
  public componentWillMount () {
    if (!this.props.researchers.count()) {
      this.props.loadItem(
        'sites',
        this.props.siteId
      );
    }
  }

  public render () {
    const {
      closeModal,
      siteName,
      researchers,
      loadingResearchers,
      selectedResearchers
    } = this.props;

    return (
      <Modal onClickOutside={closeModal}>
        <ModalHeader>
          <ModalCloseIcon onClick={closeModal}>
            <IconTimes className="icon" />
          </ModalCloseIcon>
          <h1>
            Edit {siteName && `${siteName} `}researchers
          </h1>
        </ModalHeader>
        <ModalBody>
          {
            loadingResearchers && (
              <p>
                Loading...
              </p>
            )
          }
          {
            !loadingResearchers && !researchers.count() ? (
              <p>
                There are no researchers for this site.
              </p>
            ) : (
              <Table fill>
                <TableHead>
                  <TableRow>
                    <TableHeader>
                      Include
                    </TableHeader>
                    <TableHeader>
                      Name
                    </TableHeader>
                    <TableHeader>
                      Email
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    researchers.map((researcher) => (
                      <ResearcherRow
                        key={researcher.id}
                        selected={selectedResearchers.includes(researcher.id)}
                        researcher={researcher}
                        onChange={this.onChangeResearcher}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            )
          }
        </ModalBody>
        <ModalFooter>
          <SpacedGroup block className="margin-vertical-base">
            <Button onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={this.onDone} className="primary">
              Done
            </Button>
          </SpacedGroup>
        </ModalFooter>
      </Modal>
    );
  }

  private onDone = () => {
    this.props.onDone(this.props.selectedResearchers.toArray());
    this.props.closeModal();
  }

  private onChangeResearcher = (id: string, selected: boolean) => {
    let { selectedResearchers } = this.props;

    if (selected) {
      selectedResearchers = selectedResearchers.add(id);
    } else {
      selectedResearchers = selectedResearchers.remove(id);
    }

    this.props.setProps({
      selectedResearchers,
    });
  }
}

export const mapStateToProps = (state: IStore, props: IOwnProps & SetProps): IOwnProps & SetProps & IStateProps => {
  const itemsSite = state.items.sites;
  const site = itemsSite && itemsSite.get('id') === props.siteId && itemsSite;

  return {
    ...props,
    loadingResearchers: isPending(state.responses, LOAD_ITEM),
    researchers: site && site.researchers_display || List(),
  };
}

function getInitialProps ({selectedResearchers}: ISetProps) {
  return {
    selectedResearchers,
  };
}

const connected = connect(mapStateToProps, { closeModal, loadItem })(ResearchersModal);

export default withSetProps<ISetProps, IOwnProps>(getInitialProps)(connected);
