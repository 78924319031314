import { Column, Row, Well } from '@dabapps/roe';
import { List, OrderedMap } from "immutable";
import * as React from "react";
import { FontAwesome } from 'react-inline-icons';
import { Link } from 'react-router';

const { IconLightbulbO } = FontAwesome;

interface IProps {
  data: OrderedMap<string, string>;
  title: string;
  icon?: JSX.Element;
  path?: string;
  tip?: string;
}

export default class ProfileSection extends React.PureComponent<IProps, void> {
  public render() {
    const { data, icon, path, title, tip } = this.props;
    return (
      <Well className="padding-base margin-large">
        <h6>{icon} {title} {Boolean(path) && <Link to={`/app/profile/edit/${path}`}>Edit</Link>}</h6>
        <Well className="padding-base inner-well">
          <Row>
            {List(data).map(([key, value]) => {
              return (
                <Column xs={4} key={key}>
                  <p>
                    <strong>{key}</strong>
                  </p>
                  <p>{value}</p>
                </Column>
              );
            })}
          </Row>
        </Well>
        {Boolean(tip) && <p><IconLightbulbO className="icon-medium-large"/>{tip}</p>}
      </Well>
    );
  }
}
