import * as React from 'react';

let script: HTMLScriptElement | undefined;

const listeners: Array<() => any> = [];

export type IExternalProps = Partial<{
  title: string;
  options: google.visualization.ChartOptions;
  height: string | number;
  chartType: string;
}> & {
    data: google.visualization.ChartData;
  }

export default class GoogleChart extends React.PureComponent<IExternalProps, void> {
  private element: HTMLDivElement | undefined;

  public componentDidMount() {

    if (!script) {
      script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = `https://www.gstatic.com/charts/loader.js`;

      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);
      script.onload = this.registerCharts;
    }

    if (typeof google === 'undefined' || typeof google.charts === 'undefined') {
      if (listeners.indexOf(this.chartsLoaded) < 0) {
        listeners.push(this.chartsLoaded);
      }
    } else {
      this.chartsLoaded();
    }
  }

  public componentWillUnmount () {
    const index = listeners.indexOf(this.chartsLoaded);
    if (index >= 0) {
      listeners.splice(index, 1);
    }
  }

  public render() {
    const {
      data,
      options,
      chartType,
      ...remainingProps
    } = this.props;

    return (
      <div {...remainingProps} ref={(element: HTMLDivElement) => this.element = element} />
    );
  }

  private registerCharts = () => {
    listeners.forEach((listener) => {
      if (typeof listener === 'function') {
        listener();
      }
    })
  }

  private chartsLoaded = () => {
    if (typeof google === 'object' && typeof google.charts === 'object') {
      if (typeof google.visualization === 'undefined') {
        google.charts.load('current', { packages: ['corechart', 'bar', 'sankey'] });
        google.charts.setOnLoadCallback(this.visualisationLoaded);
      } else {
        this.visualisationLoaded();
      }
    }
  }

  private visualisationLoaded = () => {
    const { data, options, chartType = 'BarChart' } = this.props;

    if (typeof google === 'object' && typeof google.visualization === 'object') {

      const renderTable = new google.visualization.arrayToDataTable([
        ...data,
      ]);

      const renderOptions = {
        title: null,
        ...options,
      };

      const chartObj: any = {
        AreaChart: () => new google.visualization.AreaChart(this.element as HTMLDivElement),
        PieChart: () => new google.visualization.PieChart(this.element as HTMLDivElement),
        ColumnChart: () => new google.visualization.ColumnChart(this.element as HTMLDivElement),
        BarChart: () => new google.visualization.BarChart(this.element as HTMLDivElement),
        SankeyChart: () => new google.visualization.Sankey(this.element as HTMLDivElement),
      };

      const chart = chartObj[chartType]();
      chart.draw(renderTable, renderOptions);
    }
  }
}
