import { fromJS } from 'immutable';
import * as React from 'react';

import GenericList from '^/components/chadmin/generic-list';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_ROUTE = '${id}/';

const LIST_COLUMNS = fromJS([
  {
    name: ['name'],
    display_name: 'Name',
    type: 'detail',
    route: DETAIL_ROUTE,
    sortable: false,
  },
  {
    name: ['display'],
    display_name: 'Display Name',
    type: 'text',
  },
  {
    name: ['active'],
    display_name: 'Is active?',
    type: 'boolean',
    sortable: false,
  }
]);

const MESSAGES = {
  title: 'titles.user_titles.title',
  create: 'titles.user_titles.create',
};

const COLLECTION_NAME = 'TITLES';

type IExternalProps = PermissionHOCChildProps;

export default class List extends React.PureComponent<IExternalProps, void> {
  public render() {
    return (
      <GenericList
        canCreate={this.props.permissions.canCreate}
        messages={MESSAGES}
        collectionColumns={LIST_COLUMNS}
        collectionName={COLLECTION_NAME}
        createUrl="titles"
        collectionKey={'users/titles'}
        defaultFilters={fromJS({include_inactive: false})}
      />
    );
  }
}
