import { fromJS } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { RESET_PASSWORD, resetPassword } from '../../actions/auth';
import { getResponseStatus, IResponseStatus } from '../../responses';
import { IStore } from '../../store';
import LandingHeader from '../navigation/landing-header';
import SetPasswordForm from './set-password-form';

export interface IProps {
  response: IResponseStatus;
  params: {
    token: string;
    uid: string;
  }
  resetPassword: typeof resetPassword;
  createNewPassword: boolean;
}

export class SetPasswordPage extends React.PureComponent<IProps, void> {
 public render () {
   return (
     <div>
       <LandingHeader/>
        <SetPasswordForm
          onSubmit={this.props.resetPassword.bind(this)}
          params={this.props.params}
          response={this.props.response}
          createNewPassword={this.props.createNewPassword}>
        </SetPasswordForm>
      </div>
    );
  }
}

export function mapStateToProps({responses}: IStore, existing: Partial<IProps>) {
  const createNew = fromJS(existing).getIn(['location', 'query', 'create_new']);
  return {
    ...existing,
    response: getResponseStatus(responses, RESET_PASSWORD),
    createNewPassword: typeof createNew === 'string' ? !!parseInt(createNew, undefined) : false
  }
}

export default connect(mapStateToProps, {resetPassword})(SetPasswordPage);
