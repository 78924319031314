import { List, Map } from 'immutable';
import * as React from "react";
import { IProfileLog } from "../../../store/data-types";

interface IProps {
  profilelog: IProfileLog
}

const EXCLUDED_KEYS = [
  'operation_type',
  'outcome',
  'contact_type',
];

const titleCase = (key: string) => {
  return key ? key[0].toUpperCase() + key.slice(1).toLowerCase() : '';
}

export default class AuditLog extends React.PureComponent<IProps, {}> {

  constructor() {
    super()
    this.renderInitalRow = this.renderInitalRow.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.formatKey = this.formatKey.bind(this);
  }

  public renderRow(excludeVolunteer: boolean, row: any, key: string): JSX.Element | null {

    if (EXCLUDED_KEYS.indexOf(key) !== -1 || (key === 'volunteer' && excludeVolunteer)) {
      return null;
    }

    if (Map.isMap(row)) {
      return (
        <div key={`${key}-wrapper`}>
          { row.map((innerRow: any, innerkey: string) => this.renderRow(true, innerRow, innerkey)).toArray() }
        </div>
      );
    } else if (List.isList(row)) {
      return (
        <div key={`${key}-wrapper`}>
          <li>{this.formatKey(key)}:</li>
          {row.map((innerRow, index) => {
            return (
                <ul className='inner-audit' key={`${key}-${index}`}>
                  {this.renderRow(true, innerRow, key)}
              </ul>
             );
          })}
        </div>
      );
    }

    return (<li key={`${key}`}>{this.formatKey(key)}: {row}</li>);
  }

  public renderInitalRow(row: any, key: string) {
    return this.renderRow(false, row, key)
  }

  public render() {

    const { profilelog } = this.props;

    if (profilelog.changes_audit && profilelog.changes_audit.isEmpty()) {
      return <p>No changes logged</p>;
    }

    return (
      <ul>
      { profilelog.changes_audit.map(this.renderInitalRow).toArray() }
      </ul>
    );
  }

  private formatKey(key: string) {
    return titleCase(key.replace(/_/g, ' '))
  }
}
