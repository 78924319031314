'use strict';

(function () {

  var React = require('react');
  var assign = require('lodash.assign');

  module.exports = function IconCopyright (props) {
    return React.createElement(
      'svg',
      assign(
        {
          viewBox: '0 0 2305.62 2305.62',
          role: 'img',
          'aria-label': 'IconCopyright'
        },
        props
      ),
      React.createElement(
        'title',
        null,
        'IconCopyright'
      ),
      React.createElement(
        'path',
        {
          transform: 'translate(384.81, 1791.82) scale(1, -1)',
          d: 'M1150 462v-109q0 -50 -36.5 -89t-94 -60.5t-118 -32.5t-117.5 -11q-205 0 -342.5 139t-137.5 346q0 203 136 339t339 136q34 0 75.5 -4.5t93 -18t92.5 -34t69 -56.5t28 -81v-109q0 -16 -16 -16h-118q-16 0 -16 16v70q0 43 -65.5 67.5t-137.5 24.5q-140 0 -228.5 -91.5t-88.5 -237.5q0 -151 91.5 -249.5t233.5 -98.5q68 0 138 24t70 66v70q0 7 4.5 11.5t10.5 4.5h119q6 0 11 -4.5t5 -11.5zM768 1280q-130 0 -248.5 -51t-204 -136.5t-136.5 -204t-51 -248.5t51 -248.5t136.5 -204t204 -136.5t248.5 -51t248.5 51t204 136.5t136.5 204t51 248.5t-51 248.5t-136.5 204t-204 136.5t-248.5 51zM1536 640q0 -209 -103 -385.5t-279.5 -279.5t-385.5 -103t-385.5 103t-279.5 279.5t-103 385.5t103 385.5t279.5 279.5t385.5 103t385.5 -103t279.5 -279.5t103 -385.5z'
        }
      )
    );
  };

})();
