import { Alert, Button, Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { defineMessages } from "react-intl"
import { FormattedMessage } from "react-intl"
import { Link } from 'react-router';
import { FormProps, reduxForm } from 'redux-form';
import { IResponseStatus } from '../../responses';
import FormErrorsRenderer from "../forms/form-errors-renderer";
import GroupField from '../forms/group-field';

export interface IFormData {
  email: string;
}

interface IProps {
  response: IResponseStatus;
  onSubmit(data: IFormData): void;
}

const messages = defineMessages({
  label: {
      description: 'Email field label',
      id: 'generic.email',
  }
});

export class ChangeEmail extends React.PureComponent<FormProps<IFormData, {}, {}> & IProps, void> {
  public render() {
    const {hasSucceeded, isLoading, formErrors} = this.props.response;

    return (
      <Row>
        <Column sm={12} md={8} lg={6}>
          <form onSubmit={this.props.handleSubmit}>
              { hasSucceeded && (
                <Alert className="success">
                    <FontAwesome.IconCheck
                      className={'icon-small'}/> <FormattedMessage id='pages.change-email.success'/>
                </Alert>
              )}
            <FormattedMessage id="pages.change-email.help"/>
            <GroupField
              block
              label={messages.label}
              name="email"
              type="email"
              disabled={isLoading}
              formErrors={formErrors}
            />
              <Button className="primary" type="submit" disabled={isLoading}>
                <FormattedMessage id='pages.change-submit'/>
              </Button>
              <Link to="/app/" className="cancel-link">
                <FormattedMessage id='generic.cancel'/>
              </Link>
            <FormErrorsRenderer formErrors={formErrors} errorKey="non_field_errors"/>
          </form>
        </Column>
      </Row>
    );
  }
}

export default reduxForm({
  form: 'change-email'
})(ChangeEmail)
