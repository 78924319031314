import { Alert } from '@dabapps/roe';
import {
  convertMeasurementsToValue,
  convertValueToMeasurements
} from '^/components/volunteers/utils';
import { List, Map } from 'immutable';
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { routerActions } from 'react-router-redux';
import { formValueSelector } from 'redux-form';
import { getCollectionByName } from "../../../collections/reducers";
import { collectionsModule, ICollectionsState, } from '../../../reducers/collections';
import { itemsModule, ItemType } from "../../../reducers/items";
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  isPending
} from "../../../responses";
import { IStore } from "../../../store";
import {
  DisplayUnit,
  IAdvertTypeRecord,
  ITitleRecord,
  IUserRecord,
  IUserVolunteerMedicationRecord,
  IVolunteerMedicationRecord,
  IVolunteerRecord,
  UserRecord,
  UserVolunteerMedicationRecord,
  VolunteerRecord
} from "../../../store/data-types";
import {
  filterEmptyStringsFromList,
  flattedNestedFormErrors,
  formatMonthNumber,
  formatYear,
  FormErrors,
  generateDate,
  INestedFormErrorsResponse,
  NestedFormErrorsList,
  transformDisplayNameToValueLabel,
  transformIdNameToValueLabel
} from '../../../utils';
import Loading from "../../chadmin/common/Loading";
import FormErrorsRenderer from "../../forms/form-errors-renderer";
import PageHeader from "../../page-header";
import EditVolunteerDetailsForm, {
  IFormData
} from "../edit-volunteer-details/edit-as-callhandler/edit-volunteer-details-form";
const { actions: { getCollection, addItem } } = collectionsModule;
import { clearSimpleComponentState } from "../../../actions/actions";
import { ADD_TO_COLLECTION } from '../../../collections/actions';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { IDataProps } from "../edit-volunteer-details/form-fields/edit-drivers-details-form-fields";
import { COMPONENT_KEY } from '../edit-volunteer-details/form-fields/edit-volunteer-medication-component';

type IExternalProps = PermissionHOCChildProps;

interface IStateProps {
  advertsTypes: IDataProps[];
  collectionKey: keyof ICollectionsState;
  errors: FormErrors | null;
  initialValues: IFormData;
  item: IUserRecord;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  medicationEdits: List<IUserVolunteerMedicationRecord>;
  motivationErrors: Map<string, List<string>>;
  nonFieldErrors: Map<string, List<string>>;
  telephoneErrors: Map<string, List<string>>;
  titleChoices: Array<{value: string, label: string}>;
  volunteerId: string;
  volunteer: IVolunteerRecord;
}

interface IDispatchProps {
  addItem: typeof addItem;
  clearSimpleComponentState: typeof clearSimpleComponentState;
  getCollection: typeof getCollection;
  pushRoute: typeof routerActions.push;
}

type IProps = IExternalProps & IStateProps & IDispatchProps;

export class CreateVolunteer extends React.PureComponent<IProps, {}> {
  public constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this)
  }

  public componentWillMount() {
    this.props.clearSimpleComponentState(COMPONENT_KEY);
    this.loadAllOptions();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.itemHasSucceeded && !this.props.itemHasSucceeded) {
      this.props.pushRoute(`/app/volunteers/${nextProps.volunteerId}/`);
    }
  }

  public render() {
    const { advertsTypes, itemHasSucceeded, itemHasFailed, ...rest } = this.props;
    return (
      <div>
        {itemHasFailed && <Alert className="error">
            <p><FormattedMessage id='generic.update_fail' /></p>
          </Alert>
        }
        <EditVolunteerDetailsForm
          {...rest}
          advertTypesOptions={advertsTypes}
          onSubmit={this.submit}
        />
      </div>
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      "drivers/adverts-types",
      { pageSize: 150 },
      "drivers/adverts-types"
    );
    this.props.getCollection(
      "users/titles",
      { pageSize: 150 },
      "users/titles"
    );
  }

  private submit(data: IFormData) {
    const {
      volunteer: {
        motivations,
        height,
        weight,
        ...remainingVolunteer
      },
      ...user
    } = data;
    let { volunteer } = this.props;
    const { item, medicationEdits } = this.props;

    if (motivations) {
      volunteer = volunteer.set('motivations', filterEmptyStringsFromList(motivations));
    }

    if (height) {
      volunteer = volunteer
        .set('height', convertMeasurementsToValue(height))
        .set('height_display_unit', height.display_unit)
    }

    if (weight) {
      volunteer = volunteer
        .set('weight', convertMeasurementsToValue(weight))
        .set('weight_display_unit', weight.display_unit)
    }

    this.props.addItem(
      this.props.collectionKey,
      item.merge(user)
        .set('medications', medicationEdits)
        .set('volunteer', volunteer.merge({...remainingVolunteer})),
      this.props.collectionKey,
      'users/volunteers/create'
    );
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IStateProps {
  const { collections, responses } = state;
  const user = UserRecord();
  const collectionKey = 'users/volunteers';
  const volunteer = user.volunteer ? user.volunteer : VolunteerRecord();
  const volunteerMedications = state.simpleComponentState.get(COMPONENT_KEY, List());
  const response = responses.getIn([ADD_TO_COLLECTION, collectionKey]);

  const medicationEdits = volunteerMedications.map((med: IVolunteerMedicationRecord) => {
    const selector = formValueSelector(med.id);
    const formValues = selector(
      state,
      'id',
      'total_dosage',
      'medication',
      'frequency',
    );
    return UserVolunteerMedicationRecord(formValues);
  });

  const { formErrors = Map<string, NestedFormErrorsList>() } =
    getResponseStatus(responses, ADD_TO_COLLECTION, collectionKey) as any as INestedFormErrorsResponse;
  const {
    availability,
    comments,
    gender,
    birth_date,
    telephone_numbers,
    address_1,
    address_2,
    address_3,
    city,
    county,
    postcode,
    advert_source,
    diabetes_type,
    display_hbA1c,
    hbA1c_result_display_unit,
    display_height,
    height_display_unit,
    display_weight,
    weight_display_unit,
    motivations,
  } = volunteer;
  const {
    title,
    first_name,
    last_name,
    email
  } = user;
  return {
    advertsTypes: transformIdNameToValueLabel(getCollectionByName(
      collections["drivers/adverts-types"],
      "drivers/adverts-types"
    ).results),
    titleChoices: transformDisplayNameToValueLabel(getCollectionByName(
      collections["users/titles"],
      "users/titles"
    ).results.filter((record: ITitleRecord) => record.display !== '')),
    errors: getFormErrors(responses, ADD_TO_COLLECTION, collectionKey) || null,
    item: user,
    volunteer,
    itemHasFailed: hasFailed(responses, ADD_TO_COLLECTION, collectionKey),
    itemHasSucceeded: hasSucceeded(responses, ADD_TO_COLLECTION, collectionKey),
    itemIsPending: isPending(responses, ADD_TO_COLLECTION, collectionKey),
    nonFieldErrors: flattedNestedFormErrors(formErrors, 'non_field_errors'),
    telephoneErrors: flattedNestedFormErrors(formErrors, 'telephone'),
    motivationErrors: flattedNestedFormErrors(formErrors, 'motivation'),
    volunteerId: response && response.data ? response.data.getIn(['volunteer', 'id']) : '',
    collectionKey,
    medicationEdits,
    initialValues: {
      title,
      first_name,
      last_name,
      email,
      volunteer: {
        availability,
        comments,
        gender,
        birth_date,
        telephone_numbers,
        address_1,
        address_2,
        address_3,
        city,
        county,
        postcode,
        advert_source,
        diabetes_type,
        hbA1c_result: display_hbA1c,
        hbA1c_result_display_unit,
        height: convertValueToMeasurements(display_height, height_display_unit),
        weight: convertValueToMeasurements(display_weight, weight_display_unit),
        motivations,
        advert_type: (volunteer && volunteer.advert_source_display &&
          volunteer.advert_source_display.ad_type_display &&
          volunteer.advert_source_display.ad_type_display.id) || '',
        month_diagnosed: volunteer.month_diagnosed || '',
        year_diagnosed: volunteer.year_diagnosed,
      }
    },
  };
}

export default connect(mapStateToProps, {
  getCollection,
  addItem,
  clearSimpleComponentState,
  pushRoute: routerActions.push
})(CreateVolunteer);
