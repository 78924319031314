import {
  convertValueAndUnitToString
} from '^/components/volunteers/utils';
import { OrderedMap } from 'immutable';
import * as moment from "moment";
import * as React from "react";
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { resetRequestState } from "../../../actions";
import { UPDATE_USER_DETAILS } from "../../../actions/auth";
import {
  DIABETES_TYPE,
  GENDER,
  HBA1C_RESULT_UNIT,
  transformMonthAndYearToDateDisplay
} from '../../../consts/constants';
import { hasSucceeded } from "../../../responses";
import { IStore } from "../../../store";
import { IUserRecord, IVolunteerRecord } from "../../../store/data-types";
import {
  formatAddress,
  formatDate,
} from '../../../utils';
import { createDriversMap } from './view-volunteer-details';
import VolunteerMedicationsList from "./volunteer-medications-list";
import ProfileSection from "./volunteer-profile-section";

import { Alert } from '@dabapps/roe';

const { IconLock, IconUserCircleO, IconCommentO, IconPlusSquare } = FontAwesome;

interface IExternalProps {
  userProfile: IUserRecord;
  volunteer: IVolunteerRecord;
}

interface IProps extends IExternalProps {
  resetRequestState: typeof resetRequestState;
  updateHasSucceeded: boolean;
}

export class VolunteerLanding extends React.PureComponent<IProps, {}> {
  public componentWillUnmount() {
    this.props.resetRequestState(UPDATE_USER_DETAILS);
  }

  public render() {
    const {
      userProfile,
      volunteer,
      updateHasSucceeded
    } = this.props;

    return (
      <div>
        <div className="margin-base">
          {updateHasSucceeded &&
            <Alert className="success">
              <p><FormattedMessage id='me.success'/></p>
            </Alert>
          }
          <h4>Welcome!</h4>
          <p>
            Welcome back to the One Research Volunteer Outreach Programme.
             Your personal information enables us to search for any ongoing
              clinical trials that are suitable for your participation.
               So it’s really important they are kept up-to-date.
            <IconLock className="icon-medium-large"/>
          </p>
        </div>
        <ProfileSection
          title="Your Details"
          icon={<IconUserCircleO className="icon-medium-large"/>}
          path="personal-details/"
          /* tslint:disable-next-line:max-line-length */
          tip="We need your contact information so that if you wish to join the programme, we can send you detailed information and a member of our team, or research site can contact you. Your location, date of birth and gender may form part of the trial selection criteria."
          data={OrderedMap({
            "Name": `${userProfile.first_name} ${userProfile.last_name}` || 'Not specified',
            "Email": userProfile.email || 'Not specified',
            "Address": formatAddress([
              volunteer.address_1,
              volunteer.address_2,
              volunteer.address_3,
              volunteer.city,
              volunteer.postcode,
              volunteer.county,
            ]) || 'Not specified',
            "Telephone": volunteer.telephone_numbers.map((telephone) => telephone.telephone).join(', ')
              || 'Not specified',
            "Date of birth": volunteer.birth_date ? formatDate(moment.utc(volunteer.birth_date)) : 'Not specified',
            "Gender": GENDER.get(volunteer.gender) || 'Not specified',
          })}
        />
        <ProfileSection
          title="How did you hear about us?"
          icon={<IconCommentO className="icon-medium-large"/>}
          path="drivers-details/"
          /* tslint:disable-next-line:max-line-length */
          tip="Please tell us how you heard about this volunteer outreach programme so we can reach more people like you."
          data={createDriversMap(volunteer, true)}
        />
        <ProfileSection
          title="Medical Details"
          icon={<IconPlusSquare className="icon-medium-large"/>}
          path="medical-details/"
          /* tslint:disable-next-line:max-line-length */
          tip="The Volunteer Outreach Programme is a matching service that links volunteers like you with a local research site. Trials vary and your medical details determine if there is a suitable upcoming trial."
          data={OrderedMap({
            "Diabetes type": DIABETES_TYPE.get(volunteer.diabetes_type) || 'Not specified',
            "Diagnosed": transformMonthAndYearToDateDisplay(volunteer.month_diagnosed, volunteer.year_diagnosed),
            "Last HbA1c result": `${volunteer.display_hbA1c}
              ${HBA1C_RESULT_UNIT.get(volunteer.hbA1c_result_display_unit, '')}`,
              "Height": convertValueAndUnitToString(volunteer.display_height, volunteer.height_display_unit),
              "Weight": convertValueAndUnitToString(volunteer.display_weight, volunteer.weight_display_unit),
            "BMI": `${volunteer.bmi}`
          })}
        />
        <VolunteerMedicationsList
          id={volunteer.id}
          path="medication/"
          /* tslint:disable-next-line:max-line-length */
          tip="The medication you are on will certainly be a factor in trial matching and it’s really important that you keep this section up-to date. Be sure to keep your account login details in a safe place so you can access and amend this information when you want. You might find it a handy record for your own use too."
        />
      </div>
    )
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { responses } = state;

  return {
    ...props,
    updateHasSucceeded: hasSucceeded(responses, UPDATE_USER_DETAILS),
  }
}

export default connect(mapStateToProps, {resetRequestState})(VolunteerLanding);
