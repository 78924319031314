import * as React from 'react';
import Banner from './banner';
import TabBar from './tabbar';

interface IProps {
  showTabBar?: boolean;
  userType: string;
  pathname: string;
}

export default class Navigation extends React.PureComponent<IProps, void> {
  public render() {
    const { showTabBar, userType, pathname } = this.props;
    return (
      <div id="main-navbar" className="navbar">
        <Banner />
        {showTabBar && <TabBar pathname={pathname} userType={userType} />}
      </div>
    );
  }
}
