import { Button, Column, Row } from '@dabapps/roe';
import { fromJS, List, Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../collections/types';
import {
  LOAD_ITEM,
  UPDATE_ITEM,
} from '../../items/actions';

import { collectionsModule, ICollectionsState } from '../../reducers/collections';
import { IItemsState, itemsModule, ItemType } from '../../reducers/items';
import {
  getFormErrors,
  getResponseStatus,
  hasFailed,
  hasSucceeded,
  IResponseStatus,
  isPending
} from '../../responses';
import { IStore } from "../../store";
import { IChadminDetailMessages } from '../../store/data-types/index';
import AdminEditCreate from '../chadmin/edit-create/AdminEditCreate';
import PageHeader from '../page-header';
import CollectionTable from '../tables/collection-table';
import { IBasicDictionary, TItem } from './types';

const { actions: { loadItem, updateItem, patchItem } } = itemsModule;

interface IExternalProps {
  formName: string;
  messages: IChadminDetailMessages;
  detailFields: List<string>;
  detailFieldOptions: Map<string, any>;
  itemsName: ItemType;
  params: {
    id: string;
  };
  onCancelLinkTo?: string;
  patch?: boolean;
  getInitialValues?: (item: TItem) => IBasicDictionary
}

interface IProps<T> extends IExternalProps {
  item: T;
  loading: boolean;
  itemHasFailed: boolean;
  itemHasSucceeded: boolean;
  itemIsPending: boolean;
  errors: Map<string, any> | undefined;
  loadItem: typeof loadItem;
  updateItem: typeof updateItem;
  patchItem: typeof patchItem;
}

export class GenericDetail<T> extends React.PureComponent<IProps<T>, void> {

  public constructor(props: IProps<T>) {
    super(props);
    this.update = this.update.bind(this);
    this.patch = this.patch.bind(this);
  }

  public componentWillMount() {
    this.props.loadItem(this.props.itemsName, this.props.params.id);
  }

  public render () {
    const {
      item,
      itemHasFailed,
      itemHasSucceeded,
      itemIsPending,
      errors,
      loading,
      detailFieldOptions,
      detailFields,
      getInitialValues,
      onCancelLinkTo,
      patch
    } = this.props;

    return (
      <div>
        <PageHeader header={<FormattedMessage id={this.props.messages.edit} />} />
        <AdminEditCreate
          item={item}
          fields={detailFields}
          itemOptions={detailFieldOptions}
          formName={this.props.formName}
          patchItem={patch ? this.patch : this.update }
          getInitialValues={getInitialValues}
          itemErrors={errors}
          loading={loading}
          itemHasFailed={itemHasFailed}
          itemHasSucceeded={itemHasSucceeded}
          itemIsPending={itemIsPending}
          onCancelLinkTo={onCancelLinkTo}
        />
      </div>
    );
  }

  private update(data: Partial<T>): void {
    this.props.updateItem(this.props.itemsName, this.props.params.id, data);
  }

  private patch(data: Partial<T>): void {
    this.props.patchItem(this.props.itemsName, this.props.params.id, data);
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps) {
  const { items, responses } = state;
  const {itemsName} = props;
  const loading = isPending(responses, LOAD_ITEM, itemsName)

  return {
    errors: getFormErrors(responses, UPDATE_ITEM, itemsName),
    item: items.get(itemsName),
    loading,
    itemHasFailed: hasFailed(responses, UPDATE_ITEM, itemsName),
    itemHasSucceeded: hasSucceeded(responses, UPDATE_ITEM, itemsName),
    itemIsPending: isPending(responses, UPDATE_ITEM, itemsName),
  };
}

export default connect(mapStateToProps, { loadItem, updateItem, patchItem })(GenericDetail);
