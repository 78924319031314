import { FormGroup } from '@dabapps/roe';
import { List } from 'immutable';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IFormArrayComponentProps } from '../../../forms/form-array';
import Motivation from './motivation';

export default class Motivations extends React.PureComponent<IFormArrayComponentProps<string> , {} > {
  public componentDidMount() {
    const { items } = this.props;
    if (items && !List(items).count()) {
      this.props.addItem(null, '');
    }
  }

  public render () {
    const { items } = this.props;

    return (
      <div>
        <FormGroup noLabel>
          <p><FormattedMessage id='volunteer.motivation'/></p>
        </FormGroup>
        {
          items.map(({id, value}) => (
            <Motivation
              key={id}
              id={id}
              motivation={value}
              updateMotivation={this.onUpdateItem}
              removeMotivation={this.onRemoveItemClick}
            />
          ))
        }
        <FormGroup noLabel className="font-size-small margin-top-none margin-bottom-large">
          <a className='button small primary' onClick={this.onAddItemClick}>
            <FormattedMessage id="drivers.motivation.add" />
          </a>
        </FormGroup>
      </div>
    )
  }

  private onAddItemClick = () => {
    this.props.addItem(null, '');
  }

  private onUpdateItem = (id: string, value: string) => {
    this.props.onChange(id, value);
  }

  private onRemoveItemClick = (id: string) => {
    this.props.removeItem(id);
  }
}
