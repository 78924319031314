import {
  FormGroup,
} from '@dabapps/roe';
import * as React from 'react';
import { FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl } from "react-intl"
import { ComponentConstructor, Field } from 'redux-form';

import { FormErrors } from '../../utils';
import FormErrorsRenderer from './form-errors-renderer';

interface IProps {
  formErrors?: FormErrors;
  label?: FormattedMessage.MessageDescriptor;
  placeholder?: FormattedMessage.MessageDescriptor;
  type?: string;
  name: string;
  disabled?: boolean;
  optional?: boolean;
  className?: string;
  component?: ComponentConstructor<any> | 'input' | 'select' | 'textarea';
  block?: boolean;
  labelLast?: boolean;
}

export const normalizeBoolean = (value: any) => Boolean(value);

class GroupFieldWrapped extends React.PureComponent<IProps & InjectedIntlProps, void> {

  public formatOptionalMessage(messageDescripitor?: FormattedMessage.MessageDescriptor) {
    return (messageDescripitor) ? this.props.intl.formatMessage(messageDescripitor) : "";
  }

  public render () {
    const {
      formErrors,
      name,
      label,
      labelLast,
      component,
      optional,
      type,
      disabled,
      intl,
      placeholder,
      ...remainginProps
    } = this.props;
    const errorClass = (formErrors && formErrors.get(name)) ? 'error' : '';
    const placeholderText = this.formatOptionalMessage(placeholder) || this.formatOptionalMessage(label);
    return (
      <FormGroup {...remainginProps}>
        { label && !labelLast && (
        <label htmlFor={name}>
          <FormattedMessage {...label}/>
          {optional ? <span className="optional">Optional</span> : null}
        </label>
        )}
        <Field
          id={name}
          placeholder={placeholderText}
          name={name}
          component={component || 'input'}
          type={type}
          disabled={disabled}
          className={errorClass}
          normalize={
            (type === 'checkbox' || type === 'radio')
              ? normalizeBoolean
              : undefined
          }
        >
          {this.props.children}
          </Field>
        { label && labelLast && (
        <label htmlFor={name}>
          <FormattedMessage {...label}/>
          {optional && <span className="optional">Optional</span>}
        </label>
        )}
        <FormErrorsRenderer formErrors={formErrors} errorKey={name}/>
      </FormGroup>
    );
  }
}

const GroupField = injectIntl(GroupFieldWrapped);
export default GroupField
