import { List } from "immutable";
import * as React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import * as uuid from 'uuid/v4';

type FormArrayComponent = React.StatelessComponent<IFormArrayComponentProps<any>> |
  React.ComponentClass<IFormArrayComponentProps<any>>;

export interface IFormArrayItem<Item> {
  id: string;
  value: Item;
}

export interface IFormArrayComponentProps<Item> {
  items: Array<IFormArrayItem<Item>>;
  addItem(idOrIndex: string | number | undefined | null, newItem: any): void;
  removeItem(idOrIndex: string | number): void;
  onChange(idOrIndex: string | number, newValue: any): void;
}

interface IComponentWrapperProps extends WrappedFieldProps<any> {
  arrayComponent: FormArrayComponent;
}

interface IFormArrayProps {
  name: string;
  component: FormArrayComponent;
}

class ComponentWrapper extends React.PureComponent<IComponentWrapperProps, {}> {
  private trackingIds: string[];

  public constructor (props: IComponentWrapperProps) {
    super(props);

    const { input: { value } } = props;

    this.trackingIds = (List.isList(value) ? value.toArray() : value || []).map(() => uuid());
  }

  public componentWillReceiveProps (nextProps: IComponentWrapperProps) {
    const { input: { value } } = this.props;
    const { input: { value: nextValue } } = nextProps;

    if (
      (value && !nextValue) ||
      (!value && nextValue) ||
      (value && nextValue && value.length !== nextValue.length)
    ) {
      this.trackingIds = (nextValue || []).map(() => uuid());
    }
  }

  public render () {
    const {
      input: {
        value
      },
      arrayComponent: ArrayComponent,
      ...remainingProps
    } = this.props;

    return (
      <ArrayComponent
        {...remainingProps}
        items={(value || []).map((item: any, index: number) => {
          return {
            id: this.trackingIds[index],
            value: item
          };
        })}
        addItem={this.addItem}
        removeItem={this.removeItem}
        onChange={this.onChange}
      />
    );
  }

  private onChange = (idOrIndex: string | number, newValue: any) => {
    const { input: { value, onChange } } = this.props;

    const index = typeof idOrIndex === 'number' ? idOrIndex : this.trackingIds.indexOf(idOrIndex);

    let newValues: List<any> | any[] = [];

    if (index >= 0) {
      if (List.isList(value)) {
        newValues = value.setIn([index], newValue);
      } else {
        newValues = [...(value || [])];
        newValues[index] = newValue;
      }
    }
    onChange(newValues);
  }

  private addItem = (idOrIndex: string | number | undefined | null, newItem: any) => {
    const { input: { value, onChange } } = this.props;
    const isImmutable = List.isList(value);

    let index: number = isImmutable ? value.count() : value.length;

    if (typeof idOrIndex === 'number') {
      index = idOrIndex;
    } else if (typeof idOrIndex === 'string') {
      index = this.trackingIds.indexOf(idOrIndex);
    }

    this.trackingIds.splice(index, 0, uuid());

    if (isImmutable) {
      onChange(value.splice(index, 0 , newItem));
    } else {
      const newValues = [...(value || [])];
      newValues.splice(index, 0, newItem);

      onChange(newValues);
    }
  }

  private removeItem = (idOrIndex: string | number) => {
    const { input: { value, onChange } } = this.props;
    const isImmutable = List.isList(value);

    const index = typeof idOrIndex === 'number' ? idOrIndex : this.trackingIds.indexOf(idOrIndex);

    if (index >= 0) {
      this.trackingIds.splice(index, 1);

      if (isImmutable) {
        onChange(value.splice(index, 1));
      } else {
        const newValues = [...(value || [])];
        newValues.splice(index, 1)

        onChange(newValues);
      }
    }
  }
}

// tslint:disable-next-line:max-classes-per-file
export default class FormArray extends React.PureComponent<IFormArrayProps, {}> {
  public render () {
    const { component, ...remainingProps } = this.props;

    return (
      <Field
        {...remainingProps}
        arrayComponent={component}
        component={ComponentWrapper}
      />
    );
  }
}
