import { Button, Column, FormGroup, Row, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FormProps, reduxForm } from 'redux-form';
import { getResponseStatus, IResponseStatus, isPending } from '../../responses';
import { IStore } from '../../store';
import FormErrorsRenderer from "../forms/form-errors-renderer";
import GroupField from '../forms/group-field';
import PostcodeLookup, { TAddressAutoFillFieldMap } from '../forms/postcode-lookup';

import { ReactSelectWrapper } from '../forms/react-select-wrapper';

const FORM_NAME = 'SitesDetailFields';

const messages = defineMessages({
  name: {
    description: 'Name label',
    id: 'generic.name',
  },
  radius: {
    description: 'Radius label',
    id: 'generic.radius',
  },
  address1: {
    description: 'Address 1 label',
    id: 'generic.address_1',
  },
  address2: {
    description: 'Address 2 label',
    id: 'generic.address_2',
  },
  county: {
    description: 'County label',
    id: 'generic.county',
  },
  city: {
    description: 'City label',
    id: 'generic.city',
  },
  postcode: {
    description: 'Postcode label',
    id: 'generic.postcode',
  },
  active: {
    description: 'Active label',
    id: 'sites.is-active',
  }
});

export const AUTOFILL_FIELDS: TAddressAutoFillFieldMap = {
  address_1: 'address_1',
  address_2: 'address_2',
  city: 'city',
  county: 'county',
  postcode: 'postcode',
};

export interface ISitesDetail {
  id: string,
  name: string,
  radius: number,
  active: boolean,
  address_1: string,
  address_2: string,
  postcode: string,
  city: string,
  county: string,
}

interface IProps {
  response: IResponseStatus;
}

export interface IExternalProps {
  initialValues: ISitesDetail;
  formName: string;
}

export default class SitesDetailFields extends React.PureComponent<IProps & IExternalProps, {}> {
  public render() {
    const { isLoading, formErrors, hasSucceeded } = this.props.response;
    return (
        <div>
          <div>
            <Row>
              <Column xs={6} md={6} xsPush={6}>
              <GroupField
                block
                label={messages.address1}
                name="address_1"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              <GroupField
                block
                label={messages.address2}
                name="address_2"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              <GroupField
                block
                label={messages.county}
                name="county"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              <GroupField
                block
                label={messages.city}
                name="city"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              <GroupField
                block
                label={messages.postcode}
                name="postcode"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              </Column>
              <Column xs={5} md={6} xsPull={6}>
              <GroupField
                block
                label={messages.name}
                name="name"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              </Column>
              <Column sm={5} md={3} xs={5} xsPull={6}>
              <GroupField
                block
                label={messages.radius}
                name="radius"
                type="text"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              <GroupField
                block
                label={messages.active}
                name="active"
                type="checkbox"
                disabled={this.props.response.isLoading}
                formErrors={this.props.response.formErrors}
              />
              </Column>
              <Column xs={6} md={6} xsPull={6}>
                <PostcodeLookup
                  formName={this.props.formName}
                  autofillFields={AUTOFILL_FIELDS}
                />
              </Column>
            </Row>
        </div>
        <FormErrorsRenderer formErrors={this.props.response.formErrors} errorKey="non_field_errors"/>
      </div>
    );
  }
}
