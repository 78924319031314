import { IAction } from '../actions';
import { GET_USER_INFO, LOGIN, UPDATE_USER_DETAILS } from '../actions/auth';
import { IUserRecord, UserRecord } from '../store/data-types';

export function userProfile(state: IUserRecord | null = null, action: IAction<any, any>) {
  switch (action.type) {
    case GET_USER_INFO.SUCCESS:
    case UPDATE_USER_DETAILS.SUCCESS:
      return UserRecord(action.payload);
    case LOGIN.FAILURE:
      return null;
    default:
      return state;
  }
}
