import {fromJS, List, Map} from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { GET_COLLECTION } from '^/collections/actions';
import { getCollectionByName } from '^/collections/reducers';
import GenericDetail from '^/components/chadmin/generic-detail';
import { MEDICATION_TYPE_CHADMIN_CHOICES } from '^/consts/constants';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';
import { collectionsModule } from '^/reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '^/responses';
import { IStore } from '^/store';
import { INamedActiveObjectRecord } from '^/store/data-types';

const { actions: { getCollection } } = collectionsModule;

const FORM_NAME = 'MEDICATION_DETAIL';

const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-4', 'column sm-4']],
    elements: [['trade_name', 'generic_name']],
  },
  {
    fieldClassNames: [['column sm-4', 'column sm-4', 'column sm-4']],
    elements: [['manufacturer', 'med_type', 'med_class']],
  },
  {
    fieldClassNames: [['column sm-4', 'column sm-4', 'column sm-4']],
    elements: [['min_dosage', 'max_dosage', 'unit']],
  },
  {
    fieldClassNames: [['column sm-4']],
    elements: [['active']],
  },
]);
const DETAIL_FIELD_OPTIONS = fromJS({
  trade_name: {
    label: 'Trade name',
    max_length: 255,
    type: 'text',
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  },
  min_dosage: {
    label: 'Min dosage',
    max_length: 10,
    type: 'text',
  },
  max_dosage: {
    label: 'Max dosage',
    max_length: 10,
    type: 'text',
  },
  manufacturer: {
    label: 'Manufacturer',
    type: 'choices',
    choices: []
  },
  generic_name: {
    label: 'Generic name',
    type: 'choices',
    choices: []
  },
  med_type: {
    label: 'Type',
    type: 'choices',
    choices: MEDICATION_TYPE_CHADMIN_CHOICES
  },
  med_class: {
    label: 'Class',
    type: 'choices',
    choices: []
  },
  unit: {
    label: 'Unit',
    type: 'choices',
    choices: []
  },
});

const MESSAGES = {
  edit: 'medication.list.edit'
};

interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

interface IValueCollections {
  classes: List<INamedActiveObjectRecord>;
  units: List<INamedActiveObjectRecord>;
  manufacturers: List<INamedActiveObjectRecord>;
  genericnames: List<INamedActiveObjectRecord>;
}

interface ICollectionProps extends IValueCollections {
  response: IResponseStatus;
  getCollection: typeof getCollection;
  loading: boolean;
}

// tslint:disable-next-line:no-unused-variable
interface IProps extends ICollectionProps, IExternalProps {}

export class MedicationDetail extends React.PureComponent<IProps, void> {

  public constructor() {
    super();
    this.transformFieldOptions = this.transformFieldOptions.bind(this);
  }

  public componentWillMount() {
    this.loadAllOptions();
  }

  public render() {
    return (
      <GenericDetail
        formName={FORM_NAME}
        messages={MESSAGES}
        params={this.props.params}
        detailFields={DETAIL_FIELDS}
        detailFieldOptions={this.transformFieldOptions()}
        onCancelLinkTo={'/app/medications/medication/'}
        itemsName={'medications/medication'} />
    );
  }

  private loadAllOptions() {
    this.props.getCollection(
      'medications/class',
      { pageSize: 150 },
      'VALUES-medications/class'
    );

    this.props.getCollection(
      'medications/unit',
      { pageSize: 150 },
      'VALUES-medications/unit'
    );

    this.props.getCollection(
      'medications/manufacturer',
      { pageSize: 150 },
      'VALUES-medications/manufacturer'
    );

    this.props.getCollection(
      'medications/generic-name',
      { pageSize: 150 },
      'VALUES-medications/generic-name'
    );
  }

  private transformFieldOptions() {
    return DETAIL_FIELD_OPTIONS.set('unit', Map({
      label: 'Unit',
      type: 'choices',
      clearable: false,
      choices: this.props.units.map((entry: INamedActiveObjectRecord) => {
        return Map({value: entry.get('id'), display_name: entry.get('name')})})
    }))
    .set('med_class', Map({
      label: 'Class',
      type: 'choices',
      clearable: false,
      choices: this.props.classes.map((entry: INamedActiveObjectRecord) => {
        return Map({value: entry.get('id'), display_name: entry.get('name')})})
    }))
    .set('generic_name', Map({
      label: 'Generic name',
      type: 'choices',
      clearable: false,
      choices: this.props.genericnames.map((entry: INamedActiveObjectRecord) => {
        return Map({value: entry.get('id'), display_name: entry.get('name')})})
    }))
    .set('manufacturer', Map({
      label: 'Manufacturer',
      type: 'choices',
      clearable: false,
      choices: this.props.manufacturers.map((entry: INamedActiveObjectRecord) => {
        return Map({value: entry.get('id'), display_name: entry.get('name')})})
    }));
  }
}

function mapStateToProps({ collections, responses }: IStore, props: IExternalProps) {

  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, 'users/volunteers'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users/volunteers'),
    classes: getCollectionByName(collections['medications/class'], 'VALUES-medications/class').results,
    units: getCollectionByName(collections['medications/unit'], 'VALUES-medications/unit').results,
    manufacturers:
      getCollectionByName(collections['medications/manufacturer'], 'VALUES-medications/manufacturer').results,
    genericnames:
      getCollectionByName(collections['medications/generic-name'], 'VALUES-medications/generic-name').results
  };
}
export default connect(mapStateToProps, {getCollection})(MedicationDetail);
