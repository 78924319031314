import { List } from 'immutable';

import { resetRequestState } from '^/actions';
import { GET_VOLUNTEER_COUNTS, TRIGGER_VOLUNTEER_MATCHING } from '^/actions/studies';
import { ADD_TO_COLLECTION } from '^/collections/actions';
import { UPDATE_ITEM } from '^/items/actions';
import { ResponsesReducerState } from '^/reducers/responses';
import { getFormErrors } from '^/responses';
import store from '^/store';
// tslint:disable-next-line:no-unused-variable
import { ISiteRecord } from '^/store/data-types';
// tslint:disable-next-line:no-unused-variable
import { IStudySiteRecord } from '^/store/data-types/studies';
import { combineErrors } from '^/utils';

export const getStudyErrors = (responses: ResponsesReducerState) => combineErrors(
  getFormErrors(responses, UPDATE_ITEM, 'studies'),
  getFormErrors(responses, ADD_TO_COLLECTION, 'studies'),
  getFormErrors(responses, GET_VOLUNTEER_COUNTS),
  getFormErrors(responses, TRIGGER_VOLUNTEER_MATCHING),
);

export const resetStudyRequests = () => {
  store.dispatch(resetRequestState(UPDATE_ITEM, 'studies'));
  store.dispatch(resetRequestState(ADD_TO_COLLECTION, 'studies'));
  store.dispatch(resetRequestState(GET_VOLUNTEER_COUNTS));
  store.dispatch(resetRequestState(TRIGGER_VOLUNTEER_MATCHING));
};

export function getCircles (studySites: List<IStudySiteRecord>, sites: List<ISiteRecord>) {
  return studySites.map((studySite) => {
    const matchingSite = sites.find((site) => site.id === studySite.site);

    if (matchingSite && matchingSite.location && matchingSite.radius) {
      return {
        center: matchingSite.location,
        radius: matchingSite.radius,
      };
    }

    return null;
  }).toArray();
}

export function getPolygons (
  studySites: List<IStudySiteRecord>,
  sites: List<ISiteRecord>
) {
  return studySites.map((studySite) => {
    if (studySite.poly) {
      return studySite.poly.coordinates[0].map((coordinate) => ({
        lat: coordinate[1],
        lng: coordinate[0],
      }));
    }

    return null;
  }).toArray();
}
