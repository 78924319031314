import {Dict} from '@dabapps/simple-records';
import { DisplayUnit, Hba1cUnit } from '^/store/data-types';
import { List, Map } from 'immutable';
import {GeneralOptions} from '../store/data-types/studies';
import {
  generateMonths,
  generateYears,
  transformMapToDisplayNameValue,
  transformMapToValueDisplayName,
  transformMapToValueLabel,
} from "../utils";

// TODO: Removed duplicate constants from backend

export const NR_OF_PROFILE_LOGS_ON_VOLUNTEER_PROFILE = 7;

export const NBSP = '\u00a0'; // Non-breaking space character

export const CRAFTY_CLICKS_URL = 'https://api.craftyclicks.co.uk/address/1.1/';

export const PROFILE_STATUS_NAMES = {
  NEED_CALL: 'NEED_CALL',
  NEED_QA: 'NEED_QA',
  REVIEWED: 'REVIEWED',
  ARCHIVED: 'ARCHIVED',
  NOT_ARCHIVED: 'NEED_CALL,NEED_QA,REVIEWED',
};

export const PROFILE_STATUS_LABELS = Map({
  [PROFILE_STATUS_NAMES.NEED_CALL]: 'Needs Call',
  [PROFILE_STATUS_NAMES.NEED_QA]: 'Needs QA',
  [PROFILE_STATUS_NAMES.REVIEWED]: 'Profile Reviewed',
  [PROFILE_STATUS_NAMES.ARCHIVED]: 'Archived',
});

export const PROFILE_STATUS_VALUE_LABELS = transformMapToValueLabel(PROFILE_STATUS_LABELS);

export const CALL_OUTCOMES = Map({
  ACTIVE: 'Active',
  UPDATED: 'Updated',
  ARCHIVED: 'Archived',
  NO_ACTION: 'No action'
});

export const CALL_OUTCOME_CHOICES = transformMapToValueDisplayName(CALL_OUTCOMES);

export const STUDY_OUTCOME_NAMES = {
  PRESTUDY_CHECK: 'PRESTUDY_CHECK',
  FAILED_PRESTUDY_CHECK: 'FAILED_PRESTUDY_CHECK',
  RESEARCHER_REVIEW_REQUIRED: 'RESEARCHER_REVIEW_REQUIRED',
  CONTACT_IN_PROGRESS: 'CONTACT_IN_PROGRESS',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  REQUEST_REMOVAL: 'REQUEST_REMOVAL',
  SUITABLE: 'SUITABLE',
};

export const STUDY_OUTCOMES_PRE_STUDY_CHECK = List([
  STUDY_OUTCOME_NAMES.FAILED_PRESTUDY_CHECK,
  STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED,
])

export const STUDY_OUTCOMES_RESEARCHER = List([
  STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS,
  STUDY_OUTCOME_NAMES.NOT_ELIGIBLE,
  STUDY_OUTCOME_NAMES.REQUEST_REMOVAL,
  STUDY_OUTCOME_NAMES.SUITABLE
])

export const STUDY_OUTCOME = Map({
  [STUDY_OUTCOME_NAMES.PRESTUDY_CHECK]: 'Pre-study check',
  [STUDY_OUTCOME_NAMES.FAILED_PRESTUDY_CHECK]: 'Failed Pre-study check',
  [STUDY_OUTCOME_NAMES.RESEARCHER_REVIEW_REQUIRED]: 'Researcher review required',
  [STUDY_OUTCOME_NAMES.CONTACT_IN_PROGRESS]: 'Contact in progress',
  [STUDY_OUTCOME_NAMES.NOT_ELIGIBLE]: 'Not eligible',
  [STUDY_OUTCOME_NAMES.REQUEST_REMOVAL]: 'Requested removal from VOP',
  [STUDY_OUTCOME_NAMES.SUITABLE]: 'Suitable for screening',
});

export const RECRUITMENT_FLOW_NAMES = {
  ...STUDY_OUTCOME_NAMES,
  VOLUNTEERS: 'VOLUNTEERS',
  ELIGIBLE: 'ELIGIBLE',
  INELIGIBLE: 'INELIGIBLE',
  MATCHED: 'MATCHED',
  UNMATCHED: 'UNMATCHED',
  NOT_YET_PRESTUDY_CHECKED: 'NOT_YET_PRESTUDY_CHECKED',
  ARCHIVED_AFTER_MATCH: 'ARCHIVED_AFTER_MATCH',
  PRESTUDY_CHECKED: 'PRESTUDY_CHECKED',
};

export const RECRUITMENT_FLOW_LABELS = Map({
  VOLUNTEERS: 'Volunteers',
  ELIGIBLE: 'Eligible',
  INELIGIBLE: 'Ineligible',
  MATCHED: 'Matched',
  UNMATCHED: 'Unmatched',
  NOT_YET_PRESTUDY_CHECKED: 'Awaiting pre-study check',
  ARCHIVED_AFTER_MATCH: 'Archived after match',
  PRESTUDY_CHECKED: 'Passed pre-study check',
  ARCHIVED_AFTER_PRESTUDY_CHECK : 'Archived after pre-study check'
});

// http://there4.io/2012/05/02/google-chart-color-list/
export const STUDY_OUTCOME_COLOURS: string[] = [
  '#22AA99', '#FF9900', '#E67300', '#0099C6', '#5574A6', '#DC3912', '#329262', '#109618', '#3366CC', '#990099',
  '#3B3EAC', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#AAAA11', '#6633CC', '#8B0707'];

export const STUDY_OUTCOME_CHOICES = transformMapToValueDisplayName(STUDY_OUTCOME);

export const OR_USER_TYPES = Map({
  CALL_HANDLER: 'Call Handler',
  QA: 'Quality Assurance',
  MARKETING: 'Marketing',
});
export const OR_USER_TYPES_CHADMIN_CHOICES = transformMapToDisplayNameValue(OR_USER_TYPES);

export const ADMIN_USER_TYPES = Map({
  CRA: 'Clinical Research Associate',
  RESEARCHER: 'Site Researcher',
  PROJECT_MANAGER: 'Project Manager',
  ADMIN: 'Admin'
});
export const ADMIN_USER_TYPES_CHADMIN_CHOICES = transformMapToDisplayNameValue(ADMIN_USER_TYPES);

export const USER_TYPES = Map({VOLUNTEER: 'Volunteer'}).merge(OR_USER_TYPES).merge(ADMIN_USER_TYPES);
export const USER_TYPE_CHOICES = transformMapToValueLabel(USER_TYPES);
export const USER_TYPE_CHADMIN_CHOICES = transformMapToDisplayNameValue(USER_TYPES);

export const USER_STATUS_NAMES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};

export const USER_STATUS = Map({
  [USER_STATUS_NAMES.ACTIVE]: 'Active',
  [USER_STATUS_NAMES.DISABLED]: 'Disabled'
});

export const USER_STATUS_LIST_FILTER = Map({
  true: 'Hide',
  false: 'Show'
});

export const USER_STATUS_CHOICES = transformMapToValueLabel(USER_STATUS);
export const USER_STATUS_CHADMIN_CHOICES = transformMapToDisplayNameValue(USER_STATUS);

export const EMAIL_VERIFIED = Map({
  true: 'Verified',
  false: 'Not verified',
});

export const GENDER = Map({
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
});
export const GENDER_CHOICES = transformMapToValueLabel(GENDER);
export const GENDER_CHADMIN_CHOICES = transformMapToDisplayNameValue(GENDER);

export const HBA1C_RESULT_UNIT_DEFAULT: Hba1cUnit = 'MMOL';
export const HBA1C_RESULT_UNIT = Map<Hba1cUnit, string>().set('MMOL', 'mmol').set('PERCENTAGE', '%');
export const HBA1C_RESULT_UNIT_CHOICES = transformMapToValueLabel(HBA1C_RESULT_UNIT);
export const HBA1C_RESULT_UNIT_CHADMIN_CHOICES = transformMapToDisplayNameValue(HBA1C_RESULT_UNIT);

export const HEIGHT_UNIT_DEFAULT: DisplayUnit = 'CM';
export const HEIGHT_UNIT = Map<DisplayUnit, string>().set('CM', 'cm').set('INCHES', 'feet/inches');
export const HEIGHT_UNIT_CHOICES = transformMapToValueLabel(HEIGHT_UNIT);
export const HEIGHT_UNIT_CHADMIN_CHOICES = transformMapToDisplayNameValue(HEIGHT_UNIT);

export const WEIGHT_UNIT_DEFAULT: DisplayUnit = 'KG';
export const WEIGHT_UNIT = Map<DisplayUnit, string>().set('KG', 'kg').set('POUND', 'stone/pounds');
export const WEIGHT_UNIT_CHOICES = transformMapToValueLabel(WEIGHT_UNIT);
export const WEIGHT_UNIT_CHADMIN_CHOICES = transformMapToDisplayNameValue(WEIGHT_UNIT);

export const DIABETES_TYPE_NAMES = {
  TYPE_1: 'TYPE_1',
  TYPE_2: 'TYPE_2'
};

export const DIABETES_TYPE = Map({
  [DIABETES_TYPE_NAMES.TYPE_1]: 'Type 1',
  [DIABETES_TYPE_NAMES.TYPE_2]: 'Type 2'
});

export const MEDICATION_TYPE = Map({
    DIET_AND_EXCERCISE: 'Diet and Exercise',
    INSULIN_INJECTION: 'Insulin Injection',
    INSULIN_PUMP: 'Insulin Pump',
    OTHER_INJECTION: 'Other Injection',
    TABLET: 'Tablet',
});

export const MEDICATION_TYPE_CHOICES = transformMapToValueLabel(MEDICATION_TYPE);
export const MEDICATION_TYPE_CHADMIN_CHOICES = transformMapToDisplayNameValue(MEDICATION_TYPE);

export const DIABETES_TYPE_CHOICES = transformMapToValueLabel(DIABETES_TYPE);
export const DIABETES_TYPE_CHADMIN_CHOICES = transformMapToDisplayNameValue(DIABETES_TYPE);

export const MONTHS = generateMonths();
export const MONTHS_CHOICES = transformMapToValueLabel(MONTHS);
export const MONTHS_CHADMIN_CHOICES = transformMapToDisplayNameValue(MONTHS);

export const YEARS = generateYears(100);
export const YEARS_CHOICES = transformMapToValueLabel(YEARS);

export const FILTER_OPTIONS_READABLE = Map({
  NOT_FILTERED: '--',
  YES: 'Yes',
  NO: 'No'
});

export const BLANK_NOT_FILTERED: {value: GeneralOptions, label: string} = {
  value: 'NOT_FILTERED',
  label: 'Not filtered'
}

export const YES_NO_CHOICES = [
  {
    value: 'YES',
    label: 'Yes'
  },
  {
    value: 'NO',
    label: 'No'
  }
];

export const INCLUDE_CHOICES_READABLE: Dict<string> = {
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
  OPTIONAL: 'Optional'
};

export const INCLUDE_CHOICES = [
  {
    value: 'INCLUDE',
    label: 'Include'
  },
  {
    value: 'EXCLUDE',
    label: 'Exclude'
  },
  {
    value: 'OPTIONAL',
    label: 'Optional'
  }
];

export function transformMonthAndYearToDateDisplay(month: string | null, year: string | null): string {
  const monthAsIntOrNull = month && parseInt(month, 10);
  const yearAsIntOrNull = year && parseInt(year, 10);

  if (monthAsIntOrNull) {
    const monthDisplay = MONTHS.get(`${monthAsIntOrNull}`);
    if (yearAsIntOrNull) {
      return `${monthDisplay} ${yearAsIntOrNull}`;
    } else {
      return `${monthDisplay}`;
    }
  } else {
    if (yearAsIntOrNull) {
      return `${yearAsIntOrNull}`;
    } else {
      return '-';
    }
  }
}
