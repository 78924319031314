import {fromJS} from 'immutable';
import * as React from 'react';

import GenericDetail from '^/components/chadmin/generic-detail';
import { PermissionHOCChildProps } from '^/permissions/permissions-hoc';

const DETAIL_FIELDS = fromJS([
  {
    fieldClassNames: [['column sm-6', 'column sm-6']],
    elements: [['name', 'active']],
  }
]);

const FORM_NAME = 'CLASS_DETAIL';

const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  }
});

const MESSAGES = {
  edit: 'medication.list.class.edit'
};

// tslint:disable-next-line:no-unused-variable
interface IExternalProps extends PermissionHOCChildProps {
  params: {
    id: string;
  };
}

export default class Detail extends React.PureComponent<IExternalProps, void> {
    public render() {
        return (
            <GenericDetail
                formName={FORM_NAME}
                messages={MESSAGES}
                params={this.props.params}
                detailFields={DETAIL_FIELDS}
                detailFieldOptions={DETAIL_FIELD_OPTIONS}
                onCancelLinkTo={'/app/medications/class/'}
                itemsName={'medications/class'} />
        );
    }
}
