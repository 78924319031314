import * as copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';

interface IProps {
  Component?: string;
  className: string;
  value: string;
  title: string;
}

export default class CopyToClipboard extends React.PureComponent<IProps, void> {
  public render () {
    const {
      Component = 'a',
      children,
      className,
      title,
    } = this.props;

    return (
      <Component className={className} onClick={this.copyValueToClipboard} title={title}>
        {children}
      </Component>
    );
  }

  private copyValueToClipboard = () => {
    copyToClipboard(this.props.value);
  }
}
