import { Column, Row, Well } from '@dabapps/roe';
import { fromJS, List, Map, OrderedMap } from "immutable";
import * as React from "react";
import { FontAwesome } from 'react-inline-icons';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../../collections/types';
import { MEDICATION_TYPE } from '../../../consts/constants';
import { collectionsModule, ICollectionsState} from '../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from "../../../store";
import GenericList from '../../chadmin/generic-list';

const { actions: { getCollection } } = collectionsModule;
const { IconLightbulbO, IconMedkit } = FontAwesome;

const LIST_COLUMNS = fromJS([
    { name: ['medication', 'med_type'], valueMap: MEDICATION_TYPE, display_name: 'Medicine type', type: 'valuemap' },
    { name: ['medication', 'trade_name'], display_name: 'Medicine name', type: 'string' },
    { name: ['medication', 'generic_name_display', 'name'], display_name: 'Generic name', type: 'string' },
    { name: ['frequency_display', 'name'], display_name: 'Frequency', type: 'string' },
    { name: ['total_dosage'], display_name: 'Total dose', type: 'string' },
    { name: ['medication', 'unit_display', 'short_name'], display_name: 'Unit', type: 'string' },
]);

const COLLECTION_NAME = 'MEDICATIONS';
const COLLECTION_KEY = 'users/volunteers/medications'

interface IProps {
  id: string;
  path?: string;
  tip?: string;
}

export default class VolunteerMedicationsList extends React.PureComponent<IProps, void> {
    public render() {
        const { id, tip, path } = this.props;
        return (
          <Well className="padding-base margin-large">
            <h6>
              <IconMedkit className="icon-medium-large"/> Medications {Boolean(path) &&
              <Link to={`/app/profile/edit/${id}/${path}`}>Edit</Link>}
            </h6>
            <Well className="padding-base inner-well">
            <GenericList
                collectionColumns={LIST_COLUMNS}
                collectionName={COLLECTION_NAME}
                collectionKey={COLLECTION_KEY}
                collectionOverrideUrl={`users/volunteers/${id}/medications`}/>
            </Well>
            {Boolean(tip) && <p><IconLightbulbO className="icon-medium-large"/>{tip}</p>}
          </Well>
        );
    }
}
