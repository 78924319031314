import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Button, Column, Row } from '@dabapps/roe';
import { Location } from 'history';
import { fromJS, Map } from 'immutable';
import * as moment from 'moment';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { Link } from "react-router";
import { GET_COLLECTION } from '../../../collections/actions';
import { getCollectionByName } from '../../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../../collections/types';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import { collectionsModule } from '../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from "../../../store";
import { IVolunteerListRecord } from '../../../store/data-types';
import PageHeader from '../../page-header';
import ListFilterForm, { IFormData } from './list-filter-form';
import VolunteersList from './volunteers-list';

const { actions: { getCollection } } = collectionsModule;

const COLLECTION_NAME: string = 'VOLUNTEERS_LIST';
const PAGE_SIZE = 15;

interface ISetProps { // tslint:disable-line
  filters: IFormData | null;
}

interface IExternalProps extends PermissionHOCChildProps {
  loading: boolean;
  response: IResponseStatus;
  location?: Location;
}

interface IDispatchProps {
  getCollection: typeof getCollection;
}

type SetProps = SetPropsInterface<ISetProps>;
type Props = SetProps & IDispatchProps & IExternalProps & InjectedIntlProps;

export class VolunteersFilter extends React.PureComponent<Props, {}> {

  public constructor(props: Props) {
    super(props);
    this.getCollection = this.getCollection.bind(this);
  }

  public render() {
    const { permissions, user, location } = this.props;
    return (
      <div>
        <Row>
          <Column xs={12} sm={4} md={3}>
            <div className='side-panel'>
              <h2>Search</h2>
              <ListFilterForm
                defaultValues={location}
                response={this.props.response}
                onSubmit={this.changeFilters.bind(this)}
                isAnonymized={permissions.isAnonymized !== undefined ? permissions.isAnonymized : false}
              />
            </div>
          </Column>
          <Column xs={12} sm={8} md={9}>
            <PageHeader header='Volunteers'>
              {permissions.canCreate &&
                <Link to='/app/volunteers/create/' className='button primary'>
                  <FormattedMessage id="generic.new_volunteer" />
                </Link>
              }
            </PageHeader>

            <VolunteersList
              permissions={permissions}
              user={user}
              location={location}
              pushRoute={this.props.pushRoute}
              filters={this.props.filters}
            />

          </Column>
        </Row>
      </div>
    );
  }

  public getCollection(options: ICollectionOptions) {
    const newFilters = (options.filters || Map<string, string>());

    return this.props.getCollection(
      'users/volunteers',
      {
        filters: newFilters,
        page: options.page || 1,
        ...options,
      },
      COLLECTION_NAME
    );
  }

  private changeFilters(data: IFormData) {
    this.props.setProps({filters: data});
    this.getCollection({
      filters: fromJS(data),
      pageSize: PAGE_SIZE
    });
  }
}

function mapStateToProps({ collections, responses }: IStore, props: Props) {

  return {
    ...props,
    loading: isPending(responses, GET_COLLECTION, 'users/volunteers'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users/volunteers')
  };
}

const VolunteersFilterIntl = injectIntl(VolunteersFilter);

const connected = connect<{}, IDispatchProps, SetProps & IExternalProps>(mapStateToProps, {
  getCollection,
})(VolunteersFilterIntl);

function getInitialProps () {
  return {
    filters: null
  };
}

export default withSetProps<ISetProps, IExternalProps>(getInitialProps)(connected);
