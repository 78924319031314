import * as React from 'react';

import { WrappedFieldProps } from '@types/redux-form';
import { Map } from 'immutable';

import { IConfig, IWrappedFieldProps } from './field-types/types';

import PostcodeLookup from '../../../forms/postcode-lookup';
import FieldBoolean from './field-types/FieldBoolean';
import FieldChar from './field-types/FieldChar';
import FieldDate from './field-types/FieldDate';
import FieldFile from './field-types/FieldFile';
import FieldImage from './field-types/FieldImage';
import FieldMany from './field-types/FieldMany';
import FieldNumber from './field-types/FieldNumber';
import FieldSelect from './field-types/FieldSelect';
import FieldStatic from './field-types/FieldStatic';

function renderField(
  fieldConfig: Map<string, any>,
  fieldProps: IWrappedFieldProps
) {
  switch (fieldConfig.get('type')) {
    case 'date':
      return <FieldDate {...fieldProps} />;
    case 'datetime':
      return <FieldDate {...fieldProps} isDateTime />;
    case 'image upload':
      return <FieldImage {...fieldProps} />;
    case 'file upload':
      return <FieldFile {...fieldProps} />;
    case 'integer':
    case 'float':
      return <FieldNumber {...fieldProps} />;
    case 'boolean':
      return <FieldBoolean {...fieldProps} />;
    case 'static':
      return <FieldStatic {...fieldProps} />;
    case 'many':
      return <FieldMany {...fieldProps} />;
    case 'postcode-lookup':
      return (
        <PostcodeLookup
          formName={fieldProps.formName}
          autofillFields={fieldConfig.get('autofill_fields').toJS()}
          columnClass='column md-12'
          isBlock={true}
        />
      )
    default:
      if (fieldConfig.get('choices')) {
        return <FieldSelect {...fieldProps} />;
      } else {
        return <FieldChar {...fieldProps} />;
      }
  }
}

interface IFieldProps<S> extends WrappedFieldProps<S> {
  isEditing?: boolean;
  fieldConfig: Map<string, any>;
  formName: string;
  readOnly: any;
  errors: any;
  setUpload?(file: File): void;
}

export const Field = (props: IFieldProps<{}>) => {
  const { readOnly, fieldConfig, input, errors } = props;

  // TODO: make sure errors are passed in here see below comment
  const fieldProps = {
    ...props,
    ...input,
    config: fieldConfig.toJS() as IConfig,
    errors: errors && errors.get(input && input.name),
    readOnly: readOnly || fieldConfig.get('readOnly'),
  };

  const renderedField = renderField(fieldConfig, fieldProps);

  const { help_text } = fieldConfig.toObject();

  if (help_text) {
    return (
      <div>
        <p className="admin-helptext">
          {help_text}
        </p>
        {renderedField}
      </div>
    );
  }

  return renderedField;
};

export default Field;
