import * as React from 'react';

import { FieldGroup } from './FieldGroup';
import { IWrappedFieldProps } from './types';

export const FieldSelect = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
  className
}: React.HTMLProps<JSX.Element> & IWrappedFieldProps) => {
  return (
    <FieldGroup className={className} config={config} errors={errors}>
      <input
        type="checkbox"
        checked={value}
        name={name}
        onChange={onChange}
        disabled={readOnly}
      />
    </FieldGroup>
  );
};

export default FieldSelect;
