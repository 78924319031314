import {List, Map, Stack} from 'immutable';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { setSimpleComponentState } from '../../../../actions/actions';
import { GET_COLLECTION } from '../../../../collections/actions';
import { getCollectionByName } from '../../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../../../collections/types';
import { collectionsModule, ICollectionsState, } from '../../../../reducers/collections';
import { getResponseStatus, IResponseStatus, isPending } from '../../../../responses';
import { IStore } from "../../../../store";
import { IMedicationRecord, INamedActiveObjectRecord, IVolunteerMedicationRecord} from "../../../../store/data-types";
import VolunteerMedicationForm from './edit-volunteer-medication-form';

const { actions: { getCollection } } = collectionsModule;

import {
  Column,
  FormGroup,
  Row,
} from '@dabapps/roe';

interface IValueCollections {
  genericnames: List<INamedActiveObjectRecord>;
  types: List<string>;
  frequencies: List<INamedActiveObjectRecord>;
  medications: List<IMedicationRecord>;
}

interface IExternalProps extends IValueCollections {
  form: string;
  volunteerMedication: IVolunteerMedicationRecord;
  onRemove: (id: string) => void;
}

interface ICollectionProps extends IExternalProps {
  changesStack: Stack<List<string>>;
  getCollection: typeof getCollection;
  setSimpleComponentState: typeof setSimpleComponentState;
  selectorValues: any;
}

function getComponentStoreKey(props: IExternalProps): [string] {
  return ['volunteerMedicationForm', props.form];
}

export class FormWrapper extends React.PureComponent<ICollectionProps, {}> {

  public componentWillReceiveProps(newProps: ICollectionProps) {
    this.filterFieldOptions(newProps)
  }

  public render() {
    return (
      <VolunteerMedicationForm
        onRemove={this.props.onRemove}
        formValues={this.props.selectorValues}
        onFieldChange={(field: string, value: string) => this.onFieldChange(field, value)}
        initialValues={this.getInitialValues()}
        medications={this.props.medications}
        frequencies={this.props.frequencies}
        genericnames={this.props.genericnames}
        types={this.props.types}
        form={this.props.form} />
    );
  }

  public onFieldChange(field: string, value: string) {
    const componentKey = getComponentStoreKey(this.props).toString();
    const fieldValues = List([field, value]);
    const updatedStack = this.props.changesStack.push(fieldValues);
    this.props.setSimpleComponentState(componentKey, updatedStack);
  }

  public getInitialValues() {
    const med = this.props.volunteerMedication;

    return {
      id: med.id,
      total_dosage: med.total_dosage,
      medication: med.medication.id,
      generic_name: med.medication.generic_name,
      frequency: med.frequency,
      med_type: med.medication.med_type,
    }
  }

  public filterFieldOptions(newProps: ICollectionProps) {
    const {
      form,
      selectorValues
    } = this.props;
    const newSelectorValues = newProps.selectorValues;
    if (!Object.keys(selectorValues).length) {
      return;
    }
    if (!this.props.changesStack.equals(newProps.changesStack)) {
      const lastSelectedFields = newProps.changesStack.slice(0, 2).reverse();
      const fieldFilters = Map(lastSelectedFields).filter((value, key) => value !== '');
      this.props.getCollection(
        'medications/medication',
        {
          filters: fieldFilters,
          pageSize: 150
        },
        getCollectionName(form, 'medications/medication')
      );
    }
  }
}

function getCollectionName(formName: string, collectionKey: string): string {
  return `volunteer-medication-${collectionKey}-${formName}`;
}

function mapStateToProps( store: IStore, props: IExternalProps) {
  const { collections, responses } = store;
  const { form } = props;

  const getCollectionOrDefault = <T extends (INamedActiveObjectRecord | IMedicationRecord)>(collectionKey: keyof ICollectionsState , defaultValues: List<T>): List<T> => {  // tslint:disable-line
    // Returns either the filtered collection of this component, or the collection will all options passed from parent component
    const c = collections[collectionKey];
    const collectionName = getCollectionName(form, collectionKey);
    const { results, filters } = getCollectionByName(
      c as Map<string | undefined, ICollection<T>>,
      collectionName
    );
    if (filters) {
      return results;
    }
    return defaultValues;
  }
  const componentStoreKey = getComponentStoreKey(props);
  const medications = getCollectionOrDefault('medications/medication', props.medications);
  const types = medications.map((med: IMedicationRecord) => med.get('med_type')).toSet().toList();

  let genericnames = medications.map((med: IMedicationRecord) => med.get('generic_name_display'));
  genericnames = genericnames.filter((a, i) => genericnames.findIndex((b) => b.id === a.id) === i);
  const filterCollections = {
    frequencies: props.frequencies,
    genericnames,
    medications,
    types,
  }
  const selector = formValueSelector(form);
  const selectorValues = selector(
    store,
    'med_type',
    'medication',
    'generic_name',
  );
  const changesStack =
    store.simpleComponentState.get(componentStoreKey.toString(), Stack());

  return {
    changesStack,
    selectorValues,
    ...props,
    ...filterCollections
  }

}

export default connect(mapStateToProps,
  {
    getCollection,
    setSimpleComponentState
  }
)(FormWrapper);
