'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _isPristine = require('./isPristine');

var _isPristine2 = _interopRequireDefault(_isPristine);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createIsDirty = function createIsDirty(structure) {
  return function (form, getFormState) {
    var isPristine = (0, _isPristine2.default)(structure)(form, getFormState);
    return function (state) {
      return !isPristine(state);
    };
  };
};

exports.default = createIsDirty;