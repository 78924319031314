import { Button, Column, FormGroup, Row, SpacedGroup } from '@dabapps/roe';
import * as classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { addResearchersToSite, removeResearcher } from '../../actions/sites';
import { GET_COLLECTION } from '../../collections/actions';
import { getCollectionByName } from '../../collections/reducers';
import { ICollection, ICollectionOptions } from '../../collections/types';
import { collectionsModule, ICollectionsState, } from '../../reducers/collections';
import { itemsModule, ItemType } from '../../reducers/items';
import { TResearchersList } from '../../reducers/sites';
import { getResponseStatus, IResponseStatus, isPending } from '../../responses';
import { IStore } from '../../store';
import { ISiteRecord, IUserRecord, UserRecord } from '../../store/data-types';
import { ReactSelectWrapper } from '../forms/react-select-wrapper';
import ISitesDetail from './sites-detail-fields';

const { actions: { getAllCollection } } = collectionsModule;
const { actions: { loadItem } } = itemsModule;

const COLLECTION_NAME = 'SITES';
const RESEARCHERS_COLLECTION = 'RESEARCHERS';
const DETAIL_FIELDS = fromJS([
  'name',
  'radius',
  'address_1',
  'address_2',
  'county',
  'city',
  'postcode',
  'active',
]);

const FORM_NAME = 'SITES_DETAIL_LIST';

const DETAIL_FIELD_OPTIONS = fromJS({
  name: {
    label: 'Name',
    max_length: 255,
    type: 'text',
  },
  radius: {
    label: 'Radius',
    max_length: 255,
    type: 'text',
  },
  active: {
    label: 'is Active?',
    type: 'boolean',
  },
  address_1: {
    label: 'Address line 1',
    max_length: 255,
    type: 'text',
  },
  address_2: {
    label: 'Address line 2',
    max_length: 255,
    type: 'text',
  },
  city: {
    label: 'City',
    max_length: 255,
    type: 'text',
  },
  county: {
    label: 'County',
    max_length: 255,
    type: 'text',
  },
  postcode: {
    label: 'Postcode',
    max_length: 255,
    type: 'text',
  }
});

const MESSAGES = {
  edit: 'sites.list.edit'
};

interface IValueCollections {
  users: List<IUserRecord>;
}

interface IDispatchProps {
  addResearchersToSite: typeof addResearchersToSite;
  removeResearcher: typeof removeResearcher;
  getAllCollection: typeof getAllCollection;
  loadItem: typeof loadItem;
}

export interface IExternalProps {
  response: IResponseStatus;
  siteResearcher: Map<string, TResearchersList>;
  itemsName: ItemType;
  params: {
    id: string;
  };
}

interface IStateProps extends IValueCollections {
  loading: boolean;
}

type Props = IStateProps & IValueCollections & IDispatchProps & IExternalProps;

export class SiteResearcherList extends React.PureComponent<Props, {}> {
  public componentWillMount() {
    this.loadAllOptions();
  }
  public render() {
    const { isLoading, formErrors, hasSucceeded } = this.props.response;
    const { id } = this.props.params;
    return (
      <div>
        <p className="border-bottom"><strong><FormattedMessage id="sites.detail.researchers" /></strong></p>
          <Row>
            {
              this.props.siteResearcher.toList().flatten().map((siteResearcher: IUserRecord) => (
                <Column md={6} className="site-researchers" key={siteResearcher.get('id')}>
                  <Row className="roe-sites">
                    <Column md={8} className={'text-align-left'}>
                      {siteResearcher.get('display_full_name')}
                    </Column>
                    <Column md={4}>
                    <Button
                      className="button light"
                      onClick={() => {
                        this.props.removeResearcher(siteResearcher.get('id'), this.props.params.id);
                      }}
                    >
                    <FormattedMessage id="generic.remove"/>
                    </Button>
                    </Column>
                  </Row>
                </Column>
              ))
            }
          </Row>
          <FormGroup>
          <p className="border-top-sites"><FormattedMessage id="sites.add_researchers"/></p>
          <Field
            component={ReactSelectWrapper}
            simpleValue
            clearable={false}
            className="select"
            placeholder='Type name/email to search...'
            name='siteResearcher'
            onChange={this.onChangeUser}
            options={this.props.users.map((entry: IUserRecord) => {
            return {value: entry.get('id'), label: entry.get('name_email_dropdown')}
            }).toArray()}
          />
        </FormGroup>
      </div>
    );
  }
  private loadAllOptions() {
    this.props.getAllCollection(
      'users',
      {
        filters:  Map({ type: 'RESEARCHER' }),
        pageSize: 150,
      },
      'users'
    );
  }
  private onChangeUser = (e: React.ChangeEvent<string>, value: string) => {
    const selectedUser = this.props.users.find(
      (user: IUserRecord) => {
        return user.id === value;
    });
    if (selectedUser) {
      this.props.addResearchersToSite(
        selectedUser,
        this.props.params.id
      )
    }
  }
}

export function mapStateToProps(state: IStore, props: IExternalProps): IExternalProps | IStateProps {
  const selector = formValueSelector(FORM_NAME);
  const formValues = selector(
    state,
    'siteResearcher',
  ) || UserRecord();

  const { collections, responses, siteResearcher } = state;
  const { params: { id } } = props;
  const selectedIds = siteResearcher.get(id, List<IUserRecord>())
    .reduce((reducer: List<string>, user) => reducer.push(user.get('id')), List());

  const valueCollections: IValueCollections = {
    users:  getCollectionByName(collections.users, 'users').results
    .filterNot((user: IUserRecord) => selectedIds.includes(user.get('id')))
  }

  return {
    loading:  isPending(responses, GET_COLLECTION, 'users'),
    response: getResponseStatus(responses, GET_COLLECTION, 'users'),
    siteResearcher: siteResearcher.get(id, List()),
    ...valueCollections
  };
}

export default connect( mapStateToProps, {
  loadItem, addResearchersToSite, getAllCollection, removeResearcher})(
  reduxForm({
    form: 'SiteResearcherList',
  })(SiteResearcherList)
);
