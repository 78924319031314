import { Alert, Button, Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { defineMessages } from "react-intl"
import { FormattedMessage } from "react-intl"
import { Link } from 'react-router';
import { FormProps, reduxForm } from 'redux-form';
import { IResponseStatus } from '../../responses';
import FormErrorsRenderer from "../forms/form-errors-renderer";
import GroupField from '../forms/group-field';

export interface IFormData {
  confirm_password: string;
  current_password: string;
  password: string;
}

interface IProps {
  response: IResponseStatus;
  onSubmit(data: IFormData): void;
}

export class ChangePassword extends React.PureComponent<FormProps<IFormData, {}, {}> & IProps, void> {
  public render() {
    const {hasSucceeded, isLoading, formErrors} = this.props.response;

    const messages = defineMessages({
      confirm: {
          description: 'Login password label',
          id: 'pages.change-password.confirm',
      },
      current: {
          description: 'The current user password',
          id: 'pages.change-password.current',
      },
      new: {
          description: 'Login password label',
          id: 'pages.change-password.new',
      },
    });
    return (
      <Row>
        <Column sm={12} md={8} lg={6}>
          <form onSubmit={this.props.handleSubmit}>
              { hasSucceeded && (
                <Alert className="success">
                    <FontAwesome.IconCheck
                      className={'icon-small'}/> <FormattedMessage id="pages.change-password.success"/>
                </Alert>
              )}
            <GroupField
              block
              label={messages.current}
              name="current_password"
              type="password"
              disabled={isLoading}
              formErrors={formErrors}
            />
            <b><FormattedMessage id="pages.change-password.note"/>: </b>
            <FormattedMessage id="pages.change-password.help"/>
            <GroupField
              block
              label={messages.new}
              name="password"
              type="password"
              disabled={isLoading}
              formErrors={formErrors}
            />
            <GroupField
              block
              label={messages.confirm}
              name="confirm_password"
              type="password"
              disabled={isLoading}
              formErrors={formErrors}
            />
              <Button className="primary" type="submit" disabled={isLoading}>
                <FormattedMessage id='pages.change-submit'/>
              </Button>
              <Link to="/app/" className="cancel-link">
                <FormattedMessage id='generic.cancel'/>
              </Link>
            <FormErrorsRenderer formErrors={formErrors} errorKey="non_field_errors"/>
          </form>
        </Column>
      </Row>
    );
  }
}

export default reduxForm({
  form: 'change-password'
})(ChangePassword)
