import { Button, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';

export interface IProps {
  id?: string;
  openLoginModal(): void;
}

class CtaButtons extends React.PureComponent<IProps, {}> {
  public render() {
    const {
      openLoginModal,
      ...remainingProps
    } = this.props;

    return (
      <div {...remainingProps} className="landing-inset text-align-center margin-top-xxlarge margin-bottom-xxlarge">
        <SpacedGroup>
          <Button className="margin-bottom-base button-landing button-landing-register"
            onClick={this.props.openLoginModal}>Login now</Button>
          <a href="tel:08081696969"
            className="button button-landing button-landing-call margin-bottom-base">
            Got a question? Call free anytime on <strong>0808 169 66 66</strong></a>
        </SpacedGroup>
      </div>
    );
  }
}

export default CtaButtons;
