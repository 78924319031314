import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import {
  Alert,
  Button,
  Column,
  FormGroup,
  Row,
  Section,
  SpacedGroup,
} from '@dabapps/roe';
import { fromJS, List, Map, Set } from 'immutable';
import * as moment from 'moment'; // tslint:disable-next-line:no-unused-variable
import * as React from 'react';
import { FontAwesome } from 'react-inline-icons';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { getFormValues, reduxForm } from 'redux-form';

import { loadMarkers, saveStudyAndView } from '^/actions/studies';
import { LOAD_ITEM } from '^/items/actions';
import { IPoly, IVolunteerLocationRecord } from '^/store/data-types/studies';
import {
  addPolygon,
  addStudyLoadVolunteerCountsAndRedirectToDetailPage,
  addStudyLoadVolunteerCountsAndRedirectToSavePage,
  GET_VOLUNTEER_COUNTS,
  removePolygon,
  TRIGGER_VOLUNTEER_MATCHING,
  updateStudyAndLoadVolunteers,
  updateStudyRedirectToSavePage,
} from '../../../actions/studies';
import { ADD_TO_COLLECTION } from '../../../collections/actions';
import {
  getCollectionByName,
  getCollectionItems,
} from '../../../collections/reducers';
import {
  ICollection,
  ICollectionOptions,
} from '../../../collections/types';
import {
  BLANK_NOT_FILTERED,
  DIABETES_TYPE,
  HBA1C_RESULT_UNIT,
  INCLUDE_CHOICES_READABLE } from '../../../consts/constants';
import { PermissionHOCChildProps } from '../../../permissions/permissions-hoc';
import {
  collectionsModule,
  ICollectionsState,
} from '../../../reducers/collections';
import { itemsModule } from '../../../reducers/items';
import { getFormErrors, getResponseStatus, IResponseStatus, isPending } from '../../../responses';
import { IStore } from '../../../store';
import {
  ISiteRecord,
  IUserRecord,
  IVolunteerListRecord,
} from '../../../store/data-types';
import {
  FormData,
  ICircle,
  IStudyRecord,
  IStudySiteRecord,
  StudyRecord,
} from '../../../store/data-types/studies';
import { FormErrors } from '../../../utils';
import Collapse from '../../collapse';
import PageHeader from '../../page-header';
import CollectionTable from '../../tables/collection-table';
import { IColumnData } from '../../tables/simple-table';
import StudyDetails from '../details';
import GoogleMap from '../google-map';
import SaveForm, { FORM_NAME as SAVE_FORM_NAME } from '../save/form';
import { getCircles, getPolygons, getStudyErrors, resetStudyRequests } from '../utils';
import SearchForm, { CollapseIds, FORM_NAME as SEARCH_FORM_NAME } from './form';
import SitesSelection from './sites-selection';
import { convertFormDataForServer, convertServerDataForForm } from './utils';

const { IconExclamationTriangle } = FontAwesome;
const { actions: { getAllCollection } } = collectionsModule;
const { actions: { loadItem, updateItem } } = itemsModule;

const COLLECTION_NAME = 'STUDY_SEARCH';

const DEFAULT_INITIAL_VALUES: FormData = {
  study_medications: [{}],
  general: {
    tablets: BLANK_NOT_FILTERED.value,
    injection_insulin: BLANK_NOT_FILTERED.value,
    injection_other: BLANK_NOT_FILTERED.value,
  },
};

interface ISetProps {
  openCollapse: CollapseIds | null;
}

interface IDispatchProps {
  addStudyLoadVolunteerCountsAndRedirectToDetailPage: typeof addStudyLoadVolunteerCountsAndRedirectToDetailPage;
  addStudyLoadVolunteerCountsAndRedirectToSavePage: typeof addStudyLoadVolunteerCountsAndRedirectToSavePage;
  updateStudyAndLoadVolunteers: typeof updateStudyAndLoadVolunteers;
  updateStudyRedirectToSavePage: typeof updateStudyRedirectToSavePage;
  saveStudyAndView: typeof saveStudyAndView;
  getAllCollection: typeof getAllCollection;
  loadItem: typeof loadItem;
  updateItem: typeof updateItem;
  addPolygon: typeof addPolygon;
  removePolygon: typeof removePolygon;
  replace: typeof replace;
  loadMarkers: typeof loadMarkers;
}

interface IStateProps {
  active: boolean;
  study: IStudyRecord | null;
  studyLoading: boolean;
  criteriaSummaryText: string;
  siteSummaryText: string;
  formErrors: FormErrors;
  initialValues: FormData | undefined;
  searchFormData: FormData;
  saveFormData: FormData;
  sites: List<ISiteRecord>;
  clinicalResearchers: List<IUserRecord>;
  studySites: List<IStudySiteRecord>;
  addResponse: IResponseStatus;
  circles: Array<null | ICircle>;
  polygons: Array<null | google.maps.LatLng[]>;
  volunteerMarkers: google.maps.LatLng[];
}

interface IOwnProps extends PermissionHOCChildProps {
  params: {
    id?: string;
  };
}

type Props = SetPropsInterface<ISetProps> & IDispatchProps & IStateProps & IOwnProps;

const MESSAGES = {
  saveSearch: 'studies.save_search',
  title: 'studies.search_title',
  addCriteria: 'studies.add_criteria',
  edit: 'studies.edit',
};

export class StudySearch extends React.PureComponent<Props, void> {
  private toggleCriteriaCollapse: (Event: React.MouseEvent<HTMLElement>) => void;
  private toggleSitesCollapse: (Event: React.MouseEvent<HTMLElement>) => void;

  public constructor (props: Props) {
    super(props);

    this.toggleCriteriaCollapse = this.toggleCollapse.bind(this, 'criteria');
    this.toggleSitesCollapse = this.toggleCollapse.bind(this, 'sites');
  }

  public componentWillMount() {
    resetStudyRequests();

    this.props.getAllCollection('sites', {});
    this.props.getAllCollection('users', {filters: Map({type: 'RESEARCHER'})}, COLLECTION_NAME);
    this.props.getAllCollection('medications/medication');

    this.loadExistingStudy(this.props.params.id);
  }

  public componentWillReceiveProps (nextProps: Props) {
    const { params: { id } } = nextProps;

    if (id !== this.props.params.id) {
      this.loadExistingStudy(id);
    }
  }

  public render() {
    const {
      active,
      study,
      studyLoading,
      sites,
      circles,
      polygons,
      clinicalResearchers,
      studySites,
      openCollapse,
      initialValues,
      formErrors,
      volunteerMarkers,
      params,
    } = this.props;

    const displayResults = Boolean(params.id);

    return (
      <div>
        <PageHeader header={getPageHeaderText(studyLoading, study)} >
          {!(studyLoading || active) &&
            <a className="button primary" onClick={this.onSaveStudy}>
              <FormattedMessage id={MESSAGES.saveSearch} />
            </a>
          }
        </PageHeader>

        {
          study && active && (
            <SaveForm
              params={params}
              onSubmit={this.onSaveStudy}
              initialValues={initialValues}
              study={study}
            />
          )
        }

        {
          Boolean(formErrors && formErrors.get('errors', List()).count()) && (
            <Alert className="error">
              <IconExclamationTriangle className={'icon-small'} />
              {' '}
              Please fix the errors
            </Alert>
          )
        }

        {
          study && active && (
            <div>
              <hr />
              <StudyDetails
                hideSites
                study={study}
              />
            </div>
          )
        }

        <SearchForm
          hideCriteria={active}
          criteriaSummaryText={this.props.criteriaSummaryText}
          siteSummaryText={this.props.siteSummaryText}
          formErrors={formErrors}
          initialValues={initialValues}
          onSubmit={this.onUpdateSearch}
          openCollapse={active ? 'sites' : openCollapse}
          toggleCriteriaCollapse={this.toggleCriteriaCollapse}
          toggleSitesCollapse={this.toggleSitesCollapse}
        />

        {
          !displayResults && (
            <Section className="text-align-center">
              <strong>
                <FormattedMessage id={MESSAGES.addCriteria} />
              </strong>
            </Section>
          )
        }

        {
          displayResults && (
            <GoogleMap
              width="100%"
              height={500}
              markers={volunteerMarkers}
              circles={circles}
              polygons={polygons}
              allowCreatingPolygons
              onCreatePolygon={this.onCreatePolygon}
              onRemovePolygon={this.onRemovePolygon}
            />
          )
        }
      </div>
    );
  }

  private onCreatePolygon = (index: number, polygon: IPoly) => {
    const studySite = this.props.studySites.get(index);

    if (studySite && studySite.id) {
      const site = this.props.sites.find((item) => item.id === studySite.site);

      if (site && site.location) {
        this.props.addPolygon(studySite.id, polygon, site.location);
      }
    }
  }

  private onRemovePolygon = (index: number) => {
    const studySite = this.props.studySites.get(index);

    if (studySite && studySite.id) {
      this.props.removePolygon(studySite.id);
    }
  }

  private loadExistingStudy (id?: string) {
    if (id) {
      this.props.loadItem('studies', id);
      this.props.loadMarkers(id);
    } else {
      this.props.getAllCollection('users/volunteers/locations');
    }
  }

  private getFormData = () => this.props.active ? {
      ...this.props.saveFormData,
      study_sites: this.props.searchFormData.study_sites
    } : this.props.searchFormData

  private onSaveStudy = () => {
    const { active, saveFormData, searchFormData, params: { id } } = this.props;
    const formattedData = convertFormDataForServer(this.getFormData());

    if (id) {
      if (active) {
        this.props.saveStudyAndView(id, formattedData)
      } else {
        this.props.updateStudyRedirectToSavePage(id, formattedData);
      }
    } else {
      this.props.addStudyLoadVolunteerCountsAndRedirectToSavePage(formattedData);
    }
  }

  private onUpdateSearch = (formData: FormData) => {
    const { params: { id } } = this.props;
    const formattedData = convertFormDataForServer(formData);

    if (id) {
      this.props.updateStudyAndLoadVolunteers(id, formattedData);
    } else {
      this.props.addStudyLoadVolunteerCountsAndRedirectToDetailPage(formattedData);
    }
  }

  private toggleCollapse (id: CollapseIds, event: React.MouseEvent<HTMLSpanElement>) {
    event.preventDefault();

    const { openCollapse } = this.props;

    this.props.setProps({
      openCollapse: openCollapse === id ? null : id
    });
  }
}

export { StudySearch as UnconnectedStudySearch };

export function getPageHeaderText(studyLoading: boolean, study: IStudyRecord | null): string | JSX.Element {
  if (studyLoading) {
    return 'Loading...';
  }
  return study ? study.study_name : <FormattedMessage id={MESSAGES.title} />;
}

export function getCriteriaSummaryText(formDataRaw: FormData): string {
  const formData = fromJS(formDataRaw) || Map();
  const medicationCounts = formData.get('study_medications', List())
    .countBy((medication: Map<string, string>) => {
      return medication.get('include');
    });
  const validOptions = List(Object.keys(INCLUDE_CHOICES_READABLE));
  const medicationCountText = medicationCounts.filter((count: number, key: string) => {
    return count && validOptions.contains(key);
  }).reduce((reduction: List<string>, count: number, key: string) => {
    const keyText: string = INCLUDE_CHOICES_READABLE[key] || '';
    return reduction.push(`${keyText} - ${count}`)
  }, List()).join(', ');
  const diabetesType = formData.getIn(['general', 'diabetes_type'], null);
  const diabetesText = `Diabetes type: ${DIABETES_TYPE.get(diabetesType, '--')} `;
  const medicationText = `Medication filters: ${medicationCountText || '--'}`;
  if (!medicationCountText && !diabetesType) {
    return 'Not specified';
  }
  return diabetesText + medicationText;
}

export function getSiteSummaryText(formDataRaw: FormData): string {
  const formData = fromJS(formDataRaw) || Map();
  const sites = formData.get('study_sites', List());
  const sitesCount = sites.countBy((site: Map<string, string>) => {
    return site.get('green_lit', false);
  });
  const greenLit = sitesCount.get(true, 0);
  const total = sites.size;
  if (!total) {
    return 'Not specified';
  }
  return `${greenLit} of ${total} sites green lit`;
}

function mapStateToProps(
  state: IStore,
  props: IOwnProps & SetPropsInterface<ISetProps>
): IOwnProps & SetPropsInterface<ISetProps> & IStateProps {
  const { collections, responses, markers } = state;

  const study = state.items.studies;
  const active = study && study.active || false;

  const { params: { id } } = props;

  const formErrors = getStudyErrors(responses);

  const sites = getCollectionItems(collections.sites);
  const studySites = study && study.study_sites || List<IStudySiteRecord>();

  const volunteerLocations = getCollectionItems(collections.get('users/volunteers/locations')) || List();

  const circles = getCircles(studySites, sites);
  const polygons = getPolygons(studySites, sites);
  const searchFormData = getFormValues<FormData, IStore>(SEARCH_FORM_NAME)(state);
  const saveFormData = getFormValues<FormData, IStore>(SAVE_FORM_NAME)(state);
  const criteriaSummaryText = getCriteriaSummaryText(searchFormData);
  const siteSummaryText = getSiteSummaryText(searchFormData);

  const volunteerMarkers: google.maps.LatLng[] = props.params.id ?
      markers :
      volunteerLocations
        .map((user) => user.location)
        .toArray();

  return {
    ...props,
    active,
    study,
    initialValues: id && study ? convertServerDataForForm(study) : DEFAULT_INITIAL_VALUES,
    searchFormData,
    saveFormData,
    formErrors,
    clinicalResearchers:
      getCollectionItems(collections.get('users'), COLLECTION_NAME) ||
      List<IUserRecord>(),
    studyLoading: isPending(responses, LOAD_ITEM, 'studies'),
    addResponse: getResponseStatus(responses, ADD_TO_COLLECTION, 'studies'),
    criteriaSummaryText,
    siteSummaryText,
    sites,
    circles,
    polygons,
    studySites,
    volunteerMarkers: volunteerMarkers.filter((latLng) => latLng),
  };
}

const ConnectedComponent = connect(mapStateToProps, {
  getAllCollection,
  loadItem,
  addStudyLoadVolunteerCountsAndRedirectToSavePage,
  addStudyLoadVolunteerCountsAndRedirectToDetailPage,
  updateStudyAndLoadVolunteers,
  updateStudyRedirectToSavePage,
  updateItem,
  saveStudyAndView,
  addPolygon,
  removePolygon,
  replace,
  loadMarkers,
})(StudySearch);

function getInitialProps () {
  return {
    openCollapse: null
  };
}

const StudySearchWithSetProps = withSetProps<ISetProps, IOwnProps>(getInitialProps)(ConnectedComponent);

export default StudySearchWithSetProps;
