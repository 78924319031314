import * as classNames from 'classnames';
import * as React from 'react';
import { HTMLProps, PureComponent } from 'react';
import { RECRUITMENT_FLOW_LABELS, RECRUITMENT_FLOW_NAMES } from '../../../consts/constants';

// tslint:disable-next-line:interface-name
export interface SankeyDataNode {
  children: string[] | null;
  name: string | null;
  type: string;
  value: number;
}

export type SankeyData = SankeyDataNode[];

// tslint:disable-next-line:interface-name
interface SankeyNode {
  name: string;
  value: number;
  children: string[];
  hasChildren: boolean;
  type: string;
}

// tslint:disable-next-line:interface-name
interface SankeyLookupTable {
  [i: string]: SankeyNode[] | undefined;
}

// tslint:disable-next-line:interface-name
interface Props {
  width?: number;
  height?: number;
  data?: SankeyData;
  responsive?: boolean;
  getRef?: (element: HTMLDivElement) => any;
  colors?: string[];
}

// tslint:disable-next-line:interface-name
interface State {
  width: number;
  height: number;
  lookup: SankeyLookupTable;
}

// tslint:disable-next-line:interface-name
interface NodeProps {
  lookup: SankeyLookupTable;
  node: SankeyNode;
  parentHeight: number;
  parentValue: number;
  nodeWidth: number;
  index: number;
  rootNode?: boolean;
}

function scaleNodeHeight (parentHeight: number, parentValue: number, childValue: number) {
  const value = childValue / parentValue;

  if (!value) {
    return 0;
  } else if (value === 1) {
    return value * parentHeight;
  }

  return Math.round(
    (Math.atan((value - 0.5) * Math.PI) + Math.PI * 0.5) / Math.PI * parentHeight
  );
}

const GAP_HEIGHT = 5;

const theGoodTheBadAndTheNeutralClass = (nodeType: string) => {
  switch (nodeType) {
    case RECRUITMENT_FLOW_NAMES.PRESTUDY_CHECKED:
    case RECRUITMENT_FLOW_NAMES.MATCHED:
    case RECRUITMENT_FLOW_NAMES.ELIGIBLE:
    case RECRUITMENT_FLOW_NAMES.SUITABLE:
      return 'sankey-good';
    case RECRUITMENT_FLOW_NAMES.ARCHIVED_AFTER_MATCH:
    case RECRUITMENT_FLOW_NAMES.UNMATCHED:
    case RECRUITMENT_FLOW_NAMES.INELIGIBLE:
    case RECRUITMENT_FLOW_NAMES.NOT_ELIGIBLE:
    case RECRUITMENT_FLOW_NAMES.FAILED_PRESTUDY_CHECK:
    case RECRUITMENT_FLOW_NAMES.REQUEST_REMOVAL:
      return 'sankey-bad';
    case RECRUITMENT_FLOW_NAMES.VOLUNTEERS:
    case RECRUITMENT_FLOW_NAMES.RESEARCHER_REVIEW_REQUIRED:
    case RECRUITMENT_FLOW_NAMES.CONTACT_IN_PROGRESS:
    case RECRUITMENT_FLOW_NAMES.NOT_YET_PRESTUDY_CHECKED:
    case RECRUITMENT_FLOW_NAMES.PRESTUDY_CHECK:
    default:
      return 'sankey-neutral';
  }
}

const Node = ({
  rootNode,
  lookup,
  parentHeight,
  parentValue,
  nodeWidth,
  index,
  node: {
    name,
    hasChildren,
    children,
    value,
    type
  }
}: NodeProps): JSX.Element => {
  const height = scaleNodeHeight(parentHeight, parentValue, value);

  return (
    <li
      className="sankey-node"
      style={{height}}
    >
      <div
        className={classNames('sankey-node-wrapper', theGoodTheBadAndTheNeutralClass(type))}
        style={{width: nodeWidth}}
      >
        {!rootNode && <div className="sankey-node-link" />}
        <div className="sankey-node-label">
          {name}
        </div>
        <div className="sankey-node-bar" />
      </div>
      {
        hasChildren && (
          <div className="sankey-children">
            <ul>
              {
                children.map((childName, childIndex) => {
                  const childNodes = lookup[childName];

                  if (!childNodes || !childNodes.length) {
                    return null;
                  }

                  return childNodes.map((childNode) => {
                    return (
                      <Node
                        key={`${childName}:${childNode.name}`}
                        node={childNode}
                        lookup={lookup}
                        parentHeight={height}
                        parentValue={value}
                        nodeWidth={nodeWidth}
                        index={childIndex}
                      />
                    );
                  });
                })
              }
            </ul>
          </div>
        )
      }
    </li>
  );
};

export class SankeyChart extends PureComponent<Props, State> {
  private element?: HTMLDivElement;

  public constructor (props: Props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      lookup: this.createLookupTable(props.data),
    };
  }

  public componentWillReceiveProps (nextProps: Props) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        lookup: this.createLookupTable(nextProps.data),
      });
    }
  }

  public componentDidMount () {
    window.addEventListener('resize', this.onResizeWindow);

    this.onResizeWindow();
  }

  public componentWillUnmount () {
    window.removeEventListener('resize', this.onResizeWindow);
  }

  public render () {
    const {
      width: stateWidth,
      height: stateHeight,
      lookup,
    } = this.state;

    const {
      width = stateWidth,
      height = stateHeight,
      data,
    } = this.props;

    const nodeNames = Object.keys(lookup);
    const firstNodes = nodeNames.length >= 0 && lookup[nodeNames[0]];
    const firstNode = firstNodes && firstNodes[0];
    const breadth = this.findBreadth(lookup);
    const gaps = this.findGaps(lookup);
    const nodeWidth = Math.floor(width / breadth);
    const nodeArea = height - GAP_HEIGHT * gaps;

    return (
      <div
        className="sankey-chart"
        ref={this.getRef}
        width={width}
        height={height}
      >
        {
          firstNode && (
            <ul>
              <Node
                rootNode
                node={firstNode}
                lookup={lookup}
                parentHeight={nodeArea}
                parentValue={firstNode.value}
                nodeWidth={nodeWidth}
                index={0}
              />
            </ul>
          )
        }
      </div>
    );
  }

  private getRef = (element: HTMLDivElement) => {
    this.element = element;

    if (typeof this.props.getRef === 'function') {
      this.props.getRef(element);
    }
  }

  private onResizeWindow = () => {
    if (this.element && this.props.responsive) {
      const parent = this.element.parentElement;

      if (parent) {
        this.setState({
          width: parent.offsetWidth,
          height: parent.offsetHeight,
        });
      }
    }
  }

  private createLookupTable (data?: SankeyData) {
    if (!data) {
      return {};
    }

    return data.reduce((lookupTable: SankeyLookupTable, datum) => {
      const {
        type,
        name,
        children,
        value
      } = datum;

      const existingTypes = lookupTable[type];

      if (!existingTypes) {
        lookupTable[type] = [{
          name: `${name ? name : RECRUITMENT_FLOW_LABELS.get(type, type)} (${value})`,
          type,
          value,
          children: children || [],
          hasChildren: Boolean(children && children.length)
        }];
      } else {
        lookupTable[type] = existingTypes.concat({
          name: `${name ? name : RECRUITMENT_FLOW_LABELS.get(type, type)} (${value})`,
          type,
          value,
          children: children || [],
          hasChildren: Boolean(children && children.length)
        });
      }

      return lookupTable;
    }, {});
  }

  private findChildBreadth (lookupTable: SankeyLookupTable, node: SankeyNode) {
    const breadth = 1;
    let highestBreadth = 0;

    for (const childName of node.children) {
      const childNodes = lookupTable[childName];

      if (childNodes && childNodes.length) {
        for (const childNode of childNodes) {
          const childBreadth = this.findChildBreadth(lookupTable, childNode);

          if (childBreadth > highestBreadth) {
            highestBreadth = childBreadth;
          }
        }
      }
    }

    return breadth + highestBreadth;
  }

  private findBreadth (lookupTable: SankeyLookupTable) {
    let breadth = 0;

    for (const key in lookupTable) {
      if (Object.prototype.hasOwnProperty.call(lookupTable, key)) {
        const childNodes = lookupTable[key];

        if (childNodes && childNodes.length) {
          for (const childNode of childNodes) {
            const childBreadth = this.findChildBreadth(lookupTable, childNode);

            if (childBreadth > breadth) {
              breadth = childBreadth;
            }
          }
        }
      }
    }

    return breadth;
  }

  private findChildGaps(lookupTable: SankeyLookupTable, node: SankeyNode) {
    if (!node.hasChildren || node.children.length <= 1) {
      return 0;
    }

    const gaps = node.children.length - 1;
    let highestGaps = 0;

    for (const childName of node.children) {
      const childNodes = lookupTable[childName];

      if (childNodes && childNodes.length) {
        for (const childNode of childNodes) {
          const childGaps = this.findChildGaps(lookupTable, childNode);

          if (childGaps > highestGaps) {
            highestGaps = childGaps;
          }
        }
      }
    }

    return gaps + highestGaps;
  }

  private findGaps (lookupTable: SankeyLookupTable) {
    let gaps = 0;

    for (const key in lookupTable) {
      if (Object.prototype.hasOwnProperty.call(lookupTable, key)) {
        const childNodes = lookupTable[key];

        if (childNodes && childNodes.length) {
          for (const childNode of childNodes) {
            const childGaps = this.findChildGaps(lookupTable, childNode);

            if (childGaps > gaps) {
              gaps = childGaps;
            }
          }
        }
      }
    }

    return gaps;
  }
}
